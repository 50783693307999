import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Header from "../../comp/Header";
import FooterHub from "../../comp/FooterHub";
import NewFooter from "../../comp/NewFooter";

const VideoToMp3Screen = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Video to Mp3 Converter - a free multiple file converter for android.
        </title>

        <meta
          name="description"
          content="Video to Mp3 Converter supports multiple audio and video formats. It is having features like - a video trimmer, audio cutter, and audio merger. You can set ringtones for free."
        />
      </Helmet>
      <nav className="fixed z-50">
        <Header color="bg-black" page="app" />
      </nav>
      <main className="bg-black  text-white pt-20 bg-gradient-to-b from-[#020E3D]  to-[#06225F]">
        <div className="w-3/4 mx-auto">
          <div className="flex text-start items-center space-x-4 lg:space-x-10 ml-10 mt-10">
            <img
              loading={"lazy"}
              src="/images/icons/vmp3.png"
              alt="videoTo Mp3"
              className="w-20 lg:w-32"
            />
            <h1 className="text-xl md:text-5xl font-bold ">
              VIDEO TO MP3 CONVERTER
            </h1>
          </div>
          <div className="flex items-center  justify-center z-10 relative mt-10">
            <img
              loading={"lazy"}
              src="\images\videoToMp3Screen\vtom.png"
              alt="VIDEO TO MP3  CONVERTER"
              className="h-[200px] lg:h-full w-full "
            />
            {/* <img src="/images/videoToMp3Screen/iPhone 12 Pro Mockup copy 3@2x.png" alt="videoToMP3" className='w-64 bg-red-900'/>
           <img src="/images/videoToMp3Screen/iPhone 12 Pro Mockup copy 5@2x.png" alt="videoToMP3" className='w-64 bg-red-900'/>
           <img src="/images/videoToMp3Screen/iPhone 12 Pro Mockup@2x.png" alt="videoToMP3" className='w-64 bg-red-900'/> */}
          </div>
          <div
            className="h-[800px] md:h-[750px]  bg-gradient-to-t from-[#030C4A]  to-[#072B67] 
      py-10  rounded-[20px] md:rounded-[60px] md:-mt-16 -mt-10 relative z-10 
       hover:shadow-[0_0px_60px_1px_rgba(0,0,0,0.2)]    ease-out transition-all"
          >
            <h1 className="text-3xl font-bold text-center">About App</h1>
            <h1 className="text-center text-xs md:text-xl  px-8">
              Video to mp3 converter is a utilizable app for converting mp4
              video format files into mp3 and audio files. You can trim and cut
              video files merge audio, and{" "}
              <a
                href="/blogs/video-to-mp3-converter-for-android-mobile-app"
                className="text-blue-600 font-medium"
              >
                convert video to mp3
              </a>{" "}
              with brand new features, Also can `extract mp3 songs from HD and
              full HD videos. 𝗔𝗻 𝗲𝘅𝗰𝗲𝗹𝗹𝗲𝗻𝘁 𝗺𝗽3 𝗰𝗼𝗻𝘃𝗲𝗿𝘁𝗲𝗿 𝗮𝗻𝗱 𝘃𝗶𝗱𝗲𝗼 𝗰𝘂𝘁𝘁𝗲𝗿 𝗶𝘀 𝗮𝗹𝗹
              𝘀𝗲𝘁 𝘄𝗶𝘁𝗵 𝗙𝗮𝘃𝗼𝘂𝗿𝗮𝗯𝗹𝗲 𝗮𝘀𝗽𝗲𝗰𝘁𝘀: <br /> ● Convert multiple videos files
              simultaneously. <br /> ● Audio Cutter and Video Cutter for selected parts
              of files.
            </h1>
            <div className="grid grid-cols-2 gap-10 lg:gap-4 md:grid-cols-4 mx-auto mt-20">
              <div className="flex flex-col items-center justify-center">
                <div className="bg-gradient-to-t from-[#D41A77] flex items-center justify-center to-[#BC1242] w-14 md:w-24 h-14 md:h-24  rounded-full p-2">
                  <img
                    loading={"lazy"}
                    src="\images\videoToMp3Screen\audio ctr_audio mrgr.svg"
                    alt="Ringtones functionality"
                    className="w-10  md:w-14 text-center object-contain "
                  />
                </div>
                <h1 className="mt-4 font-bold w-44 text-sm md:text-xl text-center">
                  Ringtones <br /> functionality
                </h1>
              </div>
              <div className="flex flex-col items-center justify-center">
                <div className="bg-gradient-to-t from-[#D41A77] flex items-center justify-center to-[#BC1242] w-14 md:w-24 h-14 md:h-24  rounded-full p-2">
                  <img
                    loading={"lazy"}
                    src="\images\videoToMp3Screen\audio ctr_convert v to a.svg"
                    alt="Video to  mp3 converter"
                    className="w-10 md:w-14 text-white object-contain "
                  />
                </div>
                <h1 className="mt-4 font-bold w-44 text-sm md:text-xl text-center">
                  Video to <br /> mp3 converter
                </h1>
              </div>
              <div className="flex flex-col items-center justify-center">
                <div className="bg-gradient-to-t from-[#D41A77] flex items-center justify-center to-[#BC1242] w-14 md:w-24 h-14 md:h-24  rounded-full p-2">
                  <img
                    loading={"lazy"}
                    src="\images\videoToMp3Screen\audio ctr_trim audio.svg"
                    alt="Video  cutter"
                    className="w-10 md:w-14  text-white object-contain "
                  />
                </div>
                <h1 className="mt-4 h-4 lg:h-max font-bold w-44 text-sm md:text-xl text-center">
                  Video <br /> cutter
                </h1>
              </div>
              <div className="flex flex-col items-center justify-center">
                <div className="bg-gradient-to-t from-[#D41A77] flex items-center justify-center to-[#BC1242] w-14 md:w-24 h-14 md:h-24  rounded-full p-2">
                  <img
                    loading={"lazy"}
                    src="\images\videoToMp3Screen\audio ctr_trim video.svg"
                    alt="Audio Merger"
                    className="w-10 md:w-14 text-white object-contain "
                  />
                </div>
                <h1 className="mt-4 font-bold w-44 text-sm md:text-xl text-center">
                  Audio Merger
                </h1>
              </div>
            </div>
            <div className="flex lg:flex-row flex-col lg:space-x-20 items-center space-y-4 lg:space-y-0 justify-around lg:justify-center md:justify-between mt-4 lg:mt-20 pt-10 md:border-t  border-gray-500 mx-2 md:mx-32">
              <a
                href="https://tinyurl.com/Rareprob-mp3converter"
                target={"_blank"}
                rel="noreferrer"
              >
                <img
                  src="/images/homepage/google.webp"
                  alt="google"
                  className="w-32 hover:scale-105 mx-auto md:ml-0 mt-4 transition-all ease-linear   md:w-64 cursor-pointer "
                />
              </a>
              <div className="flex items-center lg:space-x-10 space-x-4 justify-center">
                <h1 className="text-sm lg:text-2xl  md:block font-bold">
                  <strong>1 M+</strong>
                  <br />
                  Installs
                </h1>
                <h1 className="text-sm lg:text-2xl  md:block font-bold">
                  <strong>4.5+</strong>
                  <br /> Rating
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="h-44"></div>

        <NewFooter color="bg-black/10" />
      </main>
    </>
  );
};

export default VideoToMp3Screen;
