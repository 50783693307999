import React from "react";
import { Link } from "react-router-dom";
import cover from "../Image/5 (6).jpg";
interface BlogContainerType {
  name: string;
  image: string;
  text: string;
  path: string;
  logo: string;
  altz: string;
  date: string;
}

const BlogContainer: React.FC<BlogContainerType> = ({
  name,
  image,
  text,
  path,
  logo,
  altz,
  date,
}) => {
  return (
    <Link
      to={path}
      className="bg-gray-200  xl:w-[280px] h-[510px]  px-4  py-3  rounded-3xl"
    >
      <div className="flex items-center justify-start">
        {logo !== "" && <img src={logo} alt="gallery icon" className="w-8" />}
        <h1 className="text-sm md:text-lg font-semibold ml-4">{name}</h1>
      </div>
      <div className="">
        <img
          loading={"lazy"}
          src={image}
          alt={altz}
          className="w-full md:w-full h-60 object-cover lg:object-fill rounded-xl mt-6"
        />
        <p className="mt-2 px-4 text-gray-600  inline-block  bg-opacity-50 ">
          {date}
        </p>
        <h1 className=" md:w-full text-sm md:text-[14px] font-semibold h-20 mt-3 text-center">
          {text}
        </h1>
        <button className=" rounded-lg mb-4  mx-auto items-center flex mt-0 bg-[#0a4a82]/90 hover:bg-white hover:text-[#0a4a82] text-white font-medium  px-4 py-2">
          Read more
        </button>
      </div>
    </Link>
  );
};

const NewBlogMusic = () => {
  return (
    <div>
      <div className="w-[90%] mx-auto place-items-center grid lg:grid-cols-3 xl:grid-cols-4 grid-col-1 gap-10 md:gap-28">
      <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image="https://img.rareprob.com/img/website/blogs_rb/introducing-the-best-video-and-audio-player-for-android-in-2024-cover.webp"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/best-video-audio-player-android-2024"
          date="30 July 2024"
          text="Introducing the Best Video and Audio Player for Android in 2024"
        />
        <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image="https://img.rareprob.com/img/website/blogs_rb/explore-the-best-powerful-online-music-players-in-india-in-2024-now-cover.webp"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/best-offline-and-online-music-players-in-india-for-2024"
          date="16 May 2024"
          text="Explore The Best Powerful Online Music Players in India In 2024 Now"
        />
        <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image="https://img.rareprob.com/img/website/blogs_rb/how-to-convert-your-favourite-music-videos-to-mp3-with-an-all-in-one-music-player-cover.jpg"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/convert-your-favourite-video-to-mp3-with-asd-music-player"
          date="29 Feb 2024"
          text="How to convert your favourite music videos to MP3 with an All-in-One Music Player"
        />
        <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image="https://img.rareprob.com/img/website/blogs_rocks/6-best-music-player-apps-in-india-2024-cover.jpg"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/6-best-music-player-apps-in-india-for-2024
          "
          date="17 Jan 2024"
          text="6 Best Music Player Apps in India 2024"
        />
        <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image="https://img.rareprob.com/img/website/blogs_rocks/top-3-best-music-players-xiaomi-users-must-try-in-2024-cover.jpg"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/5-best-music-player-apps-for-xiaomi-users-in-2024
          "
          date="16 Jan 2024"
          text="Top 3 Best Music Players Xiaomi users must try in 2024"
        />
        <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image="https://img.rareprob.com/img/website/blogs_rocks/best-offline-music-player-apps-for-android-and-ios-cover.jpg"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/best-offline-music-players-for-android-iphone
          "
          date="19 Dec 2023"
          text="Best Offline Music Player Apps for Android and iOS"
        />
        <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image="https://img.rareprob.com/img/website/blogs_rb/celebrate-christmas-vibes-with-the-best-music-of-all-year-cover.webp"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/best-christmas-music-of-all-time"
          date="12 Dec 2023"
          text="Celebrate Christmas Vibes with the Best Music of all Year"
        />
        <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image="https://img.rareprob.com/img/website/blogs_rb/best-free-mp3-music-player-for-android-in-2023-cover.jpg"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/top-free-music-player-apps-for-android-in-2023"
          date="29 Nov 2023"
          text="Best Free MP3 Music Player for Android in 2023"
        />
        <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image="https://img.rareprob.com/img/website/blogs_rb/best-android-music-application-in-2023-cover.jpg"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/best-android-offline-online-music-player-apps-2023"
          date="27 Nov 2023"
          text="Best Android Music Applications in 2023"
        />
        <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image={cover}
          logo="/images/icons/musicIcon.webp"
          path="/blogs/best-music-player-apps-android-2023"
          date="9 Nov 2023"
          text="Music player for Android: The 3 best apps"
        />
        <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image="https://img.rareprob.com/img/website/blogs_rb/coverImage.jpg"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/guide-to-creating-perfect-mood-boosting-playlist"
          date="17 Oct 2023"
          text="Guide to Creating the Perfect Mood-Boosting Playlist
          "
        />
        <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image="https://firebasestorage.googleapis.com/v0/b/rareprob-website.appspot.com/o/rareprob%2Fknow-how-to-add-lyrics-to-music-player-through-multiple-methods%2Fadd_lyrics.webp?alt=media&token=8d6fb732-cfe0-4e88-b527-c81d16040a33"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/know-how-to-add-lyrics-to-music-player-through-multiple-methods"
          date="22 Sep 2023"
          text="Know how to Add Lyrics to your Music Player through multiple methods
          "
        />
        <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image="https://img.rareprob.com/img/default/RareProbImg/imagesRare/Rare_bass-boost-sound-equalizer-usage-guide/equalizer_cover.webp"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/bass-boost-sound-equalizer-usage-guide"
          date="22 Aug 2023"
          text="What is Bass Boost & Sound Equalizer and how can you use it?
          "
        />
        <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image="https://img.rareprob.com/img/website/blogs/music/RelaxingSounds/Relax and Meditate with the best of relaxing Sounds_ Calm Sleep Music_1.webp"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/relax-meditate-best-relaxing-sounds-calm-sleep-music"
          date="26 March 2023"
          text="Relax and Meditate with the best of relaxing Sounds : Calm Sleep Music            "
        />
        <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image="https://img.rareprob.com/img/website/blogs/music/sixFeatures/cover image.webp"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/top-6-features-music-player-app"
          date="17 March 2023"
          text="Explore top 6 features you will love about ASD Rocks Music App              "
        />
        <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image="https://img.rareprob.com/img/website/blogs/music/music-lover/cover image.webp"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/music-lovers-explore-best-music-player-app-android-device"
          date="5 March 2023"
          text=" Music Lovers! Explore the Best Music Player App for Your Android Device              "
        />
        <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image="https://img.rareprob.com/img/website/blogs/music/new/carModeCover.webp"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/best-car-mode-music-app"
          date="26 Feb 2023"
          text=" Best car mode music app for an exciting & musical road trip "
        />
        <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image="https://img.rareprob.com/img/website/blogs/music/new/rEqualizerCover.webp"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/equalizer-better-sound-quality"
          date="19 Feb 2023"
          text=" How to manage Equalizer to play better sound quality Music?            "
        />
        <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image="https://img.rareprob.com/img/website/blogs/music/new/rare-musicdesignThemes.webp"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/customized-themes"
          date="12 Feb 2023"
          text=" Style up the music app with beautiful and customised themes              "
        />
        <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image="https://img.rareprob.com/img/website/blogs/music/SleepTimerBlog-cover.webp"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/sleep-timer"
          date="5 Feb 2023"
          text="How does a sleep timer help to enjoy Online and Offline Music? "
        />
        <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image="https://img.rareprob.com/img/website/blogs/music/main page1.webp"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/music-player-for-all-type-of-audio-and-video-formats"
          date="26 Jan  2023"
          text=" Free Android app to play Music with audio and video Formats "
        />
        <BlogContainer
          name={"Music Player "}
          altz="Music Player"
          image="https://img.rareprob.com/img/website/blogs/music/music thmb.jpg"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/best-music-player-App-for-android"
          date="9 Dec 2022"
          text="All you need to know about Best Music Player App | Music Rocks              "
        />
        <BlogContainer
          name={"Music Player"}
          altz="Music Player for all types of audio & video formats"
          image="https://img.rareprob.com/img/website/blogs/music/music-front.jpg"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/free-android-mp3-player-app"
          text="Step up your music experience with Music Rocks"
          date="30 Jan 2022"
        />
      </div>
    </div>
  );
};

export default NewBlogMusic;
