import React, { useEffect } from "react";
import FooterHub from "../comp/FooterHub";
import Header from "../comp/Header";
import { Helmet } from "react-helmet";
import NewFooter from "../comp/NewFooter";
const PrivacyPolicyScreen = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Rareprob | Privacy Policy</title>
      </Helmet>
      <nav>
        <Header color="bg-[#6B009C]" page="privacy" />
      </nav>
      <main id="start">
        <div className="pt-20 w-5/6 md:w-3/4 px-1  break-words md:px-0 m-auto text-gray-700 text-xl">
          <h1 className="mt-20 font-bold text-5xl text-gray-800">
            Privacy Policy
          </h1>
          <p className="mt-4 font-medium">Last Update: September 5, 2024</p>
          <p className="mt-4 font-medium">
          ASD Dev Video Player for All Format respects and protects users’ privacy. In this policy, <strong>pay attention to the bold letters.</strong> They hold significant importance to your rights and possible interests. Please read and understand the policy carefully before using our products and services
          </p>
          <p className="mt-4 font-medium">
          This page informs you of our policies regarding collecting, using, and disclosing personal data when you use our Products or Services and the choices you have associated with that data.
          </p>
          <p className="mt-4 font-medium">
          We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information under this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.
          </p>

        <h2 className="mt-10 text-gray-800 text-3xl font-bold">Overview of Privacy Policy</h2>
        <ol className="list-decimal pl-6 space-y-2">
  <li><a href="#OverviewofPrivacyPolicy">Overview of Privacy Policy</a></li>
  <li><a href="#Definitions">Definitions</a></li>
  <li><a href="#CollectionandUse">Collection and Use of User’s Personal Information</a></li>
  <li><a href="#ReasontoCollect">Reason to Collect User’s Information</a></li>
  <li><a href="#ShareTransferandPublicdisclosure">Share, Transfer and Public disclosure of User’s Personal Information</a></li>
  <li><a href="#StorageandProtection">Storage and Protection of User’s Personal Information</a></li>
  <li><a href="#UseofCookies">Use of Cookies</a></li>
  <li><a href="#DataProtectionOfficerDPO">Data Protection Officer (DPO)</a></li>
  <li><a href="#OurUser’sRights">Our User’s Rights</a></li>
  <li><a href="#UsersofCalifornia">Users of California</a></li>
  <li><a href="#EUUsers">EU Users</a></li>
  <li><a href="#UsersofBrazil">Users of Brazil</a></li>
  <li><a href="#UsersofVirginia">Users of Virginia</a></li>
  <li><a href="#DMCACopyrightPolicy">DMCA Copyright Policy</a></li>
  <li><a href="#Infringementpolicy">Infringement policy</a>
    <ol className="list-lower-alpha pl-6">
      <li><a href="#HowtoReportInfringement">How to Report Infringement</a></li>
    </ol>
  </li>
  <li><a href="#In-appAdvertisements">In-app Advertisements</a></li>
  <li><a href="#In-appPurchases">In-app Purchases</a></li>
  <li><a href="#LiabilityLimitation">Liability Limitation</a></li>
  <li><a href="#DisclaimerofWarranty">Disclaimer of Warranty</a></li>
  <li><a href="#CopyrightandTrademarks">Copyright and Trademarks</a></li>
  <li><a href="#PushNotifications">Push Notifications</a></li>
  <li><a href="#Retentionofdata">Retention of data</a></li>
  <li><a href="#DataRecoveryPolicy">Data Recovery Policy</a></li>
  <li><a href="#Securityofdata">Security of data</a></li>
  <li><a href="#OurPolicyOnDONOTTRACKSignalsUnderTheCaliforniaOnlineProtectionActCALOPPA">Our Policy On “DO NOT TRACK” Signals Under The California Online Protection Act (CALOPPA)</a></li>
  <li><a href="#ServiceProviders">Service Providers</a>
    <ol className="list-lower-alpha ml-8">
      <li><a href="#Disclaimer">Disclaimer</a>
        <ol className="list-lower-roman ml-8">
          <li><a href="#Analytics">Analytics</a></li>
          <li><a href="#Advertising">Advertising</a></li>
          <li><a href="#BehavioralRemarketing">Behavioral Remarketing</a></li>
        </ol>
      </li>
    </ol>
  </li>
  <li><a href="#Payments">Payments</a></li>
  <li><a href="#LinkstootherSites">Links to other sites</a></li>
  <li><a href="#ChildrensPrivacy">Children’s Privacy</a></li>
  <li><a href="#PrivacyPolicyChanges" id="Definitions">Privacy Policy Changes</a></li>
  <li><a href="#ContactUs">Contact Us</a></li>
</ol>

          <h2 className="mt-10 text-gray-800 text-3xl font-bold">
            Definitions
          </h2>
          <p>We have added this part so that users can easily understand the basics of the privacy policy.
          </p>
          <ul className="ml-10 mt-2">
            <li className="list-disc font-semibold pb-4">
             <h3>Service</h3> 
              <p className="font-normal">
              Service means games, mobile applications, other products (“Games”), and websites operated by ASD Dev Video Player for All Format.
              </p>
            </li>
            <li className="list-disc font-semibold pb-4">
             <h3>Personal Data </h3> <br />
              <p className="font-normal">
              Personal Data means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession)
              </p>
            </li>
            <li className="list-disc font-semibold pb-4">
             <h3> Usage Data</h3> <br />
              <p className="font-normal">
              Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
              </p>
            </li>
            <li className="list-disc font-semibold pb-4">
             <h3>Cookies
              </h3>  <br />
              <p className="font-normal">
              Cookies are small files stored on your device (computer or mobile device).
              </p>
            </li>
            <li className="list-disc font-semibold pb-4">
             <h3>Data Controller</h3>  <br />
              <p className="font-normal">
              Data Controller means the natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and how any personal information is, or is to be, processed. For this Privacy Policy, we are a Data Controller of your Personal Data.
              </p>
            </li>
            <li className="list-disc font-semibold pb-4">
            <h3>Data Processors (or Service Providers)</h3>   <br />
              <p className="font-normal">
              Data Processor (or Service Provider) means any natural or legal person who processes the data on behalf of the Data Controller. We may use the services of various Service Providers to process your data more effectively.
              </p>
            </li>
            <li className="list-disc font-semibold pb-4">
             <h3> Data Subject (or User)</h3> <br />
              <p className="font-normal">
              Data Subject is any living individual who is using our Service and is the subject of Personal Data.

              </p>
            </li>
            <li className="list-disc font-semibold pb-4">
           <h3>Data Protection Officer(DPO) </h3> <br />
              <p className="font-normal">
              A DPO is a designated person who is in charge of managing an organization's or company's data protection policy and making sure that data protection rules and laws—like the General Data Protection Regulation (GDPR) in the European Union—are followed. The DPO plays a critical role in safeguarding individuals' rights to privacy by making sure that personal data is handled safely and compliantly with the law.  
             </p>
            </li>
          </ul>
          <h2 className="text-3xl mt-10 font-bold text-gray-900">
          Collection and Use of User’s Personal Information
          </h2>
          <p className="mt-2 ">
          ASD Dev Video Player for All Format <strong>does not collect, store, or share</strong>  any personal information. The app requires certain permissions to function properly, but these permissions are strictly used to provide the service and are not used for any other purpose.
          </p>
         <p  className="mt-2 ">The different types of permissions we ask from users to offer the best of our products and services are- </p>
          <p className="mt-2 font-semibold">1. All File Access-</p>
          <p className="mt-2">
          We need this permission to provide our users with a comprehensive and seamless experience. This permission enables us to provide advanced features, making it simple for you to organize and enjoy your media effortlessly. 
          </p>
          <p className="mt-2">
          With <a href="https://support.google.com/googleplay/android-developer/answer/10467955?hl=en" className="text-blue-600 underline">ALL FILES ACCESS</a>  permission, the app can perform all functions of file management (e.g., deleting, renaming, moving media files), browse, read, manage, download, and display any format of video files, audio files, images, or any media files on the device without limiting the user to specific app folders. This permission lets the app gather all media files on your device and display them in one place. 
          </p>
          <p className="my-2">
          Users can open and play any video or audio file stored on their device without needing to manually move the file to a specific folder or provide additional access permissions. Users get multiple options to manage files within the app, such as:
          </p>
          <ul className="list-disc ml-8">
        <li>Deleting unwanted media files.</li>
        <li>Renaming files.</li>
        <li>Moving or copying files between directories.</li>
    </ul>
    <h2 className="mt-4 font-bold">ENCRYPTION AND DECRYPTION OF FILES</h2>
    <ul className="list-disc ml-8 mt-2">
        <li>
            <span className="font-bold">Encryption:</span> The app allows users to encrypt their media files for added security. For example, if a user wants to secure sensitive video files, the app can encrypt these files with an algorithm (e.g., AES-256 encryption). The ALL FILES ACCESS permission is necessary to locate the files, read their contents, and apply encryption.
        </li>
        <li className="mt-2">
            <span className="font-bold">Decryption:</span> When users want to access encrypted media, the app uses the ALL FILES ACCESS permission to locate the encrypted files, read their content, and decrypt them for playback within the app.
        </li>
        <li className="mt-2">
           <strong>All encryption and decryption processes are done locally on the device,</strong> ensuring that no personal data is transmitted to external servers or third parties.
        </li>
    </ul>
    <div className="mt-4">
        <span className="font-bold">2. Network-</span> This permission is required when you need to <strong>download any social media videos (using our Video Downloader feature), Network streaming, or watch online trending videos when your device is connected to WLAN or Mobile Data.</strong>  We do not collect or store any information through this but only perform the functions mentioned before.
    </div>
    <div className="mt-4">
        <span className="font-bold">3. Usage and Tracking Data-</span> This Usage Data may include information such as your computer’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data. It may also include information such as the type of mobile device you use, your mobile device's unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers, usage patterns, and other diagnostic data. We collect non-personal tracking information to help us improve the App and provide better services.
    </div>
    <div className="mt-4">
        <span className="font-bold">4. Microphone-</span> Microphone access is necessary for using the audio recording feature effectively. This permission is dedicated to recording audio only. Should you delete the audio files from your local storage, Rareprob will not and does not store any of that audio material.
    </div>
    <div className="mt-4">
        <span className="font-bold">5. Consumer Support and Follow-up Services-</span> When users send their feedback or error reports via email, we obtain your username, email address, and email content. This helps in communicating with you about any problems you face while using our product or service.
    </div>
    <div className="mt-4">
        <span className="font-bold">6. API-</span> The YouTube API has been incorporated into our software to provide you with smooth access to the platform's services. It's important to mention that if you use any of our services, a third party may be notified about which parts of our Platform you have operated. Under GDPR legislation, the use of these services is a legitimate interest as defined by Art. 6(1)(f) DSGVO. For more, check- <br/>
       <a href="https://www.youtube.com/t/terms" className='text-blue-700 underline'>https://www.youtube.com/t/terms</a> <br/>
       <a href="https://policies.google.com/privacy" className='text-blue-700 underline'>https://policies.google.com/privacy</a> <br/>
    </div>
    <div className="mt-4">
        <span className="font-bold" id="ReasontoCollect">6. Sensitive Personal Data-</span> We do not ask for or collect any Sensitive Personal Data or information such as passwords related to other services, financial information such as bank account or credit card, debit card, or other payment instrument details, physical, physiological, and mental health conditions, sexual orientation, medical records, and history, or biometric information.
    </div>


    <h2 className="text-3xl mt-10 font-bold text-gray-900">
    Reason to Collect User’s Information
    </h2>
    <p className="mt-2">
    HD Video Player All Formats uses the collected data for various purposes:</p>

    <ul className="list-disc ml-8 mt-2">
        <li className="mt-2">
        To provide and maintain our Service
        </li>
        <li>
        To notify you about changes to our Service
        </li>
        <li>
        To allow you to participate in interactive features of our Service when you choose to do so
        </li>
        <li>To provide customer support
        </li>
        <li>To gather analysis or valuable information so that we can improve our Service
        </li>
        <li>To monitor the usage of our Service</li>
        <li>To detect, prevent, and address technical issues</li>
        <li>To follow legal guidelines</li>
    </ul>
          <h2 className="text-3xl mt-10 text-black font-semibold">
            Use of data
          </h2>
          <p className="mt-2">
            ASD Dev Video Player for All Format uses the collected data for
            various purposes:
          </p>
          <li className="mt-4">To provide and maintain our Service</li>
          <li >To notify you about changes to our Service</li>
          <li >
            To allow you to participate in interactive features of our Service
            when you choose to do so
          </li>
          <li >To provide customer support</li>
          <li >
            To gather analysis or valuable information so that we can improve
            our Service
          </li>
          <li >To monitor the usage of our Service</li>
          <li id="ShareTransferandPublicdisclosure">
            To detect, prevent and address technical issues
          </li>
          <br />

          <h2 className="mt-6 text-black font-semibold text-3xl">
           Share, Transfer and Public disclosure of User’s Personal Information
          </h2>
          <h3 className="mt-6 text-black font-semibold">SHARE</h3>
          <p className="mt-2 ">
          Your privacy is our priority. Your personal details are kept confidential, and we only share what's essential under the conditions mentioned in this policy.
          </p>
          <li className="mt-2">1）Obtained your permission</li>
          <li className="mt-2">2）When needed to comply with state agencies, authority, or administrative or legal instructions.</li>
          <li className="mt-2">
          3）To meet the objectives stated in 'Collection and Use of User’s Personal Information'.
          </li>
          <li className="mt-2">4）Share with ad services and tech support partners.
          </li>
          <p className="mt-2 ">A list of available third-party SDKs for integration is mentioned below under the <strong>“Service Providers”</strong>.
          </p>
          <h3 className="mt-6 text-black font-semibold">TRANSFER</h3>
          
          <p className="mt-2">
          We will not disclose your personal information to any company, organization, or individual, except as required by law, administrative enforcement, or regulatory authorities.
          </p>

          <h3 className="mt-6 text-black font-semibold">PUBLIC DISCLOSURE</h3>
          
          <p className="mt-2" id="StorageandProtection">
          As required by laws, regulations, or legal authorities, we may need to share your personal information with enforcement or regulatory bodies. Any disclosure will be limited to the specific information requested and will follow the prescribed legal procedures.
          </p>
          <h2 className="mt-6 text-black font-semibold text-3xl">
          Storage and Protection of User’s Personal Information
          </h2>
          <p className="mt-2">
          At ASD Dev, our app requires access to your device’s media storage to provide seamless functionality, such as browsing, playing, downloading and managing your media files. This access is granted through the <a href="https://developer.android.com/training/data-storage/shared/media" className="text-blue-700 underline">Media Storage Permission </a> as per Google’s guidelines. By allowing this permission, our app can retrieve and display media files stored on your device, such as videos, music, and any format of media files to enhance your user experience. Rest assured, we only access the necessary media files for the app’s core functions, and <strong>we do not collect store, share, or sell any personal information</strong> from your media library. Users retain full control over their files, and data is handled responsibly in compliance with Google's policies to ensure privacy and security.
          </p>

          <p className="mt-4" id="UseofCookies">
          We use Firebase by Google for analytics. You can check its privacy policy for more.
          Firebase-<a href="https://firebase.google.com/support/privacy" className="text-blue-700 underline">https://firebase.google.com/support/privacy</a>
          </p>
         <h2 className="text-3xl mt-8 text-black font-semibold">
          Use of Cookies
          </h2>
          <p className="mt-2">
          ASD Dev values your privacy and treats data collected through cookies and similar technologies as non-personal information. A "cookie" is a small piece of data stored on your device by websites to help enhance your browsing experience. These cookies do not contain personal details such as your name, email address, or any other identifiable information. Instead, they are used to recognize your device and remember preferences or actions taken during your visit. You have the option to disable cookies at any time through your browser settings.
          </p>
          <p className="mt-2">
          Examples of Cookies we use:<br/>
          </p>
        <ul className="list-disc ml-8 mt-2">
        <li className="mt-2">
        Session Cookies<br/>
        We use Session Cookies to operate our Service.
        </li>
        <li  className="mt-2">
        Preference Cookies<br/>
We use Preference Cookies to remember your preferences and various settings.
        </li>
        <li  className="mt-2" id="DataProtectionOfficerDPO">
        Security Cookies<br/>
We use Security Cookies for security purposes.
        </li>
        <li className="mt-2" id="OurUser’sRights">Advertising Cookies<br/>
Advertising Cookies are used to serve you with advertisements that may be relevant to you and your interests.
        </li>
    </ul>
    <h2 className="text-3xl mt-8 text-black font-semibold">
         Data Protection Officer (DPO)
          </h2>
          <p className="mt-2">
          We have appointed a Data Protection Officer (DPO) to oversee our data protection strategy and ensure that our app complies with privacy regulations. The DPO is responsible for:
          </p>
         
            <li  className="mt-2">
            Monitoring compliance with data protection laws.
            </li>
            <li  className="mt-2">Acting as a point of contact for data protection-related inquiries.
            </li>
            <li  className="mt-2">Assisting users in exercising their data protection rights.
            </li>
        
          <p  className="mt-2">If you have any questions about your privacy or would like to exercise any of your rights under applicable laws, please contact our DPO.</p>
          <p className="mt-2"><strong>Contact Information for the DPO:</strong></p>
          <ul>
            <li><strong>Email:</strong>ashish@rareprob.com</li>
            <li><strong>Contact No.:</strong> +91 8279323413</li>
          </ul>
    <h2 className="text-3xl mt-8 text-black font-semibold">Our User’s Rights</h2>
        <p className="mb-4">Depending on the laws in your jurisdiction, you may have several rights regarding your personal information. These rights could include:</p>
        <ul className="list-disc list-inside mb-4" id="UsersofCalifornia">
            <li><strong>Access to Information:</strong> You can request information about the data we have collected about you.</li>
            <li><strong>Manage Permissions:</strong> You may revoke any permissions you’ve previously granted, such as access to your camera or photo library.</li>
            <li><strong>Data Deletion:</strong> Request the deletion of your personal information.</li>
            <li><strong>Opt-Out:</strong> You can choose to opt out of receiving personalized advertisements.</li>
        </ul>
        <p className="mb-4">To exercise these rights, contact us at <a href="mailto:feedback@rareprob.com" className="text-blue-600">feedback@rareprob.com</a>. California residents should refer to the "Users of California" section, users from Brazil should refer to "Users of Brazil" and EU users should consult the "EU Users" section for further details.</p>
        
        <h2 className="text-3xl mt-8 text-black font-semibold">Users of California</h2>
        <p className="mb-4">If you are a California resident, you have the following rights according to CCPA:</p>
        <ul className="list-disc list-inside mb-4">
            <li><strong>Right to Know:</strong> Users have the right to know about how their personal information is being used or shared.</li>
        </ul>
        <ul className="list-disc list-inside mb-4">
            <li><strong>Right to Access:</strong> Request information about the personal data we have collected about you.
            </li>
        </ul>
        <ul className="list-disc list-inside mb-4">
            <li><strong>Right to Delete:</strong> Request deletion of your personal data.</li>
            
        </ul>
        <ul className="list-disc list-inside mb-4">
        <li><strong>Right to Opt-Out:</strong> Opt-out of the sale of your personal data (Note: We do not sell personal data).</li>
        </ul>
        <p className="mb-4" id="EUUsers">To exercise these rights, please contact us at <a href="mailto:feedback@rareprob.com" className="text-blue-600">feedback@rareprob.com</a>.</p>
        
        <h2 className="text-3xl mt-8 text-black font-semibold">EU Users</h2>
        <p className="mb-4">For EU users, under the GDPR, you have the right to:</p>
        <ul className="list-disc list-inside mb-4">
            <li><strong>Right to Access:</strong> Request access to your personal data and obtain a copy.</li>
            <li><strong>Right to Rectification:</strong> Request correction of inaccurate personal data.</li>
            <li><strong>Right to Erasure:</strong> Request deletion of your personal data.</li>
            <li><strong>Right to Restrict Processing:</strong> Request restriction of processing your personal data.</li>
            <li><strong>Right to Data Portability:</strong> Request transfer of your data to another organization.</li>
            <li><strong>Right to Object:</strong> Object to processing of your personal data.</li>
        </ul>
        <p className="mb-4" id="UsersofBrazil">To exercise these rights, please contact us at <a href="mailto:feedback@rareprob.com" className="text-blue-600">feedback@rareprob.com</a>.</p>

        <h2 className="text-3xl mt-8 text-black font-semibold">Users of Brazil
        </h2>
        <p className="mb-4">If you are in Brazil, you have the following rights under the LGPD:</p>
        <ul className="list-disc list-inside mb-4">
            <li><strong>Right to Confirmation:</strong> Confirm the existence of processing of your personal data.
            </li>
            <li><strong>Right to Access:</strong>  Access your personal data.
            </li>
            <li><strong>Right to Correction:</strong>  Correct incomplete, inaccurate, or outdated data.</li>
            <li><strong>Right to Deletion:</strong>Request deletion of unnecessary or excessive data.
            </li>
            <li><strong>Right to Data Portability:</strong>  Request transfer of your data to another service provider.
            </li>
            <li><strong>Right to Opposition:</strong> Oppose the processing of your personal data in certain situations.
            </li>
        </ul>
        <p className="mb-4" id="UsersofVirginia">To exercise these rights, please contact us at <a href="mailto:feedback@rareprob.com" className="text-blue-600">feedback@rareprob.com</a>.</p>

        <h2 className="text-3xl mt-8 text-black font-semibold">Users of Virginia
        </h2>
        <p className="mb-4">If you are a resident of Virginia, you have specific rights under the Virginia Consumer Data Protection Act (VCDPA):</p>
        <ul className="list-disc list-inside mb-4">
            <li><strong>Right to Access:</strong>  You have the right to confirm whether or not we are processing your personal data and to access such data. Since we do not process personal data, we are unable to provide any.
            </li>
            <li><strong>Right to Correct:</strong> You have the right to request the correction of any inaccurate personal data. Since we do not collect personal data, there is no data to correct.
            </li>
            <li><strong>Right to Delete: </strong>You can request the deletion of any personal data we have collected. Since we do not collect personal data, there's no data to delete.
            </li>
            <li><strong>Right to Data Portability:</strong>   You may request to obtain a copy of the personal data we hold in a portable format. Since we do not collect personal data, we do not have any data to provide.
            </li>
            <li><strong>Right to Opt-Out of Data Processing for Targeted Advertising, Sale, or Profiling:</strong> We do not engage in targeted advertising, data sales, or profiling based on your data.
            </li>
        </ul>
        <p className="mb-4" id="DMCACopyrightPolicy" >To exercise these rights, please contact us at <a href="mailto:feedback@rareprob.com" className="text-blue-600">feedback@rareprob.com</a>.</p>
 
        <h2 className="text-3xl mt-8 text-black font-semibold">
        DMCA Copyright Policy
          </h2>
          <p className="mt-4">
          All Content available on the Platform is licensed from third-party services such as YouTube. All copyrights are owned by their respective owners and are used with permission. The Platform only provides outbound links to these Third-Party Services and has no direct control over their content. In compliance with the Digital Millennium Copyright Act, the Company has adopted the following general stance toward copyright infringement.
          </p>
          <p className="mt-4" id="Infringementpolicy">YouTube Content: third-party content available through the use of Google’s API (such as text, images, videos, audio, or software) hosted by YouTube.
          </p>
          
          <h2 className="text-3xl mt-8 text-black font-semibold">
          Infringement policy
          </h2>
          <p className="mt-4" id="HowtoReportInfringement">
          At ASD Dev, we respect the intellectual property rights of others and expect our users to do the same. If you believe that your copyrighted work or intellectual property has been used in a way that constitutes infringement within our app, please notify us immediately.
          </p>
         
          <h3 className="text-xl mt-8 text-black font-semibold">
          How to Report Infringement
          </h3>
          <p className="mt-4">
          If you believe your rights have been violated, please provide us with the following information:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>A description of the copyrighted or intellectual property that you claim has been infringed.
            </li>
            <li>A description of where the material in question is located within the app (such as a link or screenshot).
            </li>
            <li>Your contact information, including your name, email address, and phone number.</li>
            <li>A statement that you have a good faith belief that the use of the material is not authorized by the copyright owner, its agent, or the law.
            </li>
            <li id="In-appAdvertisements">A statement that the information provided in the notification is accurate and that you are the copyright or intellectual property owner or authorized to act on behalf of the owner.
            </li>
        </ul>
        <p className="mb-4">To submit your infringement claim, please contact us at: <a href="mailto:feedback@rareprob.com" className="text-blue-600">feedback@rareprob.com</a>.</p>
         <h2 className="text-3xl mt-8 text-black font-semibold">
            In-app Advertisements
          </h2>
          <p className="mt-4">
            We reserve the right to place adverts on the platforms in order to
            maintain the free functionalities. These advertisements may be
            interspersed with other features, and some of those features may
            only be activated if you view the ads. If you choose to stop seeing
            certain adverts, you might lose access to those features or
            encounter other app restrictions.
          </p>
          <p className="mt-2" id="In-appPurchases">
            Your use of the App may be terminated if you use any method to get
            around any In-app advertisements.
          </p>
          <h2 className="text-3xl mt-8 text-black font-semibold">
            In- app Purchases
          </h2>
          <p className="mt-4">
            We offer you choices with in-app purchases to improve your user
            experience and let you explore and take advantage of more features
            across the platform.
          </p>
          <p className="mt-2">
            If you wish to make any purchase, you do so through either Google
            Play service or Apple iTunes service and also agreeing to their
            respective Terms and Conditions.
          </p>
          <a
            href="https://play.google.com/intl/en-us/about/play-terms/index.html"
            className="text-blue-600"
          >
            https://play.google.com/intl/en-us/about/play-terms/index.html
          </a>{" "}
          <br />
          <a
            href="https://www.apple.com/legal/internet-services/itunes/"
            className="text-blue-600"
          >
            https://www.apple.com/legal/internet-services/itunes/
          </a>{" "}
          <br />
          <p className="mt-2" id="LiabilityLimitation">
            Also, It is to be noted that we’re not a party to any In-app
            purchases.
          </p>

          <h2 className="text-3xl mt-8 text-black font-semibold">
            Liability Limitation
          </h2>
          <p className="mt-2">
            ASD Dev Video Player for All Format Applications shall in no event
            be liable for any loss or damage (incidental, or consequential) of
            your device or files including photos, videos, documents, and music
            caused by-
            <br /> <br />
            <p className="ml-4">
              <li className="list-disc">
                Access or inability to access the services offered in our apps.
              </li>
              <li className="list-disc"> Any third party's conduct.</li>
              <li className="list-disc" id="DisclaimerofWarranty">
                {" "}
                Unauthorized or inappropriate use of ASD Dev Video Player for
                All Format Services in mobile applications other than what it
                was intended for.
              </li>
            </p>
          </p>
          <h2 className="text-3xl mt-8 text-black font-semibold">
            Disclaimer of Warranty
          </h2>
          <p className="mt-4" id="CopyrightandTrademarks">
            Services provided by ASD Dev Video Player for All Format through mobile applications are provided “as is" and “as available,” with no warranties (expressed or implied). It also includes but is not limited to warranties of quality, marketability, fitness of services for particular purposes, or non-infringement.

            You use the ASD Dev Video Player for All Format Service at your own risk and only as permitted by law.
          </p>

          <h2 className="text-3xl mt-8 text-black font-semibold">
            Copyright and Trademarks
          </h2>
          <p className="mt-4">
          Our apps give users the ability to create their own content. ASD Dev Video Player for All Formats will never claim ownership of your original work. You alone remain the sole person responsible for every piece of content you create, and therefore you warrant it.{" "}
            <br /> <br />
            ASD Dev owns and retains all rights to its content, applications, and services. Whereas the service and content of ASD Dev Content are protected by copyright, trademark, patent, trade secret, and other laws.
            <br /> <br  id="PushNotifications"/>
            ASD Dev also reserves the right to use your feedback (without revealing any personal information about the person who provided it) as we see fit. Under no circumstances can the sender of any particular feedback claim ownership of it or ask for payment in return.
          </p>
          <h2 className="text-3xl mt-8 text-black font-semibold">
            Push Notifications
          </h2>
          <p className="mt-4">
          We provide you the choice to receive push notifications to inform you of built-in features of the Application or any additional use of the Platform or you can say enabling In-app notifications is an alternative. If you turn off notifications, you might not get information right away and you can miss out on the latest updates and information.
          </p>
          <h2 className="text-3xl mt-8 text-black font-semibold">
            Termination
          </h2>
          <p className="mt-2" id="Retentionofdata">
          Your access to any particular feature or the entire platform may be terminated if any violation is discovered in any way. The platform may be terminated with or without prior warning, and if for any reason you wish to do so, you may do so by deleting the application from your device.
          </p>
          <h2 className="text-3xl mt-8 text-black font-semibold">
            Retention of data
          </h2>
          <p className="mt-2">
          ASD Dev will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
          </p>
          <p className="mt-2" id="DataRecoveryPolicy">
          ASD Dev will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer periods.
          </p>
          <h2 className="text-3xl mt-8 text-black font-semibold">
            Data Recovery Policy{" "}
          </h2>
          <p className="mt-2">
          Our apps do not support Recoverable action after uninstallation of the app.<strong>If users are using the Hider functionality then it is crucial to remember that you may lose all the trash or hidden data if you clear all your data or factory reset your device.</strong> 
          </p>
          <p className="mt-2">
          <strong>
          To prevent your data, you may transfer the trash or hidden files from our app to your device because once you uninstall our app, you won't be able to recover those files. </strong>Our developers will not be liable for any of the private files or data.
          </p>
          <p className="mt-2" id="Securityofdata">
          At ASD Dev, we prioritize your control over personalized data. You can easily manage and delete your local database, preferences, and logs directly from the app’s settings, without the need to visit any external service or URL. This includes clearing stored information in the local database, resetting your saved preferences, and deleting any logs generated during app usage, such as activity or error logs. All data management actions can be performed entirely within the app, giving you full control over your data without reliance on external platforms. We encourage users to regularly review and manage their data based on personal preferences. 
          </p>
          <h2 className="text-3xl mt-8 text-black font-semibold">
            {" "}
            Security of data
          </h2>
          <p className="mt-2" id="OurPolicyOnDONOTTRACKSignalsUnderTheCaliforniaOnlineProtectionActCALOPPA">
          The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
          </p>
          <h2 className="text-3xl mt-8 text-black font-semibold">
          OUR POLICY ON “DO NOT TRACK” SIGNALS UNDER THE CALIFORNIA ONLINE PROTECTION ACT (CALOPPA)
          </h2>
          <p className="mt-2" id="ServiceProviders">We do not support Do Not Track (“DNT”). Do Not Track is a preference you can set in your web browser to inform websites that you do not want to be tracked.</p>
          <p className="mt-2">You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.</p>
          <h2 className="text-black text-3xl mt-4 font-semibold">
            Service Providers
          </h2>
          <p className="mt-2" id="Disclaimer">
          We may employ third-party companies and individuals to facilitate our Service (“Service Providers”), provide the Service on our behalf, perform Service-related services, or assist us in analyzing how our Service is used. These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
          </p>
          <br />
          <p className="text-black text-3xl mt-4 mb-3 font-semibold">
            Disclaimer:
          </p>
          <p className="mt-2" id="Analytics">
          Any app of ASD Dev Video Player for All Formats is not associated with any social media platform. Any unauthorized acts and/or violations of intellectual property rights are solely the user's responsibility. Before downloading and using the content, we suggest that you seek the permission of the owner.
          </p>
          <h3 className="text-black text-3xl mt-7 mb-3 font-semibold">
            Analytics
          </h3>
          <p>
            We may use third-party Service Providers to monitor and analyze the
            use of our Service.
          </p>
          <ul>
          <li className=" mt-4" >
            <h4><strong>Facebook Analytics</strong></h4>
            <p className="ml-2 mt-2">
              Facebook Analytics is provided by Facebook Inc.
            </p>
            <p className="ml-2 mt-2">
              We use Facebook Analytics for in-game data analytics to improve
              our games.
            </p>
            <p className="ml-2 mt-2">
              For more information on the privacy practices of Facebook, please
              visit Facebook’s Data Policy:{" "}
              <a
                href="https://www.facebook.com/privacy/explanation"
                className="text-blue-400"
              >
                https://www.facebook.com/privacy/explanation
              </a>{" "}
            </p>
          </li>
          <li className=" mt-4" >
           <h4> <strong>Firebase Analytics</strong></h4>
            <p className="ml-2 mt-2">
            Google Firebase offers a variety of features, which can be found on the following summary page:{" "}
              <a
                className="text-blue-400"
                href="https://firebase.google.com/products/"
              >
                https://firebase.google.com/products/
              </a>
            </p>
            <p className="ml-2 mt-2">
              The functions include the storage of apps, including users’
              personal data, such as content they have created or information
              regarding their interaction with the apps. Google Firebase also
              offers interfaces that allow interaction between the users of the
              app and other services.
            </p>
            <p className="ml-2 mt-2">
              The analysis of user interactions is carried out using the
              analysis service of Firebase Analytics. This service helps us to
              record our users’ interactions. Events such as the first time an
              app is opened, the uninstalling of an app, updates, crashes or the
              frequency of use of the app are recorded. Certain user interests
              are also recorded and evaluated.{" "}
            </p>
            <p className="ml-2 mt-2">
              The legal basis for use is Art. 6 paragraph 1 sentence 1 letter of
              GDPR. If users wish to object to interest-based advertising
              through Google marketing services, they can use the settings and
              opt-out options provided by Google:{" "}
              <a
                href="http://www.google.com/ads/preferences"
                className="text-blue-400"
              >
                http://www.google.com/ads/preferences{" "}
              </a>
              .
            </p>
            <p className="ml-2 mt-2">
              The information processed by Google Firebase may be used with
              other Google services, such as Google Analytics and Google
              marketing services. In this case, only pseudonymous information,
              such as the Android Advertising ID or the Advertising Identifier
              for iOS, will be processed to identify users’ mobile devices.
              Additional information on the use of data for marketing purposes
              by Google can be found on the summary page:{" "}
              <a
                href="https://www.google.com/policies/technologies/ads"
                className="text-blue-400"
              >
                https://www.google.com/policies/technologies/ads
              </a>
              , Google’s privacy policy is available at{" "}
              <a
                href="https://www.google.com/policies/privacy"
                className="text-blue-400"
              >
                https://www.google.com/policies/privacy
              </a>
            </p>
          </li>
          <li className="mt-4">
          <h4> <strong>GameAnalytics</strong>
              {" "}(by GameAnalytics)
            </h4> 
            
            <p className="mt-2 ml-2">
              We use GameAnalytics for in-game data analytics to improve our
              games.
            </p>
            <p className="mt-2 ml-2">
              For more information about GameAnalytics, please visit their
              Privacy Policy:{" "}
              <a
                href="https://gameanalytics.com/privacy"
                className="text-blue-400"
              >
                https://gameanalytics.com/privacy
              </a>
            </p>
          </li>
          <li className="mt-4">
          <h4> <strong>Tenjin.io</strong> {" "}(by Tenjin, Inc.)</h4> 
           
            <p className="mt-2 ml-2">
            We use Tenjin for performance attribution.
            </p>
            <p className="mt-2 ml-2">
            For more information on what type of information Tenjin collects, please visit: 
              <a
                href="https://www.tenjin.io/privacy/"
                className="text-blue-400"
              >
               https://www.tenjin.io/privacy/
              </a>
            </p>
          </li>
          <li className="mt-4">
           <h4><strong>Adjust </strong> {" "} (by Adjust GmbH.)</h4> 
          
            <p className="mt-2 ml-2">
            We use Adjust for performance attribution.
            </p>
            <p className="mt-2 ml-2">
            For more information on what type of information Adjust collects, please visit:{" "} 
              <a
                href="https://www.adjust.com/terms/privacy-policy/"
                className="text-blue-400"
              >
              https://www.adjust.com/terms/privacy-policy/
              </a>
            </p>
          </li>
          <li className="mt-4">
            <h4><strong>AppsFlyer</strong>{" "} (by AppsFlyer)</h4>
            <p className="mt-2 ml-2">
            We use AppFlyer for performance attribution.
            </p>
            <p className="mt-2 ml-2" id="Advertising">
            For more information on what type of information AppsFlyer collects, please visit: 
              <a
                href="https://www.appsflyer.com/privacy-policy/"
                className="text-blue-400"
              >
             https://www.appsflyer.com/privacy-policy/
              </a>
            </p>
          </li>
          </ul>
          <h3 className="text-black mt-8 text-4xl font-semibold">
            Advertising
          </h3>
          <p>
            We may use third-party Service Providers to show advertisements to
            you to help support and maintain our Service.
          </p>
          <ul>
          <li className="mt-2">
           <h4><strong>IronSource </strong>{" "}  (by IronSource Mobile Ltd.)</h4> 
            <p className="mt-2 ml-2">
            You can opt out of the IronSource network by following the instructions as described by Ironsource on their Privacy Policy page. Check the link for more information- {" "}
              <a
                href="https://developers.ironsrc.com/ironsource-mobile/air/ironsource-mobile-privacy-policy/"
                className="text-blue-400"
              >
               https://developers.ironsrc.com/ironsource-mobile/air/ironsource-mobile-privacy-policy/
              </a>
            </p>
          </li>
          <li className="my-4">
           <h4><strong>MoPub</strong> (Parent company- <strong>AppLovin</strong>)</h4><br/>
            You can opt out of the MoPub service by following the instructions on the MoPub Opt-out page: <a href="https://dash.applovin.com/p/how-applovin-shows-you-ads" className="text-blue-600">https://dash.applovin.com/p/how-applovin-shows-you-ads</a><br/>
            For more information on what information MoPub collects and how it is used, please read MoPub Privacy Policy: <a href="https://www.applovin.com/privacy/" className="text-blue-600">https://www.applovin.com/privacy/</a>
        </li>
        <li className="mb-4">
          <h4><strong>Facebook Audience Network</strong> (by Facebook Inc.)</h4>  <br/>
            For more information about Facebook Audience Network, please visit their Policy: <a href="https://developers.facebook.com/docs/audience-network/optimization/best-practices/an-policy/" className="text-blue-600">https://developers.facebook.com/docs/audience-network/optimization/best-practices/an-policy/</a>
        </li>
        <li className="mb-4">
           <h4><strong>AdMob</strong> (by Google Inc.)</h4> <br/>
            You can opt-out from the AdMob by Google service by following the instructions described by Google: <a href="https://support.google.com/ads/answer/2662922?hl=en" className="text-blue-600">https://support.google.com/ads/answer/2662922?hl=en</a><br/>
            For more information on how Google uses the collected information, please visit the “How Google uses data when you use our partners’ sites or app” page: <a href="http://www.google.com/policies/privacy/partners/" className="text-blue-600">http://www.google.com/policies/privacy/partners/</a> or visit the Privacy Policy of Google: <a href="http://www.google.com/policies/privacy/" className="text-blue-600">http://www.google.com/policies/privacy/</a>
        </li>
        <li className="mb-4">
          <h4><strong>AppLovin</strong> (by AppLovin Corporation)</h4>  <br/>
            If you’d like to opt out of AppLovin service, please visit the AppLovin Opt-out page: <a href="https://dash.applovin.com/p/how-applovin-shows-you-ads" className="text-blue-600">https://dash.applovin.com/p/how-applovin-shows-you-ads</a><br/>
            If you’d like to learn more about AppLovin, please visit the Privacy Policy page of AppLovin: <a href="https://www.applovin.com/privacy/" className="text-blue-600">https://www.applovin.com/privacy/</a>
        </li>
        <li className="mb-4">
          <h4> <strong>HyprMx</strong> (by HyprMX Mobile LLC.)</h4> <br/>
            For more information about HyprMX, please visit their Privacy Policy: <a href="https://jungroup.com/privacy-policy" className="text-blue-600">https://jungroup.com/privacy-policy</a>
        </li>
        <li className="mb-4">
          <h4> <strong>Pangle</strong> (by Bytedance Pte.Ltd.)</h4> <br/>
            For more information about Pangle, please visit their Privacy Policy: <a href="https://ad.oceanengine.com/union/media/privacy" className="text-blue-600">https://ad.oceanengine.com/union/media/privacy</a>
        </li>
        <li className="mb-4">
          <h4><strong>Fyber</strong> (now Digital Turbine)</h4>  <br/>
            For more information about Fyber, please visit their Privacy Policy: <a href="https://www.digitalturbine.com/legal/privacy-policy" className="text-blue-600">https://www.digitalturbine.com/legal/privacy-policy</a>
        </li>
        <li className="mb-4">
          <h4><strong>Vungle</strong> (by Vungle Inc.)</h4>  <br/>
            You can opt out of Vungle service by following the instructions as described by Vungle on their Privacy Policy page: <a href="http://vungle.com/privacy/" className="text-blue-600">http://vungle.com/privacy/</a><br/>
            For more information about Vungle, please visit their Privacy Policy: <a href="http://vungle.com/privacy/" className="text-blue-600">http://vungle.com/privacy/</a>
        </li>
        <li className="mb-4">
          <h4><strong>Mintegral</strong> (by Mintegral International Ltd.)</h4>  
            <br/>
            For more information about Mintegral, please visit their Privacy Policy: <a href="https://www.mintegral.com/en/privacy/" className="text-blue-600">https://www.mintegral.com/en/privacy/</a>
        </li>
        <li className="mb-4">
          <h4> <strong>Unity Ads</strong> (by Unity)</h4> <br/>
            For more information about Unity Ads, please visit their Privacy Policy: <a href="https://unity3d.com/legal/privacy-policy" className="text-blue-600">https://unity3d.com/legal/privacy-policy</a>
        </li>
        <li className="mb-4">
          <h4><strong>Ogury</strong> (by Ogury Limited)</h4>  <br/>it their Privacy Policy: <a href="https://www.ogury.com/privacy/" className="text-blue-600">https://www.ogury.com/privacy/</a>
        </li>
        <li className="mb-4">
          <h4>  <strong>Tapjoy</strong> (by Tapjoy Inc.)</h4><br/>
            You can opt-out of Tapjoy service by following the instructions as described by Tapjoy on their Privacy Policy page: <a href="https://www.tapjoy.com/legal/" className="text-blue-600">https://www.tapjoy.com/legal/</a><br/>
            For more information about Tapjoy, please visit their Privacy Policy: <a href="https://dev.tapjoy.com/en/legal/Privacy-Policy" className="text-blue-600">https://dev.tapjoy.com/en/legal/Privacy-Policy</a>
        </li>
        <li className="mb-4">
          <h4><strong>Chartboost</strong> (by Chartboost Inc.)</h4>  <br/>
            For more information about Chartboost, please visit their Privacy Policy: <a href="https://docs.chartboost.com/en/legal/privacy-policy/" className="text-blue-600">https://docs.chartboost.com/en/legal/privacy-policy/</a>
        </li>
        <li className="mb-4">
          <h4><strong>AdColony</strong> (by Jirbo Inc.)</h4>  <br/>
            You can opt out of AdColony service, check the link below for more information: <a href="https://www.digitalturbine.com/legal/do-not-sell-or-share-my-personal-information-website" className="text-blue-600">https://www.digitalturbine.com/legal/do-not-sell-or-share-my-personal-information-website</a><br/>
            You can also opt out of AdColony through the Digital Advertising Alliance in the USA <a href="http://www.aboutads.info/choices/" className="text-blue-600">http://www.aboutads.info/choices/</a> or opt out using your mobile device settings.<br/>
            For more information about AdColony, please visit their Privacy Policy: <a href="http://www.adcolony.com/privacy-policy/" className="text-blue-600">http://www.adcolony.com/privacy-policy/</a>
        </li>
        <li className="my-2">
          <h4><strong>InMobi</strong> (by InMobi Pvt. Ltd.)</h4>  <br/>
            You can opt out of the InMobi service by following the instructions as described on the InMobi Opt-out page: <a href="http://www.inmobi.com/page/opt-out/" className="text-blue-600">http://www.inmobi.com/page/opt-out/</a><br/>
            For more information on the privacy practices and policies of InMobi, please visit the InMobi Privacy Policy: <a href="http://www.inmobi.com/privacy-policy/" className="text-blue-600">http://www.inmobi.com/privacy-policy/</a>
        </li>
        <li className="my-2">
          <h4><strong>StartApp</strong> (by StartApp Inc.)</h4>  <br/>
            You can opt out of interest-based ads served by StartApp by following the instructions on their Privacy Policy, section “8. Your Choices and Controls”: <a href="https://www.startapp.com/policy/privacy-policy/" className="text-blue-600">https://www.startapp.com/policy/privacy-policy/</a><br/>
            For more information on what information StartApp collects and how it is used, please read their Privacy Policy: <a href="https://www.startapp.com/policy/privacy-policy/" className="text-blue-600">https://www.startapp.com/policy/privacy-policy/</a>
        </li>
        <li className="my-2">
          <h4><strong>Smaato</strong> (by Smaato, Inc.)</h4>  <br/>
            For more information on what information Smaato collects and how it is used, please read their Privacy Policy: <a href="https://www.smaato.com/privacy/" className="text-blue-600">https://www.smaato.com/privacy/</a>
        </li>
        <li className="my-2">
          <h4> <strong>Verizon</strong> (by Verizon Communication Inc.)</h4> <br/>
            For more information on what information Verizon collects and how it is used, please read their Privacy Policy: <a href="https://www.verizon.com/about/privacy/" className="text-blue-600">https://www.verizon.com/about/privacy/</a>
        </li>
        <li className="my-2" id="BehavioralRemarketing">
          <h4><strong>Google AdSense & DoubleClick Cookie</strong></h4>  <br/>
            Google, as a third-party vendor, uses cookies to serve ads on our Service. Google's use of the DoubleClick cookie enables it and its partners to serve ads to our users based on their visit to our Service or other websites on the Internet. You may opt out of the use of the DoubleClick Cookie for interest-based advertising by visiting the Google Ads Settings web page: <a href="http://www.google.com/ads/preferences/" className="text-blue-600">http://www.google.com/ads/preferences/</a>
        </li>
          </ul>
          <h3 className="mt-8 text-black text-3xl font-semibold">
            Behavioral Remarketing
          </h3>
          <p className="mt-2 ">
            ASD Dev Video Player for All Format uses remarketing services to
            advertise on third party websites to you after you visited our
            Service. We and our third-party vendors use cookies to inform,
            optimise and serve ads based on your past visits to our Service.
          </p>
          <li className="mt-2">
         <strong>Google Ads</strong>  
            <p className="mt-2 ml-2">
              Google AdWords remarketing service is provided by Google Inc.{" "}
            </p>
            <p className="mt-2 ml-2">
              You can opt-out of Google Analytics for Display Advertising and
              customise the Google Display Network ads by visiting the Google
              Ads Settings page:{" "}
              <a
                className="text-blue-400"
                href="http://www.google.com/settings/ads"
              >
                http://www.google.com/settings/ads
              </a>
            </p>
            <p className="mt-2 ml-2">
              Google also recommends installing the Google Analytics Opt-out
              Browser Add-on{" "}
              <a
                className="text-blue-400"
                href="https://tools.google.com/dlpage/gaoptout "
              >
                – https://tools.google.com/dlpage/gaoptout –
              </a>{" "}
              for your web browser. Google Analytics Opt-out Browser Add-on
              provides visitors with the ability to prevent their data from
              being collected and used by Google Analytics.
            </p>
            <p className="mt-2 ml-2">
              For more information on the privacy practices of Google, please
              visit the Google Privacy & Terms web page:{" "}
              <a
                className="text-blue-400"
                href="https://policies.google.com/privacy?hl=en"
              >
                https://policies.google.com/privacy?hl=en
              </a>
            </p>
          </li>
          <li className="mt-2">
         <strong>Facebook Ads</strong> 
            <p className="mt-2 ml-2">
              Facebook remarketing service is provided by Facebook Inc.{" "}
            </p>
            <p className="mt-2 ml-2">
              You can learn more about interest-based advertising from Facebook
              by visiting this page:{" "}
              <a
                className="text-blue-400"
                href="https://www.facebook.com/business/help/488043719226449?id=434838534925385
"
              >
               https://www.facebook.com/business/help/488043719226449?id=434838534925385

              </a>
            </p>
            <p className="mt-2 ml-2">
              To opt-out from Facebook’s interest-based ads, follow these
              instructions from Facebook:{" "}
              <a
                className="text-blue-400"
                href=" https://www.facebook.com/help/568137493302217"
              >
                {" "}
                https://www.facebook.com/help/568137493302217
              </a>
            </p>
            <p className="mt-2 ml-2">
              Facebook adheres to the Self-Regulatory Principles for Online
              Behavioural Advertising established by the Digital Advertising
              Alliance. You can also opt-out from Facebook and other
              participating companies through the Digital Advertising Alliance
              in the USA{" "}
              <a
                href="http://www.aboutads.info/choices/"
                className="text-blue-400"
              >
                http://www.aboutads.info/choices/
              </a>{" "}
              , the Digital Advertising Alliance of Canada in Canada{" "}
              <a
                className="text-blue-400"
                href="http://www.aboutads.info/choices/"
              >
                http://www.aboutads.info/choices/
              </a>{" "}
              or the European Interactive Digital Advertising Alliance in Europe{" "}
              <a
                className="text-blue-400"
                href="http://www.youronlinechoices.eu/"
              >
                http://www.youronlinechoices.eu/
              </a>{" "}
              , or opt-out using your mobile device settings.
            </p>
            <p>
              For more information on the privacy practices of Facebook, please
              visit Facebook’s Data Policy:{" "}
              <a
                className="text-blue-400"
                href="https://www.facebook.com/privacy/explanation"
              >
                https://www.facebook.com/privacy/explanation
              </a>
            </p>
          </li>
          <li className="mt-2">
            <strong>AppLovin</strong> (by AppLovin Corporation)
            <p>
              If you’d like to opt out of AppLovin service, please visit the AppLovin Opt-out page: 
                <a href="https://dash.applovin.com/p/how-applovin-shows-you-ads" className="text-blue-600">https://dash.applovin.com/p/how-applovin-shows-you-ads</a><br/>
                If you’d like to learn more about AppLovin, please visit the Privacy Policy page of AppLovin: 
                 <a href="https://www.applovin.com/privacy/" className="text-blue-600">https://www.applovin.com/privacy/</a></p>
          </li>
          <li className="mt-2"> 
                    <strong>Other Social Media Platforms</strong>
                    <p >
                        We may use your information to send you marketing and promotional communications about our apps and services. This includes updates, special offers, and news related to our products. You may receive these communications through various channels, including email, push notifications, or in-app messaging.
                    </p>  
                </li>
          
         <p className="mt-2">
         To promote our apps and engage with users, we also use a variety of social media platforms such as:
         </p>
         <ul className="pl-5">
                <li className="mt-4">
                  <h5> <strong>Facebook</strong></h5> 
                    <p className="ml-4">
                        Rareprob- <a href="https://www.facebook.com/rareprob" className="text-blue-600">https://www.facebook.com/rareprob</a><br/>
                        RocksPlayer- <a href="https://www.facebook.com/bestvideoplayer" className="text-blue-600">https://www.facebook.com/bestvideoplayer</a>
                    </p>
                </li>
                <li className="mt-4">
                <h5><strong>Instagram</strong></h5>    
                    <p className="ml-4">
                        Rareprob- <a href="https://www.instagram.com/rareprob_/" className="text-blue-600">https://www.instagram.com/rareprob_/</a><br/>
                        RocksPlayer- <a href="https://www.instagram.com/rocksplayer1/" className="text-blue-600">https://www.instagram.com/rocksplayer1/</a>
                    </p>
                </li>
                <li className="mt-4">
                <h5> <strong>X (formerly Twitter)</strong></h5>   
                    <p className="ml-4">
                        Rareprob- <a href="https://x.com/rareprob" className="text-blue-600">https://x.com/rareprob</a>
                    </p>
                </li>
                <li className="mt-4">
                  <h5><strong>LinkedIn</strong></h5>  
                    <p className="ml-4">
                        Rareprob- <a href="https://www.linkedin.com/company/rareprob/mycompany/" className="text-blue-600">https://www.linkedin.com/company/rareprob/mycompany/</a>
                    </p>
                </li>
                <li className="mt-4">
                  <h5> <strong>YouTube</strong></h5> 
                    <p className="ml-4">
                        Rareprob- <a href="https://www.youtube.com/channel/UCNXZXijMtxueoWaNiye73AQ" className="text-blue-600">https://www.youtube.com/channeln/UCNXZXijMtxueoWaNiye73AQ</a><br/>
                        RocksPlayer- <a href="https://www.youtube.com/channel/UCu1zMANOj8xaEde2HjaX43w" className="text-blue-600">https://www.youtube.com/channel/UCu1zMANOj8xaEde2HjaX43w</a>
                    </p>
                </li>
                <li className="mt-4">
                 <h5><strong>Pinterest</strong></h5>   
                    <p className="ml-4">
                        Rareprob- <a href="https://pin.it/UfXhnCWtj" className="text-blue-600">https://pin.it/UfXhnCWtj</a><br/>
                        RocksPlayer- <a href="https://pin.it/2bCovR688" className="text-blue-600">https://pin.it/2bCovR688</a>
                    </p>
                </li>
                <li className="mt-4">
                  <h5> <strong>Discord</strong></h5> 
                    <p className="ml-4">
                        Rareprob- <a href="https://discord.gg/XHGV4VB49a" className="text-blue-600">https://discord.gg/XHGV4VB49a</a>
                    </p>
                </li>
                <li className="mt-4">
                  <h5><strong>Telegram</strong></h5>  
                    <p className="ml-4">
                    RocksPlayer- <a href="https://t.me/asdrocksvideoplayer" className="text-blue-600">https://t.me/asdrocksvideoplayer</a>
                    </p>
                </li>
            </ul>
            <p className="mt-4" >
            These platforms allow us to share updates, features, and promotional content, and interact with users who follow our accounts. Please note that while we use these platforms to advertise and promote our apps, we do not share your personal information with these social networks unless you specifically opt-in or interact with our content directly on their platforms.
            </p>
            <p className="mt-4" id="Payments">
            If you do not wish to receive marketing communications, you can opt-out by following the unsubscribe instructions provided in any email or contacting us directly.
            </p>
          
          <h2 className="mt-8 text-black text-3xl font-semibold">Payments</h2>
          <p className="mt-2 ">
            We may provide paid products and/or services within the Service. In
            that case, we use third-party services for payment processing (e.g.
            payment processors).
          </p>
          <p className="mt-2">
           <strong>
           We will not store or collect your payment card details.
            </strong> That
            information is provided directly to our third-party payment
            processors whose use of your personal information is governed by
            their Privacy Policy. These payment processors adhere to the
            standards set by PCI-DSS as managed by the PCI Security Standards
            Council, which is a joint effort of brands like Visa, MasterCard,
            American Express and Discover. PCI-DSS requirements help ensure the
            secure handling of payment information.
          </p>
          <p className="mt-2">The payment processors we work with are:</p>
          <li className="mt-2 text-lg">
            Apple Store In-App Payments
            <p className="mt-2 text-lg">
              Their Privacy Policy can be viewed at{" "}
              <a
                className="text-blue-400"
                href="https://www.apple.com/legal/privacy/en-ww/"
              >
                https://www.apple.com/legal/privacy/en-ww/
              </a>
            </p>
          </li>
          <li className="mt-2 text-lg">
            Google Play In-App Payments
            <p className="mt-2 text-lg" id="LinkstootherSites">
              Their Privacy Policy can be viewed at{" "}
              <a
                className="text-blue-400"
                href="https://www.google.com/policies/privacy/"
              >
                https://www.google.com/policies/privacy/
              </a>
            </p>
          </li>
          <h2 className="mt-8 text-black text-3xl font-semibold">
            Links to other sites
          </h2>
          <p className="mt-2" id="ChildrensPrivacy">
            Our Service may contain links to other sites that are not operated
            by us. If you click a third party link, you will be directed to that
            third party’s site. We strongly advise you to review the Privacy
            Policy of every site you visit. We have no control over and assume
            no responsibility for the content, privacy policies or practices of
            any third party sites or services.
          </p>
          <h2 className="text-black text-3xl mt-8 font-semibold">
            Children’s Privacy
          </h2>
          <p className="mt-2">
          At ASD Dev, our products and services are designed for users who are 13 years of age or older (or the age specified by law in your country/region). If you are a guardian of a child under the age of 13 (or the legal age in your country/region), we encourage you to actively guide and supervise the child during their use of our products, ensuring the safety of their personal information.
          </p>
          <p className="mt-2"  id="PrivacyPolicyChanges">
          We advise child users under 13 (or the prescribed age in your country/region) to carefully read this policy with the assistance of a parent or guardian before using our services. All services or the provision of information should only be done with parental or guardian consent. If we discover that we have inadvertently collected personal information from a child, we will take swift action to delete the data.
          </p>
          <h2 className="text-black text-3xl mt-8 font-semibold">
            Changes to this privacy policy
          </h2>
          <p className="mt-2">
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
            We will let you know via email and/or a prominent notice on our
            Service, prior to the change becoming effective and update the
            “effective date” at the top of this Privacy Policy.
          </p>
          <p className="mt-2 " >
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
          <h2 className="mt-8 text-black text-3xl font-semibold" id="ContactUs">Contact us</h2>
          <p className="mt-2 mb-20">
          If you have any questions or suggestions, please contact us at  <a href="mailto:feedback@rareprob.com" className="text-blue-700 underline">feedback@rareprob.com</a>.
          We are also open to your feedback.
          </p>
        </div>
        <div className=" pt-10">
          <NewFooter color="bg-[#6a1f9b]/90" />
        </div>
      </main>
    </>
  );
};

export default PrivacyPolicyScreen;
