import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import "react-accessible-accordion/dist/fancy-example.css";

const FaqVideoDownloader: React.FC = () => {
  return (
    <div className="w-3/4 mx-auto mt-20">
      <Accordion className="" allowZeroExpanded={true}>
        <AccordionItem
          style={{ border: "0.2px solid white", borderRadius: "5px" }}
        >
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg"
            >
              1. What is the best video downloader app?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="!text-gray-300 !ml-6">
              A Video downloader should offer fast and quick downloads for all
              social media platforms. ASD Video downloader supports multiple
              social media platforms along with video locker, fast browser and
              automatic video detection to provide easy app experience.
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem
          style={{
            border: "0.2px solid white",
            borderRadius: "5px",
            marginTop: "10px",
          }}
        >
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
                    !text-white  !mt-3
                     !rounded-lg"
            >
              2. How to download Instagram reels without watermark ?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              With ASD Video downloader, you can download reels, videos and
              photos with no watermarks . To download ig reels, you just need to
              go through a few easy steps.
            </p>
            <p>1. Copy the link of the reels videos</p>
            <img
              src="/images/faq/videoDownloader/Artboard 103.webp"
              alt="Download Instagram reels"
              className="w-56 !mt-3 mb-7"
            />
            <p>2. Open/Install ASD Video downloader: Hider</p>
            <img
              src="/images/faq/videoDownloader/Artboard 104.webp"
              alt="Open/Install ASD Video Downloader "
              className="w-56 !mt-3 mb-7"
            />
            <p>3. Check out the address bar and paste the link you copied</p>
            <img
              src="/images/faq/videoDownloader/Artboard 103 copy 3.webp"
              alt="Check Address Bar Video Downloader"
              className="w-56 !mt-3 mb-7"
            />
            <p>4. Tap on the “Download” button</p>
            <img
              src="/images/faq/videoDownloader/Artboard 103 copy 4.webp"
              alt="Tap Download Button"
              className="w-56 !mt-3 mb-7"
            />
            <p>
              Done! You can find your downloaded videos right in the storage
              folder.
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem
          style={{
            border: "0.2px solid white",
            borderRadius: "5px",
            marginTop: "10px",
          }}
        >
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
                    !text-white  !mt-3
                     !rounded-lg"
            >
              3. Can I download instagram reels without the Login process?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              Yes, you can download any videos and photos without login
              credentials. But it is to make sure that only the public account
              videos can be downloaded without a login process. To download ig
              reels, you just need to go through a few easy steps.
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem
          style={{
            border: "0.2px solid white",
            borderRadius: "5px",
            marginTop: "10px",
          }}
        >
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
                    !text-white  !mt-3
                     !rounded-lg"
            >
              4. How many social media platforms does it support?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              This fast downloader supports multiple social media platforms. You
              can explore Instagram, Facebook, DailyMotion, Vimeo, Twitter and
              other platforms to download the favourite social media videos or
              photos.
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem
          style={{
            border: "0.2px solid white",
            borderRadius: "5px",
            marginTop: "10px",
          }}
        >
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
                    !text-white  !mt-3
                     !rounded-lg"
            >
              5. How does an auto detection feature work?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              To download videos with an auto detect feature is easy and simple.
              Just enable auto-detection from the settings menu and when you
              login from its fast browser then it detects the videos and makes a
              list of videos detected. <br />
              Then, you can select the videos and tap on the download button for
              quick downloading Facebook, DailyMotion, Vimeo, Twitter and other
              platforms to download the favourite social media videos or photos.
            </p>
            <div className="flex items-center justify-center space-x-10 mt-10">
              <img
                src="/images/faq/videoDownloader/auto dect-1.webp"
                className="w-72 rounded-xl object-cover"
                alt="Auto Detection feature in Video Downloader"
              />
              <img
                src="/images/faq/videoDownloader/auto dect.webp"
                className="w-72"
                alt="Auto Detection feature in Video Downloader"
              />
            </div>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem
          style={{
            border: "0.2px solid white",
            borderRadius: "5px",
            marginTop: "10px",
          }}
        >
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
                    !text-white  !mt-3
                     !rounded-lg"
            >
              6. Can I hide my private videos with Video Downloader?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              Yes, ASD Video downloader offers the free video locker which lets
              you hide your private photos and videos in a Pin-protected folder.{" "}
              <br />
              You can lock/unlock photos or videos anytime with a safe folder to
              keep your files safe from nosy individuals.
            </p>
            <div className="flex items-center justify-center space-x-10 mt-10">
              <img
                src="/images/faq/videoDownloader/hider.webp"
                className="w-56  h-80 
                        object-contain "
                alt="Private Videos in Video Downloader"
              />
              <img
                src="/images/faq/videoDownloader/hider_.webp"
                className="w-56"
                alt="Private Videos in Video Downloader"
              />
            </div>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem
          style={{
            border: "0.2px solid white",
            borderRadius: "5px",
            marginTop: "10px",
          }}
        >
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
                    !text-white  !mt-3
                     !rounded-lg"
            >
              7. How to watch downloaded videos offline?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              ASD Video downloader supports a built-in video player to watch the
              download content offline without switching to the media gallery.
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem
          style={{
            border: "0.2px solid white",
            borderRadius: "5px",
            marginTop: "10px",
          }}
        >
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
                    !text-white  !mt-3
                     !rounded-lg"
            >
              8. Does it support WhatsApp status downloading?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              Yes, this ASD video downloader app supports WhatsApp status saver
              feature. Just after allowing folder permission, you can tap on the
              “save” button and download multiple statuses in a storage folder.
              ASD Video downloader supports a built-in video player to watch the
              download content offline without switching to the media gallery.
            </p>
            <img
              src="/images/faq/videoDownloader/whats app status_.webp"
              alt="Whatsaap status downloading"
              className="w-56 !mt-3 mb-7"
            />
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem
          style={{
            border: "0.2px solid white",
            borderRadius: "5px",
            marginTop: "10px",
          }}
        >
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
                    !text-white  !mt-3
                     !rounded-lg"
            >
              9. How can I download Facebook reels/videos ?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              With an easy and secure downloader, you can try a copy-paste
              method to download fb videos or photos.This tool will auto detect
              all the videos and lets you select your preferred videos to
              download.
            </p>
            <p>1. Copy the link of the videos/photos</p>
            <img
              src="/images/faq/videoDownloader/fb download.webp"
              alt="Copy Links to Facebook Video"
              className="w-56 !mt-3 mb-7"
            />
            <p>2. Open/Install ASD Video downloader: Hider</p>
            <img
              src="/images/faq/videoDownloader/Artboard 104.webp"
              alt="Open/Install ASD Video Downloader "
              className="w-56 !mt-3 mb-7"
            />
            <p>
              3. Select the detected videos and tap on the “Download’ button.
            </p>
            <img
              src="/images/faq/videoDownloader/fb downloader_.webp"
              alt="Check Address Bar Video Downloader"
              className="w-56 !mt-3 mb-7"
            />
            <p>4. Now, check the storage folder to watch downloaded videos.</p>
            <p>
              Done! You can find your downloaded videos right in the storage
              folder.
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem
          style={{
            border: "0.2px solid white",
            borderRadius: "5px",
            marginTop: "10px",
          }}
        >
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
                    !text-white  !mt-3
                     !rounded-lg"
            >
              10. Does it support multiple languages?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              Yes, this app supports more than 20 languages for a user-friendly
              app experience. You can change your preferred language from the
              ‘settings” menu. Simply go to settings and tap on “Languages”,
              then select the preferred language for easy app experience. <br />{" "}
              <br />
              tap on the “save” button and download multiple statuses in a
              storage folder. ASD Video downloader supports a built-in video
              player to watch the download content offline without switching to
              the media gallery.
            </p>
            <div className="flex items-center justify-center space-x-10 mt-10">
              <img
                src="/images/faq/videoDownloader/whatsaapFaq1.webp"
                className="w-56  "
                alt="Private Videos in Video Downloader"
              />
              <img
                src="/images/faq/videoDownloader/whatsaapFaq.webp"
                className="w-56"
                alt="Private Videos in Video Downloader"
              />
            </div>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem
          style={{
            border: "0.2px solid white",
            borderRadius: "5px",
            marginTop: "10px",
          }}
        >
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
                    !text-white  !mt-3
                     !rounded-lg"
            >
              11. How to download Social Media Videos for free?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              To download Social Media Videos for free try ASD Video Downloader
              App . <br /> <br /> You can automatically download videos, reels
              and posts from your Social Media account from this App or you can
              also copy and paste the link of the desired videos in the browser
              of the App. You can easily download videos from Instagram,
              Facebook, Vimeo and Dailymotions and more.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default FaqVideoDownloader;
