import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    
  } from 'react-accessible-accordion';
  
  // Demo styles, see 'Styles' section below for some notes on use.
  import 'react-accessible-accordion/dist/fancy-example.css';
import { TargetLink } from './Util';

const TweedleFaqScreen = () => {
  return (
    <div className='w-3/4 mx-auto mt-20'>
    <Accordion className='' allowZeroExpanded={true}>
          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}}>
           
          <AccordionItemHeading 
                className='accordion__arrow'   >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                  1.  What is the ASD Tweedle app for?
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                    ASD Tweedle is a free ringtone app with the widest collection of enchanting ringtones from all genres in the world. You can set ringtones, notification sounds, and contact-based ringtones with the app. Choose from natural, animal, babies, lullabies, Bollywood, popular, and many more ringtones. 
                  </p>
              </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}}>
          <AccordionItemHeading 
                className='accordion__arrow'  >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                   2. How to use Pro ringtones?
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                    Effortlessly use and apply Pro ringtones without making any in-app purchases, simply watch an ad in the app and unlock your favourite ringtones instantly!
                  </p>
                  <img src="/images/faq/tweedle/faqTweedleUnlock.webp" 
                  className='w-56 mt-6'
                  alt="Pro Ringtones" />
              </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}}>
          <AccordionItemHeading 
                className='accordion__arrow'   >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                  3. What different genre ringtones can I find on this app?
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                     Here are some of the genres in which you can find 15-20 ringtones each – Nature, Pop, Classic, Bollywood, Dance, Funny, Electrical, Animal, Baby, LofiHits, Trending, Game and so much more!
                  </p>
                  <img src="/images/faq/tweedle/faqTweedleCategories.webp" 
                  className='w-56 mt-6'
                  alt="Genre  Ringtones" />
              </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}}>
          <AccordionItemHeading 
                className='accordion__arrow'    >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                  4. Will the ringtone disappear if I uninstall the app?
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                     No, the ringtones you have applied and ringtones you already have downloaded don’t get lost on uninstalling the app. They can also be found in your device’s storage.
                  </p>
               
              </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
          <AccordionItemHeading 
                className='accordion__arrow'   >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                  5. Can you set different ringtones at the same time?
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                    Yes, you can set unique ringtones for your contacts, this way you would also know who is the caller just by listening to the ringtone from afar. 
                  </p>
               
              </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}}>
          <AccordionItemHeading 
                className='accordion__arrow'   >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                  6. Where can I find the downloaded ringtones?
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                    Finding downloaded ringtones isn’t difficult at all. Just refer to the ‘me’ section of the app and on the top, you will be able to see the ringtones you are currently using. You can also find all the downloaded ringtones at the bottom of this page.
                  </p>
                  <img src="/images/faq/tweedle/faqTweedleMe.webp" 
                  className='w-56 mt-6'
                  alt="Downloaded Ringtones" />
              </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}}>
          <AccordionItemHeading 
                className='accordion__arrow'    >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                  7. Can I trim your favourite audio and set it as a ringtone?
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                    No, you can only download the ringtones already available in the app. You can find your favourite music and songs in the app to set as ringtones but if you still can’t,  then try our <TargetLink link='https://play.google.com/store/apps/details?id=mp3converter.videotomp3.ringtonemaker'>ASD Video to MP3 converter app</TargetLink>  and trim your desired songs and set them as ringtones or notifications sounds from the app itself.
                  </p>
                  {/* <img src="/images/faq/fileManger/ZIP FILE_ (1).webp" 
                  className='w-56 mt-6'
                  alt="File Compression using File Manager" /> */}
              </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem  style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}}>
          <AccordionItemHeading 
                className='accordion__arrow'   >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                  8. Is ASD Twiddle a paid ringtone app?
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                    We are pleased to inform you that ASD Tweedle is not a paid app! It is completely free to use.
 
                  </p>
                 
              </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}}>
          <AccordionItemHeading 
                className='accordion__arrow'    >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                  9.  How can I know which ringtone I set from this app?
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                     Refer to the ‘me’ section of the ASD Tweedle app and check the ‘ringtone set’ option on the top. You can easily see which ringtone and notification sound is currently applied.
                  </p>
                  <img src="/images/faq/tweedle/faqTweedleMeset.webp" 
                  className='w-56 mt-6'
                  alt="Setted Ringtone" />
              </AccordionItemPanel>
          </AccordionItem>

      </Accordion>
  </div>
  )
}

export default TweedleFaqScreen