import { getAnalytics, logEvent } from "firebase/analytics";
import React from "react";
import { Link } from "react-router-dom";

type HeadingType ={
 text:string
}

type AppProductTypes ={
    imageUrl : string,
    appName :string,
    link:string,

}

type TargetLinkType = {
    link:string,
    children:string
}


type BlogLinkType= {
    text:string,
    link:string
}

type BlogImageType = {
    image:string,
    alt:string
}

export const HeadingText : React.FC<HeadingType> = ({text})=>{
    return (
        <h2 className="lg:text-4xl text-left text-2xl font-bold ">
           {text}
        </h2>
    )
}

export const SubHeadingText : React.FC<HeadingType> = ({text})=>{
    return (
        <h1 className="lg:text-3xl text-xl font-bold ">
           {text}
        </h1>
    )
}

export const NormalText : React.FC<HeadingType> = ({text})=>{
    return (
        <h1 className="lg:text-2xl text-lg font-bold ">
           {text}
        </h1>
    )
}


export const SmallText : React.FC<HeadingType> = ({text})=>{
    return (
        <h1 className="lg:text-xl text-sm font-bold ">
           {text}
        </h1>
    )
}


export const TargetLink:React.FC<TargetLinkType> = ({link,children}) => {
    return (
      <a href={link} className="text-blue-400 font-medium" target={"_blank"} rel="noreferrer">{children}</a>
    )
  }
  
  



export  const BlogLink:React.FC<BlogLinkType> = ({text,link}) =>{

    return (
        <Link to={link} className="text-blue-400 font-medium" >
            {text}
        </Link>        
    )
   }

export  const BlogImage:React.FC<BlogImageType> = ({image,alt}) =>{

    return (
       <img src={image}
       alt={alt}
       className=' max-w-full hover:shadow-[0_0px_60px_1px_rgba(0,0,0,0.2)]   shadow-[0_0px_10px_6px_rgba(0,0,0,0.11)]  sm:max-h-[500px] 2xl:max-h-[500px] mx-auto  mt-10 rounded-xl object-contain' />
             
    )
   }
   


export const AppProduct:React.FC<AppProductTypes> = ({imageUrl,appName,link})=>{

    const analytics = getAnalytics();

    return (
        <Link to={link} onClick={()=>{
            logEvent(analytics, 'select_content', {
                content_type: "AppsGamePage",
                content_id: appName,
                items: [{ name: appName }]
              });
        }} className={` hover:scale-110 transition-all  py-4 lg:py-6
        mx-3 px-2 lg:p-4 flex flex-col items-center  rounded-2xl `}>
            <img src={imageUrl} 
            className="h-20 lg:w-28 w-20 lg:h-28 rounded-2xl"
            alt={appName} />
             <h1 className="text-xs mt-2 lg:text-sm font-semibold">{appName}</h1>
        </Link >
    )
}