import React from 'react'

const RocksScreenFeatures = () => {
  return (
    <div className='flex flex-col items-center justify-center mt-20'>
    <h1 className='text-[20px] md:text-[55px] font-medium '> Features You Will Adore The Most</h1>
    <div className='mx-10 grid grid-cols-2 md:grid-cols-3 gap-10 mt-10'>
    <Feature image={"rocks player dual audio ICON"} heading="Dual Audio" 
    text={"Dual audio options are available on this video player to enhance your video viewing experience. Dual Audio Movies are encoded with a dual audio track, allowing you to watch movies in the language of your choice."}/>
    <Feature image={"rocks player SUBTITLE ICON-104-104"} heading="Subtitle " 
    text={"If you have downloaded a web series or movie in different language but there is no subtitle, you can search and download the same with this video player. So, you no longer have to do guesswork."}/>
    <Feature image={"rocks player  PIP ICON_multiplayer copy"} heading="PIP" 
    text={"With picture-in-picture mode, you don’t have to block screen to do a quick task. You can continue watching your favorite video and multitask for a while.Then, you can come back to full screen with just one tap in this video player. "}/>
    <Feature image={"rocks player  4K ICON_multiplayer copy 3_multiplayer copy 3"} heading="4K" 
    text="This professional video player supports all formats, including ultra HD and 4K videos. All in all, it is one of the best video players for Android. "/>
    <Feature image={"rocks player BG ICON_multiplayer copy 2"} heading="Background Play" 
    text={"Rocks Video Player is built with background video playback so that you can listen audio file of your preferred videos with an ease. This Video player lets you provide the best possible background playback experience"}/>
    <Feature image={"rocks player  EQUALIZER  ICON_multiplayer copy 4"} heading="Equalizer" 
    text={" Built-in Equalizer in video player is like icing on the cake. This app lets you manage the sound quality of your movies or videos to offer the best quality audio experience"}/>
    </div>
  
    </div>
    
  )
}

export default RocksScreenFeatures

interface RocksFeatureType{
    image:string,
    heading:string,
    text:string,

}

const Feature:React.FC<RocksFeatureType> =({image ,heading,text})=>{
    return (
        <div className='flex items-start justify-between space-x-4'>
                   <img src={`/images/rocksplayerScreen/${image}.png`} alt={heading} className='w-16  h-16 object-contain hidden md:block'/>
            <div>
            <img src={`/images/rocksplayerScreen/${image}.png`} alt={heading} className='w-10  mx-auto h-10 object-contain  md:hidden'/>

                <h1 className='text-xl md:text-2xl mt-4 text-center md:text-left font-medium text-white'>{heading}</h1>
                <p className='max-w-[200px] text-center md:text-left text-xs font-normal text-gray-200'>{text}</p>
            </div>
        </div>
    )
}