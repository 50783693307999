import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    
  } from 'react-accessible-accordion';
  
  import 'react-accessible-accordion/dist/fancy-example.css';

const GalleryFaqScreen = () => {
  return (
    <div className='w-3/4 mx-auto mt-20'>
      <Accordion className='' allowZeroExpanded={true}>
            <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
             
                <AccordionItemHeading 
                className='accordion__arrow'   >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                      1. What is the purpose of the gallery photo editing app?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel> 
                    <p className='!text-gray-300 !ml-6'>
                      The Gallery: photo editing app as the name suggests is a premium photo editing app for android devices. The app edits your photos as you like and produces high-quality results.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>


            <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
                <AccordionItemHeading  className='accordion__arrow' >
                <AccordionItemButton 
                  
                    className='accordion__button !bg-transparent
                    !text-white  !mt-3
                     !rounded-lg'
                     >
                    2.     What are the features of Gallery: photo editing app?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                      Your photos' saturation, brightness, and contrast can all be adjusted. You can crop, rotate, resize, and rename photos, add filters and effects, stickers, backgrounds, and frames, create collages, hide media files, and so on.

                    </p>
                    <img src="/images/faq/gallery/galleryFAQ2.webp" 
                    alt="Gallery Crop, Rotate etc" className='w-56 !mt-3 mb-7'/>
               
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
                <AccordionItemHeading  className='accordion__arrow'>
                <AccordionItemButton 
                 
                    className='accordion__button !bg-transparent
                    !text-white  !mt-3
                     !rounded-lg' 
                     >
                   3.       	What to do if you forgot the password to the vault folder?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                       If you happen to forget the pin for the app's secret folder, you can enter the email address you entered when you first set the pin. When you click on 'forgot password,' you will be asked to enter the same email address again, and you will receive further assistance via email.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
                <AccordionItemHeading  className='accordion__arrow'>
                <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white  !mt-3
                     !rounded-lg'
                     >
                  4.       	How can I locate the album I am looking for without manually scrolling all the way down?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                       To find the folder you're looking for, simply type its name into the app's search bar.
                    </p>
                    <div className='flex items-center justify-center space-x-10 mt-10'>
                        <img src="/images/faq/gallery/galleryFAQ4-1.webp" className='w-72 rounded-xl' 
                        alt="Search Bar Gallery" />
                        <img src="/images/faq/gallery/galleryFAQ5.webp" 
                        className='w-80' alt="Gallery Search folder" />
                    </div>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
                <AccordionItemHeading  className='accordion__arrow'>
                <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white  !mt-3
                     !rounded-lg'
                     >
                   5.       	How to move photos from one album to another?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                       In Android versions less than 11, you can easily move or copy photos from one album to another. If your Android version is 11 or higher, you can use the <a href="https://tinyurl.com/rareprob-File-Manager" className='text-blue-400 font-medium'>ASD File Manager app</a>  to do this easily while managing all of your files efficiently.
                    </p>
                   
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
                <AccordionItemHeading  className='accordion__arrow'>
                <AccordionItemButton 
                  
                    className='accordion__button !bg-transparent
                    !text-white  !mt-3
                     !rounded-lg'
                     >
                    
                     6.       	Can I restore the deleted photos from this app?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>

                      Yes, you can recover deleted photos and videos from this app. All you have to do is click on the trash basket icon 🗑️ in the app's top right corner. Select the media file to be restored and press the restore button. Your restored photo will now be visible in its original folder/album. However, make sure you don't delete the photo permanently because you won't be able to recover it if you do.
                    </p>
                    <img src="/images/faq/gallery/galleryFAQ3.webp" 
                    alt="Gallery Crop, Rotate etc" className='w-56 !mt-3 mb-7'/>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
                <AccordionItemHeading  className='accordion__arrow'>
                <AccordionItemButton 
                  
                    className='accordion__button !bg-transparent
                    !text-white  !mt-3
                     !rounded-lg'
                     >
                   7.       	How can I edit my photos professionally for free?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                       The Gallery: photo editing app is ideal for creating high-quality photos with exclusive features such as colour correction, filters, stickers, backgrounds, and more. The photos you edit with the app are social media-ready and will get you more likes and appreciation.

                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
                <AccordionItemHeading  className='accordion__arrow'>
                <AccordionItemButton 
                   
                    className='accordion__button !bg-transparent
                    !text-white  !mt-3
                     !rounded-lg'
                     >
                   8.       	Does the Gallery app give you high-quality edited pictures?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                      Yes, unlike other photo editing apps that degrade the original quality of your picture after editing it, the Gallery: photo editing app produces high-quality edited pictures.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
                <AccordionItemHeading  className='accordion__arrow'>
                <AccordionItemButton 
                 
                    className='accordion__button !bg-transparent
                    !text-white  !mt-3
                     !rounded-lg'
                     >
                  9.       	How can you save WA status on your device using the Gallery app?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                     The Gallery photo editing app automatically saves the WhatsApp status you have watched on your device. Simply open the Gallery app and click on the ‘WA status section’ and find all the statuses you have watched. From here you can download the status you want.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
                <AccordionItemHeading  className='accordion__arrow'>
                <AccordionItemButton 
                   
                    className='accordion__button !bg-transparent
                    !text-white  !mt-3
                     !rounded-lg'
                     >
                   10.     	How to sort albums?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                       In the Gallery photo editing app, you can sort the albums alphabetically (A-Z) or chronologically (by date) (Newest to oldest) and vice-versa.
                    </p>
                    <div className='flex items-center justify-center space-x-10 mt-10'>
                        <img src="/images/faq/gallery/galleryFAQ4.webp" className='w-72' 
                        alt="Gallery Filter" 
                        />
                        <img src="/images/faq/gallery/galleryFAQ6.webp" className='w-56' 
                        alt="Gallery Filter" 
                        />

                    </div>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
                <AccordionItemHeading  className='accordion__arrow' >
                <AccordionItemButton 
                  
                    className='accordion__button !bg-transparent
                    !text-white  !mt-3
                     !rounded-lg'
                     >
                    11.     	Can I make a collage using this app?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                      The Gallery photo editing app allows you to make collages from two to nine photos. You can change the border and its size, layout design, add filters, backgrounds, neon stickers, emojis, and text, and modify the colour correction of the collage.


                    </p>
                    <img src="/images/faq/gallery/galleryFAQ1.webp" 
                    alt="Gallery Crop, Rotate etc" className='w-56  !mt-3 mb-7'/>
               
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
                <AccordionItemHeading  className='accordion__arrow'>
                <AccordionItemButton 
                 
                    className='accordion__button !bg-transparent
                    !text-white  !mt-3
                     !rounded-lg'
                     >
                 12.     	Can you use the app to doodle on your photos?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                      Yes, you can doodle on your photos in any colour with thick or thin strokes using the Gallery photo editing app.

                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
                <AccordionItemHeading  className='accordion__arrow'>
                <AccordionItemButton 
                 
                    className='accordion__button !bg-transparent
                    !text-white  !mt-3
                     !rounded-lg'
                     >
                
                   13.     	Can you make your photo black and white?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Yes, the Gallery photo editing app provides a plethora of high-end filters and effects for your photos; you can select the appropriate filter in black and white effects and edit your photos to your liking!

                    </p>
                </AccordionItemPanel>
            </AccordionItem>

        </Accordion>
    </div>
  )
}

export default GalleryFaqScreen