import React from "react";
import { Link } from "react-router-dom";

interface BlogContainerType {
  name: string;
  image: string;
  text: string;
  path: string;
  logo: string;
  altz: string;
  date: string;
}

const BlogContainer: React.FC<BlogContainerType> = ({
  name,
  image,
  text,
  path,
  logo,
  altz,
  date,
}) => {
  return (
    <Link
      to={path}
      className="bg-gray-200  xl:w-[280px] h-[510px]  px-4  py-3  rounded-3xl"
    >
      <div className="flex items-center justify-start">
        {logo !== "" && <img src={logo} alt="gallery icon" className="w-8" />}
        <h1 className="text-sm md:text-lg font-semibold ml-4">{name}</h1>
      </div>
      <div className="">
        <img
          loading={"lazy"}
          src={image}
          alt={altz}
          className="w-full md:w-full h-60 object-top lg:object-fill rounded-xl mt-6"
        />
        <p className="mt-2 px-4 text-gray-600  inline-block  bg-opacity-50 ">
          {date}
        </p>
        <h1 className=" md:w-full text-sm md:text-[14px] font-semibold h-20 mt-3 text-center">
          {text}
        </h1>
        <button className=" rounded-lg mb-4  mx-auto items-center flex mt-0 bg-[#0a4a82]/90 hover:bg-white hover:text-[#0a4a82] text-white font-medium  px-4 py-2">
          Read more
        </button>
      </div>
    </Link>
  );
};

const NewBlogVD = () => {
  return (
    <div>
      <div className="w-[90%] mx-auto place-items-center grid lg:grid-cols-3 xl:grid-cols-4 grid-col-1 gap-10 md:gap-28">
        <BlogContainer
          name={"Video Downloader"}
          altz="Video Downloader"
          image="https://img.rareprob.com/img/website/blogs_rb/introducing-the-free-dp-generator-feature-cover.jpg"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/free-dp-generator-that-stand-out-your-profile-picture-online"
          date="13 Mar 2024"
          text="Everyone wants a picture-perfect post, stories, status, and much more to enhance their social media."
        />
        <BlogContainer
          name={"Video Downloader"}
          altz="Video Downloader"
          image="https://img.rareprob.com/img/website/blogs_rb/best-2024-apps to-download-videos-3-free-options-cover.jpg"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/best-apps-to-download-videos-2024"
          date="27 Dec 2023"
          text="Best 2024 Apps to download videos: 3 free Options "
        />
        <BlogContainer
          name={"Video Downloader"}
          altz="Video Downloader"
          image="https://img.rareprob.com/img/website/blogs_rb/how-to-download-social-media-videos-easily-from-android-cover.jpg"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/download-social-media-videos-easily-from-android"
          date="20 Dec 2023"
          text="How to download social media videos easily from Android "
        />

        <BlogContainer
          name={"Video Downloader"}
          altz="Video Downloader"
          image="https://img.rareprob.com/img/website/blogs/videoDownloader/repost/rareVd-repostCover.jpg"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/how-to-repost-on-instagram-using-asd-video-downloader"
          date="7 Jun 2023"
          text="How to Repost on Instagram using the ASD Video Dowloader app"
        />
        <BlogContainer
          name={"Video Downloader"}
          altz="Video Downloader"
          image="https://img.rareprob.com/img/website/blogs/videoDownloader/dailymotion/videoDownloader-dailymotionCover.webp"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/download-dailymotion-videos-free"
          date="24 May 2023"
          text="How ASD Video Downloader app is the best choice to download Dailymotion videos "
        />

        <BlogContainer
          name={"Video Downloader"}
          altz="Video Downloader"
          image="https://img.rareprob.com/img/website/blogs/videoDownloader/download/Artboard 11 (5) (1).webp"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/download-vimeo-videos"
          date="18 May 2023"
          text="How to download Vimeo Video with the ASD Video Downloader app"
        />
        <BlogContainer
          name={"Video Downloader"}
          altz="Video Downloader"
          image="https://img.rareprob.com/img/website/blogs/videoDownloader/download/rare-tiktok-front.webp"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/download-tiktok-videos-for-free"
          date="11 May 2023"
          text="Enjoy TikTok videos offline anytime and anywhere with the ASD Video Downloader."
        />

        <BlogContainer
          name={"Video Downloader"}
          altz="Video Downloader"
          image="https://img.rareprob.com/img/website/blogs/videoDownloader/download/rare-twitter.webp"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/Twitter-Video-Downloader"
          date="11 May 2023"
          text="Find the best way to download Twitter videos with the ASD Video Downloader."
        />
        <BlogContainer
          name={"Video Downloader"}
          altz="Video Downloader"
          image="https://img.rareprob.com/img/website/blogs/videoDownloader/download/rare-media-videos.webp"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/photo-downloader-for-social-media-platforms"
          date="9 May 2023"
          text="How to download photos from different platforms using a free Photo Downloader"
        />
        <BlogContainer
          name={"Video Downloader"}
          altz="Video Downloader"
          image="https://img.rareprob.com/img/website/blogs/videoDownloader/download/rare-asd-videoDownloader.webp"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/Safe-Instagram-Video-Downloader"
          date="9 May 2023"
          text="What are the 3 safest ways to download Instagram videos with ASD Video Downloader?"
        />
        <BlogContainer
          name={"Video Downloader"}
          altz="Video Downloader"
          image="https://img.rareprob.com/img/website/blogs/videoDownloader/videoDownloaderFourFeatures/rare-videoAllFormatCover.webp"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/download-social-media-videos"
          date="17 March 2023"
          text="Top 4 Reasons to download social media videos from ASD Video Downloader"
        />
        <BlogContainer
          name={"Video Downloader"}
          altz="Video Downloader"
          image="https://img.rareprob.com/img/website/blogs/videoDownloader/instagram-reel/cover image (1).webp"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/ways-to-download-instagram-reels-fast"
          date="5 March 2023"
          text=" Best 3 ways to download Instagram reels with fast downloader              "
        />
        <BlogContainer
          name={"Video Downloader"}
          altz="Video Downloader"
          image="https://img.rareprob.com/img/website/blogs/videoDownloader/rare-FacebookDCover.webp"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/easy-way-to-download-facebook-videos"
          date="26 Feb  2023"
          text=" Top 3 ways to download Facebook videos with fast Facebook video downloader              "
        />
        <BlogContainer
          name={"Video Downloader"}
          altz="Video Downloader"
          image="https://img.rareprob.com/img/website/blogs/videoDownloader/rVideoDCover.webp"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/download-videos-with-video-detection"
          date="19 Feb  2023"
          text=" How does Video detection help in ASD Video Downloader?              "
        />
        <BlogContainer
          name={"Video Downloader"}
          altz="Video Downloader"
          image="https://img.rareprob.com/img/website/blogs/videoDownloader/rare-videoDHCover.webp"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/manage-bookmarks-and-Downloads"
          date="12 Feb  2023"
          text=" Manage bookmarks and downloads easily with Video Downloader               "
        />
        <BlogContainer
          name={"Video Downloader"}
          altz="Video Downloader"
          image="https://img.rareprob.com/img/website/blogs/videoDownloader/cover (4).webp"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/manage-storage"
          date="5 Feb  2023"
          text=" Manage the storage, free up files space with Video Downloader 2023              "
        />
        <BlogContainer
          name={"Video Downloader"}
          altz="Video Downloader"
          image="https://img.rareprob.com/img/website/blogs/videoDownloader/new/videoDownloader-cover.webp"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/secure-your-private-videos-using-video-downloader"
          date="28 Jan  2023"
          text=" Best locker to keep the private files safe with password protection"
        />
        <BlogContainer
          name={"Video Downloader"}
          altz="Video Downloader"
          image="https://img.rareprob.com/img/website/blogs/videoDownloader/cover (1).webp"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/insta-video-downloader"
          date="19 Jan  2023"
          text="Save videos with free insta video downloader 
          "
        />
        <BlogContainer
          name={"Video Downloader"}
          altz="Video Downloader "
          image="https://img.rareprob.com/img/website/blogs/videoDownloader/Artboard 2 (5).webp"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/browser"
          date="8 Jan  2023"
          text="Built-in Browser for Faster Downloads | Video Downloader"
        />
        <BlogContainer
          name={"Video Downloader "}
          altz="Video Downloader "
          image="https://img.rareprob.com/img/website/blogs/videoDownloader/Artboard 1 (4).webp"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/how-to-download-social-media-photos"
          date="30 Dec 2022"
          text="Download social media photos on your device | Video Downloader              "
        />
        <BlogContainer
          name={"Video Downloader "}
          altz="Video Downloader "
          image="https://img.rareprob.com/img/website/blogs/videoDownloader/front  image.jpg"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/online-video-downloader"
          date="22 Dec 2022"
          text="Watch your favourite social media videos offline | Video Downloader            "
        />
      </div>
    </div>
  );
};

export default NewBlogVD;
