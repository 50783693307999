import { createContext, useState } from "react";

export const RadioContext = createContext({
  currentChannel: {},
  playingState: false,
  index: "",
  data: [],
  playing: "",
});

export const RadioProvider = ({ children }) => {
  const [currentChannel, setCurrentChannel] = useState({});
  const [playingState, setPlayingState] = useState(false);
  const [index, setIndex] = useState("");
  const [playing, setPlaying] = useState("");

  const [storeData, setStoreData] = useState([]);

  const value = {
    currentChannel,
    setCurrentChannel,
    playingState,
    setPlayingState,
    playing,
    setPlaying,
    storeData,
    setStoreData,
    index,
    setIndex,
  };

  return (
    <RadioContext.Provider value={value}>{children}</RadioContext.Provider>
  );
};
