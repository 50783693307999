import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Header from "../../comp/Header";
import RocksScreenFeatures from "../../comp/RocksScreenFeatures";
import FooterHub from "../../comp/FooterHub";
import NewFooter from "../../comp/NewFooter";
const { Fade, Rotate } = require("react-reveal");

const RockPlayerScreen = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Best Asd video player app for android phones – Rocks Player
        </title>

        <meta
          name="description"
          content="Rocks player is the HD video player that supports all video format. This video downloader app supports subtitles, gesture control, dark mode, and a floating window."
        />
      </Helmet>
      <nav className="fixed z-50">
        <Header color="bg-black" page="app" />
      </nav>
      <main className="bg-gradient-to-t from-slate-800 to-black text-white">
        <a
          href="https://tinyurl.com/rocksplayer"
          className="fixed z-50 bottom-10 lg:bottom-2 right-2 rounded-3xl px-7  drop-shadow-2xl
        py-3 bg-white text-black hover:scale-105 transition-all font-semibold"
        >
          Install App
        </a>

        <div
          className="
       font-sans
       md:bg-cover
       bg-contain
       md:bg-center
       bg-hero-pattern
       space-x-40
     flex items-center justify-center  bg-no-repeat
     h-[550px] md:h-[500px]  p-4 md:p-20"
        ></div>

        <div className="flex flex-col -mt-52 md:mt-10 md:flex-row items-center justify-center md:sapce-x-40">
          <Rotate left top>
            <img
              src="/images/rocksplayerScreen/8-min.webp"
              loading={"lazy"}
              alt=""
              className="w-[300px] md:w-[400px]  object-contain mt-10"
            />
          </Rotate>
          <div className="ml-4 md:ml-20">
            <h1 className="text-[20px]  md:text-[55px] w-[300px] md:w-[500px] text-center md:text-left font-bold">
              Built-in Music player
            </h1>
            <p className="text-sm mb-10 md:text-lg w-[300px] md:w-[600px] text-center md:text-left font-medium mt-4">
              {" "}
              Dive into the world of Music with{" "}
              <a
                href="/blogs/music-player-for-all-type-of-audio-and-video-formats"
                className="text-blue-600 font-medium"
              >
                Music Rocks
              </a>
              ! Music Rocks is designed with the concept to make your musical
              experience indelible. Music rocks is equipped with plethora of
              features including, built-in video player, car mode feature, calm
              sleep music, radio Fm, sleep timer and more. To magnify your
              musical journey, music rocks is all set with tremendous
              functionalities.
            </p>
            <a
              href="https://tinyurl.com/rocksplayer"
              target={"_blank"}
              rel="noreferrer"
            >
              <img
                src="/images/homepage/google.webp"
                alt="google"
                className="w-32  rounded-xl p-2 mx-auto md:ml-0 hover:scale-105 transition-all ease-in-out  md:w-56 cursor-pointer "
              />
            </a>
          </div>
        </div>
        <div className="flex items-center justify-center flex-col md:flex-row md:space-x-40 mt-10 text-right">
          <img
            loading={"lazy"}
            src="/images/rocksplayerScreen/2.webp"
            alt="Built-in Music player"
            className="w-[250px] md:w-[400px]  md:hidden object-cover"
          />

          <div>
            <h1 className="text-[20px] text-center md:text-left  md:text-[55px] font-bold w-[300px] md:w-[500px]">
              Video Downloader
            </h1>
            <p className="text-sm md:text-lg w-[300px] text-center md:text-left md:w-[600px] font-medium mt-4">
              Hassle free video downloader for all your social media videos.{" "}
              <br />
              Video Downloader lets you download all your favourite social media
              videos along with Full HD built-in video player. This app supports
              fast and secure downloading to make provide you the best possible
              app experience
            </p>
            <a
              href="https://tinyurl.com/rocksplayer"
              target={"_blank"}
              rel="noreferrer"
            >
              <img
                src="/images/homepage/google.webp"
                alt="google"
                className="w-32  rounded-xl mx-auto md:ml-0  mt-10 p-2 hover:scale-105 transition-all ease-in-out  md:w-56 cursor-pointer "
              />
            </a>
            {/* <a rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.rocks.music.videoplayer&hl=en_IN&gl=US" target={"_blank"}>
                 <div className='border w-32 border-white mx-auto md:ml-0 rounded-3xl p-2  mt-4 text-center  cursor-pointer' >
                     Download 
                 </div>
             </a> */}
          </div>
          <img
            loading={"lazy"}
            src="/images/rocksplayerScreen/2.webp"
            alt="Video Downloader"
            className="w-[300px] md:w-[320px] rounded-xl hidden md:block object-cover"
          />
        </div>
        <RocksScreenFeatures />
        <div className=" text-white  bg-[url('https://jthemes.net/themes/wp/nextapp/wp-content/themes/nextapp/images/hero-1.jpg')] mt-20 mx-2 md:mx-28 rounded-3xl h-[500px] flex flex-col items-center justify-center">
          <h1 className="text-sm md:text-xl px-20 text-center font-medium text-white">
            <strong className="text-xl md:text-5xl mb-8">
              Gratitude Gratitude Gratitude!
            </strong>{" "}
            <br />
            It's been three years and our users have been showing this constant
            love towards our app and here's the result
          </h1>
          <div className="flex items-center justify-center text-4xl space-x-10 mt-10">
            <h1 className="text-center font-bold text-2xl">
              App Installs <br /> 3Cr+
            </h1>
            <h1 className="text-center font-bold text-2xl">
              {" "}
              App Rating <br /> 4.3
            </h1>
          </div>
        </div>
        <div className="flex flex-col md:flex-row mt-20 items-center justify-center ">
          <div>
            <h1 className="text-[20px] text-center md:text-left  md:text-[55px] font-medium w-[300px] md:w-[500px]">
              Built-in Video To Mp3 Converter
            </h1>
            <p className="w-[300px] text-center md:text-left  md:w-[500px] text-sm md:text-xl font-normal">
              Convert mp4 into mp3 with as quick as a flash! Rocks Video Player
              is designed with in-built feature of video to mp3 converter that
              lets you convert your favourite video files into mp3. You can
              explore variety of features with this app like, audio merger, set
              ringtones,audio cutter and more
            </p>
            <a
              href="https://tinyurl.com/rocksplayer"
              target={"_blank"}
              rel="noreferrer"
            >
              <img
                src="/images/homepage/google.webp"
                alt="google"
                className="w-32  rounded-xl mx-auto md:ml-0  mt-10 p-2 hover:scale-105 transition-all ease-in-out  md:w-56 cursor-pointer "
              />
            </a>
            {/* <a rel="noreferrer"  href="https://play.google.com/store/apps/details?id=com.rocks.music.videoplayer&hl=en_IN&gl=US" target={"_blank"}>
                 <div className='border w-28 mx-auto border-white text-center rounded-3xl p-2 mt-8 cursor-pointer' >
                     Download
                 </div>
             </a> */}
          </div>
          <img
            loading={"lazy"}
            src="/images/rocksplayerScreen/Group 6rocks player mp3-min.webp"
            alt="Built-in Video To Mp3 Converter"
            className="h-[600px] w-[650px] object-contain mt-20"
          />
        </div>
        <div
          className="mx-4 md:mx-20 rounded-3xl  items-center justify-center flex flex-col md:flex-row  h-[500px] mt-10 text-white space-x-40 
     bg-gradient-to-r
    from-[#3a65d2] to-[#6688de]
     "
        >
          <div>
            <h1 className=" text-2xl text-center md:text-left md:text-5xl md:w-96 font-medium">
              Online Videos and Status Saver
            </h1>
            <p className="text-center px-2 md:text-left md:w-[340px] text-normal">
              Rocks Video Player is equipped with this capability, which
              includes trending videos, trending news about technology, sports,
              entertainment, and more, to provide you the best online video
              search experience and along with that Rocks Video Player lets you
              save WA status with hassle free experience.
            </p>
          </div>
          {/* <p className='text-xs w-[700px] text-center'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ea quisquam laborum aspernatur soluta nostrum aliquid mollitia voluptatibus error</p> */}
          <div></div>
          <div className="relative hidden md:block">
            <Fade left>
              <img
                loading={"lazy"}
                src="/images/rocksplayerScreen/6.webp"
                alt=""
                className="w-[200px] absolute top-16 left-40 z-40"
              />
            </Fade>

            <img
              loading={"lazy"}
              src="/images/rocksplayerScreen/9.webp"
              alt="Online Videos and Status Saver"
              className="w-[260px] relative z-20"
            />
          </div>
          {/* <img src="images/Group 1.png" alt="logo" className='w-[400px]'/> */}
        </div>
        {/* <div className=' p-20 flex flex-col items-center justify-center'>
         <h1 className='text-6xl mb-10 '>Next App is Cross Platform App</h1>
         <img src="https://jthemes.org/wp/nextapp/wp-content/uploads/2019/03/cross-platform.png" alt="" />
     </div> */}
        {/* <div className='flex flex-col items-center justify-center mt-20'>
         <h1 className='text-2xl  md:text-5xl font-medium text-center md:text-left'>Visually appealing & friendly UI for YOU</h1>
     </div> */}
        {/* <RocksPlayerSwiper/> */}
        <div className="h-44">

        </div>
        <NewFooter color="bg-black"/>
      </main>
    </>
  );
};

export default RockPlayerScreen;
