import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    
  } from 'react-accessible-accordion';
  
  // Demo styles, see 'Styles' section below for some notes on use.
  import 'react-accessible-accordion/dist/fancy-example.css';

const RadioFaqScreen = () => {
  return (
    <div className='w-3/4 mx-auto mt-20'>
    <Accordion className='' allowZeroExpanded={true}>
          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}}>
           
          <AccordionItemHeading 
                className='accordion__arrow'   >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                  1.   What is the ASD Radio Monkey app for?
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                    The ASD Radio Monkey app is a live AM FM Radio app, that lets you listen to live radio stations worldwide. Apart from listening to live FM Radio the app also includes features like a Sleep Timer, a Sound Equalizer, an in-built recorder, colourful themes, an equalizer, multiple language support, and free online games! 
                  </p>
              </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}}>
          <AccordionItemHeading 
                className='accordion__arrow'  >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                   2. How many radio stations are there in the ASD Radio Monkey app?
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                    More than 30000 radio stations can be accessed with the ASD Radio Monkey app. These AM and FM radios are from around the world and are available in multiple languages.
                  </p>
                  {/* <img src="/images/faq/tweedle/faqTweedleUnlock.webp" 
                  className='w-56 mt-6'
                  alt="Pro Ringtones" /> */}
              </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}}>
          <AccordionItemHeading 
                className='accordion__arrow'   >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                  3. How can I record live radio?
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                    As your favourite radio station continues to play, click on the mic button to record your favourite show live. You can then refer to the recordings and enjoy your favourite show offline anytime.
                  </p>
                  <img src="/images/faq/radio/faqRadioMonkey.webp" 
                  className='w-56 mt-6'
                  alt="Fvourite Radio Stations" />
              </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}}>
          <AccordionItemHeading 
                className='accordion__arrow'    >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                  4. Are the radio stations live or recorded?
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                     All radio stations run live on the app just as they are currently running in their region. However, you can record these live stations and listen to them anytime.
                  </p>
               
              </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
          <AccordionItemHeading 
                className='accordion__arrow'   >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                  5. How many languages can you hear radio stations in?
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                     The ASD Radio Monkey app supports radio stations in more than 150 languages. You can listen to radio stations in languages like Spanish, German, Chinese, French, Greek, Italian, Russian, Romanian, Arabic, Turkish, Swedish, Ukrainian, Hindi, Japanese, Norwegian, Korean, Danish, Tamil, Hebrew, Punjabi and many other languages along with English!
                  </p>
                  <img src="/images/faq/radio/faqRadioMonkeylanguage.webp" 
                  className='w-56 mt-6'
                  alt=" Radio Stations" />
              </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}}>
          <AccordionItemHeading 
                className='accordion__arrow'   >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                  6.  Can I explore radio stations for specific genres?
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                    Yes, there is a whole section dedicated to specific genres like news, sports, religion, business, dance, rock, folk, pop, and more.
                  </p>
                  <img src="/images/faq/radio/faqRadioMonkeyCategories.webp" 
                  className='w-56 mt-6'
                  alt="Radio Stations Genres" />
              </AccordionItemPanel>
          </AccordionItem>

     


      </Accordion>
  </div>
  )
}

export default RadioFaqScreen