import { getAnalytics, logEvent } from "firebase/analytics";
import "./Puzzlegames.css";

const PuzzleGames = () => {
  const analytics = getAnalytics();

  return (
    <div
      className="puzzle_container"
      onPointerOver={() => window.adsbygoogle.push({})}
    >
      <h2>Puzzle Games</h2>
      <div>
        <a
          href="https://play.rareprobgames.com/WoodieBricks2/index.html"
          rel="noreferrer"
          onClick={() =>
            logEvent(analytics, "Rareprob Puzzle Games", {
              content_type: "Woodie Brick",
              content_id: "Woodie Brick",
              items: [{ name: "Woodie Brick" }],
            })
          }
        >
          <img
            src="https://play-lh.googleusercontent.com/e_8zrZeMjzFKsx5TcXPbOgCgJfzs_avCTGNR3kAq69AW7XftFwIngR76gBp1u-tMPa4=w240-h480-rw"
            alt="Tic Tac Toe"
          />
          <h4>Woodie Brick</h4>
        </a>
        <a
          href="https://play.rareprobgames.com/tictactoe/index.html"
          rel="noreferrer"
          onClick={() =>
            logEvent(analytics, "Rareprob Puzzle Games", {
              content_type: "Tic Tac Toe",
              content_id: "Tic Tac Toe",
              items: [{ name: "Tic Tac Toe" }],
            })
          }
        >
          <img src="/images/gamesScreen/new/tic.jpg" alt="X&O Game" />
          <h4>X&O Game</h4>
        </a>
        <a
          href="https://play.rareprobgames.com/2048/index.html"
          rel="noreferrer"
          onClick={() =>
            logEvent(analytics, "Rareprob Puzzle Games", {
              content_type: "2048",
              content_id: "2048",
              items: [{ name: "2048" }],
            })
          }
        >
          <img src="/images/gamesScreen/new/2048.jpg" alt="2048" />
          <h4>2048</h4>
        </a>
        <a
          href="https://play.rareprobgames.com/water-sort-puzzle/index.html"
          rel="noreferrer"
          onClick={() =>
            logEvent(analytics, "Rareprob Puzzle Games", {
              content_type: "Water Sort",
              content_id: "Water Sort",
              items: [{ name: "Water Sort" }],
            })
          }
        >
          <img src="/images/gamesScreen/new/water.jpg" alt="Water Sort" />
          <h4>Water Sort</h4>
        </a>
        <a
          href="https://play.rareprobgames.com/FindDiff2.0/index.html"
          onClick={() =>
            logEvent(analytics, "Rareprob Puzzle Games", {
              content_type: "Find the difference",
              content_id: "Find the difference",
              items: [{ name: "Find the difference" }],
            })
          }
          rel="noreferrer"
        >
          <img
            src="/images/gamesScreen/new/find.jpg"
            alt="Find the difference"
          />
          <h4>Find the difference</h4>
        </a>
      </div>
    </div>
  );
};

export default PuzzleGames;
