import React, { useEffect, useState } from "react";
import { pageDesign } from "../firebase";
import GridMobileView from "../screens/GridMobileView/GridMobileView";

import NewGamesScreen from "../screens/NewGamesScreen";
import { Helmet } from "react-helmet";

const GamePages = () => {
  const [pageNo, setPageNo] = useState(Number);
  useEffect(() => {
    pageDesign.then((result) => {
      setPageNo(result);
    });
  });
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Rareprob : Play Free Online Web | Android | iOS | Games</title>

        <meta
          name="description"
          content="Play Free Online Brain Games like- Find Difference, Tic-Tac-Toe, and Stack Ball with Multiplayer and Single-player features. Enjoy the best 2D and 3D Arcade games| Rareprob."
        />
        <meta
          property="og:title"
          content="Rareprob : Play Free Online Web | Android | iOS | Games"
        />
        <meta
          property="og:description"
          content="Play Free Online Brain Games like- Find Difference, Tic-Tac-Toe, and Stack Ball with Multiplayer and Single-player features. Enjoy the best 2D and 3D Arcade games| Rareprob."
        />
        <meta
          name="twitter:title"
          content="Rareprob : Play Free Online Web | Android | iOS | Games"
        />
        <meta
          name="twitter:description"
          content="Play Free Online Brain Games like- Find Difference, Tic-Tac-Toe, and Stack Ball with Multiplayer and Single-player features. Enjoy the best 2D and 3D Arcade games| Rareprob."
        />
      </Helmet>
      <div>
        {pageNo === 1 ? (
          ""
        ) : pageNo === 2 ? (
          <GridMobileView />
        ) : pageNo === 3 ? (
          <NewGamesScreen />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default GamePages;
