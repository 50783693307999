import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Header from "../comp/Header";
import HomeApps from "../comp/HomeApps";
import NewFooter from "../comp/NewFooter";

const AppScreen = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Explore Our Utility Mobile Apps for Every Need | Rareprob</title>

        <meta
          name="description"
          content="Discover a range of utility mobile apps designed to enhance productivity, entertainment, and convenience on various platforms."
        />
      </Helmet>
      <nav>
        <Header color="bg-gray-700/20" page="apps" />
      </nav>
      <main
        className=" h-full text-white  pt-32  overflow-hidden  bg-gradient-to-t from-[#66369C] via-[#442e89] to-[#185589]"
        id="start"
      >
        <HomeApps gamePage={false} page="apps" />
        <div className="h-44"></div>
        <NewFooter color="bg-black/10" />
      </main>
    </>
  );
};

export default AppScreen;
