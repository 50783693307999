import React, { useEffect } from "react";
import Header from "../comp/Header";
import { Helmet } from "react-helmet";
import OurTeam from "../comp/OurTeam";
import NewFooter from "../comp/NewFooter";
import { scroll } from "../comp/ScrollToTop";

const About = () => {
  useEffect(scroll, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Rareprob Solutions Private Limited | About Us</title>
        <meta
          name="description"
          content="About - Rareprob Solutions is a Private Limited App development company ,a collection of the best prime applications globally."
        />
      </Helmet>
      <nav>
        <Header color="bg-purple-700" page="about" />
      </nav>
      <div
        className=" pt-40 bg-gradient-to-b from-[#66369C] via-[#442e89] to-[#185589]"
        id="start"
      >
        <main className="w-3/4 mx-auto text-white pb-10">
          <h1 className="text-[30px]  md:text-[55px] font-bold text-center">
            About Us{" "}
          </h1>
          <h2 className="text-sm md:text-xl text-center mt-4">
            Rareprob Solutions is a Private Limited Company incorporated on 28
            September 2018. As everything is evolving with time so people are
            looking for digital solutions for accomplishing their needs. We
            believe every new thing starts with a unique and authentic plan and
            this is the reason that our team is working with the concept of
            concretizing ideas into utilizable apps With our user’s constant
            faith and love,{" "}
            <a href="/" className="text-blue-500">
              Rareprob
            </a>{" "}
            is growing well with some of our leading applications like Rocks
            Video Player, File Manager, Music Player, Video to Mp3 Converter,
            Gallery: Photo Editor and a lot more.
          </h2>
          <div className="flex flex-col md:flex-row items-center justify-between mt-40">
            <div>
              <h1 className="text-[30px] text-center md:text-left  md:text-[55px] font-bold ">
                VISION
              </h1>
              <h2 className="w-72 text-lg md:w-96 mt-4 text-center md:text-left font-semibold">
                To accelerate the world of apps with the productivity and
                utility in the world of app development. We are committed to
                explore authentic plans to develop apps with the plethora of
                features.
              </h2>
            </div>
            <img
              src="/images/aboutScreen/Group 253.svg"
              loading={"lazy"}
              alt="Rareprob : vision"
              className="w-[400px] mt-10 md:mt-0 rounded-2xl"
            />
          </div>
          <div className="flex md:hidden flex-col md:flex-row items-center justify-between mt-40">
            <div>
              <h1 className="text-[30px] text-center md:text-left  md:text-[55px] font-bold ">
                MISSION
              </h1>
              <h2 className="w-72 md:w-96 text-lg mt-4 text-center md:text-left font-semibold">
                Our emphasis is on expanding the ideas and plans to provide
                distinctive and well-designed apps to build user’s trust in the
                field of application world.
              </h2>
            </div>
            <img
              src="/images/aboutScreen/Group 277.svg"
              loading={"lazy"}
              alt="Rareprob : MISSION"
              className="w-[400px] mt-10 md:mt-0 rounded-2xl"
            />
          </div>
          <div className=" md:flex hidden items-center justify-between mt-40">
            <img
              src="/images/aboutScreen/Group 277.svg"
              loading={"lazy"}
              alt="graphic"
              className="w-[600px] rounded-2xl"
            />
            <div>
              <h1 className="text-5xl font-bold ">MISSION</h1>
              <h2 className="w-96 mt-4 font-semibold text-lg text-left">
                Our emphasis is on expanding the ideas and plans to provide
                distinctive and well-designed apps to build user’s trust in the
                field of application world.
              </h2>
            </div>
          </div>
          <OurTeam />
        </main>
        <div className="h-32"></div>
        <NewFooter color="bg-black/10" />
      </div>
    </>
  );
};

export default About;
