import React, { useEffect } from "react";
import FooterHub from "../../comp/FooterHub";
import { Helmet } from "react-helmet";
import Header from "../../comp/Header";

const GalleryBlog = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Perfect App for Photo Editor and Collage Maker | Gallery App
        </title>

        <meta
          name="description"
          content="Are you looking for some information about our gallery app? visit Our blog for more knowledge on photo editor and collage maker App."
        />
      </Helmet>
      <Header color="bg-purple-800" page="blogs" />
      <main className="w-full px-6 overflow-hidden md:px-44 text-lg text-black mx-auto pt-20 bg-white">
        <h1 className="text-5xl font-bold mt-10">
          GALLERY: PHOTO EDITOR AND COLLAGE MAKER
        </h1>
        <p className="mt-10">
          Creating visually appealing photos isn’t difficult anymore! Have you
          ever wondered for the easy to use photo editor that can make your
          pictures look picture perfect? Let’s know about{" "}
          <a
            href="https://tinyurl.com/Rareprob-photosgallery"
            className="text-blue-600 font-medium"
          >
            GALLERY APP
          </a>
          . A perfect app for photo editing along with numerous of filters and
          effects. This app supports variety of features for free and it
          includes photo editor, collage maker, photo hider, IG videos
          downloader and much more.
        </p>
        <h1 className="text-3xl font-medium mt-10">Why Choose Gallery App?</h1>
        <p className="mt-2">
          Gallery supports variety of features along with photo editor. It
          supports in-built browser, vault feature and a lot more. To know more,
          let’s read more about its features.
        </p>
        <img
          src="https://img.rareprob.com/img/website/blogs/gallery/gallery%20main_.webp"
          alt="GALLERY: PHOTOs EDITOR AND COLLAGE MAKER"
          className="mx-auto w-44 md:w-72 mt-4"
        />
        <h1 className="text-3xl font-medium mt-10">
          PERFECT{" "}
          <a
            href="/apps/gallery-photo-editor-and-collage-maker-app"
            className="text-blue-600 font-medium"
          >
            PHOTO-EDITOR
          </a>
        </h1>
        <p className="mt-2">
          Gallery lets you edit photos with numerous of filters and effects. You
          can even set fascinating background to make your photos look picture
          perfect.
        </p>
        <img
          loading={"lazy"}
          src="https://img.rareprob.com/img/website/blogs/gallery/PERFECT%20PHOTO-EDITOR.webp"
          alt="PERFECT PHOTOs-EDITOR"
          className="mx-auto w-44 md:w-72 mt-4"
        />
        <h1 className="text-3xl font-medium mt-10">COLLAGE MAKER</h1>
        <p className="mt-2">
          Gallery supports collage maker functionality and you can find out many
          layout to make your photos stand out. Select multiple feature and fit
          them into a perfect layout.
        </p>
        <img
          loading={"lazy"}
          src="https://img.rareprob.com/img/website/blogs/gallery/collerge%20maker_.webp"
          alt="collage maker"
          className="mx-auto w-44 md:w-72 mt-4"
        />
        <h1 className="text-3xl font-medium mt-10">PHOTOS AND VIDEOS HIDER</h1>
        <p className="mt-2">Say hello to VAULT functionality.</p>
        <img
          loading={"lazy"}
          src="https://img.rareprob.com/img/website/blogs/gallery/valt.webp"
          alt="PHOTOS AND VIDEOS HIDER"
          className="mx-auto w-44 md:w-72 mt-4"
        />
        <p className="mt-2">
          Gallery lets you hide your private photos and videos with password
          protection. You can set a pin and hide your picture and videos
          accordingly.
        </p>
        <h1 className="text-3xl font-medium mt-10">IG VIDEOS DOWNLOADER</h1>
        <p className="mt-2">
          Gallery lets you{" "}
          <a
            href="https://www.rocksplayer.com/"
            className="text-blue-600 font-medium"
          >
            download IG videos
          </a>{" "}
          with no hassle. You can explore and download multiple videos with
          faster and smoother experience.
        </p>
        <img
          loading={"lazy"}
          src="https://img.rareprob.com/img/website/blogs/gallery/IG%20VIDEOS%20DOWNLOADER.webp"
          alt="IG downloader"
          className="mx-auto w-44 md:w-72 mt-4"
        />
        <h1 className="text-3xl font-medium mt-10">IN-BUILT HD VIDEO PLAYER</h1>
        <p className="mt-2">
          Gallery is all set with in-built HD video player that enhance your
          video experience. You can enjoy movies and videos with 4K quality
          along with basic functionality
        </p>
        <img
          loading={"lazy"}
          src="https://img.rareprob.com/img/website/blogs/gallery/inbuiltHDPlayer.webp"
          alt="IN-BUILT HD VIDEO PLAYER"
          className="mx-auto w-3/4 mt-4"
        />
        <p className="mt-2">
          In this Blog, We’ve mentioned some of its features. To avail its basic
          features along with prime functionality, Install{" "}
          <a href="/" className="text-blue-600 font-medium">
            GALLERY APP
          </a>{" "}
          with no delay.
        </p>
        <div className="h-44"></div>
        <FooterHub textBlack={true} />
      </main>
    </>
  );
};

export default GalleryBlog;
