import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Header from "../../comp/Header";
import FooterHub from "../../comp/FooterHub";
import NewFooter from "../../comp/NewFooter";

const MusicPlayerScreen = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Music Player - MP4, MP3 Player: free music player and HQ audio player
          for android{" "}
        </title>
        <meta
          name="description"
          content="Music Player - MP4, MP3 Player supports all formats. You can adjust the equalizer and bass booster. Enjoy free FM Radio and customized Music."
        />
      </Helmet>
      <nav className="fixed z-50">
        <Header color="bg-black " page="app" />
      </nav>
      <main className="bg-music-bg bg-center bg-no-repeat bg-cover text-white">
        <div
          className="
     bg-cover
     bg-musicPattern 
     flex md:flex-row flex-col items-center justify-between   bg-no-repeat
     md:h-[600px]  h-[900px]
     p-4 md:px-20"
        >
          <div className="mt-20 md:mt-44">
            <h1 className="md:text-5xl text-3xl text-gray-200 mt-10 md:mt-10 text-center md:text-left  md:w-[500px]">
              Discover the new aura of Music with ASD Rocks Music Player{" "}
            </h1>
            <p className=" md:w-[300px] mt-4 text-center md:text-left">
              Music Rocks is designed with Online & Offline Music including Calm
              Sleep Music, Built-in Radio and the prime feature is HD video
              player for the finest video experience
            </p>
            <div className="flex gap-5">
              <a
                href="https://tinyurl.com/Rareprob-Music-Player"
                target={"_blank"}
                rel="noreferrer"
              >
                <div className=" w-40 h-20">
                  <img
                    src="/images/homepage/google_play.png"
                    alt="google"
                    className="mt-5 w-32 h-14 hover:scale-105  transition-all ease-linear   md:w-72 cursor-pointer "
                  />
                </div>
              </a>
              {/* <a href="https://play.google.com/store/apps/details?id=com.rocks.music" target={"_blank"} rel="noreferrer">
             <img src="/images/homepage/Group 12.png" alt="google" className='w-32 border rounded-xl mx-auto md:ml-0 mt-10 p-2 hover:scale-105 transition-all ease-in-out  md:w-44 cursor-pointer '/>
           </a> */}
              {/* <img src="/images/homepage/Group 12.png" alt="google" className='w-40 md:ml-2 mx-auto mt-10 cursor-pointer ' onClick={()=>window.open('https://play.google.com/store/apps/details?id=com.rocks.music&hl=en_IN&gl=US')}/> */}
              <a
                href="https://apps.apple.com/in/app/music-player-mp4-mp3-player/id1620115069"
                target={"_blank"}
                rel="noreferrer"
              >
                <div className=" w-40 h-20">
                  <img
                    src="/images/homepage/app_store.png"
                    alt="apple"
                    className="mt-5 w-32 h-14 hover:scale-105  transition-all  ease-linear   md:w-72 cursor-pointer "
                  />
                </div>
              </a>
            </div>
          </div>
          <div>
            <img
              loading={"lazy"}
              src="/images/musicScreen/mobile.webp"
              alt="Music with Music Rocks"
              className="md:w-[450px] mx-auto mt-4 md:mt-32"
            />
          </div>
        </div>
        <h2 className="text-center font-medium md:font-bold text-3xl md:text-5xl mt-20">
          About App
        </h2>
        <p className="text-center text-sm md:text-lg mx-4 md:mx-64 font-medium mt-2">
          {" "}
          This is a powerful and high-quality mp3 player and{" "}
          <a
            href="https://play.google.com/store/apps/details?id=com.rocks.music"
            className="text-red-600 font-medium"
            target={"_blank"}
            rel="noreferrer"
          >
            video player app
          </a>{" "}
          which supports all formats of audio files and video files. You can
          locate and manage all music and audio files from your phone memory and
          SD card. Also, you can browse all your local video files and play
          status videos, trailers, movies, and any other videos stored on your
          device. You can use this free{" "}
          <a
            href="/blogs/music-player-for-all-type-of-audio-and-video-formats"
            className="text-red-600 font-medium"
          >
            offline Music Player
          </a>{" "}
          as your default mp3 player and default video player. It’s a HQ audio
          player and HD Video player where you can play Full HD, 4K, and 8K
          videos using this app. You can identify all your mp3 audio files and
          videos files on your
        </p>
        <h2 className="text-3xl md:text-5xl ml-32 mt-20 font-bold mb-20 ">
          Features{" "}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-4  mx-16 md:mx-32 gap-10">
          <MusicFeatures
            iconImage={"/images/musicScreen/equalizer.png"}
            heading={"Equalizer"}
            image="\images\musicScreen\Equalizerimage-min.webp"
            text={
              "Rocks Player is designed with the Equalizer feature thus it will improve the sound quality of your android device with the Bass Boost, Virtualizer, and Equalizer"
            }
          />
          <MusicFeatures
            iconImage={"/images/musicScreen/cs_icon_cs.svg"}
            heading={"Calm Sleep"}
            image="/images/musicScreen/Background 1c-min.webp"
            text={
              "Relax your mind with Calm Sleep Music. Music Player is designed with Calm Sleep functionality that lets you listen to soothing music for a relaxed sleep. "
            }
          />
          <MusicFeatures
            iconImage={"/images/musicScreen/ICONtheme.png"}
            heading={"Themes"}
            image="\images\musicScreen\themesmenia-min.webp"
            text={
              "Rocks Player supports captivating and magical themes so that you can switch to different themes on this app according to your preference.      "
            }
          />
          <MusicFeatures
            iconImage={"/images/musicScreen/ICONvideotomp3.png"}
            heading={"Video to Mp3 Converter"}
            image="\images\musicScreen\videotomp3-min.webp"
            text={
              "Rocks Player supports video to mp3 converter. You can extract audio from any of your selected video songs and enjoy it wherever you are.      "
            }
          />
          <MusicFeatures
            iconImage={"/images/musicScreen/ICONsleep time.png"}
            heading={"Sleep Timer"}
            image="\images\musicScreen\SleepTimer-min.webp"
            text={
              "Rocks player is all set with in-built Sleep timer. It softly fade the music out and stop it at the selected timing to prevent your device from battery drainage and heating issues.      "
            }
          />
          <MusicFeatures
            iconImage={"/images/musicScreen/ICONplaylist.png"}
            heading={"Playlists"}
            image="\images\musicScreen\Playlist-min.webp"
            text={
              "Rocks player allows you to create playlists so that you can play your favorite playlist wherever you are without searching for it.      "
            }
          />
          <MusicFeatures
            iconImage={"/images/musicScreen/ringtone.png"}
            heading={"Ringtone"}
            image="\images\musicScreen\ringtonimage-min.webp"
            text="Rocks Player is designed with the Ringtone maker functionality that lets you trim the music and set it as ringtone for your android device      "
          />
          <MusicFeatures
            iconImage={"/images/musicScreen/radio 100-100.png"}
            heading={"Radio"}
            image="\images\musicScreen\Ar-min.webp"
            text={
              "Radio Monkey is right up your alley. It is equipped with over 50,000+ radio stations, allowing users to tune in to their favourite radio stations      "
            }
          />
        </div>
        <div className="h-32"></div>
        <NewFooter color="bg-black" />
      </main>
    </>
  );
};

export default MusicPlayerScreen;

interface MusicFeatureType {
  heading: string;
  image: string;
  text: string;
  iconImage: string;
}

const MusicFeatures: React.FC<MusicFeatureType> = ({
  heading,
  image,
  text,
  iconImage,
}) => {
  return (
    <div>
      <div className="relative hover:scale-105 transition-all ease-out">
        <div className="absolute z-20  top-10 w-60 flex items-center justify-center flex-col">
          <img
            loading={"lazy"}
            src={iconImage}
            alt={heading}
            className="w-20 h-20 text-white  object-contain"
          />
          <h3 className="text-2xl text-center font-bold text-white">
            {heading}
          </h3>
        </div>
        <div>
          <img
            loading={"lazy"}
            src={image}
            alt={heading}
            className="w-56 min-w-[224px] md:w-56   h-56 object-cover object-center rounded-2xl filter  "
          />
          <div className="bg-gray-900 hover:bg-opacity-70 bg-opacity-30 w-56 md:w-56   h-56 rounded-2xl absolute  z-10 top-0 transition-all ease-out"></div>
        </div>
      </div>
      <h4 className="w-52 md:w-60 text-[10px] text-center md:text-left font-medium mt-2">
        {text}
      </h4>
    </div>
  );
};
