import React from "react";
import { Link } from "react-router-dom";

interface BlogContainerType {
  name: string;
  image: string;
  text: string;
  path: string;
  logo: string;
  altz: string;
  date: string;
}

const BlogContainer: React.FC<BlogContainerType> = ({
  name,
  image,
  text,
  path,
  logo,
  altz,
  date,
}) => {
  return (
    <Link
      to={path}
      className="bg-gray-200  xl:w-[280px] h-[510px]  px-4  py-3  rounded-3xl"
    >
      <div className="flex items-center justify-start">
        {logo !== "" && <img src={logo} alt="gallery icon" className="w-8" />}
        <h1 className="text-sm md:text-lg font-semibold ml-4">{name}</h1>
      </div>
      <div className="">
        <img
          loading={"lazy"}
          src={image}
          alt={altz}
          className="w-full md:w-full h-60 object-cover lg:object-fill rounded-xl mt-6"
        />
        <p className="mt-2 px-4 text-gray-600  inline-block  bg-opacity-50 ">
          {date}
        </p>
        <h1 className=" md:w-full text-sm md:text-[14px] font-semibold h-20 mt-3 text-center">
          {text}
        </h1>
        <button className=" rounded-lg mb-4  mx-auto items-center flex mt-0 bg-[#0a4a82]/90 hover:bg-white hover:text-[#0a4a82] text-white font-medium  px-4 py-2">
          Read more
        </button>
      </div>
    </Link>
  );
};

const NewBlogGallery = () => {
  return (
    <div>
      <div className="w-[90%] mx-auto place-items-center grid lg:grid-cols-3 xl:grid-cols-4 grid-col-1 gap-10 md:gap-28">
        <BlogContainer
          name={"Gallery"}
          altz="Gallery"
          image="https://img.rareprob.com/img/website/blogs_rb/how-to-safeguard-your-privacy-with-the-hider-feature-in-the-gallery-app-cover.jpg"
          path="/blogs/5-simple-steps-to-hide-photos-and-videos"
          logo="/images/icons/gallery.png"
          date="08 April 2024"
          text="
          Looking for quick ways to hide your data on your Android Phone? Here is the step-by-step guide on.
          "
        />
        <BlogContainer
          name={"Gallery"}
          altz="Gallery"
          image="https://img.rareprob.com/img/website/blogs_rb/how-to-create-free-ai-images-cover.jpg"
          path="/blogs/free-ai-image-generator"
          logo="/images/icons/gallery.png"
          date="26 Mar 2024"
          text="
          How to Create Free AI Images from Text in the ASD Gallery app
          "
        />
        <BlogContainer
          name={"Gallery"}
          altz="Gallery"
          image="https://img.rareprob.com/img/website/blogs_rb/all-in-one-asd-gallery-app-cover.webp"
          path="/blogs/create-your-best-christmas-memories"
          logo="/images/icons/gallery.png"
          date="22 Dec 2023"
          text="
          Create your best Christmas memories with the All-in-one ASD Gallery App
          "
        />
        <BlogContainer
          name={"Gallery"}
          altz="Gallery"
          image="https://firebasestorage.googleapis.com/v0/b/rareprob-website.appspot.com/o/rareprob%2Fcollage_cover.webp?alt=media&token=6bdd8ef6-7b3b-4b7a-bfc9-9a754e2287d7"
          path="/blogs/choose-best-free-collage-maker-android-device"
          logo="/images/icons/gallery.png"
          date="11 July 2023"
          text="
          Now Choose the Best Free Collage Maker for your Android device
          "
        />
        <BlogContainer
          name={"Gallery"}
          altz="Gallery"
          image="https://img.rareprob.com/img/website/blogs/gallery/asd_Gallery/cover_.webp"
          logo="/images/icons/gallery.png"
          path="/blogs/why-asd-gallery-app-better-than-default-gallery-apps"
          date="2 July 2023"
          text="Why ASD Gallery App is Better than Default Gallery Apps"
        />
        <BlogContainer
          name={"Gallery"}
          altz="Gallery"
          image="https://img.rareprob.com/img/website/blogs/gallery/rare-GalleryCorrectionCover.webp"
          logo="/images/icons/gallery.png"
          path="/blogs/how-to-use-color-correction-to-improve-images"
          date="12 Jun 2023"
          text="
          Discover how to use colour correction effectively to improve your photos.
          "
        />
        <BlogContainer
          name="Gallery"
          altz="Photo Editor & Collage Maker with Photo Vault"
          date="3 April 2023"
          image="https://img.rareprob.com/img/website/blogs/gallery/tenGalleryFeatures/galleryCover.webp"
          logo="/images/icons/gallery.png"
          path="/blogs/best-photo-editing-app-asd-gallery"
          text="Top 10 features of the best photo editing app to enhance your photos | ASD Gallery App"
        />
        <BlogContainer
          name="Gallery"
          altz="Photo Editor & Collage Maker with Photo Vault"
          date="1 Dec 2021"
          image="https://img.rareprob.com/img/website/blogs/gallery-front.jpg"
          logo="/images/icons/gallery.png"
          path="/blogs/gallery-photo-editor-collage-maker-app"
          text="Make your photos look picture perfect with Gallery"
        />
      </div>
    </div>
  );
};

export default NewBlogGallery;
