import Header from "../../comp/Header";
import { BlogImage, BlogLink, TargetLink } from "../../comp/Util";
import { Helmet } from "react-helmet";
import NewFooter from "../../comp/NewFooter";
import { useRef } from "react";

const RestorePicturesGallery = () => {
  const fetchSchema = () =>
    JSON.stringify({
      "@context": "https://schema.org",
      "@graph": [
        {
          "@type": "Article",
          "@id":
            "https://rareprob.com/blogs/how-to-restore-permanently-lost-pictures-gallery/#article",
          isPartOf: {
            "@id":
              "https://rareprob.com/blogs/how-to-restore-permanently-lost-pictures-gallery",
          },
          author: {
            name: "Rareprob",
            "@id": "",
          },
          headline:
            "Recover Lost Gallery Pictures: Easy Step-by-Step Guide | ASD Gallery",
          description:
            "Learn how to restore permanently deleted pictures from your gallery with our simple and effective recovery methods. Get your memories back now!",
          datePublished: "2023-07-28T15:57:51+00:00",
          dateModified: "2023-07-28T15:59:52+00:00",
          mainEntityOfPage: {
            "@id":
              "https://rareprob.com/blogs/how-to-restore-permanently-lost-pictures-gallery",
          },
          wordCount: 1143,
          publisher: {
            "@id": "https://rareprob.com/blogs/#organization",
          },
          image: {
            "@id":
              "https://rareprob.com/blogs/how-to-restore-permanently-lost-pictures-gallery/#primaryimage",
          },
          thumbnailUrl:
            "https://firebasestorage.googleapis.com/v0/b/rareprob-website.appspot.com/o/restoreImage.jpg?alt=media&token=5a2027ed-fb4b-4251-8985-648b39f72c06",
          articleSection: [
            "How to restore permanently lost pictures from the gallery",
            "Best ASD Gallery App",
          ],
          inLanguage: "en-US",
        },
        {
          "@type": "WebPage",
          "@id":
            "https://rareprob.com/blogs/how-to-restore-permanently-lost-pictures-gallery",
          url: "https://rareprob.com/blogs/how-to-restore-permanently-lost-pictures-gallery",
          name: "Recover Lost Gallery Pictures: Easy Step-by-Step Guide | ASD Gallery",
          isPartOf: {
            "@id": "https://rareprob.com/blogs/#website",
          },
          primaryImageOfPage: {
            "@id":
              "https://rareprob.com/blogs/how-to-restore-permanently-lost-pictures-gallery/#primaryimage",
          },
          image: {
            "@id":
              "https://rareprob.com/blogs/how-to-restore-permanently-lost-pictures-gallery/#primaryimage",
          },
          thumbnailUrl:
            "https://firebasestorage.googleapis.com/v0/b/rareprob-website.appspot.com/o/restoreImage.jpg?alt=media&token=5a2027ed-fb4b-4251-8985-648b39f72c06",
          datePublished: "2023-07-28T15:57:51+00:00",
          dateModified: "2023-07-28T15:59:52+00:00",
          description:
            "Learn how to restore permanently deleted pictures from your gallery with our simple and effective recovery methods. Get your memories back now!",
          breadcrumb: {
            "@id":
              "https://rareprob.com/blogs/how-to-restore-permanently-lost-pictures-gallery",
          },
          inLanguage: "en-US",
          potentialAction: [
            {
              "@type": "ReadAction",
              target: [
                "https://rareprob.com/blogs/how-to-restore-permanently-lost-pictures-gallery",
              ],
            },
          ],
        },
        {
          "@type": "ImageObject",
          inLanguage: "en-US",
          "@id":
            "https://rareprob.com/blogs/how-to-restore-permanently-lost-pictures-gallery/#primaryimage",
          url: "https://firebasestorage.googleapis.com/v0/b/rareprob-website.appspot.com/o/restoreImage.jpg?alt=media&token=5a2027ed-fb4b-4251-8985-648b39f72c06",
          contentUrl:
            "https://firebasestorage.googleapis.com/v0/b/rareprob-website.appspot.com/o/restoreImage.jpg?alt=media&token=5a2027ed-fb4b-4251-8985-648b39f72c06",
          width: 1200,
          height: 630,
        },
        {
          "@type": "BreadcrumbList",
          "@id":
            "https://rareprob.com/blogs/how-to-restore-permanently-lost-pictures-gallery",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "Home",
              item: "https://rareprob.com/",
            },
            {
              "@type": "ListItem",
              position: 2,
              name: "Blogs",
              item: "https://rareprob.com/blogs",
            },
            {
              "@type": "ListItem",
              position: 3,
              name: "How to restore permanently lost pictures from the gallery",
              item: "https://rareprob.com/blogs/how-to-restore-permanently-lost-pictures-gallery",
            },
          ],
        },
        {
          "@type": "WebSite",
          "@id": "https://rareprob.com/blogs/#website",
          url: "https://rareprob.com/blogs",
          name: "Rareprob Blog",
          description: "",
          publisher: {
            "@id": "https://rareprob.com/blogs/#organization",
          },
          potentialAction: [
            {
              "@type": "SearchAction",
              target: {
                "@type": "EntryPoint",
                urlTemplate:
                  "https://rareprob.com/blogs/?s={search_term_string}",
              },
              "query-input": "required name=search_term_string",
            },
          ],
          inLanguage: "en-US",
        },
        {
          "@type": "Organization",
          "@id": "https://rareprob.com/blogs/#organization",
          name: "Rareprob",
          url: "https://rareprob.com/blogs",
          logo: {
            "@type": "ImageObject",
            inLanguage: "en-US",
            "@id": "https://rareprob.com/blogs",
            url: "https://rareprob.com/images/homepage/rare-logo.webp",
            contentUrl: "https://rareprob.com/images/homepage/rare-logo.webp",
            width: 160,
            height: 32,
            caption: "Rareprob Logo",
          },
          image: {
            "@id": "https://rareprob.com/blogs",
          },
          sameAs: [
            "https://www.facebook.com/rareprob",
            "https://www.instagram.com/rareprob_/",
            "https://www.linkedin.com/company/rareprob/",
            "https://twitter.com/rareprob",
            "https://in.pinterest.com/rareprob/",
            "https://www.youtube.com/channel/UCNXZXijMtxueoWaNiye73AQ/",
          ],
        },
      ],
    });

  const introRef = useRef<HTMLHeadingElement>(null);
  const androidRef = useRef<HTMLHeadingElement>(null);
  const windowRef = useRef<HTMLHeadingElement>(null);
  const macRef = useRef<HTMLHeadingElement>(null);
  const lastRef = useRef<HTMLHeadingElement>(null);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Recover Lost Gallery Pictures: Easy Step-by-Step Guide | ASD Gallery
        </title>
        <meta
          name="description"
          content="Learn how to restore permanently deleted pictures from your gallery with our simple and effective recovery methods. Get your memories back now!"
        />

        {/* <!-- Facebook Meta Tags --> */}
        <meta
          property="og:url"
          content="https://rareprob.com/blogs/how-to-restore-permanently-lost-pictures-gallery"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Recover Lost Gallery Pictures: Easy Step-by-Step Guide | ASD Gallery"
        />
        <meta
          property="og:description"
          content="Learn how to restore permanently deleted pictures from your gallery with our simple and effective recovery methods. Get your memories back now!"
        />
        <meta
          property="og:image"
          content="https://firebasestorage.googleapis.com/v0/b/rareprob-website.appspot.com/o/restoreImage.jpg?alt=media&token=5a2027ed-fb4b-4251-8985-648b39f72c06"
        />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="rareprob.com" />
        <meta
          property="twitter:url"
          content="https://rareprob.com/blogs/how-to-restore-permanently-lost-pictures-gallery"
        />
        <meta
          name="twitter:title"
          content="Recover Lost Gallery Pictures: Easy Step-by-Step Guide | ASD Gallery"
        />
        <meta
          name="twitter:description"
          content="Learn how to restore permanently deleted pictures from your gallery with our simple and effective recovery methods. Get your memories back now!"
        />
        <meta
          name="twitter:image"
          content="https://firebasestorage.googleapis.com/v0/b/rareprob-website.appspot.com/o/restoreImage.jpg?alt=media&token=5a2027ed-fb4b-4251-8985-648b39f72c06"
        />
        <script type="application/ld+json">{fetchSchema()}</script>
      </Helmet>
      <Header color="bg-purple-800" page="blogs" />
      <main
        className="w-full text-lg px-6 overflow-hidden md:px-64 text-black mx-auto pt-20 bg-white"
        ref={introRef}
      >
        <h1
          id="benefits-video-downloading"
          className="text-3xl md:text-5xl mt-10 font-bold text-center"
        >
          How to restore permanently lost pictures from the gallery
        </h1>
        {/* <p className='mt-2 text-center'><i>Find how the ASD Gallery app is packed with all essential photo-enhancing features that don’t make your pictures look unreal!</i></p> */}
        <img
          src="https://img.rareprob.com/img/default/RareProbImg/imagesRare/how-to-restore-permanently-lost-pictures-gallery/restore_photos_and_videos.webp"
          alt=" Discover New Notes Feature in ASD File Manager App | Rareprob"
          className=" max-w-full hover:shadow-[0_0px_60px_1px_rgba(0,0,0,0.2)]   shadow-[0_0px_10px_6px_rgba(0,0,0,0.11)]  sm:max-h-[500px] 2xl:max-h-[500px] mx-auto  mt-10 rounded-xl object-contain"
        />
        <article className="mt-10">
          <p>
            Photos are an important part of everyone’s life nowadays. But there
            are many reasons that it may get deleted and you need to recover
            them. You might accidentally click on delete all photos or maybe
            format an SD card accidentally. <br />
            <br />
            In an instant, you can lose your precious memories or one-time
            events such as graduation or wedding which can never be replaced or
            created again. But with the{" "}
            <strong>
              ASD{" "}
              <BlogLink
                link="/apps/gallery-photo-editor-and-collage-maker-app"
                text="Gallery"
              />{" "}
              app
            </strong>{" "}
            , you can easily recover your pictures even after its deleted. In
            this blog, we have provided some methods about how you can easily
            recover your photos that too without backup.
          </p>
        </article>

        <div style={{ marginTop: "40px" }}>
          <h2 className="text-xl md:text-3xl mt-8 font-bold ">
            Table of Contents
          </h2>
          <h3
            style={{
              marginTop: "10px",
              fontWeight: "bold",
              color: "blue",
              cursor: "pointer",
            }}
            onClick={() =>
              introRef.current?.scrollIntoView({ behavior: "smooth" })
            }
          >
            1. Introduction
          </h3>
          <h3
            style={{
              marginTop: "10px",
              fontWeight: "bold",
              color: "blue",
              cursor: "pointer",
            }}
            onClick={() =>
              androidRef.current?.scrollIntoView({ behavior: "smooth" })
            }
          >
            2. How to Recover Deleted Photos from Android Device
          </h3>
          <div style={{ marginLeft: "10px" }}>
            <h4>a. Google Photos</h4>
            <h4>b. Microsoft OneDrive</h4>
            <h4>c. Dropbox</h4>
          </div>
          <h3
            style={{
              marginTop: "10px",
              fontWeight: "bold",
              color: "blue",
              cursor: "pointer",
            }}
            onClick={() =>
              windowRef.current?.scrollIntoView({ behavior: "smooth" })
            }
          >
            3. How to Recover Deleted Photos on Windows
          </h3>
          <div style={{ marginLeft: "10px" }}>
            <h4>a. From the Recycle Bin</h4>
            <h4>b. From Backup Files</h4>
          </div>
          <h3
            style={{
              marginTop: "10px",
              fontWeight: "bold",
              color: "blue",
              cursor: "pointer",
            }}
            onClick={() =>
              macRef.current?.scrollIntoView({ behavior: "smooth" })
            }
          >
            4. How to Recover Deleted Pictures on Mac
          </h3>
          <div style={{ marginLeft: "10px" }}>
            <h4>a. From Trash</h4>
            <h4>b. From a Time Machine </h4>
            <h4>c. From iTunes Backup</h4>
          </div>
          <h3
            style={{
              marginTop: "10px",
              fontWeight: "bold",
              color: "blue",
              cursor: "pointer",
            }}
            onClick={() =>
              lastRef.current?.scrollIntoView({ behavior: "smooth" })
            }
          >
            5. Let’s Wrap Up
          </h3>
        </div>

        <article>
          <h2 className="text-xl md:text-3xl mt-8 font-bold " ref={androidRef}>
            How to Recover Deleted Photos on Android Device
          </h2>{" "}
          <br />
          <p>
            There are multiple methods where you can get deleted photos back to
            your Android device. The method you will choose further will be
            based on cloud storage or you may recover from your device itself.{" "}
            <br />
            <br />
            Here are some techniques given on how you can recover your photos
            from an Android device:
          </p>
          <h3 className="font-bold mt-10 text-2xl">Google Photos</h3>
          <BlogImage
            image="https://img.rareprob.com/img/default/RareProbImg/imagesRare/how-to-restore-permanently-lost-pictures-gallery/google_photos_backup.webp"
            alt="From the Google Photos, Microsoft One Drive, and Dropbox | ASD Gallery app | Rareprob"
          />
          <p className="mt-10">
            {" "}
            You can recover your photos or files easily from the gallery with
            the Google Photo app. This app only works if it's been less than 60
            days since the files or photos were sent to the trash. These steps
            won’t work if you have permanently deleted photos or emptied the
            trash before backing up.
          </p>
          <li>Click on the Google Photos app on your device.</li>
          <li>Click on the search button. </li>
          <li>Tap on the top-left Menu ˃ Trash option. </li>
          <li>Click and hold the pictures you want to recover.</li>
          <li>
            Then click on Restore and you can get back your deleted photos.{" "}
          </li>
          <h3 className="font-bold mt-10 text-2xl">Microsoft OneDrive</h3>
          <BlogImage
            image="https://img.rareprob.com/img/default/RareProbImg/imagesRare/how-to-restore-permanently-lost-pictures-gallery/OneDrive_backup.webp"
            alt="From the Microsoft OneDrive | ASD Gallery app | Rareprob"
          />
          <p className="mt-10">
            {" "}
            The OneDrive Recycle Bin enables you to retrieve your deleted
            pictures by following the steps:
          </p>
          <li>
            <strong>Step-1</strong>: Open the OneDrive app on your Android
            device.
          </li>
          <li>
            <strong>Step-2</strong>: Select <strong>Me</strong> in the
            navigation pane.
          </li>
          <li>
            <strong>Step-3</strong> : Then click on Recycle Bin.{" "}
          </li>
          <li>
            <strong>Step-4</strong> : Then choose the photos that you want to
            recover and simply click on the Restore button.
          </li>
          <h3 className="font-bold mt-10 text-2xl">Dropbox</h3>
          <BlogImage
            image="https://img.rareprob.com/img/default/RareProbImg/imagesRare/how-to-restore-permanently-lost-pictures-gallery/Dropbox_backup.webp"
            alt="From the Dropbox Backup | ASD Gallery app | Rareprob"
          />
          <p className="mt-10">
            {" "}
            Dropbox users must follow the steps to recover deleted photos:
          </p>
          <li>
            Open the web browser on an Android device and we are using Chrome
            for these steps.
          </li>
          <li>Go to dropbox.com and open the Dropbox website. </li>
          <li>Then sign in to your Dropbox account. </li>
          <li>Click on Files in the left sidebar.</li>
          <li>Select the deleted files or photos.</li>
          <li>
            Tap on the file that you want to retrieve and click on restore and
            your files will be sent to their original Dropbox location.
          </li>
        </article>

        <article>
          <h2 className="text-xl md:text-3xl mt-8 font-bold " ref={windowRef}>
            How to Recover Deleted Photos on Windows
          </h2>
          {/* <BlogImage
          alt={"Photo Collage Maker online free | ASD Gallery app | Rareprob"}
          image="/images/blogs/gallery/asd_Gallery/collage maker.webp"
        /> */}
          <p className="mt-4">
            Given are the steps to restore deleted photos for Windows users.
            These are reliable ways to restore your photos even if your photos
            are deleted for some time.
          </p>
          <h3 className="font-bold mt-10 text-2xl">
            Method-1: From the Recycle Bin
          </h3>
          <h4 className="font-medium mt-4">
            {" "}
            Steps to Retrieve Deleted Photos from Recycle Bin:
          </h4>
          <li>Click on the Recycle Bin by double-clicking on the icon.</li>
          <li>Locate and select the photos or files you want to recover.</li>
          <li>
            You must check the original location of files as they are shown
            there only.
          </li>
          <li>
            Right-click on your photos and click on Restore or you can even drag
            and drop your files or photos from Recycle Bin to the folder you
            want.
          </li>
          <h3 className="font-bold mt-10 text-2xl">
            Method-2: From Backup Files
          </h3>
          <BlogImage
            alt={"From Backup Files | ASD Gallery App | Rareprob"}
            image="https://img.rareprob.com/img/default/RareProbImg/imagesRare/how-to-restore-permanently-lost-pictures-gallery/restore_selected_items.webp"
          />
          <p className=" mt-10">
            There are important files and data that you must be backing up on
            your computer regularly. But in case of data loss, the value of
            these backups is explained.
          </p>
          <p>
            Given are the steps below to recover deleted pictures from the
            backup:
          </p>
          <li>
            {" "}
            <strong>Step-1</strong> : First, connect to the storage media that
            contains the backup.
          </li>
          <li>
            <strong>Step-2</strong> : Then click on the Control Panel ˃ System
            and Maintenance ˃ Backup and Restore.
          </li>
          <li>
            <strong>Step-3</strong> : There are two options given to the users
            if they want to Restore my files or Restore all user's files select
            one.{" "}
          </li>
          <li>
            <strong>Step-4</strong> : You can also search for lost photos by
            using Browse for a file or the Browse for Folder option.{" "}
          </li>
          <li>
            <strong>Step-5</strong> : Then you can select the photos or files
            that you want to recover.
          </li>
        </article>

        <article>
          <h3 className="text-lg md:text-2xl mt-8 font-bold " ref={macRef}>
            How to Recover Deleted Photos on Mac
          </h3>

          <p className="mt-4">
            There are various methods given below by which you can recover your
            deleted photos on Mac. If your files got deleted recently then you
            might be able to recover them instantly from Trash Bin. Mac also
            provides multiple ways to back up the pictures so they can be
            recovered if needed.
          </p>
          <h4 className="font-bold mt-10 text-2xl">Method-1: From the Trash</h4>
          <p className="mt-4">
            If someone's files or photos get deleted first thing they check is
            the trash bin and MacOS provides the option to temporarily store
            your file for quick recovery. Although after some time files roll
            off the bin when it's full or when you empty it.
          </p>
          <p> Following are the steps to restore deleted images from Trash:</p>
          <li>Open the trash bin on Mac by clicking on the icon.</li>
          <li>Check the photos or files that you want to recover.</li>
          <li>
            Then right-click on the file and select <strong>Put Back</strong>{" "}
            and the pictures will get restored to their original location.
          </li>
          <h4 className="font-bold mt-10 text-2xl">
            Method-2: From a Time Machine Backup
          </h4>
          <BlogImage
            alt={"From a Time Machine Backup| ASD Gallery App | Rareprob"}
            image="https://img.rareprob.com/img/default/RareProbImg/imagesRare/how-to-restore-permanently-lost-pictures-gallery/Time_Machine_Backup.webp"
          />
          <p className="mt-4">
            Time Machine is a built-in tool that helps to recover and back up
            and comes with a Mac operating system. It's mainly used to make
            backup copies of your data so you can even use them to recover
            photos that may be lost from your computer.
          </p>
          <p className="mb-4">
            Use the Time Machine tool to recover photos by following the steps:
          </p>
          <li> First, connect the backup disk to your Mac device.</li>
          <li>
            Open the finder window in the folder where your photos were
            previously located.
          </li>
          <li>
            Then click on the clock icon on the Mac menu bar and select{" "}
            <strong>Enter Time Machine</strong> option.
          </li>
          <li>
            Then you can choose the files and photos you want to recover and
            click on the restore button.
          </li>
          <h4 className="font-bold mt-10 text-2xl">
            Method-3: From iTunes Backup
          </h4>
          <BlogImage
            alt={"From iTunes Backup| ASD Gallery App | Rareprob"}
            image="https://img.rareprob.com/img/default/RareProbImg/imagesRare/how-to-restore-permanently-lost-pictures-gallery/iTunes_Backup.webp"
          />
          <p className="mt-4">
            The iTunes Backup method is valid only if you have created a backup
            already for your iTunes library to an external drive.
          </p>
          <p>Steps to retrieve your photos from iTunes Backup</p>
          <li className="mt-4">
            Tap on the external drive and you can select the iTunes folder.
          </li>
          <li>
            Right-click and choose the <strong>copy</strong> option and paste
            the iTunes library to your file location.
          </li>
          <li>
            Open the iTunes while holding the <strong>Option</strong> key.
          </li>
          <li>
            Then click on <strong>Choose Library</strong> option when prompted.
          </li>
          <li>
            You can now even select the location where you want to copy the
            iTunes folder.
          </li>
        </article>

        <article>
          <h5 className="text-xl md:text-3xl mt-8 font-bold " ref={lastRef}>
            Let’s Wrap Up
          </h5>{" "}
          <p className="mt-4">
            Photos that are saved on your Android device are the most precious
            ones and memories they hold. But sometimes the pictures may get
            deleted accidentally but with multiple tools and techniques, you can
            easily recover your photos from the gallery on your Android device.{" "}
            <br /> <br />
            If you are an Android user you have multiple options that can save
            your photos from being getting deleted. You can recover your photos
            and files from the trash, google photos, Dropbox, OneDrive, and much
            more. So fill your gallery with photos without fear of being
            deleted.
          </p>
        </article>

        <div className="h-32"></div>
        {/* <FooterHub textBlack={true} /> */}
      </main>
      <NewFooter color="bg-black" />
    </>
  );
};

export default RestorePicturesGallery;
