import React from "react";
import InnerVideodownloader from "./InnerVideodownloader";
import {
  Accordion,
  AccordionItemButton,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";

const VideoDownloaderSteps = () => {
  return (
    <div>
      <h3 className="text-xl lg:text-2xl font-semibold mt-14">
        How to Download Videos from ASD Video Downloader
      </h3>
      <p className="text-lg mt-2">
        There are two methods by which you can download your videos, images,
        reels, etc. from any social media platform.
      </p>
      <h3 className="text-lg lg:text-xl font-semibold mt-14">Method 1</h3>
      <p className="text-[18px] mt-2">Download videos through PC</p>
      <div className="mt-10 grid xl:grid-cols-3 grid-cols-1 gap-10">
        <LinkCard
          image={"/images/videoDownloader/vDowPostLink.webp"}
          heading={"Copy the link"}
          text={
            "Copy the link of the video from your favorite social media platform, i.e., IG, FB, Twitter, Vimeo, Linkedin, etc."
          }
        />
        <LinkCard
          image={"/images/videoDownloader/vCopywebsite.webp"}
          heading={"Paste the link"}
          text={
            "Come  back here and paste the link it into the ASD Video Downloader browser and hit the download button. "
          }
        />
        <LinkCard
          image={"/images/videoDownloader/vDownHd.webp"}
          heading={"Your video is ready"}
          text={
            "Now you can download your videos in HD quality and see them in the download folder of your device."
          }
        />
      </div>
      {/*  Method 2 downloader */}
      <h3 className="text-lg lg:text-xl font-semibold mt-14">Method 2</h3>
      <p className="text-[18px] mt-2">
        Download Videos through your Android device
      </p>
      <div className="h-64 lg:h-40 mt-14 rounded-xl bg-gradient-to-r to-[#FF6526]/10 from-[#FF0073]/10 flex flex-col lg:flex-row items-center justify-center space-y-3 lg:space-y-0 lg:justify-between px-10">
        <div className="flex items-center justify-center space-x-2">
          <img
            src="/images/videoDownloader/videoDownloaderHiderIcon.webp"
            className="w-16 lg:w-20 h-1w-16 lg:h-20"
            alt="Video Downloader Icon"
          />
          <p className="w-3/4">
            <strong>Download with Video Downloader App</strong> <br /> We now
            provide an app for Instagram video Download. It is fast, easy, with
            no watermark and HD quality
          </p>
        </div>
        <a
          href="https://tinyurl.com/Rareprob-Video-Downloader"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="/images/homepage/google.webp"
            className="w-32 lg:w-64 rounded-xl object-contain "
            alt="Google Icons"
          />
        </a>
      </div>
      {/*  Method 2 Points */}
      <div className="mt-10">
        <li className="list-disc">
          Download the ASD Video Downloader app from Play Store.
        </li>
        <li className="list-disc">
          Open the app and paste the desired video link into the browser.
        </li>
        <li className="list-disc">
          Click on the Download button to download your favorite reels, images,
          etc.
        </li>
        <li className="list-disc">
          You can see your downloaded videos in the app file section or your
          device gallery.
        </li>
      </div>

      {/* WHY USE ASD VIDEO DOWNLOADER */}
      <InnerVideodownloader />
      <div>
        <div className="flex items-center justify-center space-x-2 mt-20">
          <img
            src="/images/videoDownloader/videoDownloaderHiderIcon.webp"
            className="w-20"
            alt="Video Downloader"
          />
          <p className="text-xl font-bold ">Video Downloader</p>
        </div>
        <h3 className="text-lg lg:text-xl font-semibold mt-14">
          Why ASD Video Downloader
        </h3>
        <p className="mt-6">
          <li className="text-lg">
            Video Downloader is a user-friendly application for downloading
            every social media video & photo along with a hassle-free
            experience.
          </li>
          <li className="text-lg">
            {" "}
            You can download high-quality videos within seconds The video
            Downloader app is useful if you are traveling and want to watch
            videos and reels, offline you can watch them easily after
            downloading.
          </li>
          <li className="text-lg">
            To watch videos uninterrupted, and movies seamlessly then a video
            downloader will be great for you.{" "}
          </li>
          <li className="text-lg">
            If your internet connection is slow then with ASD Video Downloader
            you can quickly download the videos and watch them later.
          </li>
          <li className="text-lg">
            It helps you to download videos, images, and reels, from many social
            media platforms i.e., IG, Vimeo, FB, Dailymotion, etc. and you can
            share videos with your family and friends as well.
          </li>
        </p>
      </div>
      <div className="grid grid-cols-2 lg:grid-cols-3 gap-10 lg:gap-20 mx-4 lg:mx-20 mt-32">
        <div className="flex  items-center space-x-4 justify-center">
          <img
            src="/images/videoDownloader/social-media.png"
            className="w-10 lg:w-16 h-10 lg:h-16"
            alt="icon"
          />
          <p className="text-left text-xs  lg:text-sm font-normal">
            Download Social Media reels videos and posts
          </p>
        </div>

        <div className="flex  items-center space-x-4 justify-center">
          <img
            src="/images/videoDownloader/Group 24970.svg"
            className="w-10 lg:w-16 h-10 lg:h-16"
            alt="icon"
          />
          <p className="text-left text-xs  lg:text-sm font-normal">
            Download & Play in just one click
          </p>
        </div>
        <div className="flex  items-center space-x-4 justify-center">
          <img
            src="/images/videoDownloader/Group 24953.svg"
            className="w-10 lg:w-16 h-10 lg:h-16"
            alt="icon"
          />
          <p className="text-lef text-xs lg:text-sm font-normal">
            Manage Progress & history of your downloads
          </p>
        </div>
        <div className="flex space-x-4  items-center justify-center">
          <img
            src="/images/videoDownloader/Group 24960.svg"
            className="w-10 lg:w-16 h-10 lg:h-16"
            alt="icon"
          />
          <p className="text-lef text-xs lg:text-sm font-normal">
            Easy to access File Storage
          </p>
        </div>
        <div className="flex space-x-4  items-center justify-center">
          <img
            src="/images/videoDownloader/Group 24972.svg"
            className="w-10 lg:w-16 h-10 lg:h-16"
            alt="icon"
          />
          <p className="text-lef text-xs lg:text-sm font-normal">
            Play HD videos offline with the built-in player
          </p>
        </div>
        <div className="flex space-x-4  items-center justify-center">
          <img
            src="/images/videoDownloader/Group 24966.svg"
            className="w-10 lg:w-16 h-10 lg:h-16"
            alt="icon"
          />
          <p className="text-left text-xs  lg:text-sm font-normal">
            Search anything on the internet with Browser
          </p>
        </div>
      </div>
      <h3 className="text-lg lg:text-xl font-semibold mt-14">
        Frequently Asked Questions (FAQs)
      </h3>
      <Accordion className="mt-10  " allowZeroExpanded={true}>
        <AccordionItem
          style={{
            border: "0.2px solid white",
            borderRadius: "5px",
            marginTop: "10px",
          }}
        >
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button 
                     !text-black !bg-gradient-to-r from-[#fd4a15]/10 to-[#fe2c78]/10
                      !rounded-lg"
            >
              Q 1 : What is a video downloader browser?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <h3>
              A video downloader lets you download videos from multiple social
              media platforms easily and quickly. The videos are downloaded
              directly to your device's gallery. <br /> <br />
            </h3>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem
          style={{
            border: "0.2px solid white",
            borderRadius: "5px",
            marginTop: "10px",
          }}
        >
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button 
                     !text-black !bg-gradient-to-r from-[#fd4a15]/10 to-[#fe2c78]/10
                      !rounded-lg"
            >
              Q 2: Do I have to sign in to use the ASD Video Downloader browser?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <h3>
              No, you do not need to sign in to use the ASD Video Downloader
              browser.
              <br /> <br />
            </h3>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem
          style={{
            border: "0.2px solid white",
            borderRadius: "5px",
            marginTop: "10px",
          }}
        >
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button 
                     !text-black !bg-gradient-to-r from-[#fd4a15]/10 to-[#fe2c78]/10
                      !rounded-lg"
            >
              Q 3: Can I download the videos to my PC or not?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <h3>
              Yes, you can download the videos to your PC as well. <br /> <br />
            </h3>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem
          style={{
            border: "0.2px solid white",
            borderRadius: "5px",
            marginTop: "10px",
          }}
        >
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button 
                     !text-black !bg-gradient-to-r from-[#fd4a15]/10 to-[#fe2c78]/10
                      !rounded-lg"
            >
              Q 4: Can I download social media videos for free?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <h3>
              Yes, you can download social media videos for free by using the
              ASD Video Downloader.
              <br /> <br />
            </h3>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem
          style={{
            border: "0.2px solid white",
            borderRadius: "5px",
            marginTop: "10px",
          }}
        >
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button 
                     !text-black !bg-gradient-to-r from-[#fd4a15]/10 to-[#fe2c78]/10
                      !rounded-lg"
            >
              Q 5: Is there any app where I can download videos?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <h3>
              Yes, you can download the videos from the ASD Video Downloader app
              on your devices.
              <br /> <br />
            </h3>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem
          style={{
            border: "0.2px solid white",
            borderRadius: "5px",
            marginTop: "10px",
          }}
        >
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button 
                     !text-black !bg-gradient-to-r from-[#fd4a15]/10 to-[#fe2c78]/10
                      !rounded-lg"
            >
              Q 6: What if my query is not here in this FAQ?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <h3>
              If your query is not solved, then you can check out more FAQ pages
              for the video downloader. You can also email us your queries and
              feedback at feedback@rareprob.com
              <br /> <br />
            </h3>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default VideoDownloaderSteps;

const LinkCard = ({ image, heading, text }) => {
  return (
    <div className="max-w-sm mx-4  rounded overflow-hidden shadow-lg">
      <img
        className="w-full rounded-xl"
        src={image}
        alt="Sunset in the mountains"
      />
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">{heading}</div>
        <p className="text-gray-700 text-base">{text}</p>
      </div>
      {/* <div className="px-6 pt-4 pb-2">
      <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#photography</span>
      <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#travel</span>
      <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#winter</span>
    </div> */}
    </div>
  );
};
