import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { activate, fetchConfig, getRemoteConfig } from "firebase/remote-config";
import { getAnalytics, initializeAnalytics } from "firebase/analytics";
import { fetchAndActivate } from "firebase/remote-config";
import { getValue } from "firebase/remote-config";

const firebaseConfig = {
  apiKey: "AIzaSyBQl_rgxzfy6YBPfkL0UltiWGhjSKOnTuA",
  authDomain: "rareprob-website.firebaseapp.com",
  projectId: "rareprob-website",
  storageBucket: "rareprob-website.appspot.com",
  messagingSenderId: "385888856053",
  appId: "1:385888856053:web:c0107b1885334c277de2bc",
  measurementId: "G-YSZH5VBYG5",
};

export const app = initializeApp(firebaseConfig);
export const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 259200000;

remoteConfig.defaultConfig = {
  load_page_design: 1,
};

export const pageDesign = fetchAndActivate(remoteConfig)
  .then(() => {
    const Value = getValue(remoteConfig, "load_page_design");
    const val = Value.asNumber();
    return val;
  })
  .catch((err) => {
    // ...
    return 1;
  });
getAnalytics(app);
initializeAnalytics(app);
export const firestore = getFirestore();
