import { getAnalytics, logEvent } from "firebase/analytics";
import "./NewReleaseGames.css";

const NewReleaseGames = () => {
  const analytics = getAnalytics();

  return (
    <div
      className="casual_container"
      onPointerOver={() => window.adsbygoogle.push({})}
    >
      <h2>New Release</h2>
      <div>
        <a
          href="https://play.rareprobgames.com/hooploop/index.html"
          rel="noreferrer"
          onClick={() =>
            logEvent(analytics, "Rareprob Casual Games", {
              content_type: "Coloron",
              content_id: "Coloron",
              items: [{ name: "Coloron" }],
            })
          }
        >
          <img src="/images/gamesScreen/hoopball.png" alt="Coloron" />
          <h4>Hoop Ball</h4>
        </a>
        <a
          href="https://play.rareprobgames.com/shapehavoc/index.html"
          rel="noreferrer"
          onClick={() =>
            logEvent(analytics, "Rareprob Casual Games", {
              content_type: "Coloron",
              content_id: "Coloron",
              items: [{ name: "Coloron" }],
            })
          }
        >
          <img src="/images/gamesScreen/Shapehavoc.png" alt="Coloron" />
          <h4>Shape Havoc</h4>
        </a>
        <a
          href="https://play.rareprobgames.com/shapeswitch/index.html"
          rel="noreferrer"
          onClick={() =>
            logEvent(analytics, "Rareprob Casual Games", {
              content_type: "Coloron",
              content_id: "Coloron",
              items: [{ name: "Coloron" }],
            })
          }
        >
          <img src="/images/gamesScreen/Shapeswitch.png" alt="Coloron" />
          <h4>Shape Switch</h4>
        </a>
        <a
          href="https://play.rareprobgames.com/coloron/index.html"
          rel="noreferrer"
          onClick={() =>
            logEvent(analytics, "Rareprob Casual Games", {
              content_type: "Coloron",
              content_id: "Coloron",
              items: [{ name: "Coloron" }],
            })
          }
        >
          <img
            src="https://img.rareprob.com/img/default/RareProbImg/imagesRare/games/newGames/Lacrosse%20Ball.H03.2k-min.webp"
            alt="Coloron"
          />
          <h4>Coloron</h4>
        </a>
        <a
          href="https://play.rareprobgames.com/memory-game/index.html"
          rel="noreferrer"
          onClick={() =>
            logEvent(analytics, "Rareprob Casual Games", {
              content_type: "Mind Game",
              content_id: "Mind Game",
              items: [{ name: "Mind Game" }],
            })
          }
        >
          <img
            src="https://img.rareprob.com/img/default/RareProbImg/imagesRare/games/newGames/mind-Game.webp"
            alt="Mind Game"
          />
          <h4>Mind Game</h4>
        </a>
        <a
          href="https://play.rareprobgames.com/WoodieBricks2/index.html"
          rel="noreferrer"
          onClick={() =>
            logEvent(analytics, "Rareprob Casual Games", {
              content_type: "Mind Game",
              content_id: "Mind Game",
              items: [{ name: "Mind Game" }],
            })
          }
        >
          <img
            src="https://play-lh.googleusercontent.com/e_8zrZeMjzFKsx5TcXPbOgCgJfzs_avCTGNR3kAq69AW7XftFwIngR76gBp1u-tMPa4=w240-h480-rw"
            alt="Mind Game"
          />
          <h4>Woodie Brick</h4>
        </a>
      </div>
    </div>
  );
};

export default NewReleaseGames;
