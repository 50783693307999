import React from "react";
import { Link } from "react-router-dom";

interface BlogContainerType {
  name: string;
  image: string;
  text: string;
  path: string;
  logo: string;
  altz: string;
  date: string;
}

const BlogContainer: React.FC<BlogContainerType> = ({
  name,
  image,
  text,
  path,
  logo,
  altz,
  date,
}) => {
  return (
    <Link
      to={path}
      className="bg-gray-200  xl:w-[280px] h-[510px]  px-4  py-3  rounded-3xl"
    >
      <div className="flex items-center justify-start">
        {logo !== "" && <img src={logo} alt="gallery icon" className="w-8" />}
        <h1 className="text-sm md:text-lg font-semibold ml-4">{name}</h1>
      </div>
      <div className="">
        <img
          loading={"lazy"}
          src={image}
          alt={altz}
          className="w-full md:w-full h-60 object-left lg:object-fill rounded-xl mt-6"
        />
        <p className="mt-2 px-4 text-gray-600  inline-block  bg-opacity-50 ">
          {date}
        </p>
        <h1 className=" md:w-full  text-sm md:text-[14px] font-semibold h-20 mt-3 text-left">
          {text}
        </h1>
        <button className=" rounded-lg mb-4 items-center flex mt-0 bg-[#0a4a82]/90 hover:bg-white hover:text-[#0a4a82] text-white font-medium  px-4 py-2">
          Read more
        </button>
      </div>
    </Link>
  );
};

const NewBlogFL = () => {
  return (
    <div>
      <div className="w-[90%] mx-auto place-items-center grid lg:grid-cols-3 xl:grid-cols-4 grid-col-1 gap-10 md:gap-28">
        <BlogContainer
          name={"File Manager "}
          altz="File Manager "
          image="https://img.rareprob.com/img/website/blogs_rb/the-benefits-of-using-a-pdf-reader-cover.webp"
          logo="/images/icons/filemanagerIcon.webp"
          path="/blogs/benefits-of-using-pdf-reader"
          date="16 April 2024"
          text="Simplifying Your Digital Experience: The Benefits of Using a PDF Reader"
        />
        <BlogContainer
          name={"File Manager"}
          altz="File Manager"
          image="https://img.rareprob.com/img/website/blogs_rb/fast-in-built-browser-2024-cover.jpg"
          logo="/images/icons/file mana.webp.png"
          path="/blogs/fast-in-built-browser-2024-in-the-ASD-file-manager-app"
          date="22 Feb 2024"
          text="Explore Secure, Fast In-Built Browser 2024 in the ASD File Manager app"
        />
        <BlogContainer
          name={"File Manager"}
          altz="File Manager"
          image="https://img.rareprob.com/img/website/blogs_rb/how-zipper-files-revolutionize-file-compression-cover.jpg"
          logo="/images/icons/file mana.webp.png"
          path="/blogs/how-to-zip-files-and-create-zip-files-in-the-asd-file-manager"
          date="10 Jan 2024"
          text="How to Zip Files and Create Zip Files in the ASD File Manager"
        />
        <BlogContainer
          name={"File Manager"}
          altz="File Manager"
          image="https://img.rareprob.com/img/website/blogs_rb/how-to-password-protect-your-files-folder-in-android-device-cover.jpg"
          logo="/images/icons/file mana.webp.png"
          path="/blogs/how-to-password-protect-files-and-folders-on-android-device"
          date="08 Jan 2024"
          text="How to Password Protect Your Files & Folder in Android Device"
        />
        <BlogContainer
          name={"File Manager"}
          altz="File Manager"
          image="https://img.rareprob.com/img/website/blogs_rb/how-to-download-whatsapp-status-3-ways-cover.jpg"
          logo="/images/icons/file mana.webp.png"
          path="/blogs/how-to-download-whatsapp-status"
          date="13 Dec 2023"
          text="How to Download Whatsapp Status: 3 Ways"
        />
        <BlogContainer
          name={"File Manager"}
          altz="File Manager"
          image="https://firebasestorage.googleapis.com/v0/b/rareprob-website.appspot.com/o/rareprob%2Fnotes%2Fdaily_notes_feature.webp?alt=media&token=a0d03a7a-6c61-4f88-a2fc-b7334be68b46"
          logo="/images/icons/file mana.webp.png"
          path="/blogs/introducing-exciting-new-notes-feature-asd-file-manager-app"
          date="11 July 2023"
          text="Introducing the Exciting New Notes Feature in ASD File Manager App"
        />
        <BlogContainer
          name={"File Manager"}
          altz="File Manager"
          image="https://img.rareprob.com/img/website/blogs/fileManager/filemanager-new/rarefile-cover.webp"
          logo="/images/icons/file mana.webp.png"
          path="/blogs/top-5-ways-to-choose-right-file-manager-app"
          date="7 Jun 2023"
          text="Top 5 ways to choose the right file managing app for your device"
        />

        <BlogContainer
          name={"File Manager"}
          altz="File Manager"
          image="https://img.rareprob.com/img/website/blogs/fileManager/filemanager-new/rare-file-manage-docs.webp"
          logo="/images/icons/file mana.webp.png"
          path="/blogs/9-effective-tips-to-manage-your-files-on-phone"
          date="24 May 2023"
          text="9 Tips to Effectively Manage Your Documents with the ASD File Manager App"
        />
        <BlogContainer
          name={"File Manager"}
          altz="File Manager"
          image="https://img.rareprob.com/img/website/blogs/fileManager/seven/rare-fileSecureCover.webp"
          logo="/images/icons/file mana.webp.png"
          path="/blogs/7-reasons-file-management-app-must-have-device"
          date="17  March  2023"
          text=" 7 Reasons why a File Management app is a must-have for your device           "
        />
        <BlogContainer
          name={"File Manager"}
          altz="File Manager"
          image="https://img.rareprob.com/img/website/blogs_rb/what-is-file-encryption-cover.webp"
          logo="/images/icons/file mana.webp.png"
          path="/blogs/secure-your-private-files-with-file-encryption"
          date="5  March  2023"
          text=" What is File Encryption, its Importance, and How Does it Secure your Private files?"
        />
        <BlogContainer
          name={"File Manager"}
          altz="File Manager"
          image="https://img.rareprob.com/img/website/blogs/fileManager/rare-FileWebBroeserCover-min.webp"
          logo="/images/icons/file mana.webp.png"
          path="/blogs/discover-why-the-web-browser-of-the-asd-file-manager-app-is-like-no-other-app"
          date="26 Feb  2023"
          text=" Discover Why the Web Browser of the ASD File Manager app is like no other app!              "
        />
        <BlogContainer
          name={"File Manager"}
          altz="File Manager"
          image="https://img.rareprob.com/img/website/blogs_rb/how-to-manage-large-files-cover.webp"
          logo="/images/icons/filemanagerIcon.webp"
          path="/blogs/manage-large-files-easily-asd-file-manager-guide"
          date="19 Feb  2023"
          text=" How to Manage Large Files on Android Devices with File Manager"
        />
        <BlogContainer
          name={"File Manager"}
          altz="File Manager"
          image="https://img.rareprob.com/img/website/blogs/fileManager/rare-fileCover.webp"
          logo="/images/icons/file mana.webp.png"
          path="/blogs/Shortcut-and-Hide-Unhide-files"
          date="13 Feb  2023"
          text=" What are the Two Most Important Functions A File Manager App Should Have | ASD File Manager              "
        />
        <BlogContainer
          name={"File Manager"}
          altz="File Manager"
          image="https://img.rareprob.com/img/website/blogs/fileManager/Rareprob-scanningFront.webp"
          logo="/images/icons/file mana.webp.png"
          path="/blogs/benefits-of-scanning-documents"
          date="5 Feb  2023"
          text=" What are the Benefits of Scanning Documents and How you Can Easily Do It| ASD File Manager              "
        />
        <BlogContainer
          name={"File Manager"}
          altz="File Manager"
          image="https://img.rareprob.com/img/website/blogs_rb/how-to-share-files-offline-cover.webp"
          logo="/images/icons/file mana.webp.png"
          path="/blogs/share-files-from-android-device-to-pc"
          date="5 Feb  2023"
          text="How to transfer files from your Android Device to PC offline
          "
        />
        <BlogContainer
          name={"File Manager"}
          altz="File Manager"
          image="https://img.rareprob.com/img/website/blogs/fileManager/clean-master-cover.webp"
          logo="/images/icons/file mana.webp.png"
          path="/blogs/remove-junk-files-using-clean-master"
          date="26 Jan  2023"
          text=" Boost Your Phone’s Performance with Clean Master | File Manager 
          "
        />
        <BlogContainer
          name={"File Manager"}
          altz="File Manager "
          image="https://img.rareprob.com/img/website/blogs/fileManager/1200_1200 cover.webp"
          logo="/images/icons/file mana.webp.png"
          path="/blogs/hide-photos-videos-and-files"
          date="14 Jan  2023"
          text="Secure Your Private Files in a Secret Folder with File Manager
          "
        />
        <BlogContainer
          name={"File Manager"}
          altz="New Year 2023 "
          image="https://img.rareprob.com/img/website/blogs/fileManager/cover (2).webp"
          logo="/images/icons/file mana.webp.png"
          path="/blogs/Digital-documents"
          date="5 Jan  2023"
          text="Manage your Digital Documents with The File Manager App
          "
        />
        <BlogContainer
          name={"File Manager "}
          altz="File Manager "
          image="https://img.rareprob.com/img/website/blogs_rb/what-are-zip-files-cover.webp"
          logo="/images/icons/file mana.webp.png"
          path="/blogs/zip-files"
          date="30 Dec 2022"
          text="What are Zip Files and How to Create with the File Manager app?"
        />
        <BlogContainer
          name={"File Manager "}
          altz="File Manager "
          image="https://img.rareprob.com/img/website/blogs/fileManager/blog 2.webp"
          logo="/images/icons/file mana.webp.png"
          path="/blogs/benefits-of-the-file-manager-app-for-android-device"
          date="16 Dec 2022"
          text=" Why do you need a File Manager App and which one is The Best?"
        />
        <BlogContainer
          name={"File Manager"}
          altz=" File Manager"
          image="https://img.rareprob.com/img/website/blogs_rb/what-is-asd-file-manager-cover.webp"
          logo="/images/icons/file mana.webp.png"
          path="/blogs/best-file-manager-app-for-android"
          date="12 Nov 2022"
          text="What is ASD File Manager and Explore its advanced features"
        />
      </div>
    </div>
  );
};

export default NewBlogFL;
