import React, { useEffect } from "react";
import FooterHub from "../../comp/FooterHub";
import { Helmet } from "react-helmet";
import Header from "../../comp/Header";

const RadioMonkeyBlog = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Country Specific Online Radio Stations | Radio monkey - online Radio
          FM App
        </title>

        <meta
          name="description"
          content="Create your own list of favorite radio stations with our app Radio Monkey. Read more about the app on a blog."
        />
      </Helmet>
      <Header color="bg-purple-800" page="blogs" />
      <main className="w-full text-lg px-6 overflow-hidden md:px-44 text-black mx-auto pt-20 bg-white">
        <img
          src="https://img.rareprob.com/img/website/blogs/radio/featured.webp"
          alt="RADIO MONKEY"
          className="w-full mt-10 object-cover rounded-2xl"
        />
        <h1 className="text-5xl mt-10 font-bold">RADIO MONKEY</h1>
        <h1 className="text-3xl font-medium mt-10">
          Are you fond of listening to online radio?
        </h1>
        <p className="mt-3">
          Then RADIO MONKEY has to be on your Smartphone. It is an android
          application that connects you to the next generation of the{" "}
          <a
            href="https://tinyurl.com/Rareprob-Radio-Monkey"
            className="text-blue-600 font-medium"
          >
            online tuner
          </a>
          . Radio Monkey provides great experiences that astonish users in terms
          of design, usability, and functionality.
        </p>
        <h1 className="text-3xl font-medium mt-10">Why Choose Us?</h1>
        <p className="mt-3">
          <strong className=" ">
            <a
              href="/apps/radio-monkey-online-radio-fm-app"
              className="text-blue-600 font-medium"
            >
              RADIO MONKEY
            </a>
          </strong>{" "}
          has a user-friendly interface and over{" "}
          <strong className="text-black ">
            200 country-specific online radio stations
          </strong>{" "}
          so that users may get a sense of a variety of radio stations.
        </p>
        <img
          loading={"lazy"}
          src="https://img.rareprob.com/img/website/blogs/radio/country%20wise_.webp"
          alt="RADIO MONKEY"
          className="mb-20 mt-10 w-44 md:w-72 mx-auto"
        />
        <h1 className="text-3xl font-medium mt-10">
          Reading newspapers is tedious. isn't?
        </h1>
        <p className="mt-2">
          But with Radio Monkey, you can listen to{" "}
          <strong className="text-black">live news</strong> on your Smartphone.
          You cannot read newspapers anywhere but with Radio Monkey, it's a
          breeze to listen to them.
        </p>
        <img
          loading={"lazy"}
          src="https://img.rareprob.com/img/website/blogs/radio/newspaper.webp"
          alt="Radio Monkey : News Stations"
          className="mb-20 mt-10 w-44 md:w-72 mx-auto"
        />
        <h1 className="text-3xl font-medium mt-10">
          Looking for listening offline radio stations?
        </h1>
        <p className="mt-2">
          Try an <strong className="text-black">inbuilt Audio Recorder</strong>.
          This is one of the amazing feature of RADIO MONKEY. This functionality
          allows you to record any radio station you want, so that you can
          listen to your favourite content whenever you want, regardless of
          where you are.
        </p>
        <img
          loading={"lazy"}
          src="https://img.rareprob.com/img/website/blogs/radio/audio%20recoder.webp"
          alt="inbuilt Audio Recorder"
          className="mb-20 mt-10 w-44 md:w-72 mx-auto"
        />
        <h1 className="text-3xl font-medium mt-10">
          Do you fall asleep while listening to music?
        </h1>
        <p className="mt-4">
          Now you don't have to worry about it. RADIO MONKEY is all set with the{" "}
          <strong className="text-black">Sleep Timer feature</strong>. You can
          set a countdown before you start listening to any radio station. Once
          the countdown expires, it will pause the audio playing on your device.
          This feature will also assist you in preventing{" "}
          <strong className="text-black">battery depletion</strong>.
        </p>
        <img
          loading={"lazy"}
          src="https://img.rareprob.com/img/website/blogs/radio/sleep%20timer.webp"
          alt="Sleep Timer feature"
          className="mb-20 mt-10 w-44 md:w-72 mx-auto"
        />
        <p className="mt-2">
          To have an Equalizer on Radio application is like icing on the cake.
          You can enhance the sound quality of your android device with the Bass
          Boost, Virtualizer, and Equalizer.
        </p>
        <img
          loading={"lazy"}
          src="https://img.rareprob.com/img/website/blogs/radio/equalizer.webp"
          alt="Radio Monkey : equalizer"
          className="mb-20 mt-10 w-44 md:w-72 mx-auto"
        />
        <p className="mt-2">
          Try <strong className="text-black">RADIO MONKEY</strong> and Dive into
          the world of online radio stations.
        </p>
        <div className="h-44"></div>
        <FooterHub textBlack={true} />
      </main>
    </>
  );
};

export default RadioMonkeyBlog;
