import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useSearchParams } from "react-router-dom";
import FaqFilemanager from "../comp/FaqFilemanager";
import FaqMusicPlayer from "../comp/FaqMusicPlayer";
import FaqVideoDownloader from "../comp/FaqVideoDownloader";
import Header from "../comp/Header";
import GalleryFaqScreen from "./GalleryFaqScreen";
import TweedleFaqScreen from "../comp/TweedleFaqScreen";
import RadioFaqScreen from "../comp/RadioFaqScreen";
import VideoToMp3FaqScreen from "../comp/VideoToMp3FaqScreen";
import NewFooter from "../comp/NewFooter";
import { Helmet } from "react-helmet";

enum FAQAPPS {
  FILEMANAGER,
  VIDEODOWNLOADER,
  MUSICPLAYER,
  GALLERY,
  TWEEDLE,
  RADIO,
  VIDEOTOMP3,
}

enum AppParamType {
  FILEMANAGER,
  VIDEODOWNLOADER,
  MUSICPLAYER,
  GALLERY,
  TWEEDLE,
  RADIO,
  VIDEOTOMP3,
}

const FaqScreen: React.FC = () => {
  const [AppFaq, setAppFaq] = useState<FAQAPPS>(FAQAPPS.VIDEODOWNLOADER);
  const [ParamType, setParamType] = useState<AppParamType | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const FaqAppAcreen: () => JSX.Element = () => {
    switch (AppFaq) {
      case FAQAPPS.FILEMANAGER:
        return <FaqFilemanager />;
        break;
      case FAQAPPS.VIDEODOWNLOADER:
        return <FaqVideoDownloader />;
        break;
      case FAQAPPS.MUSICPLAYER:
        return <FaqMusicPlayer />;
        break;
      case FAQAPPS.GALLERY:
        return <GalleryFaqScreen />;
        break;
      case FAQAPPS.TWEEDLE:
        return <TweedleFaqScreen />;
        break;
      case FAQAPPS.RADIO:
        return <RadioFaqScreen />;
        break;
      case FAQAPPS.VIDEOTOMP3:
        return <VideoToMp3FaqScreen />;
        break;
      default:
        return <FaqVideoDownloader />;
        break;
    }
  };

  const fetchSearchParam = () => {
    switch (location.pathname) {
      case "/faq/video-downloader":
        setAppFaq(FAQAPPS.VIDEODOWNLOADER);
        break;
      case "/faq/file-manager":
        setAppFaq(FAQAPPS.FILEMANAGER);
        break;
      case "/faq/music-player":
        setAppFaq(FAQAPPS.MUSICPLAYER);
        break;
      case "/faq/gallery":
        setAppFaq(FAQAPPS.GALLERY);
        break;
      case "/faq/tweedle":
        setAppFaq(FAQAPPS.TWEEDLE);
        break;
      case "/faq/radio-fm":
        setAppFaq(FAQAPPS.RADIO);
        break;
      case "/faq/video-to-mp3":
        setAppFaq(FAQAPPS.VIDEOTOMP3);
        break;
      default:
        setAppFaq(FAQAPPS.VIDEODOWNLOADER);
        break;
    }
  };

  const getAllParams = () => {
    const getParams = searchParams.get("type");
    switch (getParams) {
      case "fileManager":
        setAppFaq(FAQAPPS.FILEMANAGER);
        setParamType(AppParamType.FILEMANAGER);
        break;
      case "music":
        setAppFaq(FAQAPPS.MUSICPLAYER);
        setParamType(AppParamType.MUSICPLAYER);
        break;
      case "videodownloader":
        setAppFaq(FAQAPPS.VIDEODOWNLOADER);
        setParamType(AppParamType.VIDEODOWNLOADER);
        break;
      case "gallery":
        setAppFaq(FAQAPPS.GALLERY);
        setParamType(AppParamType.GALLERY);
        break;
      case "tweedle":
        setAppFaq(FAQAPPS.TWEEDLE);
        setParamType(AppParamType.TWEEDLE);
        break;
      case "radio":
        setAppFaq(FAQAPPS.RADIO);
        setParamType(AppParamType.RADIO);
        break;
      case "videoToMp3":
        setAppFaq(FAQAPPS.VIDEOTOMP3);
        setParamType(AppParamType.VIDEOTOMP3);
        break;
      default:
        setParamType(null);
        break;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchSearchParam();
    getAllParams();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Frequently Asked Questions - Your Queries Answered |Rareprob
        </title>
        <meta
          name="description"
          content="Find quick answers to common queries about our products, services, and company. Get the information you need hassle-free."
        />
      </Helmet>
      <Header color="bg-white/10" page="faq" />
      <main
        className="
     bg-gradient-to-b  h-full   text-white  
     from-[#66369C] via-[#442E89] to-[#185589]
     "
        id="start"
      >
        <h1 className="pt-24 lg:pt-32 text-xl lg:text-3xl font-semibold text-center">
          Frequently Asked Questions
        </h1>
        {ParamType === null ? (
          <div className="grid grid-cols-3 gap-4 lg:gap-10 ml-4 lg:ml-0 lg:grid lg:grid-cols-7  mx-auto place-items-center lg:px-44 place-content-center  mt-4 lg:mt-10">
            <Link
              to="video-downloader"
              onClick={() => setAppFaq(FAQAPPS.VIDEODOWNLOADER)}
              className={`flex lg:w-32 w-24  
                        mt-4 lg:mt-0
                        cursor-pointer  flex-col ${
                          AppFaq === FAQAPPS.VIDEODOWNLOADER
                            ? "bg-white/50 backdrop-blur-2xl drop-shadow-2xl rounded-md lg:rounded-2xl text-black p-1 lg:p-2"
                            : "bg-none"
                        } space-y-2 items-center justify-center`}
            >
              <img
                src="/images/icons/videoDownloader.webp"
                className="w-10 lg:w-14 h-10 lg:h-14 "
                alt="Video Downloader Icon"
              />
              <h2 className="text-xs  text-center font-medium">
                Video Downloader
              </h2>
            </Link>
            <Link
              to="file-manager"
              onClick={() => setAppFaq(FAQAPPS.FILEMANAGER)}
              className={`flex lg:w-32 w-24 cursor-pointer  flex-col ${
                AppFaq === FAQAPPS.FILEMANAGER
                  ? "bg-white/50 backdrop-blur-2xl drop-shadow-2xl rounded-md lg:rounded-2xl text-black p-1 lg:p-2"
                  : ""
              } space-y-2 items-center justify-center`}
            >
              <img
                src="/images/icons/appIcon/filemanager.webp"
                className="w-10 lg:w-14 h-10 lg:h-14 "
                alt="File Manager Icon"
              />
              <h2 className="text-xs text-center font-medium">File Manager</h2>
            </Link>
            <Link
              to="music-player"
              onClick={() => setAppFaq(FAQAPPS.MUSICPLAYER)}
              className={`flex lg:w-32 w-24 cursor-pointer  flex-col ${
                AppFaq === FAQAPPS.MUSICPLAYER
                  ? "bg-white/50 backdrop-blur-2xl drop-shadow-2xl rounded-md lg:rounded-2xl text-black p-1 lg:p-2"
                  : ""
              } space-y-2 items-center justify-center`}
            >
              <img
                src="/images/icons/musicIcon.webp"
                className="w-10 lg:w-14 h-10 lg:h-14 "
                alt="Music Player Icon"
              />
              <h2 className="text-xs text-center font-medium">Music Player</h2>
            </Link>
            <Link
              to="gallery"
              onClick={() => setAppFaq(FAQAPPS.GALLERY)}
              className={`flex lg:w-32 w-24 cursor-pointer  
                        flex-col ${
                          AppFaq === FAQAPPS.GALLERY
                            ? "bg-white/50 backdrop-blur-2xl drop-shadow-2xl rounded-md lg:rounded-2xl text-black p-1 lg:p-2"
                            : ""
                        } 
                        space-y-2 items-center justify-center`}
            >
              <img
                src="/images/icons/gallery.png"
                className="w-10 lg:w-14 h-10 lg:h-14 "
                alt="ASD Gallery "
              />
              <h2 className="text-xs text-center font-medium">Gallery</h2>
            </Link>
            <Link
              to="tweedle"
              onClick={() => setAppFaq(FAQAPPS.TWEEDLE)}
              className={`flex lg:w-32 w-24 cursor-pointer  
                        flex-col ${
                          AppFaq === FAQAPPS.TWEEDLE
                            ? "bg-white/50 backdrop-blur-2xl drop-shadow-2xl rounded-md lg:rounded-2xl text-black p-1 lg:p-2"
                            : ""
                        } 
                        space-y-2 items-center justify-center`}
            >
              <img
                src="/images/icons/appIcon/Tweedle.webp"
                className="w-10 lg:w-14 h-10 lg:h-14 "
                alt="Tweedle "
              />
              <h2 className="text-xs text-center font-medium">Tweedle</h2>
            </Link>
            <Link
              to="radio-fm"
              onClick={() => setAppFaq(FAQAPPS.RADIO)}
              className={`flex lg:w-32 w-24 cursor-pointer  
                        flex-col ${
                          AppFaq === FAQAPPS.RADIO
                            ? "bg-white/50 backdrop-blur-2xl drop-shadow-2xl rounded-md lg:rounded-2xl text-black p-1 lg:p-2"
                            : ""
                        } 
                        space-y-2 items-center justify-center`}
            >
              <img
                src="/images/icons/radioLauncher.png"
                className="w-10 lg:w-14 h-10 rounded-xl lg:h-14 "
                alt="Radio Fm "
              />
              <h2 className="text-xs text-center font-medium">Radio Fm</h2>
            </Link>
            <Link
              to="video-to-mp3"
              onClick={() => setAppFaq(FAQAPPS.VIDEOTOMP3)}
              className={`flex lg:w-32 w-24 cursor-pointer  
                        flex-col ${
                          AppFaq === FAQAPPS.VIDEOTOMP3
                            ? "bg-white/50 backdrop-blur-2xl drop-shadow-2xl rounded-md lg:rounded-2xl text-black p-1 lg:p-2"
                            : ""
                        } 
                        space-y-2 items-center justify-center`}
            >
              <img
                src="/images/icons/vmp3.png"
                className="w-10 lg:w-14 h-10 rounded-xl lg:h-14 "
                alt="Radio Fm "
              />
              <h2 className="text-xs text-center font-medium">Video To Mp3</h2>
            </Link>
          </div>
        ) : ParamType === AppParamType.FILEMANAGER ? (
          <div className="flex items-center justify-center space-x-2 lg:space-x-20 pt-10">
            <div
              onClick={() => setAppFaq(FAQAPPS.FILEMANAGER)}
              className={`flex lg:w-32 w-24 cursor-pointer  flex-col ${
                AppFaq === FAQAPPS.FILEMANAGER
                  ? "bg-white/50 backdrop-blur-2xl drop-shadow-2xl rounded-md lg:rounded-2xl text-black p-1 lg:p-2"
                  : ""
              } space-y-2 items-center justify-center`}
            >
              <img
                src="/images/icons/appIcon/filemanager.webp"
                className="w-10 lg:w-20 h-10 lg:h-20 "
                alt="File Manager Icon"
              />
              <h2 className="text-xs text-center font-medium">File Manager</h2>
            </div>
          </div>
        ) : ParamType === AppParamType.MUSICPLAYER ? (
          <div className="flex items-center justify-center space-x-2 lg:space-x-20 pt-10">
            <div
              onClick={() => setAppFaq(FAQAPPS.MUSICPLAYER)}
              className={`flex lg:w-32 w-24 cursor-pointer  flex-col ${
                AppFaq === FAQAPPS.MUSICPLAYER
                  ? "bg-white/50 backdrop-blur-2xl drop-shadow-2xl rounded-md lg:rounded-2xl text-black p-1 lg:p-2"
                  : ""
              } space-y-2 items-center justify-center`}
            >
              <img
                src="/images/icons/musicIcon.webp"
                className="w-10 lg:w-20 h-10 lg:h-20 "
                alt="Music Player Icon"
              />
              <h2 className="text-xs text-center font-medium">Music Player</h2>
            </div>
          </div>
        ) : ParamType === AppParamType.VIDEODOWNLOADER ? (
          <div className="flex items-center justify-center space-x-2 lg:space-x-20 pt-10">
            <div
              onClick={() => setAppFaq(FAQAPPS.VIDEODOWNLOADER)}
              className={`flex lg:w-32 w-24  cursor-pointer  flex-col ${
                AppFaq === FAQAPPS.VIDEODOWNLOADER
                  ? "bg-white/50 backdrop-blur-2xl drop-shadow-2xl rounded-md lg:rounded-2xl text-black p-1 lg:p-2"
                  : "bg-none"
              } space-y-2 items-center justify-center`}
            >
              <img
                src="/images/icons/videoDownloader.webp"
                className="w-10 lg:w-20 h-10 lg:h-20 "
                alt="Video Downloader Icon"
              />
              <h2 className="text-xs text-center font-medium">
                Video Downloader
              </h2>
            </div>
          </div>
        ) : ParamType === AppParamType.GALLERY ? (
          <div className="flex items-center justify-center space-x-2 lg:space-x-20 pt-10">
            <div
              onClick={() => setAppFaq(FAQAPPS.GALLERY)}
              className={`flex lg:w-32 w-24  cursor-pointer  flex-col ${
                AppFaq === FAQAPPS.GALLERY
                  ? "bg-white/50 backdrop-blur-2xl drop-shadow-2xl rounded-md lg:rounded-2xl text-black p-1 lg:p-2"
                  : "bg-none"
              } space-y-2 items-center justify-center`}
            >
              <img
                src="/images/icons/gallery.png"
                className="w-10 lg:w-20 h-10 lg:h-20 "
                alt="Gallery Icon"
              />
              <h2 className="text-xs text-center font-medium">Gallery</h2>
            </div>
          </div>
        ) : ParamType === AppParamType.TWEEDLE ? (
          <div className="flex items-center justify-center space-x-2 lg:space-x-20 pt-10">
            <div
              onClick={() => setAppFaq(FAQAPPS.TWEEDLE)}
              className={`flex lg:w-32 w-24  cursor-pointer  flex-col ${
                AppFaq === FAQAPPS.TWEEDLE
                  ? "bg-white/50 backdrop-blur-2xl drop-shadow-2xl rounded-md lg:rounded-2xl text-black p-1 lg:p-2"
                  : "bg-none"
              } space-y-2 items-center justify-center`}
            >
              <img
                src="/images/icons/appIcon/Tweedle.webp"
                className="w-10 lg:w-20 h-10 lg:h-20 "
                alt="Tweedle Icon"
              />
              <h2 className="text-xs text-center font-medium">Tweedle</h2>
            </div>
          </div>
        ) : ParamType === AppParamType.RADIO ? (
          <div className="flex items-center justify-center space-x-2 lg:space-x-20 pt-10">
            <div
              onClick={() => setAppFaq(FAQAPPS.RADIO)}
              className={`flex lg:w-32 w-24  cursor-pointer  flex-col ${
                AppFaq === FAQAPPS.RADIO
                  ? "bg-white/50 backdrop-blur-2xl drop-shadow-2xl rounded-md lg:rounded-2xl text-black p-1 lg:p-2"
                  : "bg-none"
              } space-y-2 items-center justify-center`}
            >
              <img
                src="/images/icons/radioLauncher.png"
                className="w-10 lg:w-20 h-10 lg:h-20 "
                alt="Radio Fm Icon"
              />
              <h2 className="text-xs text-center font-medium">Radio Fm</h2>
            </div>
          </div>
        ) : ParamType === AppParamType.VIDEOTOMP3 ? (
          <div className="flex items-center justify-center space-x-2 lg:space-x-20 pt-10">
            <div
              onClick={() => setAppFaq(FAQAPPS.VIDEOTOMP3)}
              className={`flex lg:w-32 w-24  cursor-pointer  flex-col ${
                AppFaq === FAQAPPS.VIDEOTOMP3
                  ? "bg-white/50 backdrop-blur-2xl drop-shadow-2xl rounded-md lg:rounded-2xl text-black p-1 lg:p-2"
                  : "bg-none"
              } space-y-2 items-center justify-center`}
            >
              <img
                src="/images/icons/vmp3.png"
                className="w-10 lg:w-20 h-10 lg:h-20 "
                alt="Video To Mp3 icon"
              />
              <h2 className="text-xs text-center font-medium">Video To Mp3</h2>
            </div>
          </div>
        ) : null}
        {ParamType === null ? <Outlet /> : FaqAppAcreen()}
        {/* <Outlet/> */}
        {/* {FaqAppAcreen()} */}
        <div className="mt-44"></div>
        <NewFooter color="bg-black/5" />
      </main>
    </>
  );
};

export default FaqScreen;
