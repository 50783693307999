import React, { useEffect } from "react";
import Header from "../comp/Header";
import { Helmet } from "react-helmet";
import ContactUs from "../comp/ContactUs";
import NewFooter from "../comp/NewFooter";

const ContactScreen = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Get in Touch with Us - Contact Our Friendly Team | Rareprob
        </title>

        <meta
          name="description"
          content="Reach out to us for inquiries, feedback, or assistance. Our dedicated team is ready to provide you with prompt and helpful support."
        />
      </Helmet>
      <nav>
        <Header color="bg-purple-900" page="contact" />
      </nav>
      <div className=" pt-40 bg-gradient-to-b from-[#66369C] via-[#442e89] to-[#185589]">
        <ContactUs />
        <div className="h-32"></div>
        <NewFooter color="bg-black/10" />
      </div>
    </>
  );
};

export default ContactScreen;
