import React from "react";
import { Link } from "react-router-dom";

interface BlogContainerType {
  name: string;
  image: string;
  text: string;
  path: string;
  logo: string;
  altz: string;
  date: string;
}

const BlogContainer: React.FC<BlogContainerType> = ({
  name,
  image,
  text,
  path,
  logo,
  altz,
  date,
}) => {
  return (
    <Link
      to={path}
      className="bg-gray-200  xl:w-[280px] h-[510px]  px-4  py-3  rounded-3xl"
    >
      <div className="flex items-center justify-start">
        {logo !== "" && <img src={logo} alt="gallery icon" className="w-8" />}
        <h1 className="text-sm md:text-lg font-semibold ml-4">{name}</h1>
      </div>
      <div className="">
        <img
          loading={"lazy"}
          src={image}
          alt={altz}
          className="w-full md:w-full h-60 object-cover lg:object-fill rounded-xl mt-6"
        />
        <p className="mt-2 px-4 text-gray-600  inline-block  bg-opacity-50 ">
          {date}
        </p>
        <h1 className=" md:w-full text-sm md:text-[14px] font-semibold h-20 mt-3 text-center">
          {text}
        </h1>
        <button className=" rounded-lg mb-4  mx-auto items-center flex mt-0 bg-[#0a4a82]/90 hover:bg-white hover:text-[#0a4a82] text-white font-medium  px-4 py-2">
          Read more
        </button>
      </div>
    </Link>
  );
};

const NewBlogVideoToMp3 = () => {
  return (
    <div>
      <div className="w-[90%] mx-auto place-items-center grid lg:grid-cols-3 xl:grid-cols-4 grid-col-1 gap-10 md:gap-28">
      <BlogContainer
          name={"Video To MP3"}
          altz="Video To MP3"
          image="https://img.rareprob.com/img/website/blogs_rb/ultimate-top-6-video-to-mp3-converter-for-android-in-2024-cover.webp"
          logo="/images/icons/vmp3.png"
          path="/blogs/6-powerful-video-to-mp3-converter-for-android-in-2024"
          date="05 July 2024"
          text="Discover top 6 video to MP3 converter apps for Android in 2024."
        />
        <BlogContainer
          name={"Video To MP3"}
          altz="Video To Mp3 Converter"
          image="https://img.rareprob.com/img/website/blogs_rb/know-how-to-convert-and-merge-audio-files-with-video-to-mp3-converter-app-cover.webp"
          logo="/images/icons/vmp3.png"
          path="/blogs/how-to-convert-and-merge-audio-with-asd-video-to-mp3-converter"
          date="09 May 2024"
          text=" Learn about its intuitive features for format conversion, audio merging, etc."
        />
        <BlogContainer
          name={"Video To MP3"}
          altz="Video To Mp3 Converter"
          image="https://img.rareprob.com/img/website/blogs_rb/explore-the-most-popular-features-of-asd-video-to-mp3-converter-you-must-know-cover.jpg"
          logo="/images/icons/vmp3.png"
          path="/blogs/explore-the-amazing-features-of-asd-video-to-mp3-converter"
          date="26 Mar 2024"
          text="Explore The Most Popular Features Of ASD Video To MP3 Converter You Must Know"
        />
        <BlogContainer
          name="Video To MP3"
          altz="Video To Mp3 Converter"
          date="21 Feb 2024"
          image="https://img.rareprob.com/img/website/blogs_rb/how-to-convert-video-to-audio-easily-in-free-video-to-mp3-converter-cover.jpg"
          logo="/images/icons/vmp3.png"
          path="/blogs/how-to-convert-video-to-mp3-on-any-device-5-easy-steps"
          text="How to Convert Video to Audio Easily in Free Video to MP3 Converter"
        />
        <BlogContainer
          name="Video To MP3"
          altz="Video To Mp3 Converter"
          date="03 Jan 2024"
          image="https://img.rareprob.com/img/website/blogs_rb/how-to-set-ringtone-with-free-video-to-mp3-converter-app-cover.jpg"
          logo="/images/icons/vmp3.png"
          path="/blogs/how-to-set-ringtone-with-free-asd-video-to-mp3-converter"
          text="How to Set Ringtone with Free Video to MP3 Converter App
          "
        />
        <BlogContainer
          name="Video To MP3"
          altz="Video To Mp3 Converter"
          date="27 Dec 2023"
          image="https://img.rareprob.com/img/website/blogs_rb/how-to-use-audio-cutter-with-free-video-to-mp3-converter-app-cover.jpg"
          logo="/images/icons/vmp3.png"
          path="/blogs/trim-audio-with-free-video-to-mp3-converter-app"
          text="How to use Audio Cutter with Free Video to MP3 Converter App
          "
        />
        <BlogContainer
          name="Video To MP3"
          altz="Video To Mp3 Converter"
          date="11 Oct 2023"
          image="https://img.rareprob.com/img/website/rp_webimg/coverImage.jpg"
          logo="/images/icons/vmp3.png"
          path="/blogs/how-to-trim-videos-with-in-built-video-cutter-in-asd-video-to-mp3-converter"
          text="How to Trim Videos with In-Built Video Cutter in ASD Video to MP3 Converter
          "
        />
        <BlogContainer
          name="Video To MP3"
          altz="Video To Mp3 Converter"
          date="22 Aug 2023"
          image="https://img.rareprob.com/img/default/RareProbImg/imagesRare/Rare_elevate-screen-recorder-asd-video-mp3-converter-app/video_to_mp3_cover.webp"
          logo="/images/icons/vmp3.png"
          path="/blogs/elevate-screen-recorder-asd-video-mp3-converter-app"
          text="Elevate your Screen Recorder feature with the ASD Video to MP3 Converter app   "
        />
        <BlogContainer
          name="Video To MP3"
          altz="Video To Mp3 Converter"
          date="1 Nov 2021"
          image="https://img.rareprob.com/img/default/RareProbImg/imagesRare/video_to_mp3cover_.jpg"
          logo="/images/icons/vmp3.png"
          path="/blogs/video-to-mp3-converter-for-android-mobile-app"
          text="Easy to use Video to Mp3 converter for your Android device"
        />
      </div>
    </div>
  );
};

export default NewBlogVideoToMp3;
