import React, { useContext } from "react";

import "./RadioMonkey.scss";
import Lottie from "lottie-react";
import playBlue from "./Images/images/playBlue.svg";
import pausePurple from "./Images/images/pausePurple.svg";
import playPurple from "./Images/images/playPurple.svg";

import { RadioContext } from "./radioContext";
import animation from "./Images/animations/zJJkdu0GkX.json";

const ChannelCard = ({ channel, playing, setPlaying, audioPlayer, index }) => {
  const playingHandler = () => {
    // Wait for a specific amount of time (in milliseconds) before playing it again
    setPlaying(channel.stationuuid);
  };
  const {
    currentChannel,
    setCurrentChannel,
    playingState,
    setPlayingState,

    setIndex,
  } = useContext(RadioContext);

  return (
    <div
      className="flex justify-between items-center mb-5 card-container border-black p-[3vw]  lg:p-[2vw] lg:pl-[0vw] lg:pr-[0vw] lg:pb-0 cursor-pointer"
      onClick={() => {
        playingHandler();
        setCurrentChannel(channel);
        setIndex(index);
      }}
    >
      <div className="play-channel flex justify-start gap-5 items-center  text-white lg:gap-2">
        {channel?.favicon === undefined ? (
          ""
        ) : (
          <img
            src={
              channel?.favicon === ""
                ? "/images/radioScreen/radio.png"
                : channel?.favicon
            }
            className="w-10 h-10 drop-shadow-lg shadow-xl object-cover rounded-3xl border-black lg:w-[3vw] lg:h-[3vw]"
            alt={channel?.name}
            onError={(event) =>
              (event.currentTarget.src = "/images/radioScreen/radio.png")
            }
          />
        )}
        {channel.stationuuid === playing ? (
          <div className="flex gap-1 justify-start items-center w-[50vw] lg:text-[1.5vw] lg:gap-0 ">
            {/* <ReactAudioPlayer
              src={currentUrl}
              autoPlay
              controls
              className="audio-player"
            /> */}
            {playingState ? (
              ""
            ) : (
              <Lottie
                animationData={animation}
                className="flex gap-1 justify-start items-center w-[20%] h-[20%] -mt-4 lg:-mt-6 lg:w-[10%] lg:h-[10%] lg:gap-0 "
              />
            )}
            <h1 className="text-purple-500 p-0 lg:text-[1.5vw] lg:ml-9">
              {" "}
              {currentChannel?.name.length > 18
                ? currentChannel?.name.slice(0, 15) + "..."
                : currentChannel?.name}
            </h1>
          </div>
        ) : (
          <h1 className="w-[50vw] lg:text-[1.5vw] lg:ml-9 ">{channel.name}</h1>
        )}
      </div>
      <div className="">
        {channel.stationuuid === playing && playingState ? (
          <div className="lg:h-[2vw] lg:w-[2vw]">
            {/* <ReactAudioPlayer
              src={currentUrl}
              autoPlay
              controls
              className="audio-player"
            /> */}
            <img
              className="lg:h-[2vw] lg:w-[2vw]"
              src={playPurple}
              alt=""
              onClick={() => {
                audioPlayer.current?.audioEl.current?.play();
              }}
            />
          </div>
        ) : channel.stationuuid === playing ? (
          <img
            className="lg:h-[2vw] lg:w-[2vw]"
            src={pausePurple}
            alt=""
            onClick={() => {
              audioPlayer.current?.audioEl.current?.pause();
            }}
          />
        ) : (
          <div className="lg:h-[2vw] lg:w-[2vw]">
            <img className="lg:h-[4vw] lg:w-[4vw]" src={playBlue} alt="" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ChannelCard;

// //   <ReactAudioPlayer
// autoPlay={true}
// src={playingData?.url_resolved}
// ref={audioRef}
// onPlay={() => setPlayingState(PlayingState.PLAYING)}
// onError={() => {
//   let newWin = window.open(
//     playingData?.url_resolved,
//     "test",
//     params
//   );
//   setPlayingState(PlayingState.ERROR);
// }}
// onPause={() => setPlayingState(PlayingState.PAUSED)}
// volume={audioPlayerVolume / 100}
// />
