import React from 'react'
import { Link } from 'react-router-dom'


interface FooterHubType{
  textBlack?:boolean
}

const FooterHub:React.FC<FooterHubType> = ({textBlack=false}) => {
  return (
    <div className={`w-full md:w-3/4    mx-auto  ${textBlack===true?"text-black":"text-white"}`}>
    <h2 className=' text-[20px]  md:text-[55px] ml-4 md:ml-1 font-bold  mt-8 md:mt-10'>Join into our Hub</h2>
    <div className='grid grid-cols-2 gap-10 md:grid-cols-4    justify-center  mt-8 md:mt-10'>
   
    <a href='https://www.facebook.com/rareprob_-103090149004368/' rel="noreferrer"  target={"_blank"} className='flex items-center justify-center space-x-4 cursor-pointer' >
            <img src="/images/icons/icons8-facebook.svg" alt="Facebook" className='w-8 md:w-10 hover:scale-125 transition-all'/>
            <div>
            <p className='text-sm md:text-xl font-medium '>Facebook</p>
           
            </div>
       
        </a>
        <a href='https://www.instagram.com/rareprob_' rel="noreferrer"  target={"_blank"} 
        className='flex items-center justify-center space-x-4 cursor-pointer' >
          <img src="/images/icons/icons8-instagram-96.png" alt="Instagram" className='w-8 md:w-10 hover:scale-125 transition-all' />
          <div>
          <p className='text-sm md:text-xl font-medium '>Instagram</p>
          {/* <p className='text-xs'>@rareprob </p> */}
          </div>
      
       </a>
        <a href='https://www.linkedin.com/company/rareprob/' rel="noreferrer"  target={"_blank"} 
        className='flex items-center justify-center cursor-pointer space-x-4' >
            <img src="/images/icons/icons8-linkedin-circled-96.svg" alt="LinkedIn" className='w-8 md:w-10 hover:scale-125 transition-all'/>
            <div className='cursor-pointer ' >
              <p className='text-sm md:text-xl font-medium  '>LinkedIn</p>
            </div>
        </a>
        <a href="https://twitter.com/rareprob" rel="noreferrer" target={"_blank"}  
        className='flex items-center justify-center space-x-4 -ml-5  cursor-pointer '>
            <img src="/images/icons/icons8-twitter-96.png" alt="Twitter" className='w-8  md:w-10 hover:scale-125 transition-all'/>
            <div>
            <p className='text-sm md:text-xl  font-medium '>Twitter</p>
          
            </div>
        </a>
        
      
       <a href='https://www.youtube.com/channel/UCNXZXijMtxueoWaNiye73AQ' rel="noreferrer"  target={"_blank"} className='flex items-center justify-center cursor-pointer space-x-4' >
            <img src="/images/icons/icons8-youtube.svg" alt="youtube" className='w-8 md:w-10 hover:scale-125 transition-all'/>
            <div className='cursor-pointer ' >
              <p className='text-sm md:text-xl font-medium  '>YouTube</p>
             
            </div>
        </a>
       <a href="https://in.pinterest.com/rareprob/" rel="noreferrer"  className='flex items-center justify-center cursor-pointer space-x-4' target={"_blank"}>
            <img src="/images/icons/icons8-pinterest.svg" alt="Pinterest" className='w-8 md:w-10 hover:scale-125 transition-all'/>
            <div className='cursor-pointer ' >
              <p className='text-sm md:text-xl font-medium  '>Pinterest</p>
              {/* <p className='text-xs'>@rareprob</p> */}
            </div>
        </a>
      
    </div>
   <div className={`flex items-center justify-between  ${textBlack===true?"text-black":"text-white"} px-4 md:px-10 mt-20 bg-white bg-opacity-5 h-16  rounded-lg `}>
   <div className='flex items-center justify-center space-x-2 md:space-x-10'>
    <Link to="/about-us">
      <h6  className="cursor-pointer  text-xs md:text-sm ">About </h6>
    </Link>
    <Link to="/games">
      <h6  className="cursor-pointer  text-xs md:text-sm ">Games</h6>
    </Link>
    <Link to="/apps">
      <h6  className="cursor-pointer  text-xs md:text-sm ">Apps</h6>
    </Link>

    <Link to={"/blogs"}>
    <h6  className="cursor-pointer  text-xs md:text-sm ">Blogs</h6>
    
    </Link>
 
 <Link to={"/privacy-policy"}>
   <h6  className="cursor-pointer  text-xs md:text-sm ">Privacy</h6>
   
 </Link>
 
 <Link to={"/contact-us"}>
 <h6  className="cursor-pointer  text-xs md:text-sm ">Contact</h6>
 
 </Link>
   </div>
   <div className='flex items-center justify-center space-x-4 cursor-pointer  text-xs md:text-sm ' onClick={()=>window.scrollTo(0,0)}>
    <h6  className='hidden md:block'>Back To Top</h6>
    <img src="/images/icons/Layer 2@2x.png" alt="back to top" className='w-6 hidden md:block'/>
   </div>
   </div>
  </div>
  )
}

export default FooterHub