import { getAnalytics, logEvent } from "firebase/analytics";
import "./ArcadeGames.css";

const ArcadeGames = () => {
  const analytics = getAnalytics();

  return (
    <div
      className="arcade_container"
      onPointerOver={() => window.adsbygoogle.push({})}
    >
      <h2>Arcade Games</h2>
      <div>
        <a
          href="https://play.rareprobgames.com/bouncy-ball/index.html"
          rel="noreferrer"
          onClick={() =>
            logEvent(analytics, "Rareprob Arcade Games", {
              content_type: "Bouncy Ball",
              content_id: "Bouncy Ball",
              items: [{ name: "Bouncy Ball" }],
            })
          }
        >
          <img src="/images/gamesScreen/new/bouncy.jpg" alt="Bouncy Ball" />
          <h4>Bouncy Ball</h4>
        </a>
        <a
          href="https://play.rareprobgames.com/color-jump/index.html"
          rel="noreferrer"
          onClick={() =>
            logEvent(analytics, "Rareprob Arcade Games", {
              content_type: "Color Jump",
              content_id: "Color Jump",
              items: [{ name: "Color Jump" }],
            })
          }
        >
          <img src="/images/gamesScreen/new/color.jpg" alt="Color Jump" />
          <h4>Color Jump</h4>
        </a>
        <a
          href="https://play.rareprobgames.com/color-zig-zag/index.html"
          rel="noreferrer"
          onClick={() =>
            logEvent(analytics, "Rareprob Arcade Games", {
              content_type: "Color Zig Zag",
              content_id: "Color Zig Zag",
              items: [{ name: "Color Zig Zag" }],
            })
          }
        >
          <img
            src="https://img.rareprob.com/img/default/RareProbImg/imagesRare/games/color-zig-zag.gif"
            alt="Color Zig Zag"
          />
          <h4>Color Zig Zag</h4>
        </a>
        <a
          href="https://play.rareprobgames.com/shape-zig-zag/index.html"
          rel="noreferrer"
          onClick={() =>
            logEvent(analytics, "Rareprob Arcade Games", {
              content_type: "Shape Zig Zag",
              content_id: "Shape Zig Zag",
              items: [{ name: "Shape Zig Zag" }],
            })
          }
        >
          <img
            src="https://img.rareprob.com/img/default/RareProbImg/imagesRare/games/shape_zig_zag.gif"
            alt="Shape Zig Zag"
          />
          <h4>Shape Zig Zag</h4>
        </a>
        <a
          href="https://play.rareprobgames.com/2048-3d/index.html"
          rel="noreferrer"
          onClick={() =>
            logEvent(analytics, "Rareprob Arcade Games", {
              content_type: "2048 3D",
              content_id: "2048 3D",
              items: [{ name: "2048 3D" }],
            })
          }
        >
          <img
            src="https://img.rareprob.com/img/default/RareProbImg/imagesRare/games/2048-3D.gif"
            alt="2048 3D"
          />
          <h4>2048 3D</h4>
        </a>
        <a
          href="https://play.rareprobgames.com/zig-zag/index.html"
          rel="noreferrer"
          onClick={() =>
            logEvent(analytics, "Rareprob Arcade Games", {
              content_type: "Zig Zag",
              content_id: "Zig Zag",
              items: [{ name: "Zig Zag" }],
            })
          }
        >
          <img
            src="https://img.rareprob.com/img/default/RareProbImg/imagesRare/games/zigzag.gif"
            alt="Zig Zag"
          />
          <h4>Zig Zag</h4>
        </a>
      </div>
    </div>
  );
};

export default ArcadeGames;
