import React, { useEffect, useState } from "react";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../comp/Header";
import OurApps from "../comp/OurApps";
import {
  collection,
  CollectionReference,
  DocumentData,
  getDocs,
} from "firebase/firestore";
import { firestore } from "../firebase";
import { Link } from "react-router-dom";
import { getAnalytics, logEvent } from "firebase/analytics";
import { Helmet } from "react-helmet";
import NewFooter from "../comp/NewFooter";

declare global {
  interface Window {
    adsbygoogle: { [key: string]: unknown }[];
  }
}

interface ActionGameType {
  id?: string;
  name: string;
  google: boolean;
  googleLink: string;
  buttonColorOne: string;
  buttonColorTwo: string;
  gameImage: string;
  playLink: string;
  backgroundColor: string;
}

interface BannerType {
  id: string;
  banner1: boolean;
  banner2: boolean;
}

const NewGamesScreen = () => {
  const [actionGames, setActionGames] = useState<ActionGameType[]>([]);
  const [arcadeGames, setArcadeGames] = useState<ActionGameType[]>([]);
  const [puzzleGames, setPuzzleGames] = useState<ActionGameType[]>([]);
  const [brainGames, setBrainGames] = useState<ActionGameType[]>([]);
  const [topGames, setTopGames] = useState<ActionGameType[]>([]);
  const [casualGames, setCasualGames] = useState<ActionGameType[]>([]);

  const [bannerAds, setBannerAds] = useState<BannerType | null>(null);

  const analytics = getAnalytics();

  const fetchTop: () => Promise<void> = async () => {
    const aman: ActionGameType[] = [];

    getDocs<DocumentData>(
      collection(firestore, "topGames") as CollectionReference<ActionGameType>
    ).then((querySnapshot) => {
      querySnapshot.docs.forEach((value) => {
        var game: ActionGameType = {
          id: value.id,
          backgroundColor: value.data().backgroundColor,
          buttonColorOne: value.data().buttonColorOne,
          buttonColorTwo: value.data().buttonColorTwo,
          gameImage: value.data().gameImage,
          google: value.data().google,
          googleLink: value.data().googleLink,
          name: value.data().name,
          playLink: value.data().playLink,
        };

        aman.push(game);
      });
      setTopGames(aman);
    });
  };

  const fetchBanner: () => Promise<void> = async () => {
    let aman: BannerType = { id: "fd", banner1: false, banner2: false };

    getDocs<DocumentData>(
      collection(firestore, "banner") as CollectionReference<BannerType>
    ).then((querySnapshot) => {
      querySnapshot.docs.forEach((value) => {
        var game: BannerType = {
          id: value.id,
          banner1: value.data().banner1,
          banner2: value.data().banner2,
        };

        aman = game;
      });
      setBannerAds(aman);
      window.adsbygoogle.push({});
    });
  };

  const fetchAction: () => Promise<void> = async () => {
    const aman: ActionGameType[] = [];

    getDocs<DocumentData>(
      collection(
        firestore,
        "actionGames"
      ) as CollectionReference<ActionGameType>
    ).then((querySnapshot) => {
      querySnapshot.docs.forEach((value) => {
        var game: ActionGameType = {
          id: value.id,
          backgroundColor: value.data().backgroundColor,
          buttonColorOne: value.data().buttonColorOne,
          buttonColorTwo: value.data().buttonColorTwo,
          gameImage: value.data().gameImage,
          google: value.data().google,
          googleLink: value.data().googleLink,
          name: value.data().name,
          playLink: value.data().playLink,
        };

        aman.push(game);
      });
      setActionGames(aman);
    });
  };

  const fetchCasual: () => Promise<void> = async () => {
    const aman: ActionGameType[] = [];

    getDocs<DocumentData>(
      collection(
        firestore,
        "casualGames"
      ) as CollectionReference<ActionGameType>
    ).then((querySnapshot) => {
      querySnapshot.docs.forEach((value) => {
        var game: ActionGameType = {
          id: value.id,
          backgroundColor: value.data().backgroundColor,
          buttonColorOne: value.data().buttonColorOne,
          buttonColorTwo: value.data().buttonColorTwo,
          gameImage: value.data().gameImage,
          google: value.data().google,
          googleLink: value.data().googleLink,
          name: value.data().name,
          playLink: value.data().playLink,
        };
        aman.push(game);
      });
      setCasualGames(aman);
      window.adsbygoogle.push({});
    });
  };

  const fetchBrain: () => Promise<void> = async () => {
    const aman: ActionGameType[] = [];

    getDocs<DocumentData>(
      collection(firestore, "brainGames") as CollectionReference<ActionGameType>
    ).then((querySnapshot) => {
      querySnapshot.docs.forEach((value) => {
        var game: ActionGameType = {
          id: value.id,
          backgroundColor: value.data().backgroundColor,
          buttonColorOne: value.data().buttonColorOne,
          buttonColorTwo: value.data().buttonColorTwo,
          gameImage: value.data().gameImage,
          google: value.data().google,
          googleLink: value.data().googleLink,
          name: value.data().name,
          playLink: value.data().playLink,
        };
        aman.push(game);
      });
      setBrainGames(aman);
      window.adsbygoogle.push({});
    });
  };

  const fetchPuzzle: () => Promise<void> = async () => {
    const aman: ActionGameType[] = [];

    getDocs<DocumentData>(
      collection(
        firestore,
        "puzzleGames"
      ) as CollectionReference<ActionGameType>
    ).then((querySnapshot) => {
      querySnapshot.docs.forEach((value) => {
        var game: ActionGameType = {
          id: value.id,
          backgroundColor: value.data().backgroundColor,
          buttonColorOne: value.data().buttonColorOne,
          buttonColorTwo: value.data().buttonColorTwo,
          gameImage: value.data().gameImage,
          google: value.data().google,
          googleLink: value.data().googleLink,
          name: value.data().name,
          playLink: value.data().playLink,
        };

        aman.push(game);
      });
      setPuzzleGames(aman);
    });
  };

  const fetchArcade: () => Promise<void> = async () => {
    const aman: ActionGameType[] = [];

    getDocs<DocumentData>(
      collection(
        firestore,
        "arcadeGames"
      ) as CollectionReference<ActionGameType>
    ).then((querySnapshot) => {
      querySnapshot.docs.forEach((value) => {
        var game: ActionGameType = {
          id: value.id,
          backgroundColor: value.data().backgroundColor,
          buttonColorOne: value.data().buttonColorOne,
          buttonColorTwo: value.data().buttonColorTwo,
          gameImage: value.data().gameImage,
          google: value.data().google,
          googleLink: value.data().googleLink,
          name: value.data().name,
          playLink: value.data().playLink,
        };

        aman.push(game);
      });
      setArcadeGames(aman);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchTop();
    fetchBanner();
    fetchAction();
    fetchArcade();
    fetchPuzzle();
    fetchBrain();
    fetchCasual();
  }, []);

  var settings: Settings = {
    lazyLoad: "ondemand",
    dots: true,
    infinite: true,
    centerMode: false,
    arrows: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 2,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  var settingsCasual: Settings = {
    lazyLoad: "ondemand",
    dots: false,
    infinite: true,
    speed: 500,
    centerMode: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    draggable: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Rareprob : Play Free Online Web | Android | iOS | Games</title>
        <link rel="canonical" href="https://rareprob.com/games" />
        <meta
          name="description"
          content="Play Free Online Brain Games like- Find Difference, Tic-Tac-Toe, and Stack Ball with Multiplayer and Single-player features. Enjoy the best 2D and 3D Arcade games| Rareprob."
        />
      </Helmet>
      <Header color="bg-black/90" page="game" />
      <main
        className="pt-8 lg:pt-4 ml-0 lg:ml-44 bg-gradient-to-b from-[#FFFFFF] to-[#FFF4F4] overflow-hidden"
        id="start"
      >
        <div className="bg-black/10 backdrop-blur-xl	 h-40 w-10 rounded-l-xl absolute right-0 top-0 bottom-0 my-auto z-40 flex space-y-6 items-center justify-center flex-col">
          <a
            href="https://www.rocksplayer.com/"
            target={"_blank"}
            rel="noreferrer"
            onClick={() =>
              logEvent(analytics, "Game_Rocksplayer_Event", {
                content_type: "Rocksplayer Game",
                content_id: "Rocksplayer Game",
                items: [{ name: "Rocksplayer Game" }],
              })
            }
          >
            <img
              src="/images/icons/videoplayer.png"
              alt="Rocks Video Player | Rareprob"
              className="w-7 h-7 rounded-lg"
            />
          </a>

          <a
            href={"/apps/file-manager-app"}
            onClick={() =>
              logEvent(analytics, "Game_FileManager_Event", {
                content_type: "FileManager Game",
                content_id: "FileManager Game",
                items: [{ name: "FileManager Game" }],
              })
            }
          >
            <img
              src="/images/icons/file mana.webp.png"
              alt="File Manager | Rareprob"
              className="w-7 h-7 rounded-lg"
            />
          </a>
          <a
            href="/apps/video-downloader-files-hider-app"
            onClick={() =>
              logEvent(analytics, "Game_VideoDownloader_Event", {
                content_type: "VideoDownloader Game",
                content_id: "VideoDownloader Game",
                items: [{ name: "VideoDownloader Game" }],
              })
            }
          >
            <img
              src="/images/icons/videoDownloader.webp"
              alt="File Manager | Rareprob"
              className="w-7 h-7 rounded-lg"
            />
          </a>
        </div>
        <div className="ml-0 lg:ml-6 mr-4 lg:mx-64" id="gameCategories">
          {/* <h1
            className="font-bold text-xl ml-0 lg:ml-14 lg:text-2xl"
            onClick={() => console.log(bannerAds)}
          >
            Categories
          </h1>
          <Slider {...settings} className="w-screen ml-0 lg:ml-5 mt-6">
            <CategoryContainer
              link="#action"
              color="bg-[#D3FCEF]"
              name="Action Games"
              image="/images/gamesScreen/newGames/Basketball game.webp"
            />
            <CategoryContainer
              link="#puzzle"
              color="bg-[#FFF4D8]"
              name="Puzzle Games"
              image="/images/gamesScreen/newGames/Group 6750.webp"
            />
            <CategoryContainer
              link="#brain"
              color="bg-[#dfe5f3]"
              name="Brain Games"
              image="/images/gamesScreen/newGames/Game of tennis with artificial intelligence.webp"
            />
            <CategoryContainer
              link="#arcade"
              color="bg-[#fee8da]"
              name="Arcade Games"
              image="/images/gamesScreen/newGames/Arcade.webp"
            />
            <CategoryContainer
              link="#casual"
              color="bg-[#FFEBEE]"
              name="Casual Games"
              image="/images/gamesScreen/newGames/casual.webp"
            />
          </Slider> */}

          <div className="bg-[#EDEDED]  relative mt-10 lg:mt-10 rounded-2xl pb-10 pt-5 px-3 lg:px-10">
            <div className=" h-96 absolute w-56 hidden lg:block float-right -right-64">
              <h1>Recent played games</h1>
              <div className="mt-6 flex flex-col items-start justify-center space-y-4">
                <a
                  href="https://play.rareprobgames.com/FindDiff2.0/index.html"
                  className="flex items-center justify-start cursor-pointer space-x-3"
                >
                  <img
                    src="/images/gamesScreen/newGames/Group 17510@2x.webp"
                    className="w-14 h-14 rounded-xl "
                    alt="Find Difference"
                  />
                  <p className="text-xs font-normal">Find Difference</p>
                </a>

                <a
                  href="https://play.rareprobgames.com/Flappy-Ball/index.html"
                  className="flex items-center justify-start cursor-pointer space-x-3"
                >
                  <img
                    src="/images/gamesScreen/newGames/flappyBall.webp"
                    className="w-14 h-14 rounded-xl "
                    alt="Find Difference"
                  />
                  <p className="text-xs font-normal">Flappy Ball</p>
                </a>

                <a
                  href="https://play.rareprobgames.com/2048/index.html"
                  className="flex items-center justify-start cursor-pointer space-x-3"
                >
                  <img
                    src="/images/gamesScreen/newGames/2048.webp"
                    className="w-14 h-14 rounded-xl "
                    alt="2048"
                  />
                  <p className="text-xs font-normal">2048</p>
                </a>

                <a
                  href="https://play.rareprobgames.com/stackball/index.html"
                  className="flex items-center justify-start cursor-pointer space-x-3"
                >
                  <img
                    src="/images/gamesScreen/newGames/Group 17507@2x.webp"
                    className="w-14 h-14 rounded-xl "
                    alt="Stack Ball"
                  />
                  <p className="text-xs font-normal">Stack Ball</p>
                </a>
              </div>
            </div>
            <h1 className="font-bold ml-2 lg:ml-4 text-xl lg:text-2xl">
              Top Games
            </h1>
            <div
              {...settings}
              className="grid grid-cols-2 gap-x-2 place-items-center  gap-y-4 lg:gap-8 lg:grid-cols-3 mt-4 lg:mt-5"
              onPointerOver={() => window.adsbygoogle.push({})}
            >
              {topGames.map((value, index) => {
                return (
                  <GameContainer
                    key={value.id}
                    name={value.name}
                    image={value.gameImage}
                    playLink={value.playLink}
                    googleLink={value.googleLink}
                    backgroundColor={value.backgroundColor}
                    buttonColorOne={value.buttonColorOne}
                    buttonColorTwo={value.buttonColorTwo}
                  />
                );
              })}
            </div>
          </div>

          {bannerAds?.banner1 === true ? (
            <ins
              className="adsbygoogle  w-full my-10"
              style={{ display: "block" }}
              data-ad-format="auto"
              data-ad-client="ca-pub-9496468720079156"
              data-full-width-responsive="true"
            ></ins>
          ) : null}
          <div id="action"></div>

          <div
            className="bg-[#EDEDED] min-h-[500px]  mt-16 lg:mt-20 rounded-2xl pb-10 pt-5 px-3 lg:px-10 relative"
            onPointerOver={() => window.adsbygoogle.push({})}
          >
            <h1
              className="font-bold ml-2 lg:ml-4 text-xl lg:text-2xl"
              onClick={() => console.log(actionGames)}
            >
              Action Games
            </h1>
            <div className="grid grid-cols-2 gap-x-2 place-items-center  gap-y-4 lg:gap-8 lg:grid-cols-3 mt-5 lg:mt-4">
              {actionGames.map((value, index) => {
                console.log(index);
                return (
                  <GameContainer
                    key={value.id}
                    name={value.name}
                    image={value.gameImage}
                    playLink={value.playLink}
                    googleLink={value.googleLink}
                    backgroundColor={value.backgroundColor}
                    buttonColorOne={value.buttonColorOne}
                    buttonColorTwo={value.buttonColorTwo}
                  />
                );
              })}
            </div>
          </div>

          {bannerAds?.banner1 === true ? (
            <ins
              className=" adsbygoogle  w-full my-10"
              style={{ display: "block" }}
              data-ad-format="auto"
              data-ad-client="ca-pub-9496468720079156"
              data-full-width-responsive="true"
            ></ins>
          ) : null}

          {/* Arcade Games */}
          <div id="arcade"></div>
          <div
            className="bg-[#EDEDED] min-h-[500px] mt-10 lg:mt-10 rounded-2xl pb-10 pt-5 px-3 lg:px-10"
            onPointerOver={() => window.adsbygoogle.push({})}
          >
            <h1 className="font-bold ml-2 lg:ml-4 text-xl lg:text-2xl">
              Arcade Games
            </h1>
            <div
              {...settings}
              className="grid grid-cols-2 gap-x-2 place-items-center gap-y-4 lg:gap-8 lg:grid-cols-3 mt-4 lg:mt-5"
            >
              {arcadeGames.map((value, index) => {
                return (
                  <GameContainer
                    key={value.id}
                    name={value.name}
                    image={value.gameImage}
                    playLink={value.playLink}
                    googleLink={value.googleLink}
                    backgroundColor={value.backgroundColor}
                    buttonColorOne={value.buttonColorOne}
                    buttonColorTwo={value.buttonColorTwo}
                  />
                );
              })}
            </div>
          </div>

          {bannerAds?.banner1 === true ? (
            <ins
              className=" adsbygoogle  w-full my-10"
              style={{ display: "block" }}
              data-ad-format="auto"
              data-ad-client="ca-pub-9496468720079156"
              data-full-width-responsive="true"
            ></ins>
          ) : null}

          {/* Puzzle Games */}
          <div id="puzzle"></div>
          <div
            className="bg-[#EDEDED] min-h-[500px] mt-10 lg:mt-10 rounded-2xl pb-10 pt-5 px-3 lg:px-10"
            onPointerOver={() => window.adsbygoogle.push({})}
          >
            <h1 className="font-bold ml-2 lg:ml-4 text-xl lg:text-2xl">
              Puzzle Games
            </h1>
            <div
              {...settings}
              className="grid grid-cols-2 gap-x-2 place-items-center gap-y-4 lg:gap-8 lg:grid-cols-3 mt-4 lg:mt-5"
            >
              {puzzleGames.map((value, index) => {
                return (
                  <GameContainer
                    key={value.id}
                    name={value.name}
                    image={value.gameImage}
                    playLink={value.playLink}
                    googleLink={value.googleLink}
                    backgroundColor={value.backgroundColor}
                    buttonColorOne={value.buttonColorOne}
                    buttonColorTwo={value.buttonColorTwo}
                  />
                );
              })}
            </div>
          </div>

          {bannerAds?.banner2 === true ? (
            <ins
              className=" adsbygoogle  w-full my-10"
              style={{ display: "block" }}
              data-ad-format="auto"
              data-ad-client="ca-pub-9496468720079156"
              data-full-width-responsive="true"
            ></ins>
          ) : null}

          {/* Brain Games */}
          <div id="brain"></div>
          <div className="bg-[#EDEDED] min-h-[500px] mt-10 lg:mt-10 rounded-2xl pb-10 pt-5 px-3 lg:px-10">
            <h1 className="font-bold ml-2 lg:ml-4 text-xl lg:text-2xl">
              Brain Games
            </h1>
            <div className="grid grid-cols-2 gap-x-2 gap-y-4 place-items-center lg:gap-8 lg:grid-cols-3 mt-4 lg:mt-5">
              {brainGames.map((value, index) => {
                return (
                  <GameContainer
                    key={value.id}
                    name={value.name}
                    image={value.gameImage}
                    playLink={value.playLink}
                    googleLink={value.googleLink}
                    backgroundColor={value.backgroundColor}
                    buttonColorOne={value.buttonColorOne}
                    buttonColorTwo={value.buttonColorTwo}
                  />
                );
              })}
            </div>
          </div>

          {/* Casual Games */}
          <div id="casual"></div>
          <div className=" mt-10  lg:mt-10 min-h-[300px] rounded-2xl pb-10 pt-5 px-3 lg:px-10">
            <h1 className="font-bold ml-2 lg:ml-4 text-xl lg:text-2xl">
              Casual Games
            </h1>
            {/* <div  className='grid grid-cols-2 gap-8 lg:gap-8 lg:grid-cols-3 mt-3 lg:mt-10'> */}
            <Slider {...settingsCasual} className="ml-0 lg:-ml-10 w-full mt-6">
              {casualGames.map((value, index) => {
                return (
                  <CasualGameContainer
                    key={value.id}
                    name={value.name}
                    image={value.gameImage}
                    link={value.playLink}
                  />
                );
              })}
            </Slider>

            {/* </div> */}
          </div>
          <div className="mt-20"></div>
          <OurApps gamePage={true} />
          <div className="mt-20"></div>
          <div
            onClick={() =>
              (window.location.href =
                "https://play.google.com/store/apps/dev?id=5808132673176532737")
            }
            className="relative cursor-pointer bg-appspace-banner text-white h-[400px] bg-center bg-cover rounded-xl 
              space-y-24 lg:space-y-0 lg:space-x-10 flex flex-col 
              lg:flex-row items-center justify-center lg:justify-between "
          >
            <h2 className="absolute top-2 w-4 right-2 lg:right-4  rounded-sm text-white text-[10px] p-[1px] z-20">
              Ad
            </h2>
            <div className="flex flex-col items-center justify-center lg:items-start">
              <h1 className="text-2xl lg:text-5xl w-72 text-center lg:text-left font-bold">
                Fun has Just Begun
              </h1>
              <p className="mt-2 text-sm text-center lg:text-left">
                Explore more Games
              </p>
              <a
                href="https://play.google.com/store/apps/dev?id=5808132673176532737"
                onClick={(e) => {
                  e.stopPropagation();
                  logEvent(analytics, "select_content", {
                    content_type: "GampePageAppSpace",
                    content_id: "AppsapceClicked",
                  });

                  logEvent(analytics, "GampePageAppSpace", {
                    content_type: "GampePageAppSpace",
                    content_id: "AppsapceClicked",
                  });
                }}
                target={"_blank"}
                rel="noreferrer"
              >
                <img
                  src="/images/homepage/google.webp"
                  alt="appspace google"
                  className="max-w-xs  object-cover  max-h-16 lg:max-h-24 -ml-4"
                />
              </a>
            </div>
            <div className="grid lg:grid-cols-2 grid-cols-4 rounded-xl  px-6 lg:px-0  lg:w-[400px] w-full max-h-[300px] lg:min-h-full gap-0">
              <img
                src="/images/gamesScreen/newGames/Group 17507@2x.webp"
                alt="Stackball"
                className=" max-w-[100%] object-cover h-[60px]  lg:h-44 rounded-xl"
              />
              <img
                src="/images/gamesScreen/newGames/Group 17508@2x.webp"
                alt="Knifehit"
                className=" max-w-[100%] object-cover h-[60px] lg:h-44  rounded-xl"
              />
              <img
                src="/images/gamesScreen/newGames/Group 17509@2x.webp"
                alt="Tic Tac Toe"
                className=" max-w-[100%] object-cover h-[60px] lg:h-44  rounded-xl"
              />
              <img
                src="/images/gamesScreen/newGames/Group 17510@2x.webp"
                alt="Find Difference"
                className=" max-w-[100%] object-cover h-[60px] lg:h-44 rounded-xl"
              />
            </div>
          </div>
        </div>
        <div className="h-44 "></div>
      </main>
      <NewFooter color="bg-black/90" />
    </>
  );
};

export default NewGamesScreen;

// interface CategoryContainerType {
//   name: string;
//   image: string;
//   color: string;
//   link: string;
// }

// const CategoryContainer: React.FC<CategoryContainerType> = ({
//   name,
//   image,
//   color,
//   link,
// }) => {
//   const analytics = getAnalytics();

//   return (
//     <a
//       href={link}
//       className="mr-10"
//       id="categoryDrag"
//       onClick={() => {
//         logEvent(analytics, "select_content", {
//           content_type: "Game Categories",
//           content_id: name,
//           items: [{ name: name }],
//         });

//         logEvent(analytics, name, {
//           content_type: "Game Categories",
//           content_id: name,
//           items: [{ name: name }],
//         });
//         window.adsbygoogle.push({});
//       }}
//     >
//       <div
//         className={`${color}  flex flex-col lg:flex-row space-y-2 mr-6
//     lg:space-y-0  border items-center
//     justify-around rounded-2xl  h-24 lg:h-28 `}
//       >
//         <img
//           src={image}
//           className="lg:w-32 w-24 h-24 lg:h-32 object-contain"
//           alt={name}
//         />
//         <h1 className="text-center hidden lg:block text-sm mb-2 font-semibold lg:font-bold">
//           {name}
//         </h1>
//       </div>
//       <h1 className="text-center lg:hidden mt-2 text-sm mb-2 font-medium lg:font-bold">
//         {name}
//       </h1>
//     </a>
//   );
// };

interface GameContainerType {
  image: string;
  name: string;
  buttonColorOne: string;
  buttonColorTwo: string;
  backgroundColor: string;
  playLink: string;
  googleLink: string;
}

const GameContainer: React.FC<GameContainerType> = ({
  image,
  name,
  backgroundColor,
  buttonColorOne,
  buttonColorTwo,
  playLink,
  googleLink,
}) => {
  const analytics = getAnalytics();

  return (
    <div className=" ">
      <Link
        to={playLink}
        style={{ backgroundColor: backgroundColor }}
        onClick={() => {
          logEvent(analytics, "select_content", {
            content_type: name,
            content_id: name,
            items: [{ name: name }],
          });

          logEvent(analytics, name, {
            content_type: name,
            content_id: name,
            items: [{ name: name }],
          });
        }}
        className={`flex p-[6px] pb-2  lg:p-2 flex-col w-36 lg:w-52 xl:w-64 rounded-2xl relative lg:rounded-3xl 
           filter backdrop-blur-3xl items-center ${backgroundColor}  !bg-transparent
           space-y-3 lg:space-y-4 justify-center`}
      >
        <img
          src={image}
          className="w-full rounded-3xl lg:rounded-2xl shadow-lg object-cover h-32 lg:h-48"
          alt={name}
        />

        {googleLink === "" || googleLink === "null" ? (
          <></>
        ) : (
          <p className="absolute lg:top-0 top-0 z-40 lg:right-6 right-4 text-[8px] font-medium text-white">
            Ad
          </p>
        )}
        {/* <h2 className="font-medium bg-black/20 rounded-xl h-10 absolute flex items-center justify-center bottom-2 text-white text-xs lg:text-lg lg:font-bold text-center">
          {name}
        </h2> */}

        <h2 className="font-medium text-xs lg:text-lg lg:font-bold text-center">
          {name}
        </h2>
        {googleLink === "" || googleLink === "null" ? (
          <></>
        ) : (
          <a href={googleLink} target="_blank" rel="noreferrer">
            <img
              src="/images/homepage/google.webp"
              className="w-20 lg:w-40"
              alt="google"
            />
          </a>
        )}

        {/* <button
          style={{
            background: `linear-gradient(to right, ${buttonColorOne},${buttonColorTwo})`,
          }}
          className={`w-full text-white font-bold drop-shadow-2xl  rounded-xl lg:rounded-2xl  py-2 lg:py-4  text-xl bg-gradient-to-r  
                `}
        >
          Play Now
        </button> */}
      </Link>
    </div>
  );
};

interface CasualGameContainerType {
  name: string;
  image: string;
  link: string;
}

const CasualGameContainer: React.FC<CasualGameContainerType> = ({
  name,
  image,
  link,
}) => {
  const analytics = getAnalytics();

  return (
    <div>
      <div
        className="flex flex-col space-y-3 items-center justify-center "
        id="categoryDrag"
      >
        <img
          src={image}
          className="w-28 lg:w-44 h-28 lg:h-44 rounded-2xl"
          alt={name}
        />
        <h2 className="text-xs lg:font-bold text-center">{name}</h2>
        <Link
          to={link}
          onClick={() => {
            logEvent(analytics, "select_content", {
              content_type: "Casual Games",
              content_id: name,
              items: [{ name: name }],
            });
            logEvent(analytics, name, {
              content_type: "Casual Games",
              content_id: name,
              items: [{ name: name }],
            });
          }}
          className="px-3 lg:px-6 w-28 lg:w-44  text-center font-bold rounded-xl lg:rounded-2xl 
              py-2 lg:py-3 bg-[#33859C] text-white "
        >
          Play Now
        </Link>
      </div>
    </div>
  );
};
