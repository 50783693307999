import React from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

const FaqFilemanager:React.FC = () => {
  return (
    <div className='w-3/4 mx-auto mt-20'>
    <Accordion className='' allowZeroExpanded={true}>
          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}}>
           
          <AccordionItemHeading 
                className='accordion__arrow'   >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                  1. How is this ASD File Manager app different from the default File Manager app on your device? 
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                     The default File Manager lacks many functionalities which ASD File Manager offers, like shortcuts to important folders 📁, a calculator with a secret folder🔒, 🧹Clean Master, ✆ status saver, ShareOn, File compressing function, free online games🎮, multiple language support🗣️, dark mode and much more. 
                  </p>
              </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}}>
          <AccordionItemHeading 
                className='accordion__arrow'  >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                   2. How can you protect your sensitive files from other users of your phone? 
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                    The ASD File Manager app comes with a Callock function which is a secret folder hidden in the Calculator of the app. You can save all your private and sensitive data in this pin-protected secret folder. To view your hidden files, just enter the pin code in the calculator and the secret folder will open. 
                  </p>
                  <img src="/images/faq/fileManger/Layer 0.webp" 
                  className='w-56 mt-6'
                  alt="Callock" />
              </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}}>
          <AccordionItemHeading 
                className='accordion__arrow'   >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                  3. Can you Delete Files with ASD File Manager app? 
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                    Yes, you can. Deleting files with the ASD File Manager app is very easy, simply select the file you want to delete and click on the trash bin 🗑️ icon. You can choose to delete the file permanently or move it to trash (to recover the file later). 
                  </p>
                 
              </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}}>
          <AccordionItemHeading 
                className='accordion__arrow'    >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                  4. How can I recover deleted files from the ASD File Manager app? 
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                      Recovering deleted files is possible with the ASD File Manager app. The files you kept in the trash after deleting are retrievable. Just click on the bin icon on the top right corner of the app's homepage.  <br />
                      You will find your deleted files there, from there select the file you want to recover and click on the recover icon on the app. You can then find this recovered file in the original folder. 
                  </p>
                  <img src="/images/faq/fileManger/Trash (1).webp" 
                  className='w-56 mt-6'
                  alt="Recover deleted files with File Manager" />
              </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
          <AccordionItemHeading 
                className='accordion__arrow'   >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                  5. Can ASD File Manager App clear junk  and residual files?
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                    Clean Master🧹 is one of the features of the ASD File Manager app, that you can use to clear out the junk and residual files from the phone.  
                  </p>
                  <img src="/images/faq/fileManger/clean_.webp" 
                  className='w-56 mt-6'
                  alt="Clean up with Clean Master" />
              </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}}>
          <AccordionItemHeading 
                className='accordion__arrow'   >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                  6. How can I transfer files from one device to another? 
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                    You can share the desired files online with anyone you like with this app but if you want to transfer files to others without an internet connection, then you can try ShareOn feature of the ASD File Manager app. Just connect your device to another phone or PC and share files offline. 
                  </p>
                  <img src="/images/faq/fileManger/shar eo n.webp" 
                  className='w-56 mt-6'
                  alt="Transfer Files from device using File Manager" />
              </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}}>
          <AccordionItemHeading 
                className='accordion__arrow'    >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                  7. What is file compressing how can I do that? 
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                    To save space on your device or to transfer files faster without hampering the original quality of the file you can compress it into Zip file. These Zip files can be decompressed after which it returns to its original size. 
                  </p>
                  <img src="/images/faq/fileManger/ZIP FILE_ (1).webp" 
                  className='w-56 mt-6'
                  alt="File Compression using File Manager" />
              </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem  style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}}>
          <AccordionItemHeading 
                className='accordion__arrow'   >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                  8. How can I organize files on my android phone? 
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                     The best way to organize and access all the files on your device is the ASD File Manager app. Here all your files are shown in an organized format from where you can open, share, move, rename or compress these files. You won't have to search different files like Photos, Videos, PDFs, Downloads, and Zip Files separately anymore.  
                  </p>
                  <img src="/images/faq/fileManger/faqFilemanagerInternal.webp" 
                  className='w-56 mt-6'
                  alt="Organize Files using File Manager" />
              </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}}>
          <AccordionItemHeading 
                className='accordion__arrow'    >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                  9. Can you scan documents and convert them into PDFs with this File Manager app? 
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                     The ASD File Manager app comes with an in-built Scanner for your documents which can be converted into PDF files. Just click clear pictures of your physical documents, check the filter you want on your digital document, and convert it into PDF with a click of a button. You won't have to download a separate scanner app with this File Manager app 
                  </p>
                  <img src="/images/faq/fileManger/faqScanDoc.webp" 
                  className='w-56 mt-6'
                  alt="In-built Scanner using File Manager" />
              </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem  style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}}>
          <AccordionItemHeading 
                className='accordion__arrow'  >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                    10. Is there a Dark mode in the ASD File Manager app? 
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                     Yes, the ASD File Manager app comes with a dark mode functionality. The outstanding and decent size graphics in the app are always pleasing to your eyes in both light & dark modes. You can enable the dark mode to soothe your eyes and prevent them from blue light. 
                  </p>
                  <img src="/images/faq/fileManger/faqFilemanagerNightMode.webp" 
                  className='w-56 mt-6'
                  alt="Dark Mode  using File Manager" />
              </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}}>
          <AccordionItemHeading 
                className='accordion__arrow'    >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                  11. Can you play media files like videos? Which file formats are supported with this app? 
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                    The ASD File Manager app offers an in-built video player, you can also watch photos of your device, and read PDFs with a PDF file reader. The in-built video player has options like playback speed, brightness adjustment, 10 sec forward, and rewind options. 
                  </p>
                  <img src="/images/faq/fileManger/video player.webp" 
                  className='w-56 mt-6'
                  alt="Video Player using File Manager" />
              </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}}>
          <AccordionItemHeading 
                className='accordion__arrow'   >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                  12. How to Filter Duplicate media files on my device?  
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                     The Filter Duplicate feature of the ASD File Manager app scans all the duplicate media files on your device and displays them on the screen. There are separate sections for Photos, Videos, and Documents.   <br />
                     From individual folders, you can choose to check the duplicate files and then delete them from your device. You can either delete it permanently or move it to the trash to recycle it later. 
                  </p>
              </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}}>
          <AccordionItemHeading 
                className='accordion__arrow'    >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                  13. Does ASD File Manager support Widgets of Media Files? 
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                    Yes, this File Manager app supports the widget on the home screen of your device. The freshly added media files like photos are automatically updated on the home screen of the phone. 
                  </p>
                  <img src="/images/faq/fileManger/faqFileMaangerHomeScreen.webp" 
                  className='w-56 mt-6'
                  alt="Homepage Widgets using File Manager" />
              </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
          <AccordionItemHeading 
                className='accordion__arrow'   >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                  14. Can you download social media Videos and Reels from this app? 
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                   <p> 
                      Yes, you can download social media videos from the app very easily. Either copy the URL of the video or reel you want to download and paste it into the browser or simply log in to your social media account from this app. You can surf the feeds and download videos even faster. 
                  </p>
                  <img src="/images/faq/fileManger/faqFilemanagerDownloader.webp" 
                  className='w-56 mt-6'
                  alt="Download social media Videos and Reels File Manager" />
              </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}}>
          <AccordionItemHeading 
                className='accordion__arrow'    >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                  15. Can you search for anything on the internet with the File Manager app? 
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                   <p> 
                     With the in-built browser of the ASD File Manager app, you can effortlessly search anything on the internet like you would with any normal browser like Google Chrome or Firefox. 
                  </p>
              </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
          <AccordionItemHeading 
                className='accordion__arrow'   >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                  16. What is the File explorer feature of your device? 
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                   <p> 
                     With the in-built browser of the ASD File Manager app, you can effortlessly search anything on the internet like you would with any normal browser like Google Chrome or Firefox. 
                  </p>
              </AccordionItemPanel>
          </AccordionItem>
      </Accordion>
  </div>
  )
}

export default FaqFilemanager