
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";

const InnerVideodownloader = () => {
  return (
    <div className="w-full">
       <h3 className="text-lg lg:text-3xl font-semibold mt-14">
       Here is how you can download videos from the supported platforms
      </h3>
      <Accordion className="mt-10  " allowZeroExpanded={true}>
        <AccordionItem
          style={{
            border: "0.2px solid white",
            borderRadius: "5px",
            marginTop: "10px",
          }}
        >
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button 
                     !text-black !bg-gradient-to-r from-[#fd4a15]/10 to-[#fe2c78]/10
                      !rounded-lg"
            >
              Facebook Video Downloader
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <h3>
              While browsing through Facebook posts, you come across multiple
              videos you wish to download. But Facebook doesn’t allow users to
              download videos, so with ASD Video Downloader, you can easily
              download FB videos and photos. <br /> Here we have provided some
              steps from where you can download FB videos <br />
              <strong>Step-1: </strong> Open FB and click on the video you want
              to download. <br /> <strong> Step-2: </strong>
              Copy the link, and your link will automatically be pasted in the
              browser. <br /> <strong>Step-3:</strong> Tap on the "Download
              button, and your video will be in your device gallery. <br />{" "}
              <br />
            </h3>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem
          style={{
            border: "0.2px solid white",
            borderRadius: "5px",
            marginTop: "10px",
          }}
        >
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button 
                     !text-black !bg-gradient-to-r from-[#fd4a15]/10 to-[#fe2c78]/10
                      !rounded-lg"
            >
              Instagram Video Downloader
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <h3>
              IG contains several awesome videos, reels, and photos that you
              come across daily. Unfortunately, Instagram also does not allow
              users to download videos from their platform. Using the ASD Video
              Downloader, you can easily download the reel, and images from
              Instagram without reducing the quality of the video. <br /> Check
              out the steps to download the IGram videos: <br />
              <strong>Step-1: </strong> Open Instagram from the app or directly
              log in from this app. <br /> <strong> Step-2: </strong>
              Copy the link of the video you wish to download. <br />{" "}
              <strong>Step-3:</strong> Open the app and the video link will
              automatically be pasted. <br />
              <strong> Step-4: </strong>
              Click on the Download button and your video will be downloaded on
              your device gallery. <br /> <br />
            </h3>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem
          style={{
            border: "0.2px solid white",
            borderRadius: "5px",
            marginTop: "10px",
          }}
        >
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button 
                     !text-black !bg-gradient-to-r from-[#fd4a15]/10 to-[#fe2c78]/10
                      !rounded-lg"
            >
              Twitter Video Downloader
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <h3 className="mt-2">
              Twitter is one of the most famous social media platforms where you
              can exchange tweets and stay connected with a wide audience. On
              Twitter, you can save images in the app, but videos, on the other
              hand, are not saved. Now, you can download the videos quickly and
              easily with ASD Video Downloader. <br /> If you want to download
              Twitter videos, follow the steps below: <br />
              <strong>Step-1: </strong> Go to the Rareprob website.
              <br /> <strong> Step-2: </strong>
              On the homepage, click on the “Our Products” or “App” section.{" "}
              <br /> <strong>Step-3:</strong>Then tap on the ASD Video
              Downloader icon. <br />
              <strong> Step-4: </strong>
              Paste the URL of the video you want to download. <br />
              <strong> Step-5: </strong>
              Click on the download button and select the video quality i.e., HD
              or SD.
              <br /> <br />
            </h3>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem
          style={{
            border: "0.2px solid white",
            borderRadius: "5px",
            marginTop: "10px",
          }}
        >
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button 
                     !text-black !bg-gradient-to-r from-[#fd4a15]/10 to-[#fe2c78]/10
                      !rounded-lg"
            >
              Vimeo Video Downloader
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <h3 className="mt-2">
              Vimeo is a video-sharing platform that has features such as video
              customization and live streaming. It allows users to create
              high-quality videos to reach a mass audience. There are many
              interesting videos that you can't miss, and with the ASD Video
              Downloader app, you can download videos and photos hassle-free.{" "}
              <br /> Following are the steps to download Vimeo videos: <br />
              <strong>Step-1: </strong> Open ASD Video Downloader app
              <br /> <strong> Step-2: </strong>
              Then copy the link of the video you want to download. <br />{" "}
              <strong>Step-3:</strong>The link will automatically be pasted into
              the browser. <br />
              <strong> Step-4: </strong>
              Click on the Download button, and your video will be downloaded
              directly to your device's gallery.
              <br /> <br />
            </h3>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem
          style={{
            border: "0.2px solid white",
            borderRadius: "5px",
            marginTop: "10px",
          }}
        >
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button 
                     !text-black !bg-gradient-to-r from-[#fd4a15]/10 to-[#fe2c78]/10
                      !rounded-lg"
            >
              Dailymotion Video Downloader
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <h3 className="mt-2">
              Dailymotion works the same as Vimeo; both are video-sharing
              platforms. But in Dailymotion, there are different categories of
              content such as sports, movies, TV shows, news, entertainment,
              etc. There are videos that we like, but we are not able to
              download them as Dailymotion does not provide the option to
              download them on the platform.
              <br /> How can you download Dailymotion videos with the ASD Video
              Downloader app? <br />
              <strong>Step-1: </strong> In the ASD Video Downloader app, you can
              directly log into your Dailymotion account.
              <br /> <strong> Step-2: </strong>
              Then click on the video you want to download and copy the link.{" "}
              <br /> <strong>Step-3:</strong>The link will automatically be
              pasted into the browser. <br />
              <strong> Step-4: </strong>
              Tap on the download button, and the video will be downloaded to
              the device's local storage.
              <br /> <br />
            </h3>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem
          style={{
            border: "0.2px solid white",
            borderRadius: "5px",
            marginTop: "10px",
          }}
        >
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button 
                     !text-black !bg-gradient-to-r from-[#fd4a15]/10 to-[#fe2c78]/10
                      !rounded-lg"
            >
              Linkedin Video Downloader
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <h3 className="mt-2">
              LinkedIn is the largest professional network on the internet. But
              with that, you can also download the videos, interviews, or memes
              you like. Just like any other platform, LinkedIn also does not
              allow you to download any videos, but you can still download the
              videos by using ASD Video Downloader. <br /> Questions arise about
              how to download Linkedin videos from ASD Video Downloader <br />
              <strong>Step-1: </strong> Visit the Rareprob website
              <br /> <strong> Step-2: </strong>
              Then open the Linkedin app and scroll through the feed. <br />{" "}
              <strong>Step-3:</strong>Copy the link to the video you want to
              download.
              <br />
              <strong> Step-4: </strong>
              Paste the link to the browser and click on the download button.
              <br /> <br />
            </h3>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>{" "}
      {/* <h2 className="text-xl mt-20 font-bold text-center lg:text-3xl">
        Features of ASD Video Downloader
      </h2>
      <p className="mt-10">
        Here we have provided some of the best features of ASD Video Downloader
      </p>
      <p>
        <li className="list-disc">
          {" "}
          <strong>User Interface:</strong> The Video Downloader app has a simple
          user-friendly interface that lets you navigate the app easily. You can
          easily browse anything at a click, you can choose any social media
          platform, or even manage the downloaded videos. <br />
          <br />
        </li>
        <li className="list-disc">
          <strong>Fast Video Download:</strong> With this video downloader, you
          can download videos four times faster than with any other normal video
          downloader. The app detects the last video you watched and
          automatically puts the link in the search bar, so you will not have to
          paste it again and again. <br /> <br />{" "}
        </li>
        <li className="list-disc">
          <strong>Multiple Language Support:</strong> This app eliminates the
          language barrier; it supports more than 20 languages, i.e., Spanish,
          Melayu, Portuguese, Italiano, Hindi, and many more. You can use the
          app in your native language and enjoy it! <br /> <br />
        </li>
        <li className="list-disc">
          <strong>Private Locker:</strong> This feature provides you the option
          to keep your personal videos and images safe, as they will not be
          deleted from there and you can easily access them whenever you want.{" "}
          <br /> <br />
        </li>
        <li className="list-disc">
          <strong>SD Card Support:</strong> ASD Video Downloader takes very
          little space on your mobile device. It supports an SD card, and if
          your phone storage is running out, you can save the videos to an SD
          card as well and watch unlimited videos for free. <br /> <br />
        </li>
        <li className="list-disc">
          <strong>Supports All Formats:</strong> The video downloader supports
          all the formats in which you want to download your photos or videos
          such as MP4, PNG, MP3, JPG, JPEG, etc. <br /> <br />{" "}
        </li>
      </p>
      <h2 className="text-xl mt-20 font-bold text-center lg:text-3xl">
        Why ASD Video Downloader
      </h2>
      <p className="mt-10">
        <li>You can download high-quality videos within seconds </li>
        <li className="list-disc">
          The video Downloader app is useful if you are traveling and want to
          watch videos and reels, offline you can watch them easily after
          downloading.
        </li>
        <li className="list-disc">
          To watch videos uninterrupted, and movies seamlessly then a video
          downloader will be great for you.
        </li>
        <li className="list-disc">
          If your internet connection is slow then with ASD Video Downloader you
          can quickly download the videos and watch them later.
        </li>
        <li className="list-disc">
          It helps you to download videos, images, and reels, from many social
          media platforms i.e., IG, Vimeo, FB, Dailymotion, etc. and you can
          share videos with your family and friends as well.
        </li>
      </p> */}
    </div>
  );
};

export default InnerVideodownloader;
