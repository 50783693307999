import React from "react";

const Shimmer = () => {
  return (
    <div className="flex gap-3 ml-5 mt-8">
      <div className=" h-10 w-10 rounded-3xl  bg-slate-400"> </div>
      <div className=" ml-3 w-10 h-10 pr-[60vw] bg-slate-400 rounded-md"></div>
    </div>
  );
};

export default Shimmer;
