import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    
  } from 'react-accessible-accordion';
  
  // Demo styles, see 'Styles' section below for some notes on use.
  import 'react-accessible-accordion/dist/fancy-example.css';

const VideoToMp3FaqScreen = () => {
  return (
    <div className='w-3/4 mx-auto mt-20'>
    <Accordion className='' allowZeroExpanded={true}>
          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}}>
           
          <AccordionItemHeading 
                className='accordion__arrow'   >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                  1.   What are the features of the ASD Video to MP3 Converter app?
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel> 
                  <p>
                    The ASD Video to MP3 Converter app includes features such as a video-to-audio converter, audio cutter, video cutter, audio merger, voice recorder, and the ability to change video and audio formats. The app also includes free online games, ringtones, and an HD video player.
                  </p>
                  <img src="/images/faq/videoToMp3/faqVideoMp3Screen.webp" 
                  className='w-56 mt-6'
                  alt="Video To Mp3" />
              </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}}>
          <AccordionItemHeading 
                className='accordion__arrow'  >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                   2. How can you convert video to audio using this app?
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                        To convert your favourite video into an audio file, simply click on this option from the homepage of the app, select the video of your choice, and click on the ‘extract audio’ button.
                        From here you can also select the format and bitrate of the converted audio and assign a name, album, artist, and genre to the converted audio.

                  </p>
                  {/* <img src="/images/faq/tweedle/faqTweedleUnlock.webp" 
                  className='w-56 mt-6'
                  alt="Pro Ringtones" /> */}
              </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}}>
          <AccordionItemHeading 
                className='accordion__arrow'   >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                  3. How can I trim an audio file?
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                          Trimming audio files is super easy! Select the desired audio files from the device’s storage.  You can select if you want to trim the sides and extract the middle section of the song or if you want to trim the middle part and keep the sides of the song intact.
                            Also, you have the option to select the format as well as the bitrate of the trimmed audio as per your choice.

                  </p>
                  {/* <img src="/images/faq/radio/faqRadioMonkey.webp" 
                  className='w-56 mt-6'
                  alt="Fvourite Radio Stations" /> */}
              </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}}>
          <AccordionItemHeading 
                className='accordion__arrow'    >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                  4. How can I trim a video file?
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                     To trim the video files using this app, select the video you want to trim and adjust the sliders to the desired time frame. Play the preview of the trimmed video to check.  The app also has added functionality to modify your trimmed video's name and display resolution.
                  </p>
               
              </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
          <AccordionItemHeading 
                className='accordion__arrow'   >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                  5. How can I merge two and more audio using this app?
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                     To merge two or more audio files, you can select the desired audio files, arrange their order and simply click on the ‘Merge’ button on the app. Your newly merged audio file will get ready to be enjoyed in just a few seconds!
                  </p>
                  {/* <img src="/images/faq/radio/faqRadioMonkeylanguage.webp" 
                  className='w-56 mt-6'
                  alt=" Radio Stations" /> */}
              </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}}>
          <AccordionItemHeading 
                className='accordion__arrow'   >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                  6. How can I change the formats of audio and videos?
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                      Changing formats of audio from MP3 to WAV, AAC, FLAC, WMA, OGG, AC3, M4A and video formats from MP4 to MOV, 3GP, MP4, MKV, AVI, FLV is a piece of cake. Just select the desired file or files and choose your desired format. Click Done and your file in the changed format is ready!!
                  </p>
                 <div className='flex items-center justify-center space-x-4'>
                        <img src="/images/faq/videoToMp3/faqVideoMp3music.webp" 
                        className='w-56 mt-6'
                        alt="Video To Mp3" />
                            <img src="/images/faq/videoToMp3/faqVideoMp3M4A.webp" 
                        className='w-56 mt-6'
                        alt="Video To Mp3 M4A" />
                 </div>
              </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
          <AccordionItemHeading 
                className='accordion__arrow'   >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                  7. How can you achieve the good audio quality of a converted video?
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                       If you are experiencing degraded audio quality from the converted video then you can try changing the bitrate before converting the video to audio. First, select the format you want your audio to be converted to (MP3, AAC, M4A).
                        Then, you’ll see the bitrate option beside the format and can choose the desired bitrate from there. Selecting a bitrate before converting the video will give you good quality output in audio and enhance your listening experience.

                  </p>
                  <img src="/images/faq/videoToMp3/faqVideoMp3Bitrate.webp" 
                  className='w-56 mt-6'
                  alt=" quality of a converted video" />
              </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
          <AccordionItemHeading 
                className='accordion__arrow'   >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                  8. Where can I find the converted and extracted audio?
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                     An ‘Output folder’ located on the homepage of the app is where you’ll find all the outputs of your conversion and extracted audios. You can also find voice recordings, changed audio-video format files, and more.

                  </p>
                  <img src="/images/faq/videoToMp3/faqVideoMp3Output.webp" 
                  className='w-56 mt-6'
                  alt=" quality of a converted video" />
              </AccordionItemPanel>
          </AccordionItem>


          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
          <AccordionItemHeading 
                className='accordion__arrow'   >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                  9. What is the function of bitrate in the ASD Video to MP3 Converter app?
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                    Bitrate refers to the amount of data transferred into audio. The higher bitrate means the greater the audio quality of the converted video. While converting audio files, please make sure you are setting the desired bitrate to get crisp, clear, and high-quality audio results from this app.
                  </p>
                  {/* <img src="/images/faq/radio/faqRadioMonkeylanguage.webp" 
                  className='w-56 mt-6'
                  alt=" Radio Stations" /> */}
              </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}}>
          <AccordionItemHeading 
                className='accordion__arrow'   >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                  10. How can you set ringtones using this app?
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                     Set your desired ringtone from your device’s music library, you can even set notification sounds and choose particular contacts to set the ringtone. Moreover, the app includes Tweedle ringtones with more than 30 ringtones to choose from.
                  </p>
                 <div className='flex items-center justify-center space-x-4'>
                        <img src="/images/faq/videoToMp3/faqVideoMp3Ringtones.webp" 
                        className='w-56 mt-6'
                        alt="Video To Mp3" />
                            <img src="/images/faq/videoToMp3/faqVideoMp3Converter.webp" 
                        className='w-56 mt-6'
                        alt="Video To Mp3 M4A" />
                 </div>
              </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
          <AccordionItemHeading 
                className='accordion__arrow'   >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                  11. How does the voice recording feature work?
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                    Click on the recorder feature of the app and tap on the big round button to start recording the audio. Tap on it again and the recording will stop. You can check the recorded audio, delete it or share it on social media and other platforms.

                  </p>
                  {/* <img src="/images/faq/radio/faqRadioMonkeylanguage.webp" 
                  className='w-56 mt-6'
                  alt=" Radio Stations" /> */}
              </AccordionItemPanel>
          </AccordionItem>
     


      </Accordion>
  </div>
  )
}

export default VideoToMp3FaqScreen