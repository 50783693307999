import { Link } from "react-router-dom";
import React from "react";
import { useMediaQuery } from "../Util";
import { AiFillInstagram, AiFillYoutube } from "react-icons/ai";
import { FaFacebookSquare, FaPinterestSquare } from "react-icons/fa";
import { BsLinkedin, BsTwitter } from "react-icons/bs";
import { scroll } from "./ScrollToTop";

interface FOOTERTYPE {
  color: string;
  page?: string;
}

const NewFooter: React.FC<FOOTERTYPE> = ({ color, page = "none" }) => {
  const matches = useMediaQuery("(max-width: 1024px)");

  return (
    <div
      className={`lg:h-96 backdrop-blur-md ${color} relative overflow-x-hidden  px-4 lg:px-32 py-10 ${
        page == "home" ? "text-black" : "text-white"
      }  flex lg:flex-row flex-col items-center justify-center lg:justify-between `}
      style={{ zIndex: 10 }}
    >
      <div className=" h-full w-full  lg:w-1/4 flex flex-col items-start lg:items-start space-y-10 justify-start">
        <img
          src={
            page !== "home"
              ? "/images/homepage/rare-logo.webp"
              : "/images/newDesign/rarep (1).png"
          }
          alt="Rareprob logo"
          className="w-3/4  !block "
          onClick={scroll}
          style={{ cursor: "pointer" }}
        />

        <p className="text-left  text-sm font-normal">
          Elevating Utility and Entertainment through top-notch apps
        </p>
        <div className=" w-full">
          {/* <p className="text-sm  text-gray-300 font-semibold">FOLLOW US </p> */}
          <div className="flex items-center justify-start mt-2 space-x-2">
            <SocialLink
              image={<FaFacebookSquare />}
              link="https://www.facebook.com/rareprob"
            />
            <SocialLink
              image={<AiFillInstagram />}
              link="https://www.instagram.com/rareprob_/"
            />
            <SocialLink
              image={<BsLinkedin />}
              link="https://www.linkedin.com/company/rareprob/"
            />
            <SocialLink
              image={<FaPinterestSquare />}
              link="https://in.pinterest.com/rare_prob/"
            />
            <SocialLink
              image={<AiFillYoutube />}
              link="https://www.youtube.com/channel/UCNXZXijMtxueoWaNiye73AQ"
            />
            <SocialLink
              image={<BsTwitter />}
              link="https://twitter.com/rareprob"
            />
          </div>
        </div>
      </div>
      {!matches && (
        <div className=" flex flex-col items-start lg:items-center justify-start h-full w-full mt-10 lg:mt-0 lg:w-1/4">
          <h6 className="text-left  text-lg font-normal">OVERVIEW</h6>
          <div className="mt-4 grid grid-cols-4 lg:flex lg:flex-col gap-5 lg:gap-0 place-items-center  ">
            <OverViewLink link="/" page="Home" />
            <OverViewLink link="/games" page="Games" />
            <OverViewLink link="/about-us" page="About us" />
            <OverViewLink link="/apps" page="Apps" />
            <OverViewLink link="/blogs" page="Blogs" />
            <OverViewLink link="/faq" page="FAQs" />
            <OverViewLink link="/contact-us" page="Contact" />
          </div>
        </div>
      )}

      {!matches && (
        <div className=" flex flex-col lg:mb-0 mb-10 items-start lg:items-center justify-start h-full w-full lg:w-1/4 mt-10 lg:mt-0">
          <h6 className="text-left w-full text-lg font-normal">CONTACT </h6>
          <div className="mt-4 flex flex-col  justify-start items-start space-y-2">
            <ContactLink
              image="/images/icons/email.png"
              text="info@rareprob.com"
            />
            <ContactLink
              image="/images/icons/world-wide-web.png"
              text="https://rareprob.com"
            />
            <ContactLink
              image="/images/icons/telephone-call.png"
              text="+91 82793 23413"
            />
            <ContactLink
              image="/images/icons/maps-and-flags.png"
              text="Suite 1305, Second Floor, Building 1000, Doon Business Express Park, Subhash Nagar, Opposite Transport Nagar Dehradun"
            />
          </div>
        </div>
      )}
      {matches && (
        <div className="flex items-start  justify-center ">
          <div className=" flex flex-col items-start lg:items-center justify-start h-full w-full mt-10 lg:mt-0 ">
            <h6 className="text-left  text-lg font-normal">OVERVIEW</h6>
            <div className="mt-4 grid grid-cols-1 lg:flex lg:flex-col gap-2  place-items-center  ">
              <OverViewLink link="/" page="Home" />
              <OverViewLink link="/games" page="Games" />
              <OverViewLink link="/about-us" page="About us" />
              <OverViewLink link="/apps" page="Apps" />
              <OverViewLink link="/blogs" page="Blogs" />
              <OverViewLink link="/faq" page="FAQs" />
              <OverViewLink link="/contact-us" page="Contact" />
            </div>
          </div>

          <div className=" flex  flex-col lg:mb-0 mb-10 items-start lg:items-center justify-start h-full w-full   mt-10 lg:mt-0">
            <h6 className="text-left w-full text-lg font-normal">CONTACT </h6>
            <div className="mt-4 flex flex-col  justify-start items-start space-y-2">
              <ContactLink
                image="/images/icons/email.png"
                text="info@rareprob.com"
              />
              <ContactLink
                image="/images/icons/world-wide-web.png"
                text="https://rareprob.com"
              />
              <ContactLink
                image="/images/icons/telephone-call.png"
                text="+91 82793 23413"
              />
              <ContactLink
                image="/images/icons/maps-and-flags.png"
                text="Suite 1305, Second Floor, Building 1000, Doon Business Express Park, Subhash Nagar, Opposite Transport Nagar Dehradun"
              />
              {/* <ContactLink />
            <ContactLink />
            <ContactLink />
            <ContactLink /> */}
            </div>
          </div>
        </div>
      )}
      <div className="  w-screen absolute mx-auto left-0 right-0 bottom-10">
        <div className="h-[0.3px] bg-white"></div>
      </div>
      <p className="w-screen text-xs lg:text-sm absolute  mx-auto left-0 right-0 bottom-2 text-center">
        Copyright © 2023 Rareprob. All rights reserved. /
        <Link to={"/privacy-policy"} className="underline">
          {" "}
          Privacy
        </Link>
      </p>
    </div>
  );
};

export default NewFooter;

interface SocialLinkType {
  image: JSX.Element;
  link: string;
}

const SocialLink: React.FC<SocialLinkType> = ({ image, link }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer nofollow"
      className="flex items-center justify-center space-x-3"
    >
      {image}
    </a>
  );
};

interface OVERVIEWTYPE {
  page: string;
  link: string;
}

const OverViewLink: React.FC<OVERVIEWTYPE> = ({ page, link }) => {
  return (
    <a href={link}>
      <p className="">{page}</p>
    </a>
  );
};

interface ContactLinkType {
  image: string;
  text: string;
}

const ContactLink: React.FC<ContactLinkType> = ({ image, text }) => {
  return (
    <p className="flex items-center justify-start space-x-4  xl:w-72">
      <img
        src={image}
        alt="csdv"
        className="w-6 h-6 bg-white p-1 rounded-full"
      />
      {text === "info@rareprob.com" ? (
        <a target={"_blank"} rel="noreferrer" href="mailto: info@rareprob.com">
          {text}
        </a>
      ) : text === "https://rareprob.com" ? (
        <a href="https://rareprob.com" target={"_blank"} rel="noreferrer">
          {text}
        </a>
      ) : (
        <p className="text-sm font-medium"> {text}</p>
      )}
    </p>
  );
};
