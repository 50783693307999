import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const scroll = () => {
  let element = document.getElementById("start");
  //give this start id to every page to scroll to top
  if (element)
    element.scrollIntoView({
      behavior: "smooth",
    });
};

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    scroll();
  }, [pathname]);

  return null;
}
