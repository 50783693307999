import React from "react";
import { Link } from "react-router-dom";

interface BlogContainerType {
  name: string;
  image: string;
  text: string;
  path: string;
  logo: string;
  altz: string;
  date: string;
}

const BlogContainer: React.FC<BlogContainerType> = ({
  name,
  image,
  text,
  path,
  logo,
  altz,
  date,
}) => {
  return (
    <Link
      to={path}
      className="bg-gray-200  xl:w-[280px] h-[510px]  px-4  py-3  rounded-3xl"
    >
      <div className="flex items-center justify-start">
        {logo !== "" && <img src={logo} alt="gallery icon" className="w-8" />}
        <h1 className="text-sm md:text-lg font-semibold ml-4">{name}</h1>
      </div>
      <div className="">
        <img
          loading={"lazy"}
          src={image}
          alt={altz}
          className="w-full md:w-full h-60 object-cover lg:object-fill rounded-xl mt-6"
        />
        <p className="mt-2 px-4 text-gray-600  inline-block  bg-opacity-50 ">
          {date}
        </p>
        <h1 className=" md:w-full text-sm md:text-[14px] font-semibold h-20 mt-3 text-center">
          {text}
        </h1>
        <button className=" rounded-lg mb-4  mx-auto items-center flex mt-0 bg-[#0a4a82]/90 hover:bg-white hover:text-[#0a4a82] text-white font-medium  px-4 py-2">
          Read more
        </button>
      </div>
    </Link>
  );
};

const NewBlogRadio = () => {
  return (
    <div>
      <div className="w-[90%] mx-auto place-items-center grid lg:grid-cols-3 xl:grid-cols-4 grid-col-1 gap-10 md:gap-28">
        <BlogContainer
          name={"Radio Monkey"}
          altz="Radio Monkey "
          image="https://img.rareprob.com/img/website/blogs_rb/everything-you-must-know-about-the-best-fm-radio-app-now-cover.webp"
          logo="/images/icons/radioLauncher.png"
          path="/blogs/explore-the-ultimate-guide-to-the-best-fm-radio-app"
          date="26 April 2024"
          text="Discover everything you need to know about the best FM radio for android."
        />
        <BlogContainer
          name={"Radio Monkey"}
          altz="Radio Monkey"
          image="https://img.rareprob.com/img/website/blogs_rb/explore-how-to-use-the-best-fm-recorder-of-radio-monkey-now-cover.jpg"
          logo="/images/icons/radioLauncher.png"
          path="/blogs/check-out-the-fm-recorder-feature-of-radio-monkey"
          date="04 April 2024"
          text="Explore How To Use the best FM Recorder of Radio Monkey Now
          "
        />
        <BlogContainer
          name={"Radio Monkey"}
          altz="Radio Monkey"
          image="https://img.rareprob.com/img/website/blogs_rb/what-are-the-top-3-useful-features-of-radio-monkey-you-should-know-cover.jpg"
          logo="/images/icons/radioLauncher.png"
          path="/blogs/3-best-features-of-radio-monkey-you-should-know
          "
          date="07 Mar 2024"
          text="What are the Top 3 Useful Features of Radio Monkey You Should Know"
        />
        <BlogContainer
          name={"Radio Monkey"}
          altz="Radio Monkey"
          image="https://img.rareprob.com/img/website/blogs_rb/how-to-listen-to-international-live-radio-stations-on-radio-fm-in-2024-cover.jpg"
          logo="/images/icons/radioLauncher.png"
          path="/blogs/tune-into-top-international-live-radio-fm-stations-in-2024
          "
          date="12 feb 2024"
          text="How to Listen to International Live Radio Stations on Radio Monkey in 2024"
        />
      </div>
    </div>
  );
};

export default NewBlogRadio;
