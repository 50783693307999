import React from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

const FaqMusicPlayer:React.FC = () => {
  return (
    <div className='w-3/4 mx-auto mt-20'>
    <Accordion className='' allowZeroExpanded={true}>
          <AccordionItem  style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
           
          <AccordionItemHeading 
                className='accordion__arrow'    >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                   1. Does Rocks Music Player support all audio formats?
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                     Yes, To make your online and offline music experience better, ASD Rocks Music Player supports all audio formats including, AAC, MP3, MIDI, WAV, OGC, FLAC, and much more. 
                  </p>
              </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
          <AccordionItemHeading 
                className='accordion__arrow'    >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                     2. Why should I Install this music application?
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                     To build better user experience, this ASD Rocks music app offers online and offline music functionality. You can explore a variety of features like, built-in video player, calm sleep music, online FM radio with more than 30,000+ radio stations, music play with lyrics support, sleep timer, Video to mp3 converter, and alot more.
                  </p>
              </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
          <AccordionItemHeading 
                className='accordion__arrow'    >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                     3. Can I play music with online lyrics?
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                         Yes, Online Lyrics functionality supported by this music player. Just simply play any of your favourite songs and tap to the Lyrics button. <br />

                            You will be redirected to the page where you can find the online lyrics of your selected song.

                  </p>
              </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
          <AccordionItemHeading 
                className='accordion__arrow'   >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                     4. How can I record my favourite radio stations?
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                     To listen to your favourite radio station offline, simply tap to the record button and record your preferred radio station. <br />
                     It is to be noted that Music Player requires microphone permission before enabling the recorder.Kindly do so and then your radio station will be recorded easily.
                  </p>
                  <img src="/images/faq/musicPlayer/radio.webp"
                  className='w-56 mt-5 mb-10 rounded-xl'
                   alt=" Favourite Radio Stations" />
              </AccordionItemPanel>
          </AccordionItem>


          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
          <AccordionItemHeading 
                className='accordion__arrow'  >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                   5.  Can I enable a sleep timer for Calm sleep music?
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                        Yes, You can enable sleep timer for both offline and online music. 
                        Just play any track and tap to the timer icon highlighted on the player’s screen. Then, set the timing as per your preference.

                  </p>
                  <img src="/images/faq/musicPlayer/3.webp"
                  className='w-56 mt-5 mb-10 rounded-xl'
                   alt="Sleep Timer in Calm Sleep" />
              </AccordionItemPanel>
          </AccordionItem>


          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
          <AccordionItemHeading 
                className='accordion__arrow'  >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                      6. Does it support car mode functionality?
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                     Yes, you can enable the car mode from the hamburger menu directly. <br />
                      Go to the menu and tap on Car mode, then you can enjoy the music.  
                   </p>
              </AccordionItemPanel>
          </AccordionItem>


          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
          <AccordionItemHeading 
                className='accordion__arrow'  >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                   7.  Can I arrange my music playlist?
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                  Yes, you can arrange your music playlist. Simply long press the song and drag it to the preferred position. This way you can arrange your music playlist anytime. 

                  </p>
                  <img src="/images/faq/musicPlayer/Artboard 1 (2).webp"
                  className='w-56 mt-5 mb-10 rounded-xl'
                   alt="Arrange music playlist in Calm Sleep" />
              </AccordionItemPanel> 
          </AccordionItem>


          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
          <AccordionItemHeading 
                className='accordion__arrow'   >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                   8. How can I change my app’s theme?
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                     To design your music experience, you can change the app themes from multiple gradients and themes. 
                     Just Go to the hamburger menu, move down and tap “Themes”. Here, you can select themes and also customise the app with any of your photos as well.


                  </p>
                  <img src="/images/faq/musicPlayer/Artboard 1 copy.webp"
                  className='w-56 mt-5 mb-10 rounded-xl'
                   alt="Arrange app Theme" />
              </AccordionItemPanel> 
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
          <AccordionItemHeading 
                className='accordion__arrow'   >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                   9. What steps do I follow to play videos in the background ?
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                     To enjoy background playback, simply play any video/movie and then check out the bottom side of the player’s screen.
                     Simply tap on the “BG Music” icon and start playing the video as an audio file.
                  </p>
                  <div className='flex items-center justify-center space-x-10 mt-10'>
                        <img src="/images/faq/musicPlayer/hamburger.webp" className='w-44 h-96 rounded-xl ' 
                        alt="Play Videos in background" />
                        <img src="/images/faq/musicPlayer/Artboard 4.webp" 
                        className='w-44 h-96 rounded-xl '  alt="Auto Detection feature in Video Downloader" />
                    </div>
              </AccordionItemPanel> 
          </AccordionItem>


          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
          <AccordionItemHeading 
                className='accordion__arrow'   >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                   10. Where do I find online/offline subtitles?
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                  ASD Rocks Music offers a built-in Video player. So, you may check out the bottom side of the video player’s screen. Then, tap on the “cc”(subtitles) icon and select the online/offline subtitles.

                  </p>
                  <img src="/images/faq/musicPlayer/Artboard 5.webp"
                  className='w-56 mt-5 mb-10 rounded-xl'
                   alt="Arrange app Theme" />
              </AccordionItemPanel> 
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
          <AccordionItemHeading 
                className='accordion__arrow'  >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                   11.  Which music is best for reducing stress?
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                    Calm sleep music functionality offers stress buster music and other categories to soothe the body and soul. With ASD Rocks Music, you can try stress buster music, acoustic music, nature music to get the relief from the stress.
                  </p>
              </AccordionItemPanel> 
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
          <AccordionItemHeading 
                className='accordion__arrow'   >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                   12. How can I modify the sound quality?
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                     If you’re not happy enough for the default sound-quality then you can try Equaliser. This music player supports built-in bass boost equaliser to manage the sound track as per your mood.
                     Simply go to the hamburger menu and check out the Equaliser and manage sound quality.
                  </p>
                  <div className='flex items-center justify-center space-x-10 mt-10'>
                        <img src="/images/faq/musicPlayer/hamburger.webp" 
                        className='w-64 h-96 rounded-xl object-contain' 
                        alt="Modify sound quality" 
                        />
                        <img src="/images/faq/musicPlayer/Artboard 2.webp" 
                        className='w-64 h-96 rounded-xl object-contain'  
                        alt="Modify sound quality" 
                        />
                    </div>
              </AccordionItemPanel> 
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
          <AccordionItemHeading 
                className='accordion__arrow'  >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                   13.  Can’t play mp3 files?
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                     The corrupt file can make this activity possible. Please check out the file, and if your problem is still not fixed, get in touch with us at feedback.rocksplayer@gmail.com
                  </p>
              </AccordionItemPanel> 
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
          <AccordionItemHeading 
                className='accordion__arrow'   >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                   14.   Can I convert a video file into MP3 ?

                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                  Yes, ASD Rocks Music Player offers mp3 converter functionality. You can convert any video file into MP3 and AAC format. 
                  </p>
              </AccordionItemPanel> 
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
          <AccordionItemHeading 
                className='accordion__arrow'    >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                   15.  Can I convert the video file into AAC format?
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                      Yes, this music player app supports every audio format. 
                      You just have to select the video file, tap to video to audio feature,
                      select the AAC Format and then convert the preferred video file.

                  </p>
                  <img src="/images/faq/musicPlayer/Artboard 1.webp"
                  className='w-56 mt-5 mb-10 rounded-xl'
                   alt="Convert Video Files to AAC formats" />
              </AccordionItemPanel> 
          </AccordionItem>


          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
          <AccordionItemHeading 
                className='accordion__arrow'    >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                   16.  Is there any paid version available for this music player?

                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                    Yes, ASD Rocks Music offers an ads free version and multiple top-notch features to enjoy as a premium user. This premium plan offers monthly, yearly and lifetime offers. 
                  </p>
              </AccordionItemPanel> 
          </AccordionItem>


          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
          <AccordionItemHeading 
                className='accordion__arrow'    >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                   17.   What premium plans can I find in this app?

                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                     This premium plan is divided into three categories, first is Monthly(₹15.00 ), second is yearly (₹49.00), and then a Lifetime offer with just  (₹99.00).
                  </p>
                  <img src="/images/faq/musicPlayer/Layer 1.webp"
                  className='w-56 mt-5 mb-10 rounded-xl'
                   alt="Premium plans in Music player" />
              </AccordionItemPanel> 
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
          <AccordionItemHeading 
                className='accordion__arrow'   >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                   18.  Why are Calm sleep music categories not loading?

                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                    A poor internet connection or a server problem might be the cause of this action. You can retry after checking your mobile data or Wi-Fi connection.
                     If your problem is still not fixed, get in touch with us at feedback.rocksplayer@gmail.com
                  </p>
              </AccordionItemPanel> 
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
          <AccordionItemHeading 
                className='accordion__arrow'   >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                   19. Why can’t I download Trending videos from this app?

                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                      Due to YouTube Policy, this is prohibited and it could be considered under policy violation. 
                  </p>
              </AccordionItemPanel> 
          </AccordionItem>

          <AccordionItem style={{border:"0.2px solid white",borderRadius:"5px",marginTop:"10px"}} >
          <AccordionItemHeading 
                className='accordion__arrow'   >
                    <AccordionItemButton 
                    
                    className='accordion__button !bg-transparent
                    !text-white 
                     !rounded-lg'
                     >
                   20. Query not mentioned here! How can I contact you?

                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                     If you have not found your query here, you can get in touch with our support team so we can help you better.
                     You may contact us at feedback.rocksplayer@gmail.com
                  </p>
              </AccordionItemPanel> 
          </AccordionItem>







      </Accordion>
  </div>
  )
}

export default FaqMusicPlayer