import React from 'react'
import { HeadingText ,AppProduct} from './Util'
import Slider,{Settings} from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface OurAppsType{
  gamePage:boolean
}

const OurApps:React.FC<OurAppsType> = ({gamePage}) => {

    // const url:string = "https://rareprob.com/images/icons/videoplayer.png"

    var settings:Settings = {
      lazyLoad:"ondemand",
        dots: false,
        infinite: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          }
        ]
      };


  return (
      <div className={`px-2 ${gamePage===true?"lg:px-0":"lg:px-32"}  mt-20`}>
        {
          gamePage===true?
          <h1 className='font-bold mt-20 text-2xl ml-0 lg:ml-14 '>Our Products</h1>
          :
          <HeadingText text='Our Products'/>

        }
          <Slider {...settings} className="mt-4">
            <AppProduct appName='Rocks Player' imageUrl={"/images/icons/videoplayer.png"} 
            link="/apps/rocks-video-player-app"/>
             <AppProduct appName='Video Downloader' 
            imageUrl={"/images/icons/videoDownloader.webp"}
            link="/apps/video-downloader-files-hider-app"
            />
            <AppProduct appName='File Manager' imageUrl={"/images/icons/file mana.webp.png"} 
            link="/apps/file-manager-app"/>
            <AppProduct appName='Video To Mp3' imageUrl={"/images/icons/vmp3.png"} 
            link="/apps/video-to-mp3-converter-app"/> 
            <AppProduct appName='Gallery' 
            imageUrl={"/images/icons/gallery.png"}
             link="/apps/gallery-photo-editor-and-collage-maker-app"
            />
            <AppProduct appName='Music Player' 
            imageUrl={"/images/icons/musicIcon.webp"}
            link="/apps/music-player-mp4-mp3-player-app"
            />
           
            <AppProduct appName='Find Difference' 
            imageUrl={"/images/icons/game.png"}
            link="https://play.google.com/store/apps/details?id=games.find.diff.gamma&referrer=utm_source%3DWebsite-Rareprob-Find-difference%26utm_medium%3DWebsite-Rareprob-Find-difference%26utm_campaign%3DWebsite-Rareprob-Find-difference%26anid%3Dadmob"
            />
            <AppProduct appName='Radio Monkey' 
            imageUrl={"/images/icons/radioLauncher.png"}
            link="/apps/radio-monkey-online-radio-fm-app"
            />
            <AppProduct appName='Tweedle' 
            imageUrl={"/images/icons/launcher icon.webp"}
            link="/apps/tweedle-free-ringtone-app"
            />
          </Slider>
      </div>
  )
}

export default React.memo(OurApps) 