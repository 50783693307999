import React from "react";
import "./GameTile.css";

interface GAMETILE {
  bgColor: string;
  image: string;
  heading: string;
  link: string;
}

const GameTile: React.FC<GAMETILE> = ({ bgColor, heading, image, link }) => {
  return (
    <a
      href={link}
      rel="noreferrer"
      className="gameTile "
      style={{ backgroundColor: bgColor }}
    >
      <img src={image} alt={heading} />
      <h4>{heading}</h4>
      {/* <button>PLAY</button> */}
    </a>
  );
};

export default GameTile;
