import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import VideoDownloaderPage from "./screens/apps/VideoDownloaderScreen";
import TweedleScreen from "./screens/apps/TweedleScreen";
import VideoToMp3Screen from "./screens/apps/VideoToMp3Screen";
import GalleryScreen from "./screens/apps/GalleryScreen";
import FileManagerScreen from "./screens/apps/FileManagerScreen";
import MusicPlayerScreen from "./screens/apps/MusicPlayerScreen";
import RockPlayerScreen from "./screens/apps/RockPlayerScreen";
import HalloweenBlog from "./screens/blogs/HalloweenBlog";
import VideoToMp3Blog from "./screens/blogs/VideoToMp3Blog";

import GalleryBlog from "./screens/blogs/GalleryBlog";
import MusicBlog from "./screens/blogs/MusicBlog";
import RadioMonkeyBlog from "./screens/blogs/RadioMonkeyBlog";
import ContactScreen from "./screens/ContactScreen";
import ChristmasBlog from "./screens/blogs/ChristmasBlog";
import About from "./screens/About";
import PrivacyPolicyScreen from "./screens/PrivacyPolicyScreen";

import FaqScreen from "./screens/FaqScreen";
import OnlineFunGamesBlog from "./screens/blogs/OnlineFunGamesBlog";
import FileManagerSecondBlog from "./screens/blogs/FileManagerSecondBlog";
import TweedleBlogScreen from "./screens/blogs/TweedleBlogScreen";
import AppChristmasBlog from "./screens/blogs/AppChristmasBlog";
import ChristmasBudget from "./screens/blogs/ChristmasBudget";
import GuideMusicPlayerBlog from "./screens/blogs/GuideMusicPlayerBlog";
import BenefitsFileManagerBlog from "./screens/blogs/BenefitsFileManagerBlog";
import PdfBlog from "./screens/blogs/PdfBlog";
import ChristmasDecorationBlog from "./screens/blogs/ChristmasDecorationBlog";
import VideoDownloaderBlog from "./screens/blogs/VideoDownloaderBlog";
import ZipBlog from "./screens/blogs/ZipBlog";
import PhotoDownloaderBlog from "./screens/blogs/PhotoDownloaderBlog";
import NewYearBlog from "./screens/blogs/NewYearBlog";
import DigitalDocumentBlog from "./screens/blogs/DigitalDocumentBlog";
import BuilInBrowserBlog from "./screens/blogs/BuilInBrowserBlog";
import HidePhotosBlog from "./screens/blogs/HidePhotosBlog";
import InstaVideoDownloaderBlog from "./screens/blogs/InstaVideoDownloaderBlog";
import CleanMasterBlog from "./screens/blogs/CleanMasterBlog";
import FreeAndroidMp3Player from "./screens/blogs/FreeAndroidMp3Player";
import PrivateFileLockerVideoDownloader from "./screens/blogs/PrivateFileLockerVideoDownloader";
import FileManagerEasyWayToShare from "./screens/blogs/FileManagerEasyWayToShare";
import ScanningDocuments from "./screens/blogs/ScanningDocuments";
import SleepTimerBlog from "./screens/blogs/SleepTimerBlog";
import VideoDownloaderStorage from "./screens/blogs/VideoDownloaderStorage";
import FileManagerBlogShortcut from "./screens/blogs/FileManagerBlogShortcut";
import CustomizeThemes from "./screens/blogs/CustomizeThemes";
import VideoDownloaderBookMark from "./screens/blogs/VideoDownloaderBookMark";
import EqualizerMusicBlog from "./screens/blogs/EqualizerMusicBlog";
import VideoDetectionBlog from "./screens/blogs/VideoDetectionBlog";
import ManagingLargeFiles from "./screens/blogs/ManagingLargeFiles";
import CarModeBlogMusicPlayer from "./screens/blogs/CarModeBlogMusicPlayer";
import EasyFacebookDownloader from "./screens/blogs/EasyFacebookDownloader";
import FileManagerWebBrowser from "./screens/blogs/FileManagerWebBrowser";
import FileEncryptionBlog from "./screens/blogs/FileEncryptionBlog";
import MusicLoverBlog from "./screens/blogs/MusicLoverBlog";
import InstagramReelDownloader from "./screens/blogs/InstagramReelDownloader";
import AppScreen from "./screens/AppScreen";
import ExploreSixFeatures from "./screens/blogs/ExploreSixFeatures";
import SevenFileManagerFeatures from "./screens/blogs/SevenFileManagerFeatures";
import FourReasonVideoDownloader from "./screens/blogs/FourReasonVideoDownloader";
import RelaxingSound from "./screens/blogs/RelaxingSound";
import PageNotFound from "./screens/PageNotFound";
import NewBlog from "./screens/NewBlog";
import NewBlogVD from "./screens/blogs/NewBlogVD";
import NewBlogFL from "./screens/NewBlogFL";
import NewBlogDesign from "./screens/NewBlogDesign";
import BestPhotoEditing from "./screens/blogs/BestPhotoEditing";
import NewBlogMusic from "./screens/NewBlogMusic";
import NewBlogVideoToMp3 from "./screens/NewBlogVideoToMp3";
import NewBlogGallery from "./screens/NewBlogGallery";
import NewBlogOthers from "./screens/NewBlogOthers";
import PhotoDownloader from "./screens/apps/PhotoDownloader";
import InstagramVideodownloader from "./screens/blogs/InstagramVideodownloader";
import PhotoDownloaderAll from "./screens/blogs/PhotoDownloader";
import Twitterdownloader from "./screens/blogs/Twitterdownloader";
import TikTokdownloader from "./screens/blogs/TikTokdownloader";
import VimeoDownloader from "./screens/blogs/VimeoDownloader";
import NineEffectiveFileManager from "./screens/blogs/NineEffectiveFileManager";
import DailyMotionBlog from "./screens/blogs/DailyMotionBlog";
import RepostInstagram from "./screens/blogs/RepostInstagram";
import RepostInstagramFileManager from "./screens/blogs/RepostInstagramFileManager";
import ColorCorrection from "./screens/blogs/ColorCorrection";
import FathersDayBlog from "./screens/blogs/FathersDayBlog";
import CareerPage from "./screens/career/CareerPage";
import CareerLogin from "./screens/career/careerLogin/CareerLogin";
import ASDGalleryApp from "./screens/blogs/ASDGalleryApp";
import BestFreeCollageMaker from "./screens/blogs/BestFreeCollageMaker";
import FileManagerNotesFeature from "./screens/blogs/FileManagerNotesFeature";
import RestorePicturesGallery from "./screens/blogs/RestorePicturesGallery";
import NewHome from "./screens/NewHome/NewHome";
import FaqVideoDownloader from "./comp/FaqVideoDownloader";
import FaqFilemanager from "./comp/FaqFilemanager";
import FaqMusicPlayer from "./comp/FaqMusicPlayer";
import GalleryFaqScreen from "./screens/GalleryFaqScreen";
import TweedleFaqScreen from "./comp/TweedleFaqScreen";
import RadioFaqScreen from "./comp/RadioFaqScreen";
import VideoToMp3FaqScreen from "./comp/VideoToMp3FaqScreen";
import BassBoostEqualizer from "./screens/blogs/BassBoostEqualizer";
import ScreenRecorder from "./screens/blogs/ScreenRecorder";
import GridMobileView from "./screens/GridMobileView/GridMobileView";
import AddLyricsBlog from "./screens/AddLyricsBlog/AddLyricsBlog";
import ScrollToTop from "./comp/ScrollToTop";
import TrimVideosInBuilt from "./screens/blogs/TrimVideosWithIn-BuiltVideoCutterInASDVideoToMP3Converter";
import GuideToCreatePlaylist from "./screens/blogs/GuideToCreatePlaylist";
import RadioMonkey from "./screens/radioMonkey/RadioMonkey";
import { RadioProvider } from "./screens/radioMonkey/radioContext";
import BestMusicPlayersAndroid from "./screens/blogs/BestMusicPlayersAndroid";
import BestMusicAndroid2023 from "./screens/blogs/BestAndroidMusic2023";
import GamePages from "./comp/GamePages";
import FreeMusicPlayer2023 from "./screens/blogs/FreeMusicPlayer2023";
import BestFileManager2023 from "./screens/blogs/BestFileManager2023";
import CelebrateChristmas2023 from "./screens/blogs/CelebrateChristmas2023";
import DownloadWhatsappStatus2023 from "./screens/blogs/DownloadWhatsappStatus2023";
import BestOfflineMusicPlayer2023 from "./screens/blogs/BestOfflineMusicPlayer2023";
import DownloadSocialMediaVideos2024 from "./screens/blogs/downloadSocialMediaVideos2024";
import ChristmasMemories from "./screens/blogs/ChristmasMemories";
import DownloadVideos2024 from "./screens/blogs/DownloadVideos2024";
import AudioCutter2024 from "./screens/blogs/AudioCutter2024";
import RingtoneConverter2024 from "./screens/blogs/RingtoneConveter2024";
import PasswordProtect2024 from "./screens/blogs/PasswordProtect2024";
import ZipFileManager from "./screens/blogs/ZipFileFileManager";
import XiaomiMusicPlayer24 from "./screens/blogs/XiaomiMusicPlayer24";
import MusicPlayerBest62024 from "./screens/blogs/MusicPlayerBest62024";
import NewBlogRadio from "./screens/NewBlogRadio";
import RadioMonkeyInternational from "./screens/blogs/RadioMonkeyInternational";
import ConvertVideoToMp3 from "./screens/blogs/ConvertVideoToMp3";
import FileMangerBrowser from "./screens/blogs/FileManagerBrowser";
import MusicPlayerConvertMusic from "./screens/blogs/MusicPlayerConvertMusic";
import RadioMonkey3Features from "./screens/blogs/RadioMonkey3Features";
import FreeDPGenator from "./screens/blogs/FreeDPGenator";
import FreeAIimage from "./screens/blogs/FreeAIimage";
import MostPopularFeaturesOfASD from "./screens/blogs/MostPopularFeaturesOfASD";
import UseBestFM from "./screens/blogs/UseBestFM";
import SafeGaurdData from "./screens/blogs/SafeGaurdData";
import MCKekaurFMRadioBlog from "./screens/blogs/MKCekaurFMRadioBlog";
import ConvertAndMergeAudio from "./screens/blogs/ConvertAndMergeAudio";
import Powerful_Online_Music from "./screens/blogs/Powerful_Online_Music";
import Ultimate_Top_6_video_to_MP3_Converter_For_Android_in_2024 from "./screens/blogs/Ultimate_Top_6_video_to_MP3_Converter_For_Android_in_2024";
import BestVideoandAudioPlayer from "./screens/blogs/BestVideoandAudioPlayer";
import PrivacyFileManager from "./Privacy/PrivacyFileManager";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<NewHome />} />
        <Route
          path="policy/file-manager"
          element={<PrivacyFileManager/>}
        />
        <Route path="/career" element={<CareerPage />} />
        <Route path="/career/hr" element={<CareerLogin />} />
        <Route path="/faq" element={<FaqScreen />}>
          <Route index element={<FaqVideoDownloader />} />
          <Route path="video-downloader" element={<FaqVideoDownloader />} />
          <Route path="file-manager" element={<FaqFilemanager />} />
          <Route path="music-player" element={<FaqMusicPlayer />} />
          <Route path="gallery" element={<GalleryFaqScreen />} />
          <Route path="tweedle" element={<TweedleFaqScreen />} />
          <Route path="radio-fm" element={<RadioFaqScreen />} />
          <Route path="video-to-mp3" element={<VideoToMp3FaqScreen />} />
        </Route>
        <Route path="/games" element={<GamePages />} />
        {/* <Route path="/games/mobile" element={<GridMobileView />} /> */}
        <Route path="/privacy-policy" element={<PrivacyPolicyScreen />} />

        <Route path="/about-us" element={<About />} />
        <Route path="/contact-us" element={<ContactScreen />} />
        <Route path="/apps" element={<AppScreen />} />
        <Route path="/blogs" element={<NewBlog />}>
          <Route index element={<NewBlogDesign />} />
          <Route path="video-downloader" element={<NewBlogVD />} />
          <Route path="file-manager" element={<NewBlogFL />} />
          <Route path="music" element={<NewBlogMusic />} />
          <Route path="video-to-mp3" element={<NewBlogVideoToMp3 />} />
          <Route path="gallery" element={<NewBlogGallery />} />
          <Route path="radio-monkey" element={<NewBlogRadio />} />
          <Route path="otherblogs" element={<NewBlogOthers />} />
        </Route>
        <Route
          path="/blogs/know-how-to-add-lyrics-to-music-player-through-multiple-methods"
          element={<AddLyricsBlog />}
        />
        <Route
          path="/blogs/elevate-screen-recorder-asd-video-mp3-converter-app"
          element={<ScreenRecorder />}
        />
        <Route
          path="/blogs/bass-boost-sound-equalizer-usage-guide"
          element={<BassBoostEqualizer />}
        />
        <Route
          path="/blogs/6-powerful-video-to-mp3-converter-for-android-in-2024"
          element={< Ultimate_Top_6_video_to_MP3_Converter_For_Android_in_2024/>}
        />
      
        
        <Route
          path="/blogs/how-to-restore-permanently-lost-pictures-gallery"
          element={<RestorePicturesGallery />}
        />
        <Route
          path="/blogs/introducing-exciting-new-notes-feature-asd-file-manager-app"
          element={<FileManagerNotesFeature />}
        />
        <Route
          path="/blogs/choose-best-free-collage-maker-android-device"
          element={<BestFreeCollageMaker />}
        />
        <Route
          path="/blogs/best-offline-and-online-music-players-in-india-for-2024"
          element={<Powerful_Online_Music />}
        />
        <Route
          path="/blogs/why-asd-gallery-app-better-than-default-gallery-apps"
          element={<ASDGalleryApp />}
        />
        <Route
          path="/blogs/lets-find-out-how-why-when-fathers-day-celebrated"
          element={<FathersDayBlog />}
        />
        <Route
          path="/blogs/how-to-use-color-correction-to-improve-images"
          element={<ColorCorrection />}
        />
        <Route
          path="/blogs/top-5-ways-to-choose-right-file-manager-app"
          element={<RepostInstagramFileManager />}
        />
        <Route
          path="/blogs/how-to-repost-on-instagram-using-asd-video-downloader"
          element={<RepostInstagram />}
        />
        <Route
          path="/blogs/download-dailymotion-videos-free"
          element={<DailyMotionBlog />}
        />
        <Route
          path="/blogs/9-effective-tips-to-manage-your-files-on-phone"
          element={<NineEffectiveFileManager />}
        />
        <Route
          path="/blogs/download-vimeo-videos"
          element={<VimeoDownloader />}
        />
        <Route
          path="/blogs/download-tiktok-videos-for-free"
          element={<TikTokdownloader />}
        />
        <Route
          path="/blogs/Twitter-Video-Downloader"
          element={<Twitterdownloader />}
        />
        <Route
          path="/blogs/photo-downloader-for-social-media-platforms"
          element={<PhotoDownloaderAll />}
        />
        <Route
          path="/blogs/Safe-Instagram-Video-Downloader"
          element={<InstagramVideodownloader />}
        />
        <Route
          path="/blogs/best-photo-editing-app-asd-gallery"
          element={<BestPhotoEditing />}
        />
        <Route
          path="/blogs/relax-meditate-best-relaxing-sounds-calm-sleep-music"
          element={<RelaxingSound />}
        />
        <Route
          path="/blogs/download-social-media-videos"
          element={<FourReasonVideoDownloader />}
        />
        <Route
          path="/blogs/7-reasons-file-management-app-must-have-device"
          element={<SevenFileManagerFeatures />}
        />
        <Route
          path="/blogs/top-6-features-music-player-app"
          element={<ExploreSixFeatures />}
        />
        <Route
          path="/blogs/ways-to-download-instagram-reels-fast"
          element={<InstagramReelDownloader />}
        />
        <Route
          path="/blogs/music-lovers-explore-best-music-player-app-android-device"
          element={<MusicLoverBlog />}
        />
        <Route
          path="/blogs/secure-your-private-files-with-file-encryption"
          element={<FileEncryptionBlog />}
        />
        <Route
          path="/blogs/discover-why-the-web-browser-of-the-asd-file-manager-app-is-like-no-other-app"
          element={<FileManagerWebBrowser />}
        />
        <Route
          path="/blogs/easy-way-to-download-facebook-videos"
          element={<EasyFacebookDownloader />}
        />
        <Route
          path="/blogs/best-car-mode-music-app"
          element={<CarModeBlogMusicPlayer />}
        />
        <Route
          path="/blogs/manage-large-files-easily-asd-file-manager-guide"
          element={<ManagingLargeFiles />}
        />
        <Route
          path="/blogs/download-videos-with-video-detection"
          element={<VideoDetectionBlog />}
        />
        <Route
          path="/blogs/equalizer-better-sound-quality"
          element={<EqualizerMusicBlog />}
        />
        <Route
          path="/blogs/manage-bookmarks-and-Downloads"
          element={<VideoDownloaderBookMark />}
        />
        <Route path="/blogs/customized-themes" element={<CustomizeThemes />} />
        <Route
          path="/blogs/Shortcut-and-Hide-Unhide-files"
          element={<FileManagerBlogShortcut />}
        />
        <Route
          path="/blogs/manage-storage"
          element={<VideoDownloaderStorage />}
        />
        <Route path="/blogs/sleep-timer" element={<SleepTimerBlog />} />
        <Route
          path="/blogs/benefits-of-scanning-documents"
          element={<ScanningDocuments />}
        />
        <Route
          path="/blogs/share-files-from-android-device-to-pc"
          element={<FileManagerEasyWayToShare />}
        />
        <Route
          path="/blogs/explore-the-ultimate-guide-to-the-best-fm-radio-app"
          element={<MCKekaurFMRadioBlog />}
        />
        <Route
          path="/blogs/secure-your-private-videos-using-video-downloader"
          element={<PrivateFileLockerVideoDownloader />}
        />
        <Route
          path="/blogs/music-player-for-all-type-of-audio-and-video-formats"
          element={<FreeAndroidMp3Player />}
        />
        <Route
          path="/blogs/remove-junk-files-using-clean-master"
          element={<CleanMasterBlog />}
        />
        <Route
          path="/blogs/insta-video-downloader"
          element={<InstaVideoDownloaderBlog />}
        />
        <Route
          path="/blogs/hide-photos-videos-and-files"
          element={<HidePhotosBlog />}
        />
        <Route path="/blogs/browser" element={<BuilInBrowserBlog />} />
        <Route
          path="/blogs/Digital-documents"
          element={<DigitalDocumentBlog />}
        />
        <Route
          path="/blogs/fresh-free-online-games"
          element={<NewYearBlog />}
        />
        <Route
          path="/blogs/how-to-download-social-media-photos"
          element={<PhotoDownloaderBlog />}
        />
        <Route path="/blogs/zip-files" element={<ZipBlog />} />
        <Route
          path="/blogs/online-video-downloader"
          element={<VideoDownloaderBlog />}
        />
        <Route
          path="/blogs/glitz-up-with-christmas-diy-decor-ideas"
          element={<ChristmasDecorationBlog />}
        />
        <Route
          path="/blogs/benefits-of-using-pdf-reader"
          element={<PdfBlog />}
        />
        <Route
          path="/blogs/benefits-of-the-file-manager-app-for-android-device"
          element={<BenefitsFileManagerBlog />}
        />
        <Route
          path="/blogs/best-music-player-App-for-android"
          element={<GuideMusicPlayerBlog />}
        />
        <Route
          path="/blogs/Celebrate-Christmas-on-a-Budget"
          element={<ChristmasBudget />}
        />
        <Route
          path="/blogs/apps-for-christmas-celebration"
          element={<AppChristmasBlog />}
        />
        <Route
          path="/blogs/download-ringtone-maker-app-android-device"
          element={<TweedleBlogScreen />}
        />
        <Route
          path="/blogs/best-file-manager-app-for-android"
          element={<FileManagerSecondBlog />}
        />
        <Route
          path="/blogs/free-online-fun-games"
          element={<OnlineFunGamesBlog />}
        />
        <Route
          path="/blogs/christmas-activities-traditions"
          element={<ChristmasBlog />}
        />
        <Route
          path="/blogs/radio-monkey-country-specific-online-fm-app"
          element={<RadioMonkeyBlog />}
        />
        <Route
          path="/blogs/free-android-mp3-player-app"
          element={<MusicBlog />}
        />
        <Route
          path="/blogs/gallery-photo-editor-collage-maker-app"
          element={<GalleryBlog />}
        />
        <Route
          path="/blogs/video-to-mp3-converter-for-android-mobile-app"
          element={<VideoToMp3Blog />}
        />
        <Route
          path="/blogs/top-3-Android-apps-to-make-your-Halloween-more-special"
          element={<HalloweenBlog />}
        />
        <Route
          path="/blogs/how-to-trim-videos-with-in-built-video-cutter-in-asd-video-to-mp3-converter"
          element={<TrimVideosInBuilt />}
        />
        <Route
          path="/blogs/guide-to-creating-perfect-mood-boosting-playlist"
          element={<GuideToCreatePlaylist />}
        />
        <Route
          path="/blogs/best-music-player-apps-android-2023"
          element={<BestMusicPlayersAndroid />}
        />
        <Route
          path="blogs/best-android-offline-online-music-player-apps-2023"
          element={<BestMusicAndroid2023 />}
        />
        <Route
          path="/blogs/top-free-music-player-apps-for-android-in-2023"
          element={<FreeMusicPlayer2023 />}
        />
        <Route
          path="/blogs/free-ai-image-generator"
          element={<FreeAIimage />}
        />
        <Route
          path="/blogs/explore-the-amazing-features-of-asd-video-to-mp3-converter"
          element={<MostPopularFeaturesOfASD />}
        />
        <Route
          path="/blogs/check-out-the-fm-recorder-feature-of-radio-monkey"
          element={<UseBestFM />}
        />
        <Route
          path="/blogs/5-simple-steps-to-hide-photos-and-videos"
          element={<SafeGaurdData />}
        />

        {/* <Route
          path="/blogs/top-6-file-manager-app-2023"
          element={<BestFileManager2023 />}
        /> */}
           <Route
          path="/blogs/best-video-audio-player-android-2024"
          element={< BestVideoandAudioPlayer/>}
        />
        <Route
          path="/blogs/best-christmas-music-of-all-time"
          element={<CelebrateChristmas2023 />}
        />
        <Route
          path="/blogs/how-to-download-whatsapp-status"
          element={<DownloadWhatsappStatus2023 />}
        />
        <Route
          path="/blogs/best-offline-music-players-for-android-iphone"
          element={<BestOfflineMusicPlayer2023 />}
        />
        <Route
          path="/blogs/how-to-convert-and-merge-audio-with-asd-video-to-mp3-converter"
          element={<ConvertAndMergeAudio />}
        />
        <Route
          path="/blogs/download-social-media-videos-easily-from-android"
          element={<DownloadSocialMediaVideos2024 />}
        />
        <Route
          path="/blogs/create-your-best-christmas-memories"
          element={<ChristmasMemories />}
        />
        <Route
          path="/blogs/best-apps-to-download-videos-2024"
          element={<DownloadVideos2024 />}
        />
        <Route
          path="/blogs/trim-audio-with-free-video-to-mp3-converter-app"
          element={<AudioCutter2024 />}
        />
        <Route
          path="/blogs/how-to-set-ringtone-with-free-asd-video-to-mp3-converter"
          element={<RingtoneConverter2024 />}
        />
        <Route
          path="/blogs/how-to-password-protect-files-and-folders-on-android-device"
          element={<PasswordProtect2024 />}
        />
        <Route
          path="/blogs/how-to-zip-files-and-create-zip-files-in-the-asd-file-manager"
          element={<ZipFileManager />}
        />
        <Route
          path="/blogs/5-best-music-player-apps-for-xiaomi-users-in-2024"
          element={<XiaomiMusicPlayer24 />}
        />
        <Route
          path="/blogs/6-best-music-player-apps-in-india-for-2024"
          element={<MusicPlayerBest62024 />}
        />
        <Route
          path="/blogs/tune-into-top-international-live-radio-fm-stations-in-2024"
          element={<RadioMonkeyInternational />}
        />
        <Route
          path="/blogs/how-to-convert-video-to-mp3-on-any-device-5-easy-steps"
          element={<ConvertVideoToMp3 />}
        />
        <Route
          path="/blogs/fast-in-built-browser-2024-in-the-ASD-file-manager-app"
          element={<FileMangerBrowser />}
        />
        <Route
          path="/blogs/convert-your-favourite-video-to-mp3-with-asd-music-player"
          element={<MusicPlayerConvertMusic />}
        />
        <Route
          path="/blogs/3-best-features-of-radio-monkey-you-should-know"
          element={<RadioMonkey3Features />}
        />
        <Route
          path="/blogs/free-dp-generator-that-stand-out-your-profile-picture-online"
          element={<FreeDPGenator />}
        />

        <Route
          path="/apps/radio-monkey-online-radio-fm-app"
          element={
            <RadioProvider>
              <RadioMonkey />
            </RadioProvider>
          }
        />
        <Route
          path="/apps/video-downloader-files-hider-app"
          element={<VideoDownloaderPage />}
        >
          <Route path="photo-downloader" element={<PhotoDownloader />} />
        </Route>
        <Route
          path="/apps/tweedle-free-ringtone-app"
          element={<TweedleScreen />}
        />
        <Route
          path="/apps/video-to-mp3-converter-app"
          element={<VideoToMp3Screen />}
        />

        <Route
          path="/apps/gallery-photo-editor-and-collage-maker-app"
          element={<GalleryScreen />}
        />
        <Route path="/apps/file-manager-app" element={<FileManagerScreen />} />
        <Route
          path="/apps/music-player-mp4-mp3-player-app"
          element={<MusicPlayerScreen />}
        />
        <Route
          path="/apps/rocks-video-player-app"
          element={<RockPlayerScreen />}
        />
       
          
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  </React.StrictMode>
);
