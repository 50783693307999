import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Header from "../../comp/Header";
import FooterHub from "../../comp/FooterHub";
import NewFooter from "../../comp/NewFooter";

const TweedleScreen = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Download Tweedle: Best Ringtone App for free on Android.</title>
        <meta
          name="description"
          content="Tweedle is a free app for ringtones. It is having features like - Alarms and notification sounds. You can set ringtones for your contacts and even can download ringtones."
        />
      </Helmet>
      <nav>
        <Header color="bg-[#6B009C]" page="app" />
      </nav>
      <main className="bg-[#6B009C] text-white pt-10">
        <img
          loading={"lazy"}
          src="\images\tweedleScreen\Image 4@2x.png"
          alt="tweedle"
          className="absolute top-0 right-0 md:right-20 w-44 md:w-96"
        />
        <div className="w-3/4 mx-auto pt-20">
          <div className="flex items-center justify-start space-x-6">
            <img
              loading={"lazy"}
              src="/images/icons/launcher icon.webp"
              alt="tweedle"
              className="w-20 md:w-32 "
            />
            <h1 className="text-lg md:text-6xl font-bold">
              Tweedle : Ringtone App
            </h1>
          </div>
          <img
            loading={"lazy"}
            src="/images/tweedleScreen/twiddleScreenPage-min.webp"
            alt="Tweedle : Ringtone App"
            className="w-[700px] mx-auto"
          />
          <div className="h-[440px] w-[95%] mx-auto bg-purple-900  rounded-3xl  -mt-20 relative">
            <img
              loading={"lazy"}
              src="/images/tweedleScreen/twiddlebird-min.webp"
              alt="tweedle"
              className="absolute -bottom-20 right-0  w-44 md:w-72"
            />
            <h1 className="px-2 md:px-20 text-center pt-20 font-bold text-2xl md:text-5xl">
              About App
            </h1>
            <h1 className="px-2 md:px-20 text-center text-xs md:text-lg font-medium mt-10">
              Looking for{" "}
              <a
                href="/blogs/video-to-mp3-converter-for-android-mobile-app"
                className="text-blue-600 font-medium"
              >
                Free Ringtones
              </a>{" "}
              for your Android Device? Tweedle is the finest Ringtone app,
              offers tons of ringtones categories like, Retro ringtones, Pop
              ringtones, Bollywood ringtones, Hollywood ringtones, 90s Hits
              ringtones, Lo-Fi music ringtones, Trending music ringtones,
              Classical music ringtones, Nature sounds ringtones and much more.{" "}
              <br /> Tweedle supports variety of features like, Notification
              ringtones, Alarm Ringtones, Contact Ringtones and much more.
            </h1>
          </div>

          <a
            href="https://tinyurl.com/Rareprob-Tweedle"
            target={"_blank"}
            rel="noreferrer"
          >
            <div className="flex items-center justify-center md:space-x-12 mt-20 pt-10 cursor-pointer  border-gray-500 hover:scale-125 ease-out transition-all ">
              <h1 className="text-3xl hidden md:block font-bold">
                Download Now
              </h1>
              <img
                loading={"lazy"}
                src="/images/videoDownloader/Line 2.svg"
                alt="line"
                className="h-10 hidden md:block"
              />
              <img
                loading={"lazy"}
                src="/images/homepage/google.webp"
                alt="google"
                className="w-32 hover:scale-105 mx-auto md:ml-0 mt-4 transition-all ease-linear   md:w-64 cursor-pointer "
              />
            </div>
          </a>
        </div>
        <div className="h-20"></div>

        <NewFooter color="bg-black/10" />
      </main>
    </>
  );
};

export default TweedleScreen;
