import React from 'react'
import { HeadingText } from './Util'
import Slider,{Settings} from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';


interface OurAppsType{
    gamePage:boolean
    page?:string
  }

const HomeApps:React.FC<OurAppsType>  = ({gamePage,page="home"}) => {


    var settings:Settings = {
      lazyLoad:"ondemand",
        dots: false,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 2,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          }
        ]
      };


  return (
    <div className={` ${gamePage===true?"lg:px-0":"lg:px-32"} px-12  mt-10`}>
      {
        gamePage===true?
        <h2 className='font-bold mt-20 text-2xl ml-0 lg:ml-14 '>Our Products</h2>
        :
       <div className='flex items-center justify-between '>
         <HeadingText text='Our Products'/>
         {
          page==="home"?<Link to="/apps" className='cursor-pointer mt-4'>See all</Link>:<></>
         }
       
       </div>

      }
   
        <Slider  {...settings} className='mt-10'>
          
           <AppProductHome appName='Video Downloader' 
          imageUrl={"/images/icons/videoDownloader.webp"}
          link="/apps/video-downloader-files-hider-app"
          />
           <AppProductHome appName='Radio Monkey' 
          imageUrl={"/images/icons/radioLauncher.png"}
          link="/apps/radio-monkey-online-radio-fm-app"
          />
          <AppProductHome appName='Rocks Player' 
          imageUrl={"/images/icons/videoplayer.png"} 
          link="/apps/rocks-video-player-app"/>
          <AppProductHome appName='File Manager' 
          imageUrl={"/images/icons/file mana.webp.png"} 
          link="/apps/file-manager-app"/>
          <AppProductHome appName='Video To Mp3' 
          imageUrl={"/images/icons/vmp3.png"} 
          link="/apps/video-to-mp3-converter-app"/> 
          <AppProductHome appName='Gallery' 
          imageUrl={"/images/icons/gallery.png"}
           link="/apps/gallery-photo-editor-and-collage-maker-app"
          />
          <AppProductHome appName='Music Player' 
          imageUrl={"/images/icons/musicIcon.webp"}
          link="/apps/music-player-mp4-mp3-player-app"
          />
          <AppProductHome appName='Find Difference' 
          imageUrl={"/images/icons/game.png"}
          link="https://play.google.com/store/apps/details?id=games.find.diff.gamma&referrer=utm_source%3DWebsite-Rareprob-Find-difference%26utm_medium%3DWebsite-Rareprob-Find-difference%26utm_campaign%3DWebsite-Rareprob-Find-difference%26anid%3Dadmob"
          />
          <AppProductHome appName='Tweedle' 
          imageUrl={"/images/icons/launcher icon.webp"}
          link="/apps/tweedle-free-ringtone-app"
          />
        </Slider>
    </div>
)
}

export default HomeApps
type AppProductTypes ={
    imageUrl : string,
    appName :string,
    link:string,

}


 const AppProductHome:React.FC<AppProductTypes> = ({imageUrl,appName,link})=>{
    return (
        <Link id='categoryDrag' to={link} className={` hover:scale-110 transition-all    bg-white/20 backdrop-blur-xl drop-shadow-xl py-4 lg:py-6
        mx-3 px-2 lg:p-4 flex flex-col items-center  rounded-2xl `}>
            <img src={imageUrl} 
            className="h-20 lg:w-24 w-20 lg:h-24 rounded-2xl"
            alt={appName} />
             <h3 className="text-[10px] mt-2 text-center lg:text-sm font-semibold">{appName}</h3>
        </Link >
    )
}