import { getAnalytics, logEvent } from "firebase/analytics";
import "./BrainGames.css";

const BrainGames = () => {
  const analytics = getAnalytics();

  return (
    <div
      className="brain_container"
      onPointerOver={() => window.adsbygoogle.push({})}
    >
      <h2>Brain Games</h2>
      <div>
        <a
          href="https://play.rareprobgames.com/classic-block/index.html"
          rel="noreferrer"
          onClick={() =>
            logEvent(analytics, "Rareprob Brain Games", {
              content_type: "Classic Block",
              content_id: "Classic Block",
              items: [{ name: "Classic Block" }],
            })
          }
        >
          <img src="/images/gamesScreen/new/classic.jpg" alt="Classic Block" />
          <h4>Classic Block</h4>
        </a>
        <a
          href="https://play.rareprobgames.com/minesweeper/index.html"
          rel="noreferrer"
          onClick={() =>
            logEvent(analytics, "Rareprob Brain Games", {
              content_type: "Minesweeper",
              content_id: "Minesweeper",
              items: [{ name: "Minesweeper" }],
            })
          }
        >
          <img src="/images/gamesScreen/new/mine.jpg" alt="Minesweeper" />
          <h4>Minesweeper</h4>
        </a>
        <a
          href="https://play.rareprobgames.com/best-throw-vertical/index.html"
          rel="noreferrer"
          onClick={() =>
            logEvent(analytics, "Rareprob Brain Games", {
              content_type: "Shoot Your Shot",
              content_id: "Shoot Your Shot",
              items: [{ name: "Shoot Your Shot" }],
            })
          }
        >
          <img src="/images/gamesScreen/new/shoot.jpg" alt="Shoot Your Shot" />
          <h4>Shoot Your Shot</h4>
        </a>

        <a
          href="https://play.rareprobgames.com/sudoku/index.html"
          rel="noreferrer"
          onClick={() =>
            logEvent(analytics, "Rareprob Brain Games", {
              content_type: "Sudoku",
              content_id: "Sudoku",
              items: [{ name: "Sudoku" }],
            })
          }
        >
          <img src="/images/gamesScreen/new/sudoku.jpg" alt="Soduku" />
          <h4>Sudoku</h4>
        </a>
        <a
          href="https://play.rareprobgames.com/2048-Brick/index.html"
          rel="noreferrer"
          onClick={() =>
            logEvent(analytics, "Rareprob Brain Games", {
              content_type: "2048 Brick",
              content_id: "2048 Brick",
              items: [{ name: "2048 Brick" }],
            })
          }
        >
          <img src="/images/gamesScreen/new/bricks.jpg" alt="Bricks" />
          <h4>2048 Brick</h4>
        </a>
        <a
          href="https://play.rareprobgames.com/2048-3d/index.html"
          rel="noreferrer"
          onClick={() =>
            logEvent(analytics, "Rareprob Brain Games", {
              content_type: "2048 3D",
              content_id: "2048 3D",
              items: [{ name: "2048 3D" }],
            })
          }
        >
          <img src="/images/gamesScreen/new/2048_3d.jpg" alt="2048 3D" />
          <h4>2048 3D</h4>
        </a>
      </div>
    </div>
  );
};

export default BrainGames;
