import "./NewFaqSection.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import "react-accessible-accordion/dist/fancy-example.css";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

const NewFaqSection = () => {
  return (
    <div className="newFaq">
      <h3>Have any questions? Look here!</h3>
      <Accordion className="mb-20 mt-20" allowZeroExpanded={true}>
        <AccordionItem
          style={{ borderRadius: "5px", marginTop: "10px" }}
          className="faq_Accordian "
        >
          <AccordionItemHeading className="accordion__arrow accordion__heading">
            <AccordionItemButton
              className="accordion__button !bg-white
                    !text-black !rounded-t-lg 
                    m-0 p-0
                     "
            >
              <div className="flex items-center font-bold justify-between">
                <h4> What are the Utility apps? </h4>
                <AiOutlinePlus size={20} className="plus" color="red" />
                <AiOutlineMinus size={20} className="minus" color="red" />
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="!bg-white !rounded-b-lg !rouded-l-lg">
            <p className="!text-black text-sm p-4 ">
              The utility apps allow users to meet their basic digital tasks
              with the most advanced features and exceptional user-friendly
              interface, which is far better than your device’s default apps.
              These Utility apps are regularly updated to perform multiple
              functionalities effortlessly.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem
          style={{ borderRadius: "5px", marginTop: "10px" }}
          className="faq_Accordian"
        >
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button 
              !text-black 
              !bg-white
              !rounded-t-lg 
               "
            >
              <div className="flex items-center font-bold justify-between">
                <h4> What is ASD Dev? </h4>
                <AiOutlinePlus size={20} className="plus" color="red" />
                <AiOutlineMinus size={20} className="minus" color="red" />
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="!bg-white !rounded-b-lg !rouded-l-lg">
            <p className="!text-black text-sm p-4">
              ASD Dev stands for ASD Dev Video Player for All Format is the
              developer's account of Rareprob Solution on the Play Store. You
              can visit the Rareprob website and ASD Dev's app on Playstore
              where you can explore all the apps.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem
          style={{ borderRadius: "5px", marginTop: "10px" }}
          className="faq_Accordian"
        >
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-white
                    !text-black 
                    !rounded-t-lg 
                     "
            >
              <div className="flex items-center font-bold justify-between">
                <h4> Why use the ASD Dev app? </h4>
                <AiOutlinePlus size={20} className="plus" color="red" />
                <AiOutlineMinus size={20} className="minus" color="red" />
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="!bg-white !rounded-b-lg !rouded-l-lg">
            <p className="!text-gray-800 p-4 text-sm">
              The ASD Dev is made of some of the finest software engineers in
              our country who develops mobile app to provide you with multiple
              features and functionalities such as you can download your
              favorite videos and images from any social media platform, video
              hider, managing downloads, multiple language support, watching and
              listening to high-quality media content, managing your digital
              documents and much more.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem
          style={{ borderRadius: "5px", marginTop: "10px" }}
          className="faq_Accordian"
        >
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-white
                    !text-black 
                    !rounded-t-lg 
                     "
            >
              <div className="flex items-center font-bold justify-between">
                <h4> What does ASD Dev have to offer? </h4>
                <AiOutlinePlus size={20} className="plus" color="red" />
                <AiOutlineMinus size={20} className="minus" color="red" />
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="!bg-white rounded-b-lg rouded-l-lg">
            <p className="!text-black p-4 text-sm">
              ASD Dev app offers you multiple daily utility Android applications
              such as ASD Rocks Video Player, ASD File Manager, ASD Video
              Downloader, ASD Gallery app, ASD Music Player, and many more.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default NewFaqSection;
