import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Modal from "react-modal";
import "./RadioMonkey.scss";
import ChannelCard from "./ChannelCard";
import ReactAudioPlayer from "react-audio-player";
import { useContext } from "react";
import { RadioContext } from "./radioContext";
import FooterPlayer from "./FooterPlayer";
import back from "./Images/images/back.svg";
import Shimmer from "./Shimmer";

import Header from "../../comp/Header";
import NewFooter from "../../comp/NewFooter";
import { scroll } from "../../comp/ScrollToTop";

const RadioMonkey = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openSearch, setOpenSearch] = React.useState(false);
  const handleOpenSearch = () => setOpenSearch(true);
  const handleCloseSearch = () => setOpenSearch(false);

  // const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredCountry, setFilteredCountry] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [showPopular, setShowPopular] = useState(false);
  const [popularData, setPopularData] = useState([]);
  const [greeting, setGreeting] = useState("");
  const [search, setSearch] = useState("");

  const [countries, setCountries] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    window.firstaman.onclick();
  }, []);

  useEffect(() => {
    axios
      .get(`https://nl1.api.radio-browser.info/json/countries`)
      .then((response) => {
        setCountries(response.data);
        setFilteredCountry(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  // const [ip, setIp] = useState("");
  // const [countryCode, setCountryCode] = useState("");

  // const getAnotherReq = () => {
  //   axios
  //     .get(`ipinfo.io/103.121.57.34?token=1fce051bfa7fec`)
  //     .then((response) => setCountryCode(response.data.country));
  // };
  // useEffect(() => {
  //   axios.get("https://api.ipify.org/?format=json").then((response) => {
  //     setIp(response.data);
  //     getAnotherReq();
  //   });
  // }, []);
  // console.log(ip);
  // console.log(countryCode);

  // const [playing, setPlaying] = useState("");

  const [country, setCountry] = useState("India");
  const {
    currentChannel,
    setCurrentChannel,
    setPlayingState,
    playingState,
    storeData,
    setStoreData,
    playing,
    setPlaying,
  } = useContext(RadioContext);

  //ref to control audio player
  const audioPlayer = useRef(ReactAudioPlayer);
  //filterChannels
  const filterData = (value, initialData) => {
    return initialData.filter((game) =>
      game.name.toLowerCase().includes(value)
    );
  };

  //filterCountries
  const filterCountry = (value, initialData) => {
    return initialData.filter((country) =>
      country.name.toLowerCase().includes(value)
    );
  };

  // fetch country_code through ip address

  // console.log(countryCode.data.country);

  //greetings to user
  useEffect(() => {
    const setGreetingMessage = () => {
      const now = new Date();
      const currentHour = now.getHours();

      let newGreeting;
      if (currentHour >= 5 && currentHour < 12) {
        newGreeting = " Morning!";
      } else if (currentHour >= 12 && currentHour < 17) {
        newGreeting = " Afternoon!";
      } else {
        newGreeting = " Evening!";
      }

      setGreeting(newGreeting);
    };

    setGreetingMessage();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `https://at1.api.radio-browser.info/json/stations/search?country=${country}&hidebroken=true&order=clickcount&reverse=true`
      )
      .then((response) => {
        // setData(response.data);

        setStoreData(
          response?.data?.filter(
            (item) =>
              item?.url_resolved?.includes("https://") &&
              !item?.url_resolved?.includes("http://")
          )
        );
        setFilteredData(
          response?.data?.filter(
            (item) =>
              item?.url_resolved?.includes("https://") &&
              !item?.url_resolved.includes("http://")
          )
        );
        setIsLoading(false);
      })
      .then(setPopularData(storeData.sort((a, b) => b.votes - a.votes)))
      .catch((error) => {
        console.error(error);
      });
  }, [country, showPopular]);
  //sort popular data
  //

  return (
    <div className="radio-container" id="start">
      <Header color="#232f58" page="apps" />
      {/* <div className="footer-player"></div> */}
      <button
        onClick={handleOpen}
        className="relative top-16 left-[80vw] lg:top-[24vh]"
      >
        <img
          src={`/images/radioScreen/flag/${country}.webp`}
          alt=""
          className="w-11 h-11 rounded-3xl 2xl:w-16 2xl:h-16 xl:rounded-[50%]"
        />
      </button>
      <Modal
        isOpen={open}
        onRequestClose={handleClose}
        contentLabel="Example Modal"
        portalClassName="!bg-red-900"
        bodyOpenClassName="!bg-gray-900/70"
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          },
          content: {
            position: "absolute",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px",
            border: "0px",
            padding: "0",
            borderRadius: "0",

            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",

            outline: "none",
          },
        }}
      >
        <div className="flex justify-start flex-col   w-full country-modal   pt-6 pb-10 text-white ">
          <div className="search-country flex justify-start items-center gap-5 mt-5 mb-5 lg:pl-10">
            <img
              src={back}
              alt=""
              className="text-red-500 "
              onClick={() => {
                setFilteredCountry(countries);
                handleClose();
              }}
            />

            <input
              autoFocus
              type="text"
              className="text-black bg-slate-400 border-black placeholder:text-white input-search "
              placeholder="search country..."
              onChange={(e) => {
                const renderCountry = filterData(
                  e.target.value.toLowerCase(),
                  countries
                );

                setFilteredCountry(renderCountry);
              }}
            />
          </div>
          {filteredCountry.map((country) => (
            <div
              className="flex justify-start  w-90 gap-2 items-center mb-5 ml-5 mr-5 mt-5 lg:ml-[25vw] lg:w-[50vw]"
              onClick={async () => {
                setCountry(country.name);
                setShowPopular(false);
                setFilteredCountry(countries);
                handleClose();
              }}
            >
              <img
                src={`/images/radioScreen/flag/${country.name}.webp`}
                alt=""
                className="w-10 h-10 rounded-3xl "
              />
              <h2 style={{ cursor: "pointer" }}>{country.name}</h2>
              {/* <input
                type="radio"
               
                name="country"
              /> */}
            </div>
          ))}
        </div>
      </Modal>
      <div className="main-radio p-5 w-full flex flex-col lg:items-center  ">
        <h1 className="mb-10 mt-5  text-white greeting">
          {" "}
          Good <br />
          {greeting}
        </h1>

        <input
          className="input-search ml-auto mr-auto"
          placeholder="Search Channel"
          type="text"
          onClick={() => {
            handleOpenSearch();
          }}
          onChange={(e) => {
            setSearch(e.target.value);
            const renderData = filterData(search.toLowerCase(), storeData);

            setFilteredData(renderData);
          }}
          value={search}
          autoFocus={false}
        />

        {/* <input
          className="input-search w-[60vw] ml-auto mr-auto"
          placeholder="Search..."
          type="text"
          onClick={() => {
            handleOpenSearch();
          }}
          onChange={(e) => {
            setSearch(e.target.value);
            const renderData = filterData(search.toLowerCase(), data);

            setFilteredData(renderData);
          }}
          value={search}
        /> */}
        <Modal
          isOpen={openSearch}
          onRequestClose={handleCloseSearch}
          contentLabel="Example Modal"
          portalClassName="!bg-red-900"
          bodyOpenClassName="!bg-gray-900/70"
          style={{
            overlay: {
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            },
            content: {
              position: "absolute",
              top: "0px",
              left: "0px",
              right: "0px",
              bottom: "0px",
              border: "0px",
              padding: "0",
              borderRadius: "0",

              background: "#fff",
              overflow: "auto",
              WebkitOverflowScrolling: "touch",

              outline: "none",
            },
          }}
        >
          <div className="flex  flex-col justify-start items-center  search-channel  w-full  pt-20 pb-10   ">
            <div className=" sticky top-14 ChannelModal flex justify-center items-center gap-5 mt-5 pt-6 pb-10  bg-[#232f58] z-10 lg:pl-5  lg:pb-6  lg:top-15 lg:pt-10 lg:self-center lg:w-full w-full ">
              <img
                src={back}
                alt=""
                className="  text-red-500 bg-[#232f58] "
                onClick={() => {
                  setSearch("");
                  handleCloseSearch();
                  setFilteredData(storeData);
                }}
              />

              <input
                autoFocus
                className="input-search  "
                placeholder="Search..."
                type="text"
                // onClick={() => {
                //   handleOpenSearch();
                // }}
                onChange={(e) => {
                  setSearch(e.target.value);
                  const renderData = filterData(
                    e.target.value.toLowerCase(),
                    storeData
                  );

                  setFilteredData(renderData);
                }}
                value={search}
              />
            </div>
            <div>
              {filteredData.map((channel, index) => {
                return (
                  <ChannelCard
                    index={index}
                    channel={channel}
                    playing={playing}
                    setPlaying={setPlaying}
                    audioPlayer={audioPlayer}
                  />
                );
              })}
            </div>
          </div>
        </Modal>

        <div className="flex items-center justify-start ml-5 gap-5 mb-5 text-[#888] text-lg lg:mb-[5vw] lg:mt-[5vw] lg:text-[2vw] lg:gap-[5vw] lg:ml-0 cursor-pointer">
          <h4
            onClick={() => setShowPopular(false)}
            className={showPopular ? "" : "active"}
          >
            All Channels
          </h4>
          <h4
            onClick={() => {
              setShowPopular(true);
              setSearch("");
            }}
            className={showPopular ? "active" : ""}
          >
            Popular Channels
          </h4>
        </div>
        <div>
          {showPopular ? (
            <div>
              {" "}
              {isLoading ? (
                Array(10)
                  .fill(0)
                  .map((storeData, i) => <Shimmer key={i} />)
              ) : (
                <div>
                  {popularData.slice(0, 10).map((channel, index) => {
                    return (
                      <ChannelCard
                        index={index}
                        channel={channel}
                        playing={playing}
                        setPlaying={setPlaying}
                        audioPlayer={audioPlayer}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          ) : (
            <div>
              {" "}
              {isLoading ? (
                Array(10)
                  .fill(0)
                  .map((storeData, i) => <Shimmer key={i} />)
              ) : (
                <div>
                  {filteredData.map((channel, index) => {
                    return (
                      <ChannelCard
                        index={index}
                        channel={channel}
                        playing={playing}
                        setPlaying={setPlaying}
                        audioPlayer={audioPlayer}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div>
        <div className="footer">
          <ReactAudioPlayer
            src={currentChannel.url_resolved}
            autoPlay
            controls
            className="radio-player"
            onPause={() => {
              setPlayingState(true);
            }}
            onPlay={() => {
              setPlayingState(false);
            }}
            ref={audioPlayer}
          />
        </div>
      </div>
      <FooterPlayer currentChannel={currentChannel} audioPlayer={audioPlayer} />
      <NewFooter color="bg-black/10" />
    </div>
  );
};

export default RadioMonkey;

//https://at1.api.radio-browser.info/json/stations/search?country=${selectedCountry.name}&hidebroken=true&order=clickcount&reverse=true
//https://nl1.api.radio-browser.info/json/countries
