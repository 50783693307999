import React from "react";
import { Link } from "react-router-dom";

interface BlogContainerType {
  name: string;
  image: string;
  text: string;
  path: string;
  logo: string;
  altz: string;
  date: string;
}

const BlogContainer: React.FC<BlogContainerType> = ({
  name,
  image,
  text,
  path,
  logo,
  altz,
  date,
}) => {
  return (
    <Link
      to={path}
      className="bg-gray-200  xl:w-[280px] h-[510px]  px-4  py-3  rounded-3xl"
    >
      <div className="flex items-center justify-start">
        {logo !== "" && <img src={logo} alt="gallery icon" className="w-8" />}
        <h1 className="text-sm md:text-lg font-semibold ml-4">{name}</h1>
      </div>
      <div className="">
        <img
          loading={"lazy"}
          src={image}
          alt={altz}
          className="w-full md:w-full h-60 object-cover lg:object-fill rounded-xl mt-6"
        />
        <p className="mt-2 px-4 text-gray-600  inline-block  bg-opacity-50 ">
          {date}
        </p>
        <h1 className=" md:w-full text-sm md:text-[14px] font-semibold h-20 mt-3 text-center">
          {text}
        </h1>
        <button className=" rounded-lg mb-4  mx-auto items-center flex mt-0 bg-[#0a4a82]/90 hover:bg-white hover:text-[#0a4a82] text-white font-medium  px-4 py-2">
          Read more
        </button>
      </div>
    </Link>
  );
};

const NewBlogOthers = () => {
  return (
    <div>
      <div className="w-[90%] mx-auto place-items-center grid lg:grid-cols-3 xl:grid-cols-4 grid-col-1 gap-10 md:gap-28">
        <BlogContainer
          name={"Father's Day"}
          altz="Father's Day"
          date="15 Jun 2023"
          image="https://img.rareprob.com/img/website/blogs/christmas/father-day/happy-father-s-day-greeting-card-design-with-dotted-bow-tie-typography-letter_1314-2772.webp"
          logo="/images/rrp_rrp logo.png"
          path="/blogs/lets-find-out-how-why-when-fathers-day-celebrated"
          text="
          Let's Find Out How, Why & When Fathers' Day is Celebrated
          "
        />
        <BlogContainer
          name={"New Year 2023"}
          altz="New Year 2023 "
          image="https://img.rareprob.com/img/website/blogs/games/GAME COVER IMAGE.webp"
          logo="/images/rrp_rrp logo.png"
          path="/blogs/fresh-free-online-games"
          date="31 Dec 2022"
          text="Fresh Fun starts with free fun Online Games | New Year 2023              "
        />

        <BlogContainer
          name={"Christmas "}
          altz="Christmas "
          image="https://img.rareprob.com/img/website/blogs/christmas/frontchris.jpg"
          logo="/images/rrp_rrp logo.png"
          path="/blogs/glitz-up-with-christmas-diy-decor-ideas"
          date="20 Dec 2022"
          text=" Glitz up your festive mood with DIY Decoration and Gifts Ideas            "
        />

        <BlogContainer
          name={"Christmas "}
          altz="Christmas"
          image="https://img.rareprob.com/img/website/blogs/christmas/Artboard 5 copy.jpg"
          logo="/images/rrp_rrp logo.png"
          path="/blogs/Celebrate-Christmas-on-a-Budget"
          date="2 Dec 2022"
          text="How to Celebrate Christmas 2022 on Budget?"
        />

        <BlogContainer
          name={"Christmas "}
          altz="Christmas"
          image="https://img.rareprob.com/img/website/blogs/christmas/front image.jpg"
          logo="/images/rrp_rrp logo.png"
          path="/blogs/apps-for-christmas-celebration"
          date="1 Dec 2022"
          text="Top 5 Android Apps to make this Christmas Season Jolly!"
        />

        <BlogContainer
          name={"Christmas "}
          altz="Christmas"
          image="https://img.rareprob.com/img/website/blogs/christmas/Artboard 1 (8).jpg"
          logo="/images/rrp_rrp logo.png"
          path="/blogs/christmas-activities-traditions"
          date="27 Nov 2022"
          text="16 Things to do For Christmas 2022 & Traditions to Start!"
        />

        <BlogContainer
          name={"Tweedle "}
          altz=" Tweedle"
          image="https://img.rareprob.com/img/website/blogs/tweedle/tweedle.jpg"
          logo="/images/icons/appIcon/Tweedle.webp"
          path="/blogs/download-ringtone-maker-app-android-device"
          date="16 Nov 2022"
          text="Discover free prominent ringtones for your Android Device"
        />

        <BlogContainer
          name={"Games"}
          altz="Games"
          image="https://img.rareprob.com/img/website/blogs/games/game.webp"
          logo="/images/rrp_rrp logo.png"
          path="/blogs/free-online-fun-games"
          date="16 Nov 2022"
          text="Free Online Fun games: Stack ball, Find Difference and TIC TAC TOE"
        />

        <BlogContainer
          name={"Halloween Special"}
          altz="Rareprob : Halloween Special"
          date="30 Oct 2022"
          image="https://img.rareprob.com/img/website/blogs/christmas/Artboard 00000001144.jpg"
          logo="/images/rrp_rrp logo.png"
          path="/blogs/top-3-Android-apps-to-make-your-Halloween-more-special"
          text="Make this Halloween more special with music and perfect photos."
        />

        <BlogContainer
          name="Radio Monkey"
          altz="Radio Monkey"
          date="20 Oct 2021"
          image="https://img.rareprob.com/img/website/blogs/radio-front.jpg"
          logo="/images/icons/radioLauncher.png"
          path="/blogs/radio-monkey-country-specific-online-fm-app"
          text="Radio Monkey: Connect to the next-gen of Online tuners"
        />
      </div>
    </div>
  );
};

export default NewBlogOthers;
