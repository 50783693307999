import React, { useEffect, useState } from "react";
import { RadioContext } from "./radioContext";
import { useContext } from "react";
import Modal from "react-modal";
import pauseWhite from "./Images/images/Group 41.svg";
import playWhite from "./Images/images/whitePlay.svg";
import downArrow from "./Images/images/downArrow.svg";
import prev from "./Images/images/Group 39.svg";
import next from "./Images/images/Group 40.svg";
import animation from "./Images/animations/pbKlXiB3n3.json";
import Lottie from "lottie-react";

import ShareButton from "./Share";
const FooterPlayer = ({ audioPlayer }) => {
  const [openPlayer, setOpenPlayer] = React.useState(false);
  const handleOpenPlayer = () => setOpenPlayer(true);
  const handleClosePlayer = () => setOpenPlayer(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const {
    currentChannel,
    playingState,
    playing,
    setPlaying,
    storeData,
    setStoreData,
    index,
    setCurrentChannel,
    setIndex,
  } = useContext(RadioContext);

  return (
    <div
      className={currentChannel.stationuuid === playing ? "" : "hide-player"}
    >
      {windowWidth < 768 ? (
        <Modal
          isOpen={openPlayer}
          onRequestClose={handleClosePlayer}
          contentLabel="Example Modal"
          portalClassName="!bg-red-900"
          bodyOpenClassName="!bg-gray-900/70"
          style={{
            overlay: {
              position: "fixed",
              top: "50vh",
              left: "5vw",
              right: "5vw",
              bottom: "7vh",
              zIndex: "200",
              borderRadius: "6vw",
            },
            content: {
              position: "absolute",
              top: "0px",
              left: "0px",
              right: "0px",
              bottom: "0px",
              border: "0px",
              padding: "0",
              borderRadius: "0",
              background: "#272e45",
              overflow: "auto",
              WebkitOverflowScrolling: "touch",

              outline: "none",
            },
          }}
        >
          <div className="flex flex-col justify-center items-center ">
            <img
              src={downArrow}
              alt=""
              onClick={handleClosePlayer}
              className="pt-2"
            />
            <div className="modal-image">
              {currentChannel?.favicon === undefined ? (
                ""
              ) : (
                <img
                  src={
                    currentChannel?.favicon === ""
                      ? "/images/radioScreen/radio.png"
                      : currentChannel?.favicon
                  }
                  className=" drop-shadow-lg shadow-xl object-cover  border-black  logo"
                  alt={currentChannel?.name}
                  onError={(event) =>
                    (event.currentTarget.src = "/images/radioScreen/radio.png")
                  }
                />
              )}
            </div>
            <div className="modal-animation absolute">
              {" "}
              {playingState ? "" : <Lottie animationData={animation} />}
            </div>
            <h1 className="text-white mt-8 text-xl">
              {currentChannel?.name?.length > 18
                ? currentChannel?.name?.slice(0, 15) + "..."
                : currentChannel?.name}
            </h1>
            <div className="flex justify-evenly gap-7 mt-10">
              <img
                src={prev}
                alt=""
                className="modal-prev-next"
                onClick={() => {
                  if (index !== 0 && storeData.length !== 0) {
                    setCurrentChannel(storeData[index - 1]);

                    setIndex(index - 1);
                    setPlaying(storeData[index - 1].stationuuid);
                  }
                }}
              />
              {playingState ? (
                <img
                  className="modal-play-pause"
                  src={playWhite}
                  alt=""
                  onClick={() => audioPlayer.current?.audioEl.current?.play()}
                />
              ) : (
                <img
                  className="modal-play-pause"
                  src={pauseWhite}
                  alt=""
                  onClick={() => audioPlayer.current?.audioEl.current?.pause()}
                />
              )}
              <img
                src={next}
                alt=""
                className="modal-prev-next"
                onClick={() => {
                  if (
                    index !== storeData.length - 1 &&
                    storeData.length !== 0
                  ) {
                    setCurrentChannel(storeData[index + 1]);

                    setIndex(index + 1);
                    setPlaying(storeData[index + 1].stationuuid);
                  }
                }}
              />
            </div>
            <div className="mt-5 share">
              <ShareButton />
            </div>
          </div>
        </Modal>
      ) : (
        <Modal
          isOpen={openPlayer}
          onRequestClose={handleClosePlayer}
          contentLabel="Example Modal"
          portalClassName="!bg-red-900"
          bodyOpenClassName="!bg-gray-900/70"
          style={{
            overlay: {
              position: "fixed",
              top: "40vh",
              left: "29vw",
              right: "29vw",
              bottom: "5vh",
              zIndex: "200",
              borderRadius: "4vw",
              overflow: "hidden",
              overflowY: "hidden",
            },
            content: {
              position: "absolute",
              top: "0px",
              left: "0px",
              right: "0px",
              bottom: "0px",
              border: "0px",
              padding: "0",
              borderRadius: "0",
              background: "#272e45",
              overflowX: "hidden",
              overflowY: "hidden",
              WebkitOverflowScrolling: "touch",

              outline: "none",
            },
          }}
        >
          <div className="flex flex-col justify-center items-center ">
            <img
              src={downArrow}
              alt=""
              onClick={handleClosePlayer}
              className="pt-2 cursor-pointer"
            />
            <div className="modal-image">
              {currentChannel?.favicon === undefined ? (
                ""
              ) : (
                <img
                  src={
                    currentChannel?.favicon === ""
                      ? "/images/radioScreen/radio.png"
                      : currentChannel?.favicon
                  }
                  className=" drop-shadow-lg shadow-xl object-cover  border-black  logo "
                  alt={currentChannel?.name}
                  onError={(event) =>
                    (event.currentTarget.src = "/images/radioScreen/radio.png")
                  }
                />
              )}
            </div>
            {/* <div className="modal-animation absolute">
              {" "}
              {playingState ? "" : <Lottie animationData={animation} />}
            </div> */}
            <h1 className="text-white mt-8 text-xl ">
              {currentChannel?.name?.length > 18
                ? currentChannel?.name?.slice(0, 15) + "..."
                : currentChannel?.name}
            </h1>
            <div className="flex justify-evenly gap-7 mt-10">
              <img
                src={prev}
                alt=""
                className="modal-prev-next cursor-pointer"
                onClick={() => {
                  if (index !== 0 && storeData.length !== 0) {
                    setCurrentChannel(storeData[index - 1]);

                    setIndex(index - 1);
                    setPlaying(storeData[index - 1].stationuuid);
                  }
                }}
              />
              {playingState ? (
                <img
                  className="modal-play-pause cursor-pointer"
                  src={playWhite}
                  alt=""
                  onClick={() => audioPlayer.current?.audioEl.current?.play()}
                />
              ) : (
                <img
                  className="modal-play-pause cursor-pointer"
                  src={pauseWhite}
                  alt=""
                  onClick={() => audioPlayer.current?.audioEl.current?.pause()}
                />
              )}
              <img
                src={next}
                alt=""
                className="modal-prev-next cursor-pointer"
                onClick={() => {
                  if (
                    index !== storeData.length - 1 &&
                    storeData.length !== 0
                  ) {
                    setCurrentChannel(storeData[index + 1]);

                    setIndex(index + 1);
                    setPlaying(storeData[index + 1].stationuuid);
                  }
                }}
              />
            </div>
            <div className="mt-5 share">
              <ShareButton />
            </div>
          </div>
        </Modal>
      )}

      <div className="footer-player flex items-center justify-start gap-4 lg:w-[40vw]  ">
        <div onClick={handleOpenPlayer}>
          {" "}
          {currentChannel?.favicon === undefined ? (
            ""
          ) : (
            <img
              src={
                currentChannel?.favicon === ""
                  ? "/images/radioScreen/radio.png"
                  : currentChannel?.favicon
              }
              className=" drop-shadow-lg shadow-xl object-cover  border-black  logo cursor-pointer"
              alt={currentChannel?.name}
              onError={(event) =>
                (event.currentTarget.src = "/images/radioScreen/radio.png")
              }
            />
          )}
        </div>
        {storeData ? (
          <h1
            onClick={handleOpenPlayer}
            className="pr-20 lg:pr-25 lg:pl-10 cursor-pointer"
          >
            {currentChannel?.name?.length > 18
              ? currentChannel?.name?.slice(0, 15) + "..."
              : currentChannel?.name}
          </h1>
        ) : (
          ""
        )}
        <div className=" bottom-player absolute right-5 lg:right-10 ">
          {/* {playingState ? (
            <img
              className="play-pause"
              src={playWhite}
              alt=""
              onClick={() => audioPlayer.current?.audioEl.current?.play()}
            />
          ) : (
            <img
              className="play-pause"
              src={pauseWhite}
              alt=""
              onClick={() => audioPlayer.current?.audioEl.current?.pause()}
            />
          )} */}
          <div className="flex justify-evenly gap-7 ">
            <img
              src={prev}
              alt=""
              className="modal-prev-next cursor-pointer"
              onClick={() => {
                if (index !== 0 && storeData.length !== 0) {
                  setCurrentChannel(storeData[index - 1]);

                  setIndex(index - 1);
                  setPlaying(storeData[index - 1].stationuuid);
                }
              }}
            />
            {playingState ? (
              <img
                className="modal-play-pause cursor-pointer"
                src={playWhite}
                alt=""
                onClick={() => audioPlayer.current?.audioEl.current?.play()}
              />
            ) : (
              <img
                className="modal-play-pause cursor-pointer"
                src={pauseWhite}
                alt=""
                onClick={() => audioPlayer.current?.audioEl.current?.pause()}
              />
            )}
            <img
              src={next}
              alt=""
              className="modal-prev-next cursor-pointer"
              onClick={() => {
                if (index !== storeData.length - 1 && storeData.length !== 0) {
                  setCurrentChannel(storeData[index + 1]);

                  setIndex(index + 1);
                  setPlaying(storeData[index + 1].stationuuid);
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterPlayer;
