import { getAnalytics, logEvent } from "firebase/analytics";
import "./ActioGames.css";

const ActioGames = () => {
  const analytics = getAnalytics();

  return (
    <div
      className="action_container"
      onPointerOver={() => window.adsbygoogle.push({})}
    >
      <h2>Action Games</h2>
      <div>
        <a
          href="https://play.rareprobgames.com/knifehits/index.html"
          rel="noreferrer"
          onClick={() =>
            logEvent(analytics, "Rareprob Action Games", {
              content_type: "KnifeHit",
              content_id: "KnifeHit",
              items: [{ name: "KnifeHit" }],
            })
          }
        >
          <img
            src="https://img.rareprob.com/img/default/RareProbImg/imagesRare/games/Knife_hit.gif"
            alt="Knife Show"
          />
          <h4>Knife Show</h4>
        </a>
        <a
          href="https://play.rareprobgames.com/egg-drop/index.html"
          rel="noreferrer"
          onClick={() =>
            logEvent(analytics, "Rareprob Action Games", {
              content_type: "Egg Drop",
              content_id: "Egg Drop",
              items: [{ name: "Egg Drop" }],
            })
          }
        >
          <img
            src="https://img.rareprob.com/img/default/RareProbImg/imagesRare/games/egg_drop.gif"
            alt="Egg Drop"
          />
          <h4>Egg Drop</h4>
        </a>
        <a
          href="https://play.rareprobgames.com/Flappy-Ball/index.html"
          rel="noreferrer"
          onClick={() =>
            logEvent(analytics, "Rareprob Action Games", {
              content_type: "Flappy Ball",
              content_id: "Flappy Ball",
              items: [{ name: "Flappy Ball" }],
            })
          }
        >
          <img
            src="https://img.rareprob.com/img/default/RareProbImg/imagesRare/games/FLUFFY_BALL.gif"
            alt="Flappy Ball"
          />
          <h4>Flappy Ball</h4>
        </a>
        <a
          href="https://play.rareprobgames.com/gun-jump/index.html"
          rel="noreferrer"
          onClick={() =>
            logEvent(analytics, "Rareprob Action Games", {
              content_type: "Flappy Ball",
              content_id: "Flappy Ball",
              items: [{ name: "Flappy Ball" }],
            })
          }
        >
          <img
            src="https://img.rareprob.com/img/default/RareProbImg/imagesRare/games/gun_jump.gif"
            alt="Gun Jump"
          />
          <h4>Gun Jump</h4>
        </a>
        <a
          href="https://play.rareprobgames.com/stackball/index.html"
          rel="noreferrer"
          onClick={() =>
            logEvent(analytics, "Rareprob Action Games", {
              content_type: "Stack Ball",
              content_id: "Stack Ball",
              items: [{ name: "Stack Ball" }],
            })
          }
        >
          <img
            src="https://img.rareprob.com/img/default/RareProbImg/imagesRare/games/stack_ball.gif"
            alt="Break Platforms"
          />
          <h4>Break Platforms</h4>
        </a>
        <a
          href="https://play.rareprobgames.com/tap-tap-ball/index.html"
          rel="noreferrer"
          onClick={() =>
            logEvent(analytics, "Rareprob Action Games", {
              content_type: "Tap Tap Ball",
              content_id: "Tap Tap Ball",
              items: [{ name: "Tap Tap Ball" }],
            })
          }
        >
          <img
            src="https://img.rareprob.com/img/default/RareProbImg/imagesRare/games/tapTapShot.webp"
            alt="Tap Tap Ball"
          />
          <h4>Tap Tap Ball</h4>
        </a>
      </div>
    </div>
  );
};

export default ActioGames;
