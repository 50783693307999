import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../../../firebase";
import "./CareerLogin.css";

const CareerLogin = () => {
  const [todos, setTodos] = useState<any[]>([]);

  const [search, setSearch] = useState<string>("");

  const careerdata = async () => {
    const aman: any[] = [];

    await getDocs(collection(firestore, "ResumeCarrer")).then(
      (querySnapshot) => {
        console.log("Data Enterded here");
        querySnapshot.docs.forEach((value) => {
          aman.push(value.data());
        });
        setTodos(aman);
        console.log(aman);
      }
    );
  };

  useEffect(() => {
    careerdata();
  }, []);

  return (
    <div className="careerLogin">
      <h2>Jobs Applicants</h2>
      <div className="search-bar">
        <form>
          <input
            id="search"
            type="text"
            placeholder="Start typing to search... &#x1F50D;"
            value={search}
            onChange={(e) => setSearch(e.target.value.toLowerCase())}
          />
        </form>
      </div>
      {todos
        .filter((item) => {
          return search.toLowerCase() === ""
            ? JSON.stringify(item.position)
            : item.position.toLowerCase().includes(search) ||
                item.personelData.personelFName
                  .toLowerCase()
                  .includes(search) ||
                item.personelData.personelLName.toLowerCase().includes(search);
        })
        .map((value, index) => {
          return (
            <div className="careerLogin_Container_single">
              <div>
                <h4 className="careerLogin_single">{`${value?.personelData.personelFName} ${value?.personelData.personelLName}`}</h4>

                <p className="careerLogin_single_position">{value.position}</p>
                <p className="experince">
                  {value?.workData.workTotalExperience}
                </p>
                <p className="careerLogin_single_position_relocate">
                  {value.workData.workRelocate
                    ? "Ready To Relocate "
                    : "Not Ready to Relocate"}
                </p>
                <div className="Address">
                  {value.personelData.personelAddress}
                </div>
                <p>{value.personelData.personelPhone}</p>
              </div>
              <div className="border_image">
                <a href={value?.cv} target="_blank">
                  <img
                    className="pdf_image"
                    src="/images/icons/pdfLogo.png"
                    alt="Pdf file"
                  />
                </a>
                <div className="date">{value.date}</div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default CareerLogin;
