import "./GridMobileView.css";
import GameTile from "../../comp/GameTile/GameTile";
import ActioGames from "../../comp/ActioGames/ActioGames";
import ArcadeGames from "../../comp/ArcadeGames/ArcadeGames";
import BrainGames from "../../comp/BrainGames/BrainGames";
import { getAnalytics, logEvent } from "firebase/analytics";
import PuzzleGames from "../../comp/Puzzlegames/Puzzlegames";
import NewCasualGames from "../../comp/NewCasualGames/NewCasualGames";
import { useEffect, useState } from "react";
import {
  CollectionReference,
  DocumentData,
  collection,
  getDocs,
} from "firebase/firestore";

import { firestore } from "../../firebase";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "../../comp/Header";
import NewFooter from "../../comp/NewFooter";
import NewReleaseGames from "../../comp/NewReleaseGames/NewReleaseGames";

interface BannerTypeGrid {
  id: string;
  top: boolean;
  bottom1: boolean;
  bottom2: boolean;
  bottom3: boolean;
  bottom4: boolean;
}

const GridMobileView = () => {
  const [bannerAds, setBannerAds] = useState<BannerTypeGrid | null>(null);

  const analytics = getAnalytics();

  const fetchBanner: () => Promise<void> = async () => {
    let aman: BannerTypeGrid = {
      id: "fd",
      top: false,
      bottom1: false,
      bottom2: false,
      bottom3: false,
      bottom4: false,
    };

    getDocs<DocumentData>(
      collection(firestore, "newBanner") as CollectionReference<BannerTypeGrid>
    ).then((querySnapshot) => {
      querySnapshot.docs.forEach((value) => {
        var game: BannerTypeGrid = {
          id: value.id,
          top: value.data().top,
          bottom1: value.data().bottom1,
          bottom2: value.data().bottom2,
          bottom3: value.data().bottom3,
          bottom4: value.data().bottom4,
        };

        aman = game;
      });

      setBannerAds({
        bottom1: aman.bottom1,
        top: aman.top,
        bottom2: aman.bottom2,
        id: aman.id,
        bottom3: aman.bottom3,
        bottom4: aman.bottom4,
      });

      window.adsbygoogle.push({});
      window.adsbygoogle.push({});
    });
  };

  useEffect(() => {
    fetchBanner();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Rareprob : Play Free Online Web | Android | iOS | Games</title>

        <meta
          name="description"
          content="Play Free Online Brain Games like- Find Difference, Tic-Tac-Toe, and Stack Ball with Multiplayer and Single-player features. Enjoy the best 2D and 3D Arcade games| Rareprob."
        />
      </Helmet>
      <Header color="bg-gray-700/20" page="game" />
      <div className=" playIt_container " id="start">
        <main className="main-games">
          <div className="!overflow-y-scroll playIt_scroll">
            {/* <div className="bg-white/50 backdrop-blur-xl	 h-56 w-10 rounded-l-xl absolute right-0 top-[50%] bottom-0 my-auto z-40 flex space-y-6 items-center justify-center flex-col">
              <a
                href="https://www.rocksplayer.com/"
                target={"_blank"}
                rel="noreferrer"
                onClick={() =>
                  logEvent(analytics, "Game_Rocksplayer_Event", {
                    content_type: "Rocksplayer Game",
                    content_id: "Rocksplayer Game",
                    items: [{ name: "Rocksplayer Game" }],
                  })
                }
              >
                <img
                  src="/images/icons/videoplayer.png"
                  alt="Rocks Video Player | Rareprob"
                  className="w-7 h-7 rounded-lg"
                />
              </a>

              <a
                href={"/apps/file-manager-app"}
                rel="noreferrer"
                target={"_blank"}
                onClick={() =>
                  logEvent(analytics, "Game_FileManager_Event", {
                    content_type: "FileManager Game",
                    content_id: "FileManager Game",
                    items: [{ name: "FileManager Game" }],
                  })
                }
              >
                <img
                  src="/images/icons/file mana.webp.png"
                  alt="File Manager | Rareprob"
                  className="w-7 h-7 rounded-lg"
                />
              </a>
              <a
                rel="noreferrer"
                target={"_blank"}
                href="/apps/video-downloader-files-hider-app"
                onClick={() =>
                  logEvent(analytics, "Game_VideoDownloader_Event", {
                    content_type: "VideoDownloader Game",
                    content_id: "VideoDownloader Game",
                    items: [{ name: "VideoDownloader Game" }],
                  })
                }
              >
                <img
                  src="/images/icons/videoDownloader.webp"
                  alt="File Manager | Rareprob"
                  className="w-7 h-7 rounded-lg"
                />
              </a>
              <a
                rel="noreferrer"
                target={"_blank"}
                href="/apps/video-to-mp3-converter-app"
                onClick={() =>
                  logEvent(analytics, "Game_VideoToMp3_Event", {
                    content_type: "VideoToMp3 Game",
                    content_id: "VideoToMp3 Game",
                    items: [{ name: "VideoToMp3 Game" }],
                  })
                }
              >
                <img
                  src="/images/icons/vmp3.png"
                  alt="Video To Mp3 | Rareprob"
                  className="w-7 h-7 rounded-lg"
                />
              </a>
            </div> */}
            {/* <Link to={"/"}>
            <img
              src="/images/homepage/rare-logo.webp"
              alt="Rareprob "
              className="playIt_container_logo ml-4"
            />
          </Link> */}

            {/* {bannerAds?.top === true ? (
              <ins
                className="adsbygoogle  w-full !h-40 rounded-lg pt-40 lg:pt-20"
                style={{ display: "block" }}
                data-ad-format="auto"
                data-ad-client="ca-pub-9496468720079156"
                data-full-width-responsive="true"
              ></ins>
            ) : null} */}

            <div
              className="bg-black/10 py-12 pl-0 xl:px-2 xl:py-10 rounded-xl mt-6 backdrop-blur-lg"
              onPointerOver={() => window.adsbygoogle.push({})}
            >
              <h2 className="heading_recom pl-4">Recommended Games</h2>
              <div className="playIt_container_recom ">
                <div className="playIt_container_recom_one">
                  <GameTile
                    bgColor="rgba(144, 29, 132, 0.3)"
                    heading="Knife Show"
                    image="https://img.rareprob.com/img/default/RareProbImg/imagesRare/games/Knife_hit.gif"
                    link="https://play.rareprobgames.com/knifehits/index.html"
                  />
                </div>
                <div className="playIt_container_recom_two">
                  <GameTile
                    bgColor="rgba(54, 99, 101, 0.3)"
                    heading="Egg Drop"
                    image="https://img.rareprob.com/img/default/RareProbImg/imagesRare/games/egg_drop.gif"
                    link="https://play.rareprobgames.com/egg-drop/index.html"
                  />
                </div>

                <div className="playIt_container_recom_three">
                  <GameTile
                    bgColor="rgba(239, 201, 104, 0.3)"
                    heading="Gun Jump"
                    image="https://img.rareprob.com/img/default/RareProbImg/imagesRare/games/gun_jump.gif"
                    link="https://play.rareprobgames.com/gun-jump/index.html"
                  />
                </div>
                <div className="playIt_container_recom_four">
                  <GameTile
                    bgColor="rgba(78, 62, 138, 0.3)"
                    heading="Break Platforms"
                    image="https://img.rareprob.com/img/default/RareProbImg/imagesRare/games/stack_ball.gif"
                    link="https://play.rareprobgames.com/stackball/index.html"
                  />
                </div>

                <div className="playIt_container_recom_five">
                  <GameTile
                    bgColor="rgba(54, 99, 101, 0.3)"
                    heading="Woodie Brick"
                    image="https://play-lh.googleusercontent.com/e_8zrZeMjzFKsx5TcXPbOgCgJfzs_avCTGNR3kAq69AW7XftFwIngR76gBp1u-tMPa4=w240-h480-rw"
                    link="https://play.rareprobgames.com/WoodieBricks2/index.html"
                  />
                </div>
              </div>
            </div>
            <div className="pl-3">
              {bannerAds?.bottom1 === true ? (
                <ins
                  className="adsbygoogle  w-full my-10 !h-40 rounded-lg"
                  style={{ display: "block" }}
                  data-ad-format="auto"
                  data-ad-client="ca-pub-9496468720079156"
                  data-full-width-responsive="true"
                ></ins>
              ) : null}
              <NewReleaseGames />
              {bannerAds?.bottom1 === true ? (
                <ins
                  className="adsbygoogle  w-full my-10 !h-40 rounded-lg"
                  style={{ display: "block" }}
                  data-ad-format="auto"
                  data-ad-client="ca-pub-9496468720079156"
                  data-full-width-responsive="true"
                ></ins>
              ) : null}
              <ActioGames />
              {bannerAds?.bottom2 === true ? (
                <ins
                  className="adsbygoogle  w-full my-10 !h-40 rounded-lg"
                  style={{ display: "block" }}
                  data-ad-format="auto"
                  data-ad-client="ca-pub-9496468720079156"
                  data-full-width-responsive="true"
                ></ins>
              ) : null}
              <ArcadeGames />
              {bannerAds?.bottom3 === true ? (
                <ins
                  className="adsbygoogle  w-full my-10 !h-40 rounded-lg"
                  style={{ display: "block" }}
                  data-ad-format="auto"
                  data-ad-client="ca-pub-9496468720079156"
                  data-full-width-responsive="true"
                ></ins>
              ) : null}
              <PuzzleGames />
              {bannerAds?.bottom4 === true ? (
                <ins
                  className="adsbygoogle  w-full my-10 !h-40 rounded-lg"
                  style={{ display: "block" }}
                  data-ad-format="auto"
                  data-ad-client="ca-pub-9496468720079156"
                  data-full-width-responsive="true"
                ></ins>
              ) : null}
              <BrainGames />
              {bannerAds?.bottom4 === true ? (
                <ins
                  className="adsbygoogle  w-full my-10 !h-40 rounded-lg"
                  style={{ display: "block" }}
                  data-ad-format="auto"
                  data-ad-client="ca-pub-9496468720079156"
                  data-full-width-responsive="true"
                ></ins>
              ) : null}
              <NewCasualGames />
            </div>
          </div>
        </main>
        {/* <NewFooter color="bg-black/100" page="" /> */}
      </div>
    </>
  );
};

export default GridMobileView;
