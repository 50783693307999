import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../../comp/Header";
import { FiVideo } from "react-icons/fi";
import { BsImage, BsCameraReels } from "react-icons/bs";
import { IoIosCopy } from "react-icons/io";
import axios from "axios";
import { FaDownload } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import VideoDownloaderSteps from "../../comp/VideoDownloaderSteps";
import NewFooter from "../../comp/NewFooter";

enum SELECTEDDOWNLOAD {
  VIDEO,
  REEL,
  IGTV,
  PHOTOS,
}

interface FACEBOOKTYPE {
  hd: string;
  sd: string;
  thumbnail: string;
  title: string;
  url: string;
}

interface TIKTOKTYPE {
  video: string;
  music: string;
  cover: string;
  description: string;
}

// interface INSTAGRAMPOSTTYPE {
//   url:string|null,
//   type?:string|null
// }

interface INSTADOWNLOADTYPE {
  post1?: string | null;
  post2?: string | null;
  post3?: string | null;
  post4?: string | null;
  post5?: string | null;
  image1?: string | null;
  image2?: string | null;
  image3?: string | null;
  image4?: string | null;
  title: string | null;
  image: string | null;
}

interface TWTYPE {
  created_at: string;
  description: string;
  isMedia: boolean;
  isVideo: boolean;
  HD_url: string | null;
  MD_url: string | null;
  SD_url: string | null;
  image_url: string;
}

interface LINKEDINAUTHOR {
  name: string;
}

interface LINKEDINIMAGE {
  url: string;
}

interface LINKEDINVIDEO {
  name: string;
  thumbnailUrl: string;
  uploadDate: string;
  description: string;
  contentUrl: string;
}

interface LINKEDINTYPE {
  headline: string | null;
  author: LINKEDINAUTHOR | null;
  datePublished: string | null;
  image: LINKEDINIMAGE | null;
  video: LINKEDINVIDEO | null;
}

declare global {
  interface Window {
    firstaman: any;
    aman: any;
  }
}

const VideoDownloaderPage = () => {
  const [selectedItem, setSelectedItem] = useState<SELECTEDDOWNLOAD>(
    SELECTEDDOWNLOAD.VIDEO
  );
  const [downloadData, setDownloadData] = useState<FACEBOOKTYPE | null>(null);
  const [downloadTwitterData, setDownloadTwitterData] = useState<TWTYPE | null>(
    null
  );
  const [downloadInstagramData, setDownloadInstagramData] =
    useState<INSTADOWNLOADTYPE | null>(null);
  const [downloadVimeoData, setDownloadVimeoData] =
    useState<INSTADOWNLOADTYPE | null>(null);
  const [downloadTikTok, setDownloadTikTok] = useState<TIKTOKTYPE | null>(null);
  const [downloadLinkedIN, setDownloadLinkedIn] = useState<LINKEDINTYPE | null>(
    null
  );
  const [errorMessage, setErrorMessage] = useState<boolean | null>(null);

  const [title, setTitle] = useState({
    title:
      "Download Video - Downloader and Files Hider App for free on Android.",
    description:
      "Video Downloader App gives you easy access to store files and manage downloads.It gives you other features like - Photo downloader and Music downloader.Enjoy private browser with ASD Video Downloader App.",
  });

  let [loading, setLoading] = useState<boolean>(false);

  const location = useLocation();
  const inputRef = useRef<HTMLInputElement>(null);

  const fetchSearchParam = () => {
    switch (location.pathname) {
      case "/apps/video-downloader-files-hider-app":
        setSelectedItem(SELECTEDDOWNLOAD.VIDEO);
        break;
      case "/apps/video-downloader-files-hider-app/photo-downloader":
        setTitle({
          title: "All photo downloader- ASD video downloader",
          description:
            "ASD Video Downloader app is the best, free & fast photo downloader. Just copy and paste your link to social media photos to start downloading and enjoying the app.",
        });
        setSelectedItem(SELECTEDDOWNLOAD.PHOTOS);
        break;
      default:
        setTitle({
          title:
            "Download Video - Downloader and Files Hider App for free on Android.",
          description:
            "Video Downloader App gives you easy access to store files and manage downloads.It gives you other features like - Photo downloader and Music downloader.Enjoy private browser with ASD Video Downloader App.",
        });
        setSelectedItem(SELECTEDDOWNLOAD.VIDEO);
        break;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    window.firstaman.onclick();
    fetchSearchParam();
  }, []);

  const fetchDownload = async (url: string) => {
    if (url.includes("facebook") || url.includes("fb")) {
      setErrorMessage(false);
      setLoading(true);
      setDownloadData(null);
      setDownloadInstagramData(null);
      setDownloadTikTok(null);
      setDownloadTwitterData(null);
      setDownloadLinkedIn(null);

      try {
        const fetchData = await axios.get<FACEBOOKTYPE>(
          `https://pantservice.co.in/rareprob/facebook?url=${url}&password=aman`
        );

        if (fetchData.status === 200) {
          const { hd, sd, thumbnail, title, url } = fetchData.data;
          setDownloadData({
            hd,
            sd,
            title,
            thumbnail,
            url,
          });
          setLoading(false);

          window.aman.onclick();
        } else if (fetchData.status === 302) {
          setLoading(false);
          setErrorMessage(true);
          window.aman.onclick();
        } else {
          setLoading(false);
          setErrorMessage(true);
        }
      } catch (error) {
        setLoading(false);
        setErrorMessage(true);
        window.aman.onclick();
      }
    } else if (url.includes("twitter")) {
      setErrorMessage(false);
      setLoading(true);
      setDownloadData(null);
      setDownloadInstagramData(null);
      setDownloadTikTok(null);
      setDownloadTwitterData(null);
      setDownloadLinkedIn(null);

      try {
        const fetchData = await axios.get(
          `https://pantservice.co.in/rareprob/twitter?url=${url}&password=aman`
        );
        if (fetchData.status === 200) {
          console.log(fetchData.data);

          const {
            created_at = "15 oct",
            description,
            isMedia,
            isVideo,
            HD_url,
            MD_url,
            SD_url,
            image_url,
          } = fetchData.data;
          setDownloadTwitterData({
            created_at,
            description,
            isMedia,
            isVideo,
            HD_url,
            MD_url,
            SD_url,
            image_url,
          });

          setLoading(false);
          window.aman.onclick();
        } else if (fetchData.status === 302) {
          setLoading(false);
          setErrorMessage(true);
          window.aman.onclick();
        } else {
          setLoading(false);
          setErrorMessage(true);
        }
      } catch (error) {
        setLoading(false);
        setErrorMessage(true);
        window.aman.onclick();
      }
    } else if (url.includes("instagram")) {
      setErrorMessage(false);
      setLoading(true);
      setDownloadData(null);
      setDownloadInstagramData(null);
      setDownloadTikTok(null);
      setDownloadTwitterData(null);
      setDownloadLinkedIn(null);

      try {
        const fetchData = await axios.get(
          `https://pantservice.co.in/rareprob/instagram?url=${url}&password=aman`
        );

        if (fetchData.status === 200) {
          const { json } = fetchData.data;

          const { headline, video, image } = json;

          if (video !== null || image !== null) {
            var images = {
              im1: image[0]?.url,
              im2: image[1]?.url,
              im3: image[2]?.url,
              im4: image[3]?.url,
            };

            var videos = {
              v1: video[0]?.contentUrl,
              v2: video[1]?.contentUrl,
              v3: video[2]?.contentUrl,
              v4: video[3]?.contentUrl,
              v5: video[4]?.contentUrl,
            };

            setDownloadInstagramData({
              post1: videos.v1,
              post2: videos.v2,
              post3: videos.v3,
              post4: videos.v4,
              post5: videos.v5,
              title: headline ?? null,
              image1: images.im1,
              image2: images.im2,
              image3: images.im3,
              image4: images.im4,
              image: video.length === 1 ? video[0].thumbnailUrl : image[0].url,
            });
          }

          setLoading(false);
          window.aman.onclick();
        } else if (fetchData.status === 302) {
          setLoading(false);
          setErrorMessage(true);
          window.aman.onclick();
        } else {
          setLoading(false);
          setErrorMessage(true);
        }
      } catch (error) {
        setLoading(false);
        setErrorMessage(true);
        window.aman.onclick();
      }
    } else if (url.includes("vimeo")) {
      setErrorMessage(false);
      setLoading(true);
      setDownloadData(null);
      setDownloadInstagramData(null);
      setDownloadTikTok(null);
      setDownloadTwitterData(null);
      setDownloadLinkedIn(null);

      try {
        const fetchData = await axios.get(
          `https://pantservice.co.in/rareprob/vimeo?url=${url}&password=aman`
        );

        if (fetchData.status === 200) {
          const { json } = fetchData.data;

          const { headline, video, image } = json;

          if (video !== null || image !== null) {
            console.log("GONE TO CHECK");
            images = {
              im1: image[0]?.url,
              im2: image[1]?.url,
              im3: image[2]?.url,
              im4: image[3]?.url,
            };

            videos = {
              v1: video[0]?.contentUrl,
              v2: video[1]?.contentUrl,
              v3: video[2]?.contentUrl,
              v4: video[3]?.contentUrl,
              v5: video[4]?.contentUrl,
            };

            setDownloadVimeoData({
              post1: videos.v1,
              post2: videos.v2,
              post3: videos.v3,
              post4: videos.v4,
              post5: videos.v5,
              title: headline ?? null,
              image1: images.im1,
              image2: images.im2,
              image3: images.im3,
              image4: images.im4,
              image: video.length === 1 ? video[0].thumbnailUrl : image[0].url,
            });
          }

          setLoading(false);
          window.aman.onclick();
        } else if (fetchData.status === 302) {
          setLoading(false);
          setErrorMessage(true);
          window.aman.onclick();
        } else {
          setLoading(false);
          setErrorMessage(true);
        }
      } catch (error) {
        setLoading(false);
        setErrorMessage(true);
        window.aman.onclick();
      }
    } else if (url.includes("linkedin")) {
      setErrorMessage(false);
      setLoading(true);
      setDownloadData(null);
      setDownloadInstagramData(null);
      setDownloadTikTok(null);
      setDownloadTwitterData(null);
      setDownloadLinkedIn(null);

      const fetchData = await axios.get(
        `https://pantservice.co.in/rareprob/linkedIn?url=${url}&password=aman`
      );

      if (fetchData.status === 200) {
        const { json } = fetchData.data;
        console.log(json);
        const { headline, video, image, datePublished, author } = json;

        setDownloadLinkedIn({
          headline,
          video,
          image,
          datePublished,
          author,
        });
        setLoading(false);
        window.aman.onclick();
        console.log(downloadLinkedIN);
      } else {
        setLoading(false);
        setErrorMessage(true);
        window.aman.onclick();
      }
    } else if (url.includes("tiktok")) {
      setErrorMessage(false);
      setLoading(true);
      setDownloadData(null);
      setDownloadInstagramData(null);
      setDownloadTikTok(null);
      setDownloadTwitterData(null);
      setDownloadLinkedIn(null);
      const options = {
        method: "GET",
        url: "https://tiktok-downloader-download-tiktok-videos-without-watermark.p.rapidapi.com/vid/index",
        params: {
          url: url,
        },
        headers: {
          "content-type": "application/octet-stream",
          "X-RapidAPI-Key":
            "8ee6c03e4cmsh1ed4748e763db0bp1946fbjsn75e4e0a83bb0",
          "X-RapidAPI-Host":
            "tiktok-downloader-download-tiktok-videos-without-watermark.p.rapidapi.com",
        },
      };

      try {
        const fetchData = await axios.request<TIKTOKTYPE>(options);
        if (fetchData.status === 200) {
          const { video, music, cover, description } = fetchData.data;
          setDownloadTikTok({
            video: video[0] ?? "",
            music: music[0] ?? "",
            cover: cover[0] ?? "",
            description: description[0] ?? "",
          });

          setLoading(false);
          window.aman.onclick();
        }
      } catch (error) {
        setLoading(false);
        setErrorMessage(true);
        window.aman.onclick();
      }
    } else {
      setLoading(false);
      setErrorMessage(true);
      window.aman.onclick();
    }
  };

  const downloadVideos = (
    url: string,
    type: string,
    image: boolean = false
  ) => {
    axios({
      method: "get",
      url: url,
      responseType: "blob",
    })
      .then(function (response) {
        let blob = new Blob([response.data], {
          type: image === true ? "image/jpeg" : "video/mp4",
        });

        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = image === true ? `${type}.jpeg` : type;
        link.click();
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title.title}</title>
        <meta name="description" content={title.description} />
      </Helmet>
      <nav>
        <Header color="bg-black" page="apps" />
      </nav>
      <main className="pt-32 mx-4 lg:mx-44 flex flex-col items-start  justify-start adsloadfirst">
        <a
          href="https://tinyurl.com/Rareprob-Video-Downloader"
          className="fixed z-50 bottom-10 lg:bottom-2 right-2 rounded-3xl px-7  drop-shadow-2xl
        py-3 text-white bg-gray-900 hover:scale-105 transition-all font-semibold"
        >
          Install App
        </a>

        <div className="flex items-center justify-start space-x-2">
          <img
            src="/images/icons/videoDownloader.webp"
            alt="Video Downloader"
            className="w-14 lg:w-20"
          />
          <div>
            <h2 className="text-xl font-medium lg:font-semibold  lg:text-3xl">
              Video Downloader <br />
            </h2>
            <p className="text-sm lg:text-lg font-light text-gray-600">
              Welcome to an era of unlimited entertainment!
            </p>
          </div>
        </div>
        <div className="w-full mt-10 flex flex-col items-center justify-between pb-4  space-y-10 rounded-3xl bg-gradient-to-b from-[#FF6526] to-[#FF0073]">
          <div className="flex items-center space-x-4 lg:space-x-20 justify-center text-white">
            <Link
              to={"/apps/video-downloader-files-hider-app"}
              className={`flex flex-col items-center 
               ${
                 selectedItem === SELECTEDDOWNLOAD.REEL
                   ? "text-[#FF6526] bg-[#FFFFFF] "
                   : " bg-none text-white"
               }
             py-5 -mt-2 lg:mt-0 px-5 lg:py-5 lg:px-8 rounded-b-xl drop-shadow-2xl
               space-y-2 justify-center `}
              onClick={() => {
                setSelectedItem(SELECTEDDOWNLOAD.REEL);
                window.aman.onclick();
              }}
            >
              <BsCameraReels className="text-2xl lg:text-3xl" />
              <p className="text-sm lg:text-lg">Reels</p>
            </Link>
            <Link
              to={"/apps/video-downloader-files-hider-app"}
              className={`flex flex-col items-center 
                ${
                  selectedItem === SELECTEDDOWNLOAD.VIDEO
                    ? "text-[#FF6526] bg-[#FFFFFF] "
                    : " bg-none text-white"
                }
                py-5 -mt-2 lg:mt-0 px-5 lg:py-5 lg:px-8 rounded-b-xl drop-shadow-2xl
                space-y-2 justify-center `}
              onClick={() => {
                setSelectedItem(SELECTEDDOWNLOAD.VIDEO);
                window.aman.onclick();
              }}
            >
              <FiVideo className="text-2xl lg:text-3xl" />
              <p className="text-sm lg:text-lg">Videos</p>
            </Link>
            <Link
              to="photo-downloader"
              className={` flex-col items-center  flex
               ${
                 selectedItem === SELECTEDDOWNLOAD.PHOTOS
                   ? "text-[#FF6526] bg-[#FFFFFF] "
                   : " bg-none text-white"
               }
               py-5 -mt-2 lg:mt-0 px-5 lg:py-5 lg:px-8 rounded-b-xl drop-shadow-2xl
               space-y-2 justify-center `}
              onClick={() => {
                setSelectedItem(SELECTEDDOWNLOAD.PHOTOS);
                window.aman.onclick();
              }}
            >
              <BsImage className="text-2xl lg:text-3xl" />
              <p className="text-sm lg:text-lg">Photos</p>
            </Link>

            <Link
              to="/apps/video-downloader-files-hider-app"
              className={`hidden lg:flex flex-col items-center 
                ${
                  selectedItem === SELECTEDDOWNLOAD.IGTV
                    ? "text-[#FF6526] bg-[#FFFFFF] "
                    : " bg-none text-white"
                }
               py-5 -mt-5 lg:mt-0 px-8 rounded-b-xl drop-shadow-2xl
                space-y-2 justify-center `}
              onClick={() => {
                setSelectedItem(SELECTEDDOWNLOAD.IGTV);
                window.aman.onclick();
              }}
            >
              <FiVideo className="text-2xl lg:text-3xl" />
              <p className="text-sm lg:text-lg">IGTV</p>
            </Link>
          </div>

          {loading === true ? (
            <div role="status">
              <svg
                aria-hidden="true"
                className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <></>
          )}

          {downloadData !== null && (
            <div className="flex mt-10 mb-10  w-1/2 items-center justify-center">
              <div className="flex flex-col lg:flex-row items-center lg:space-x-2  justify-center ">
                <img
                  src={downloadData?.thumbnail}
                  className="w-56 h-56 object-cover rounded-xl "
                  alt={downloadData?.title}
                />
                <div className="flex flex-col items-center justify-center lg:justify-start space-x-5">
                  <h3 className="text-center  mt-3 lg:text-left text-sm text-white font-medium w-full h-20 overflow-clip ">
                    {downloadData?.title}
                  </h3>

                  <div className="flex items-center  w-full mt-4 justify-center lg:justify-start space-x-3">
                    <button
                      onClick={() =>
                        downloadVideos(downloadData.hd, "facebook")
                      }
                      className="bg-gray-200 text-[#FF6526] text-xl font-bold px-10 py-1 rounded-lg"
                    >
                      HD
                    </button>
                    <button
                      onClick={() =>
                        downloadVideos(downloadData.sd, "facebook")
                      }
                      className="bg-gray-200 text-[#FF6526] text-xl font-bold px-10 py-1 rounded-lg"
                    >
                      SD
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {downloadLinkedIN !== null && (
            <div className="flex mt-10 mb-10  w-1/2 items-center justify-center">
              <div className="flex flex-col lg:flex-row items-center lg:space-x-2  justify-center ">
                <img
                  src={downloadLinkedIN.image?.url}
                  className="w-56 h-56 object-cover rounded-xl "
                  alt={downloadLinkedIN?.headline ?? ""}
                />
                <div className="flex flex-col items-center justify-center lg:justify-start space-x-5">
                  <h3 className="text-center  mt-3 lg:text-left text-sm text-white font-medium w-full h-20 overflow-clip ">
                    {downloadLinkedIN.video?.description}
                  </h3>

                  <div className="flex items-center  w-full mt-4 justify-center lg:justify-start space-x-3">
                    <button
                      onClick={() =>
                        downloadVideos(
                          downloadLinkedIN.video?.contentUrl ?? "",
                          "LinkedIn"
                        )
                      }
                      className="bg-gray-200 text-[#FF6526] text-xl font-bold px-10 py-1 rounded-lg"
                    >
                      HD
                    </button>
                    <button
                      onClick={() =>
                        downloadVideos(
                          downloadLinkedIN.image?.url ?? "",
                          "LinkedIn",
                          true
                        )
                      }
                      className="bg-gray-200 text-[#FF6526] text-xl font-bold px-10 py-1 rounded-lg"
                    >
                      Image
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {downloadTwitterData !== null && (
            <div className="flex mt-10 flex-col space-y-6 items-center justify-center">
              <div className="flex flex-col lg:flex-row items-center space-x-7  justify-center ">
                {downloadTwitterData.HD_url?.charAt(
                  downloadTwitterData.HD_url.length - 1
                ) === "g" ? (
                  <img
                    src={downloadTwitterData.HD_url ?? ""}
                    alt="twitter pic"
                    className="w-56 drop-shadow-2xl h-56 object-cover rounded-xl "
                  ></img>
                ) : (
                  <video
                    src={downloadTwitterData.HD_url ?? ""}
                    className="w-56 drop-shadow-2xl h-56 object-cover rounded-xl "
                  ></video>
                )}

                <h3
                  className="text-center lg:text-left text-white  text-sm font-medium w-40 mt-3  lg:w-96 h-20 overflow-clip "
                  onClick={() => console.log(downloadTwitterData)}
                >
                  {downloadTwitterData.description}
                </h3>
              </div>
              <div className="flex items-center justify-center space-x-5">
                <a
                  target={"_blank"}
                  rel="noreferrer"
                  href={downloadTwitterData.HD_url ?? ""}
                  onClick={() => {
                    if (downloadTwitterData.isVideo === true) {
                      downloadVideos(
                        downloadTwitterData.HD_url ?? "",
                        "Twitter"
                      );
                    } else {
                      downloadVideos(
                        downloadTwitterData.HD_url ?? "",
                        "Twitter",
                        true
                      );
                    }
                  }}
                  className="bg-gray-200 text-[#FF6526] text-xl font-bold px-10 py-1 rounded-lg"
                >
                  HD
                </a>
                <a
                  target={"_blank"}
                  rel="noreferrer"
                  href={downloadTwitterData.MD_url ?? ""}
                  onClick={() => {
                    if (downloadTwitterData.isVideo === true) {
                      downloadVideos(
                        downloadTwitterData.MD_url ?? "",
                        "Twitter"
                      );
                    } else {
                      downloadVideos(
                        downloadTwitterData.MD_url ?? "",
                        "Twitter",
                        true
                      );
                    }
                  }}
                  className="bg-gray-200 text-[#FF6526] text-xl font-bold px-10 py-1 rounded-lg"
                >
                  SD
                </a>
              </div>
            </div>
          )}

          {downloadInstagramData !== null && (
            <div className="flex mt-10 flex-col space-y-6 items-center justify-center">
              <div className="flex flex-col lg:flex-row items-center sapce-x-2  lg:space-x-4  justify-center ">
                <img
                  src={downloadInstagramData.image ?? ""}
                  alt="Instagram"
                  className="w-40 lg:w-56 h-40 lg:h-56 object-cover rounded-xl "
                ></img>
                <h3 className="text-center text-white mt-3 text-sm ml-3 font-medium w-24 lg:w-96 h-20 overflow-clip ">
                  {downloadInstagramData?.title}
                </h3>
              </div>
              <div className="flex items-center flex-col space-y-4 lg:space-y-0 lg:flex-row justify-center lg:space-x-5">
                {downloadInstagramData.post1 !== undefined ? (
                  <a
                    rel="noreferrer"
                    target={"_blank"}
                    href={downloadInstagramData.post1 ?? ""}
                    onClick={() =>
                      downloadVideos(
                        downloadInstagramData.post1 ?? "",
                        "Instagram"
                      )
                    }
                    className="bg-gray-200 text-[#FF6526] text-xl font-bold px-10 py-1 rounded-lg"
                  >
                    <span>Download 1</span>
                  </a>
                ) : downloadInstagramData.image1 !== undefined ? (
                  <button
                    onClick={() =>
                      downloadVideos(
                        downloadInstagramData.image1 ?? "",
                        "Instagram",
                        true
                      )
                    }
                    className="bg-gray-200 text-[#FF6526] text-xl font-bold px-10 py-1 rounded-lg"
                  >
                    <span>Download 1</span>
                  </button>
                ) : (
                  <></>
                )}

                {downloadInstagramData.post2 !== undefined ? (
                  <a
                    rel="noreferrer"
                    target={"_blank"}
                    href={downloadInstagramData.post2 ?? ""}
                    onClick={() =>
                      downloadVideos(
                        downloadInstagramData.post2 ?? "",
                        "Instagram"
                      )
                    }
                    className="bg-gray-200 text-[#FF6526] text-xl font-bold px-10 py-1 rounded-lg"
                  >
                    <span>Download 2</span>
                  </a>
                ) : downloadInstagramData.image2 !== undefined ? (
                  <button
                    onClick={() =>
                      downloadVideos(
                        downloadInstagramData.image2 ?? "",
                        "Instagram",
                        true
                      )
                    }
                    className="bg-gray-200 text-[#FF6526] text-xl font-bold px-10 py-1 rounded-lg"
                  >
                    <span>Download 2</span>
                  </button>
                ) : (
                  <></>
                )}

                {downloadInstagramData.post3 !== undefined ? (
                  <button
                    onClick={() =>
                      downloadVideos(
                        downloadInstagramData.post3 ?? "",
                        "Instagram"
                      )
                    }
                    className="bg-gray-200 text-[#FF6526] text-xl font-bold px-10 py-1 rounded-lg"
                  >
                    <span>Download 3</span>
                  </button>
                ) : downloadInstagramData.image3 !== undefined ? (
                  <button
                    onClick={() =>
                      downloadVideos(
                        downloadInstagramData.image3 ?? "",
                        "Instagram",
                        true
                      )
                    }
                    className="bg-gray-200 text-[#FF6526] text-xl font-bold px-10 py-1 rounded-lg"
                  >
                    <span>Download 3</span>
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          )}

          {downloadVimeoData !== null && (
            <div className="flex mt-10 flex-col space-y-6 items-center justify-center">
              <div className="flex flex-col lg:flex-row items-center sapce-x-2  lg:space-x-4  justify-center ">
                <img
                  src={downloadVimeoData.image ?? ""}
                  alt="Instagram"
                  className="w-40 lg:w-56 h-40 lg:h-56 object-cover rounded-xl "
                ></img>
                <h3 className="text-center text-white mt-3 text-sm ml-3 font-medium w-24 lg:w-96 h-20 overflow-clip ">
                  {downloadVimeoData?.title}
                </h3>
              </div>
              <div className="flex items-center flex-col space-y-4 lg:space-y-0 lg:flex-row justify-center lg:space-x-5">
                {downloadVimeoData.post1 !== undefined ? (
                  <a
                    href={downloadVimeoData.post1 ?? ""}
                    rel="noreferrer"
                    target={"_blank"}
                    onClick={() =>
                      downloadVideos(downloadVimeoData.post1 ?? "", "Vimeo")
                    }
                    className="bg-gray-200 text-[#FF6526] text-xl font-bold px-10 py-1 rounded-lg"
                  >
                    <span>HD</span>
                  </a>
                ) : downloadVimeoData.image1 !== undefined ? (
                  <button
                    onClick={() =>
                      downloadVideos(
                        downloadVimeoData.image1 ?? "",
                        "Vimeo",
                        true
                      )
                    }
                    className="bg-gray-200 text-[#FF6526] text-xl font-bold px-10 py-1 rounded-lg"
                  >
                    <span>Download </span>
                  </button>
                ) : (
                  <></>
                )}

                {downloadVimeoData.post2 !== undefined ? (
                  <a
                    href={downloadVimeoData.post2 ?? ""}
                    rel="noreferrer"
                    target={"_blank"}
                    onClick={() =>
                      downloadVideos(downloadVimeoData.post2 ?? "", "Vimeo")
                    }
                    className="bg-gray-200 text-[#FF6526] text-xl font-bold px-10 py-1 rounded-lg"
                  >
                    <span> SD</span>
                  </a>
                ) : downloadVimeoData.image2 !== undefined ? (
                  <button
                    onClick={() =>
                      downloadVideos(
                        downloadVimeoData.image2 ?? "",
                        "Instagram",
                        true
                      )
                    }
                    className="bg-gray-200 text-[#FF6526] text-xl font-bold px-10 py-1 rounded-lg"
                  >
                    <span>Download HD</span>
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          )}

          {downloadTikTok !== null && (
            <div className="flex mt-10 flex-col space-y-6 items-center justify-center">
              <div className="flex lg:flex-row flex-col items-center space-x-7  justify-center ">
                <img
                  src={downloadTikTok.cover ?? ""}
                  alt="Tik Tok"
                  className="w-56 h-56 object-cover rounded-xl "
                ></img>
                <h3
                  className="text-center text-white  text-sm font-medium w-40  mt-4 lg:w-96 h-20 overflow-clip "
                  onClick={() => console.log(downloadTwitterData)}
                >
                  {downloadTikTok.description}
                </h3>
              </div>
              <div className="flex items-center justify-center space-x-5">
                <a
                  target={"_blank"}
                  rel="noreferrer"
                  href={downloadTikTok.video ?? ""}
                  // onClick={()=>downloadVideos(downloadTwitterData.HD_url,"Twitter")}
                  className="bg-gray-200 text-[#FF6526] px-5 py-1 rounded-xl"
                >
                  HD
                </a>
                <a
                  target={"_blank"}
                  rel="noreferrer"
                  href={downloadTikTok.music ?? ""}
                  //  onClick={()=>downloadVideos(downloadTwitterData.MD_url,"Twitter")}
                  className="bg-gray-200 text-[#FF6526] px-5 py-1 rounded-xl"
                >
                  MP3
                </a>
              </div>
            </div>
          )}

          {/*TODO SERACHBAR  */}
          <div className="bg-white h-16 flex relative space-x-2 lg:space-x-10 items-center justify-between px-2 lg:px-6  w-[80%] rounded-2xl">
            {errorMessage === null || errorMessage === false ? (
              <></>
            ) : (
              <div className="absolute   text-white rounded-2xl  h-44 flex flex-col items-center justify-center w-44 -left-10 -bottom-52">
                <img
                  src="/images/videoDownloader/icons-06.png"
                  alt="Error images"
                  className="w-56 absolute  -top-4"
                />
                <h2 className="text-center z-20 text-white  w-32  text-[12px]">
                  Please enter a valid link from Instagram, Facebook, Twitter,
                  Tiktok or try again later
                </h2>
              </div>
            )}
            <IoIosCopy
              size={40}
              className="cursor-pointer"
              onClick={() => {
                navigator.clipboard
                  .readText()
                  .then((text) => {
                    if (inputRef.current !== undefined) {
                      inputRef.current!.value = text.toString();
                    }
                  })
                  .catch((err) => {
                    console.error("Failed to read clipboard contents: ", err);
                  });
              }}
            />
            <input
              type="text"
              ref={inputRef}
              onChange={() => setErrorMessage(false)}
              onFocus={() => setErrorMessage(false)}
              placeholder="Paste the link here to download  "
              className="outline-none border-none w-full  h-10 "
            />
            <FaDownload
              size={40}
              className="cursor-pointer"
              onClick={() =>
                fetchDownload(inputRef.current?.value.toString() ?? "")
              }
            />
          </div>
        </div>

        {/* TODO DOWNLOAD DATA */}
        <h3 className="mt-14 text-xl font-semibold">Supported Sources</h3>
        <div className="flex items-center  mx-auto mt-10  rounded-xl px-2 lg:px-10  w-full  ">
          <div className="grid lg:grid-cols-4 grid-cols-2 gap-10  items-center  justify-between w-full  ">
            <a
              href="https://www.facebook.com/watch"
              target="_blank"
              rel="noreferrer"
              className="bg-gray-200 px-4 rounded-xl flex items-center justify-center space-x-2"
            >
              <img
                src="/images/videoDownloader/fb-watch-downloader.png"
                alt="Facebook"
                className="w-10 h-10 object-cover"
              />
              <p className="text-xs font-medium">Facebook Watch</p>
            </a>
            <a
              href="https://www.facebook.com/"
              target="_blank"
              rel="noreferrer"
              className="bg-gray-200 px-4 rounded-xl flex items-center justify-center space-x-2"
            >
              <img
                src="/images/videoDownloader/facebook-downloader.png"
                alt="Facebook"
                className="w-10 h-10 object-cover"
              />
              <p className="text-xs font-medium">Facebook </p>
            </a>
            <a
              href="https://www.instagram.com/"
              target="_blank"
              rel="noreferrer"
              className="bg-gray-200 px-4 rounded-xl flex items-center justify-center space-x-2"
            >
              <img
                src="/images/videoDownloader/instagram-downloader.png"
                alt="Instagram"
                className="w-10 h-10 object-cover"
              />
              <p className="text-xs font-medium">Instagram</p>
            </a>
            <a
              href="https://twitter.com/"
              target="_blank"
              rel="noreferrer"
              className="bg-gray-200 px-4 rounded-xl flex items-center justify-center space-x-2"
            >
              <img
                src="/images/videoDownloader/Twitter-downloader.png"
                alt="Twitter"
                className="w-10 h-10 object-cover"
              />
              <p className="text-xs font-medium">Twitter</p>
            </a>
            <a
              href="https://www.tiktok.com/"
              target="_blank"
              rel="noreferrer"
              className="bg-gray-200 px-4 rounded-xl flex items-center justify-center space-x-2"
            >
              <img
                src="/images/videoDownloader/tiktok-downloader.png"
                alt="Tik Tok"
                className="w-10 h-10 object-cover"
              />
              <p className="text-xs font-medium">TikTok</p>
            </a>
            <a
              href="https://www.linkedin.com/feed/"
              target="_blank"
              rel="noreferrer"
              className="bg-gray-200 px-4 rounded-xl flex items-center justify-center space-x-2"
            >
              <img
                src="/images/videoDownloader/Artboard 1.png"
                alt="LinkedIn"
                className="w-10 h-10 object-cover"
              />
              <p className="text-xs font-medium">LinkedIn</p>
            </a>
          </div>
        </div>

        <VideoDownloaderSteps />

        <div className="h-40"></div>
      </main>
      <NewFooter color="bg-black" />
    </>
  );
};

export default VideoDownloaderPage;
