import React, { useEffect } from "react";
import FooterHub from "../../comp/FooterHub";
import { Helmet } from "react-helmet";
import Header from "../../comp/Header";
import YouTube from "react-youtube";

const MusicBlog = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const opts = {
    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Music player for all types of audio and Video Formats | Music Player
          App
        </title>
        <meta
          name="description"
          content="Learn more about our Music Player App. Explore the features like- HD audio, In-built Radio, Ringtone Cutter, and multiple themes in our Blog."
        />
      </Helmet>
      <Header color="bg-purple-800" page="blogs" />
      <main className="w-full text-lg px-6 overflow-hidden md:px-44 text-black mx-auto pt-20 bg-white">
        <h1 className="text-5xl text-center font-bold mt-10">
          ASD Music Player : All format Mp3 and Mp4 Player
        </h1>

        <img
          src="https://img.rareprob.com/img/website/blogs/music/featured%20graphics_%20new%20change%20maker.webp"
          alt="Music Player : Audio and Video Formats"
          className="max-w-full hover:shadow-[0_0px_60px_1px_rgba(0,0,0,0.2)]   shadow-[0_0px_10px_6px_rgba(0,0,0,0.11)]  sm:max-h-[500px] 2xl:max-h-[400px] mx-auto  mt-10 rounded-xl object-contain"
        />
        <p className="mt-10">
          Nowadays, Music and movies are the only sources of entertainment and
          most people prefer to play media files on their smartphones. If you
          are trying hard to find an android music application that lets you
          enjoy multiple features. Then,{" "}
          <a href="/" className="text-blue-600 font-medium">
            Music Player
          </a>{" "}
          has to be your preference.{" "}
        </p>
        <p>
          <a
            href="https://play.google.com/store/apps/details?id=com.rocks.music"
            rel="noreferrer"
            target={"_blank"}
            className="font-medium text-blue-600"
          >
            Music player
          </a>{" "}
          is jam-packed with prime features like an HD audio and video player, a
          video to MP3 converter, an in-built radio, trending videos, and more.
        </p>
        <div className="flex items-center justify-center">
          <YouTube
            videoId="mhkocb9y7v8"
            id="videoPlay"
            className=" mx-auto  mt-10 rounded-xl object-cover "
            opts={opts}
            onReady={() => console.log("Ready to Play")}
          />
        </div>
        <h1 className="text-3xl font-medium mt-10">
          HD Audio and Video Player
        </h1>
        <p className="mt-2">
          Music player is built with fine HD audio and video player so that
          you'll have the best audio and video experience. This Music Player
          supports multiple formats such as{" "}
          <strong className="text-black">MP3, MIDI, FLAC, AAC</strong>, etc.
          Also, you can play files of multiple formats such as{" "}
          <strong className="text-black">8K, 4K, Mp4, AVI, M4V,</strong> etc.{" "}
        </p>
        <p>
          You don't have to install multiple apps to carry out your needs, you
          can watch movies with a high-quality resolution and if you don’t
          prefer to watch movies much but are fond of music, then enjoy{" "}
          <a
            href="https://play.google.com/store/apps/details?id=com.rocks.music"
            className="text-blue-600 font-medium"
          >
            audio player
          </a>{" "}
          functionality to have the best music experience.
        </p>
        <div className="flex justify-center items-center space-x-4 md:space-x-20 mt-10">
          <img
            loading={"lazy"}
            src="https://img.rareprob.com/img/website/blogs/music/hd%20audio_.webp"
            alt="HD Audio Player"
            className="w-44 md:w-72"
          />
          <img
            loading={"lazy"}
            src="https://img.rareprob.com/img/website/blogs/music/vedio%20player.webp"
            alt="HD Video Player"
            className="w-44 md:w-72"
          />
        </div>
        <h1 className="text-3xl font-medium mt-10">In-Built Radio</h1>
        <p className="mt-2">
          To have in-built radio in the music player app is like icing on the
          cake. With this feature, you can enjoy more than{" "}
          <strong className="text-black"> 200 online radio stations</strong>{" "}
          along with multiple features. You can listen to live music, news based
          on country- wise radio stations. The best part of this in-built radio
          is that it comes equipped with an{" "}
          <strong className="text-black">audio recorder</strong> so that any
          radio station can be recorded and listened to later.
        </p>
        <div className="flex justify-center items-center space-x-4 md:space-x-20 mt-10">
          <img
            loading={"lazy"}
            src="https://img.rareprob.com/img/website/blogs/music/in%20build%20radio.webp"
            alt="In-Built Radio "
            className="w-44 md:w-72"
          />
          <img
            loading={"lazy"}
            src="https://img.rareprob.com/img/website/blogs/music/audio%20recoder.webp"
            alt="In-Built Radio Player"
            className="w-44 md:w-72"
          />
        </div>
        <h1 className="text-3xl font-medium mt-10">CALM SLEEP</h1>
        <p className="mt-4">
          Relax your mind with Calm Sleep Music. Music Player is designed with
          Calm Sleep functionality that lets you listen to soothing music for a
          relaxed sleep. You can explore multiple calm sleep music categories
          like Lullabies, love, rain, Lo-Fi, Instrumental, acoustic, stress
          buster, meditation, flute, soft-pop, happy music, and more.
        </p>
        <img
          loading={"lazy"}
          src="https://img.rareprob.com/img/website/blogs/music/Group%201%20calm%20sleep%20mo.webp"
          alt="Calm Sleep Music"
          className="mx-auto w-44 md:w-72 mt-8"
        />

        <h1 className="text-3xl font-medium mt-10">Video to Mp3 Converter</h1>
        <p className="mt-2">
          We believe, not everyone prefers to watch videos while listening to
          music and in that case, you can extract audio from the video files to
          enjoy Mp3. This feature of{" "}
          <a
            href="/apps/music-player-mp4-mp3-player-app"
            className="text-blue-600 font-medium"
          >
            Music Player
          </a>{" "}
          lets you convert your video file into mp3. All you have to do is
          choose the video from your media files and extract the audio from it.
        </p>
        <img
          loading={"lazy"}
          src="https://img.rareprob.com/img/website/blogs/music/vedio%20to%20mp3%20converter.webp"
          alt="Video to Mp3 Converter"
          className="mx-auto w-44 md:w-72 mt-8"
        />
        <h1 className="text-3xl font-medium mt-20">Trending Videos</h1>
        <p className="mt-2">
          This feature of Music Player is all about the latest videos. It lets
          you stay up to date on what’s popular in music, gaming, beauty, news,
          learning and more. Also, you can explore{" "}
          <strong className="text-black">what’s trending</strong> all around the
          world. You can use this feature for entertainment, education, craft
          learning, skill development and more.
        </p>
        <h1 className="text-3xl font-medium mt-10">Ringtone Cutter</h1>
        <p className="mt-2">
          {" "}
          Do you enjoy switching up your ringtones? If that's the case, this
          feature is ideal for you. You no longer need to look for multiple
          ringtones because Ringtone cutter allows you to make a ringtone from
          your <strong className="text-black">
            Android's music playlist
          </strong>{" "}
          . You just have to select the song from your playlist and trim it
          accordingly.
        </p>
        <img
          loading={"lazy"}
          src="https://img.rareprob.com/img/website/blogs/music/ringtone%20cutter.webp"
          alt="ringtone cutter"
          className="mx-auto w-44 md:w-72 mt-8"
        />
        <h1 className="text-3xl font-medium mt-10">Multiple Themes</h1>
        <p className="mt-2">
          We know how important your mood is for you and that is why Music
          Player built with multiple theme functionality. You can apply theme to
          your <strong className="text-black">player’s screen</strong> according
          to your preference.
        </p>
        <p>
          The{" "}
          <strong className="text-black">
            application, as well as the inbuilt audio player
          </strong>{" "}
          application, can be customised with a variety of fascinating themes.
        </p>
        <div className="flex justify-center items-center space-x-4 md:space-x-20 mt-10">
          <img
            loading={"lazy"}
            src="https://img.rareprob.com/img/website/blogs/music/multiple%20theme.webp"
            alt="Multiple Themes"
            className="w-44 md:w-72"
          />
          <img
            loading={"lazy"}
            src="https://img.rareprob.com/img/website/blogs/music/music%20theme.webp"
            alt="Multiple Themes"
            className="w-44 md:w-72"
          />
        </div>

        <h1 className="text-3xl font-medium mt-10">
          Sleep Timer and Equalizer
        </h1>
        <p className="mt-2">
          Music Player supports these two prime features as well. Sleep timer
          automatically pauses the media player on your smartphone after a set
          period so you can go to sleep listening to music.
        </p>
        <p>
          Equalizer helps to customize the type of sounds you want to enjoy. If
          you like more bass or more of a vocal presence, this feature can help
          you out.
        </p>
        <div className="flex justify-center items-center space-x-4 md:space-x-20 mt-10">
          <img
            loading={"lazy"}
            src="https://img.rareprob.com/img/website/blogs/music/sleep%20timer_.webp"
            alt="sleep timer"
            className="w-44 md:w-72"
          />
          <img
            loading={"lazy"}
            src="https://img.rareprob.com/img/website/blogs/music/equalizer.webp"
            alt="eqalizer"
            className="w-44 md:w-72"
          />
        </div>
        <div className="h-44"></div>
        <FooterHub textBlack={true} />
      </main>
    </>
  );
};

export default MusicBlog;
