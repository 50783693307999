import React from 'react'
import { Link } from 'react-router-dom'

const PageNotFound = () => {
  return (
    <div className='flex flex-col items-center justify-center h-screen'>
       <img src="/images/errorpage.jpg" alt="error page" className='w-[80%] md:w-[40%] '/>
       <button className='px-4 bg-indigo-600 text-white font-bold py-2 rounded-xl'>
       <Link to="/"> Go to home</Link>
       </button>
    </div>
  )
}

export default PageNotFound