import React, { useEffect } from "react";
import FooterHub from "../../comp/FooterHub";
import Header from "../../comp/Header";
import { BlogImage, BlogLink, TargetLink } from "../../comp/Util";
import { Helmet } from "react-helmet";

const FathersDayBlog = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Discover Origins & Meaning of Father's Day Celebration | RareProb
        </title>
        <meta
          name="description"
          content="Join us as we explore the history and meaning of Father's Day. Learn why and when this heartwarming holiday is celebrated worldwide with RareProb."
        />
      </Helmet>
      <Header color="bg-purple-800" page="blogs" />
      <main className="w-full text-lg px-6 overflow-hidden md:px-64 text-black mx-auto pt-20 bg-white">
        <h1
          id="benefits-video-downloading"
          className="text-3xl md:text-5xl mt-10 font-bold text-center"
        >
          Let's Find Out How, Why & When Fathers' Day is Celebrated
        </h1>
        {/* <p className='mt-2 text-center'><i>Find how the ASD Gallery app is packed with all essential photo-enhancing features that don’t make your pictures look unreal!</i></p> */}
        <img
          src="https://img.rareprob.com/img/website/blogs/christmas/father-day/fathers-day-concept-with-daughter(1).webp"
          alt=" Let's Find Out How, Why & When Fathers' Day is Celebrated"
          className=" max-w-full hover:shadow-[0_0px_60px_1px_rgba(0,0,0,0.2)]   shadow-[0_0px_10px_6px_rgba(0,0,0,0.11)]  sm:max-h-[500px] 2xl:max-h-[500px] mx-auto  mt-10 rounded-xl object-contain"
        />
        <article className="mt-10">
          <p>
            Fathers are role models for their children, they inspire them,
            provide for them, and teach them values, to grow in life. You can
            never replace this man🧔from your life whenever you need something,
            they are the first person you can rely on, and even without saying
            they understand the desires of your heart. <br /> <br />
            This Father’s Day honors fathers who carry out their
            responsibilities boldly. When we are in any problem we first turn to
            our fathers as they protect us and provide meaningful life messages.{" "}
            <br /> <br />
            <i>
              By the way, there is a contest running on Father's Day 🤩 on our{" "}
              <TargetLink link="https://www.instagram.com/rareprob_/?next=%2F">
                Instagram
              </TargetLink>{" "}
              channel, so stay tuned, at the end of this article we will let you
              know how to participate and win exciting prizes 🎁,
            </i>
          </p>
        </article>

        <article>
          <h1 className="text-xl md:text-3xl mt-8 font-bold ">
            But have you wondered Why The First Father's Day was celebrated?
          </h1>{" "}
          <BlogImage
            image="https://img.rareprob.com/img/website/blogs/christmas/father-day/medium-shot-smiley-family-outside%20(1).webp"
            alt="But have you wondered When The First Father's Day was celebrated"
          />
          {/* <BlogImage alt={"Colour correction"} 
image="/images/blogs/gallery/tenGalleryFeatures/gallerySaturation.webp"/> */}
          <p className="mt-4">
            Well, there are many legends that seem to have started the occasion.
            According to the first story, <strong>Miss Sonora Smart</strong>{" "}
            initiated the tradition of celebrating Father’s Day on the death
            anniversary of her father <strong>(Mr. William Smart)</strong>, a
            civil war veteran. <br /> <br />
            Mr. William Smart raised 6 children by himself when the mother of
            his children died while giving birth to their sixth child. Sanora
            Smart wanted to honour the dedication and sacrifice of her father
            and succeeded when Washington State celebrated the{" "}
            <strong>first Father’s Day on 19 June 1910</strong>. <br /> <br />
            However, no later than that, Mother's Day gained momentum right
            away, and it took a lot of years for Father’s Day to be nationally
            recognized as a holiday. <br /> <br />
            Father's Day became a <strong>federal holiday in 1916</strong>{" "}
            thanks to the support of President Wilson and Calvin Coolidge. And
            finally, in 1966, Father's Day was officially declared on the{" "}
            <strong>Third Sunday in June</strong> by President Lyndon B.
            Johnson. The second account claims that Fairmont, West Virginia, was
            the location of the first Father's Day celebration in America. It
            was initially observed on July 5, 1908, in memory of the 361 workers
            who died in a coal mine explosion in 1907. <br /> <br />
            The second account claims that Fairmont, West Virginia, is where
            Father's Day was first observed in America. In honor of the 361 men
            who lost their lives in a coal mine explosion in 1907, it was first
            observed on July 5, 1908. <br /> <br />
          </p>
        </article>

        <article>
          <h1 className="text-xl md:text-3xl mt-8 font-bold ">
            Father’s Day is celebrated on different days in many places
          </h1>
          {/* <BlogImage
alt={"download videos from TikTok"}
image="/images/blogs/videoDownloader/download/rare-tikTokWeb.webp"
/> */}
          <p className="mt-4">
            It is celebrated on different days in different countries. In
            countries like India, the US, the UK, France, Mexico, Canada, and
            Venezuela, Father’s Day is celebrated on the third Sunday of June.{" "}
            <br />
            <br />
            Whereas in countries such as New Zealand and Australia Father’s Day
            is celebrated on the First Sunday of September. In Thailand Father’s
            Day is celebrated on 5 December and on this same day the King of
            Thailand's birthday is celebrated.
          </p>
        </article>

        <article>
          <h1 className="text-xl md:text-3xl mt-8 font-bold ">
            Here are some Interesting Father’s Day Ideas to Surprise Your
            Fathers
          </h1>
          {/* <BlogImage
            alt={"What is Saturation?"}
            image="/images/blogs/gallery/rare-GalleryCorrection.webp"
          /> */}
          <p className="mt-4">
            Father's Day gifts must be special, meaningful, and useful to him.
            All dads throughout the year inspire, love, and care for us and you
            know they deserve the best.{" "}
            <strong>
              So here are some Father’s Day ideas on how you can surprise your
              father with the most beautiful and useful Father’s day gifts.
            </strong>{" "}
            <br /> <br />
            Finding a perfect Father’s Day gift is the most amazing experience
            on Father’s Day and when you make your own it's fun and it holds a
            lot more value.
          </p>
        </article>

        <article>
          <h1 className="text-lg md:text-xl mt-8 font-bold ">
            1. Handmade Father’s Day Craft
          </h1>
          <BlogImage
            alt={"Handmade Father’s Day Craft"}
            image="https://img.rareprob.com/img/website/blogs/christmas/father-day/close-up-father-with-greeting-card.webp"
          />
          <p className="mt-4">
            Create a beautiful handmade gift 🎁 as Father’s Day card ideas are
            always special. You can make these beautiful Father’s day card with
            your own artwork on this Father’s Day. Take any piece of art that
            you drew for your Dad and take a photo of it to turn it into custom
            wall art, photo book, coffee mugs, etc.{" "}
            <strong>
              It will be a memory of a lifetime and the most precious gift your
              dad will ever receive.
            </strong>{" "}
            <br /> <br />
          </p>

          <h1 className="text-lg md:text-xl mt-8 font-bold ">
            2. Create a Playlist
          </h1>
          <BlogImage
            alt={"Create a Playlist"}
            image="https://img.rareprob.com/img/website/blogs/christmas/father-day/fathers-day-concept-with-father-daughter-listening-music.webp"
          />
          <p className="mt-4">
            Create a good playlist of all your dad’s favorite songs for Father
            Day. Make this your own jamming session 🎉 with your dad and other
            family members. <br /> <br />
            You can select songs that remind you of some of your dad’s memories
            of you both have spent together such as party songs, your first trip
            together, and all hands down Dad’s funny quotes & jokes.
            <br /> <br />
            <h1 className="text-lg md:text-xl mt-8 font-bold ">
              3. Photo Collage
            </h1>
            <BlogImage
              alt={"Photo Collage"}
              image="https://img.rareprob.com/img/website/blogs/christmas/father-day/family-photobook-about-easter-easter-card-with-love.webp"
            />
            <p className="mt-4">
              There are so many special memory with our Dads that we wish to
              live on forever. There are so many father’s day images we have
              already celebrated, then fun family trips, vacations, and
              festivals images. Compile all your favorite photos with Dad and
              create a photo collage🖼️ as a Father’s Day gift. <br /> <br />
              <strong>
                In fact, you can use the{" "}
                <BlogLink
                  link="/apps/gallery-photo-editor-and-collage-maker-app"
                  text="Gallery app "
                />{" "}
                to create beautiful collages with your father
              </strong>
              . This HD collage can easily be created with the gallery app and
              you can even print out and use in your dad’s office. And did we
              tell you yet that you can even post this collage on Instagram to
              participate in the contest?
              <br /> <br />
            </p>
            <h1 className="text-lg md:text-xl mt-8 font-bold ">
              4. Have Karaoke Night
            </h1>
            <BlogImage
              alt={"Have Karaoke Night"}
              image="https://img.rareprob.com/img/website/blogs/christmas/father-day/cheerful-young-woman-singing-her-friends-party-room-with-neon-lights-smoke.webp"
            />
            <p className="mt-4">
              Tired of looking for Father’s day gifts? What about Karaoke Night
              with your dad 🎤? So dim the lights, create a beautiful setup, and
              make a list of Karaoke songs in multiple languages using ASD Rocks
              Video Player app and voila you are all set for the musical night
              with your dad. You can sing many beautiful Father’s Day songs as
              to surprise him and make him dance as well.
              <br /> <br />
            </p>
            <h1 className="text-lg md:text-xl mt-8 font-bold ">
              5. Cooking Time
            </h1>
            {/* <BlogImage
              alt={"Cooking Time"}
              image="/images/blogs/christmas/father-day/cheerful-young-woman-singing-her-friends-party-room-with-neon-lights-smoke.webp"
            /> */}
            <p className="mt-4">
              There is a saying Summertime means grilling time 🧑‍🍳. Give your
              father a personalized apron that'll protect him from any
              accidental drips or splatters. Also,{" "}
              <strong>
                browse through different recipes on the ASD Video Player app and
                choose to cook your favourite dishes with your dad.
              </strong>
              <br /> <br />
            </p>
            <h1 className="text-lg md:text-xl mt-8 font-bold ">
              6. Pamper him for one whole day
            </h1>
            {/* <BlogImage
              alt={"Cooking Time"}
              image="/images/blogs/christmas/father-day/cheerful-young-woman-singing-her-friends-party-room-with-neon-lights-smoke.webp"
            /> */}
            <p className="mt-4">
              He works hard day and night but for one day he deserves a break,
              pampering right? So make his morning with breakfast in bed, cook
              his favorite dish, or even a dessert 🥞 and celebrate Father’s
              Day. And take him on a nostalgic trip by putting beautiful flowers
              in a tray or a beautiful Father’s Day card.
              <br /> <br />
            </p>
            <h1 className="text-lg md:text-xl mt-8 font-bold ">
              7. Throw a House Party
            </h1>
            <BlogImage
              alt={"Throw a House Party"}
              image="https://img.rareprob.com/img/website/blogs/christmas/father-day/family-members-sitting-together-full-shot.webp"
            />
            <p className="mt-4">
              Most people go out to celebrate for Father’s Day which is not
              wrong to go to their favorite food joints but house parties are
              more personal. You can invite his friends, order his favorite
              food, play fun activities like cards, and dumb charades, and even
              make a theme party 🥳 that guests will also enjoy.
              <br /> <br />
            </p>
            <h1 className="text-lg md:text-xl mt-8 font-bold ">
              8. Set Up an Outdoor Movie Night
            </h1>
            {/* <BlogImage
              alt={"Cooking Time"}
              image="/images/blogs/christmas/father-day/cheerful-young-woman-singing-her-friends-party-room-with-neon-lights-smoke.webp"
            /> */}
            <p className="mt-4">
              Family movie nights are an awesome chance to spend time together,
              and maybe this could be what your dad would love the most on
              Father’s Day. You can pick 2 or 3 favorite movies of your dad, and
              create a cozy setup with lights and some decoration so you can sit
              comfortably. And of course, don't forget snacks, popcorn🍿, and
              beverages 🍹 for movie time.
              <br /> <br />
            </p>
            <h1 className="text-lg md:text-xl mt-8 font-bold ">
              8. Bake a Father’s Day Cake
            </h1>
            <BlogImage
              alt={"Bake a Father’s Day Cake"}
              image="https://img.rareprob.com/img/website/blogs/christmas/father-day/father-kid-high-five-medium-shot.webp"
            />
            <p className="mt-4">
              This is the perfect ending to celebrate Father’s Day. You can bake
              a theme cake like a Superman theme cake or Father’s day cake
              design for your dad that says, - I Love You, Dad, or You are my
              Superhero. Or how about baking cute cupcakes 🧁 as well designed
              with Father’s Day written on it.
              <br /> <br />
            </p>
            With these surprise ideas, you can make this Father’s Day
            unforgettable for your Superhero - Dad. It is important to choose
            the right words or ways to show your love, care, and respect to the
            important person in your life. So take advantage of the day you have
            with your dad and savor every second you have together. <br />{" "}
            <br />
            And finally ending your wait for the contest!! You can participate
            in the Father’s Day contest hosted by{" "}
            <BlogLink link="/" text="Rareprob" /> on Instagram. All you need to
            do is share a picture of you with your father after adding stickers
            from the Gallery app and four lucky winners will receive amazing
            prizes which include movie tickets, gift hampers, cashback, and
            more.
          </p>
        </article>
        <div className="h-20"></div>
        <FooterHub textBlack={true} />
      </main>
    </>
  );
};

export default FathersDayBlog;
