import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import FooterHub from "../../comp/FooterHub";
import Header from "../../comp/Header";
import NewFooter from "../../comp/NewFooter";

const GalleryScreen = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Gallery | Best Gallery Photo Editor Free for Android | Rareprob</title>

        <meta
          name="description"
          content="Download the top Gallery app for free on Android. ASD Gallery photo editor is the fastest, lightweight, most stable app for editing your photo, videos, albums, GIFs!"
        />
      </Helmet>
      <nav className="z-50 fixed">
        <Header color={"bg-red-800"} page="app" />
      </nav>
      <img
        loading={"lazy"}
        src="/images/galleryScreen/Group 286.svg"
        alt="Gallery: Photos Editor and Collage Maker App for Android"
        className="absolute  md:w-[700px] z-10 top-0 right-0"
      />

      <main className="bg-gradient-to-t from-[#BE2B26] via-[#A5332F] to-[#B12722] pt-20 text-white ">
        <h1 className="text-5xl font-bold text-center text-white">GALLERY  PHOTO EDITOR</h1>
        {/* <h1 className="text-xl font-bold text-center text-white md:text-2xl mt-4">
         
        </h1> */}
        <img
          loading={"lazy"}
          src="/images/galleryScreen/Group 319@2x.png"
          alt="Gallery: Photos Editor and Collage Maker App for Android"
          className="w-[300px] md:w-[900px]  z-30 relative mx-auto"
        />
        <h2 className="text-4xl font-bold text-center text-white">ABOUT APP</h2>
        <p className="text-center font-medium mt-4 text-xl w-[300px] md:w-[700px] mx-auto">
          Photo Editor and{" "}
          <a
            href="/blogs/gallery-photo-editor-collage-maker-app"
            className="text-blue-400 font-medium"
          >
            Collage Maker Gallery App
          </a>{" "}
          with gallery-vault features is an excellent app for android devices
          supporting various features.
        </p>
        <div className="w-3/4 mx-auto mt-20">
          <h2 className="text-3xl font-bold ">KEY FEATURES</h2>

          <div className="grid grid-cols-1 md:grid-cols-2 mt-10 gap-20">
            <div className="flex items-center justify-center space-x-4">
              <img
                loading={"lazy"}
                src="/images/galleryScreen/Group edit.png"
                alt="Photos Editor"
                className="w-16"
              />

              <h3>
                <strong>Photo Editor</strong> <br /> Make you photos look
                Picture perfect with Gallery app. To make your photos look
                finely edited, you can try ohoto editing with with amusing
                filters, neons, funky stickers and alot more features.{" "}
              </h3>
            </div>
            <div className="flex items-center justify-center space-x-4">
              <img
                loading={"lazy"}
                src="/images/galleryScreen/Group 297.svg"
                alt="Collage Maker"
                className="w-16"
              />
              <h3>
                <strong>Collage Maker</strong> <br /> Create a collage with 2 to
                9 pics Set border and round shape on photos shuffle the layout,
                recreate option for the collage in the same screen with multiple
                layout support
              </h3>
            </div>
            <div className="flex items-center justify-center space-x-4">
              <img
                loading={"lazy"}
                src="/images/galleryScreen/Group  status.png"
                alt="hare Status"
                className="w-16"
              />
              <h3>
                <strong>Share Status</strong> <br /> Gallery supports Status
                functionality and it lets you find all your status videos and
                photos at one place. You can also share your favourite status
                via any sharing platform
              </h3>
            </div>
            <div className="flex items-center justify-center space-x-4">
              <img
                loading={"lazy"}
                src="/images/galleryScreen/internet.png"
                alt="In-built browser"
                className="w-16"
              />

              <h3>
                <strong>In-built browser</strong> <br /> To explore fast and
                secure browsing, Gallery is designed with in-built browser. You
                can explore many sites to enhance your browsing experience
              </h3>
            </div>
          </div>

          <a
            href="https://tinyurl.com/Rareprob-photosgallery"
            target={"_blank"}
            rel="noreferrer"
          >
            <img
              src="/images/homepage/google.webp"
              alt="google"
              className="w-32 hover:scale-105 mx-auto transition-all mt-10 mb-10 ease-linear   md:w-72 cursor-pointer "
            />
          </a>
        </div>
        <div className="h-44"></div>
        <NewFooter color="bg-black/10" />
      </main>
    </>
  );
};

export default GalleryScreen;
