import React from "react";
import shareImg from "./Images/images/share.svg";
const ShareButton = () => {
  const currentUrl = window.location.href;

  const share = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: document.title,
          text: "Check out this link:",
          url: currentUrl,
        });
      } catch (error) {
        console.error("Share failed:", error);
      }
    } else {
      alert("Web Share API is not supported in your browser.");
    }
  };

  return (
    <button onClick={share}>
      <img src={shareImg} alt="" />
    </button>
  );
};

export default ShareButton;
